import React, { FunctionComponent } from "react";
import GenericField, { GenericFieldProps } from "./GenericField";
import { formClasses } from "../../utils/BEM";

interface InputCheckboxFieldProps extends Omit<GenericFieldProps, 'control' | 'value' | 'onChange'> {
    inputLabel: string;
    value: boolean;
    onChange: (newValue: boolean) => void;
}

const InputCheckboxField: FunctionComponent<InputCheckboxFieldProps> = (props) => {
    const onChange = (change: string) => {
        props.onChange(change !== '1');
    };
    const value = props.value ? '1' : '';
    return <GenericField {...{...props, onChange, value}}
                         control={(controlProps) => {
                             const inputModifiers = {
                                 ...props.inputModifiers,
                                 disabled: !!controlProps.disabled
                             };
                             return (
                                 <div className={controlProps.className}>
                                     <div {...formClasses('checkbox-pair', inputModifiers)}>
                                         <input type={'checkbox'} {...{
                                             ...controlProps,
                                             ...formClasses('checkbox-pair-checkbox', inputModifiers),
                                             checked: props.value
                                         }} />
                                         <label
                                             htmlFor={controlProps.id} {...formClasses('checkbox-pair-label', inputModifiers)}>
                                             {props.inputLabel}
                                         </label>
                                     </div>
                                 </div>
                             );
                         }}
                         controlModifiers={{...props.controlModifiers, ...{'checkbox-list': true}}}
    />
};

export default InputCheckboxField;
