import { isNone } from './maybe';

export function parseIntSafe(text?: string, radix?: number): number | undefined {
    try {
        if (text == null) {
            return undefined;
        }
        const parsed = Number.parseInt(text, radix ?? 10);

        return Number.isInteger(parsed) ? parsed : undefined;
    } catch (e) {
        return undefined;
    }
}

export function parseFloatSafe(text?: string): number | undefined {
    try {
        if (text == null) {
            return undefined;
        }
        const parsed = Number.parseFloat(text);

        return Number.isNaN(parsed) ? undefined : parsed;
    } catch (e) {
        return undefined;
    }
}

export function safeNumber(number?: string | number | null, fallbackValue?: number): number {
    const fallback = fallbackValue ?? 0;
    try {
        if (isNone(number)) {
            return fallback;
        }
        if (typeof number === 'number') {
            return number;
        }
        const parsed = Number.parseFloat(number);
        return Number.isNaN(parsed) ? fallback : parsed;
    } catch (e) {
        return fallback;
    }
}

export function parseNumber(
    number: string | number | null | undefined,
    fallback?: number | undefined
): number | undefined {
    try {
        if (isNone(number)) {
            return fallback;
        }
        if (typeof number === 'number') {
            return number;
        }
        const parsed = Number.parseFloat(number);
        return Number.isNaN(parsed) ? fallback : parsed;
    } catch (e) {
        return fallback;
    }
}

export function safeNumberOrString(number?: string | number | null): number | string {
    const fallback = number ?? '';
    try {
        if (isNone(number)) {
            return fallback;
        }
        if (typeof number === 'number') {
            return number;
        }
        const parsed = Number.parseFloat(number);
        return Number.isNaN(parsed) ? fallback : parsed;
    } catch (e) {
        return fallback;
    }
}

export function limitNumber(value: number, params: { min?: number; max?: number }) {
    let result = value;
    if (params.min != null) {
        result = Math.max(result, params.min);
    }
    if (params.max != null) {
        result = Math.min(result, params.max);
    }
    return result;
}

export function roundNumber<T = number | undefined | null>(
    num: T,
    decimalPlaces: number = 0,
    mode: 'ceil' | 'floor' | 'round' = 'round'
): T {
    if (typeof num !== 'number') {
        return num;
    }
    const factor = Math.pow(10, decimalPlaces);
    const fn = Math[mode];
    //@ts-ignore
    return fn(num * factor) / factor;
}
