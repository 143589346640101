import React, { FunctionComponent, Suspense } from "react";
import LoadingScreenOverlay from "./components/LoadingScreenOverlay";
import { ADMIN_LINK_PREFIX, navigateA } from './Admin/Utils/LinkPrefix';
import { useCognitoSignedUserState } from './reducers/cognitoUserReducer';
import { tlbUserIsSignedIn, useAnyTlbUserState } from './reducers/tlbUserReducer';

type AppSelectorProps = {}

const AppSelector: FunctionComponent<AppSelectorProps> = () => {
    const user = useCognitoSignedUserState();
    const tlbUser = useAnyTlbUserState();
    const userGroups = user.groups;

    const TlbApp = React.lazy(() => import('./App'));
    const TechApp = React.lazy(() => import('./Technicians/TechnicianApp'))
    const OpsApp = React.lazy(() => import("./Admin/Admin"));

    const isInOps = window.location.pathname.startsWith(ADMIN_LINK_PREFIX);
    const isPilot = userGroups.includes('usr');
    const isTech = userGroups.includes('tch');

    if (!isInOps && !tlbUserIsSignedIn(tlbUser)) {
        navigateA('/');
    }
    return (
        <Suspense fallback={<LoadingScreenOverlay/>}>
            {isInOps ? (
                <OpsApp/>
            ) : (isTech && !isPilot) ? (
                <TechApp/>
            ) : (
                <TlbApp/>
            )}
        </Suspense>
    )

};

export default AppSelector;
