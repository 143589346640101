import { useSelector } from 'react-redux';
import { RootState } from '../rootReducer';

export type CognitoUserData = {
    groups: string[];
    id: string;
};
export type CognitoSignedUserState = {
    groups: string[];
    id: string;
    isAdmin: boolean;
    dataIsStale: boolean;
};

const LOCAL_STORAGE_KEY = 'alol_user_cognito';
export const COGNITO_USER_GROUP_ADMIN = 'adm';
export const COGNITO_USER_GROUP_TLB_USER = 'usr';
export const COGNITO_USER_GROUP_TECH = 'tch';
export const COGNITO_USER_GROUP_SALES = 'sls';
export const COGNITO_USER_GROUP_OPS = 'ops';

export const COGNITO_USER_GROUP_REPORT = 'rpt';

export const COGNITO_USER_STATE_SIGNED_OUT = 'signed-out';
export const COGNITO_USER_STATE_PROCESSING = 'processing';

type CognitoUnsignedUserState = typeof COGNITO_USER_STATE_SIGNED_OUT;
type CognitoProcessingUserState = typeof COGNITO_USER_STATE_PROCESSING;

export type CognitoUserState = CognitoSignedUserState | CognitoUnsignedUserState | CognitoProcessingUserState;

export const cognitoUserInitialState = getInitialState();

export const COGNITO_USER_SIGN_IN = 'COGNITO_USER_SIGN_IN';
export const COGNITO_USER_SIGN_PROCESS_START = 'COGNITO_USER_SIGN_PROCESS_START';
export const COGNITO_USER_SIGN_OUT = 'COGNITO_USER_SIGN_OUT';
export const COGNITO_USER_AUTH_ERROR = 'COGNITO_USER_AUTH_ERROR';
export type CognitoUserActionTypes =
    | {
          type: typeof COGNITO_USER_SIGN_IN;
          data: CognitoUserData;
      }
    | {
          type: typeof COGNITO_USER_SIGN_OUT | typeof COGNITO_USER_SIGN_PROCESS_START | typeof COGNITO_USER_AUTH_ERROR;
      };

export const CognitoUserReducer = (
    state: CognitoUserState | undefined,
    action: CognitoUserActionTypes | any
): CognitoUserState => {
    switch (action.type) {
        case COGNITO_USER_SIGN_IN:
            const data = action.data as CognitoSignedUserState;
            persistState(data);
            return {
                groups: data.groups.slice(),
                id: data.id,
                isAdmin: data.groups.includes(COGNITO_USER_GROUP_ADMIN),
                dataIsStale: false,
            };
        case COGNITO_USER_SIGN_OUT:
            clearState();
            return COGNITO_USER_STATE_SIGNED_OUT;
        case COGNITO_USER_SIGN_PROCESS_START:
            if (state && state !== COGNITO_USER_STATE_SIGNED_OUT) {
                return state;
            } else {
                return COGNITO_USER_STATE_PROCESSING;
            }
        case COGNITO_USER_AUTH_ERROR:
            if (state && 'object' === typeof state) {
                return state;
            } else {
                return COGNITO_USER_STATE_SIGNED_OUT;
            }
        default:
            return state || getInitialState();
    }
};

export const useCognitoSignedUserState = (): CognitoSignedUserState => {
    const state = useCognitoAnyUserState();
    if (!state || 'string' === typeof state) {
        throw new Error('User is not signed in');
    }
    return state;
};

export const useCognitoAnyUserState = (): CognitoUserState => {
    return useSelector<RootState, CognitoUserState>((state) => state.cognitoUser);
};

function persistState(state: CognitoSignedUserState) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
}

function clearState() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

function getInitialState(): CognitoUserState {
    try {
        const maybeLocalStorageData = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (maybeLocalStorageData) {
            const data: CognitoSignedUserState = JSON.parse(maybeLocalStorageData);
            return {
                groups: data.groups.slice(),
                id: data.id,
                isAdmin: data.groups.includes(COGNITO_USER_GROUP_ADMIN),
                dataIsStale: true,
            };
        } else {
            return COGNITO_USER_STATE_PROCESSING;
        }
    } catch (e) {
        console.error('Error reloading Cognito User State', e);
        return COGNITO_USER_STATE_PROCESSING;
    }
}
