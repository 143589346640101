import { Auth } from 'aws-amplify';
import { COGNITO_USER_SIGN_OUT } from '../../reducers/cognitoUserReducer';
import { store } from '../../wrappers/WithStore';
import { TLB_USER_SIGN_OUT } from '../../reducers/tlbUserReducer';

let reauthFlowPromise: Promise<void> | null = null;

export function initReauthFlow() {
    if (!reauthFlowPromise) {
        reauthFlowPromise = new Promise<void>(async (resolve, reject) => {
            try {
                console.log('reauth');
                await Auth.currentAuthenticatedUser();
                resolve();
            } catch (e) {
                if (e === "not authenticated") {
                    store.dispatch({type: TLB_USER_SIGN_OUT});
                    store.dispatch({type: COGNITO_USER_SIGN_OUT});
                    resolve();
                    return;
                }
                reject(e);
            }
        });
        reauthFlowPromise.finally(() => {
            console.log('reauth reset');
            reauthFlowPromise = null;
        })
    }
    return reauthFlowPromise;
}
