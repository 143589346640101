import { Currency } from 'utils/currency';
import { isNone, safeNumberOrString } from '../../../utils';
import { ManagementFees, PricingProfile, LeaseCharges } from './AircraftPricing';

export type PerformanceProfile = {
    weight_emptyMass_lbs?: number;
    weight_cgArm_lbs?: number;
    weight_maxZeroFuelMass_lbs?: number;
    weight_maxTakeOffMass_lbs?: number;
    weight_maxLandingMass_lbs?: number;
    weight_crewWeight_lbs?: number;
    weight_standardMale_lbs?: number;
    weight_standardFemale_lbs?: number;
    weight_standardChild_lbs?: number;
    weight_cateringEquipment_lbs?: number;
    weight_maximumPassengers_count?: number;
    weight_minimumCrew_count?: number;
    weight_maximumCargo_lbs?: number;
    weight_unit?: 'kgs' | 'lbs';
    //
    fuel_type?: 'jet' | 'avgas';
    fuel_unit?: 'kgs' | 'lbs' | 'liters' | 'usg';
    fuel_maximumFuel_unit?: number;
    fuel_taxiFuel_lbs?: number;
    fuel_consumption1stHour_unit?: number;
    fuel_consumption2ndHourPlus_unit?: number;
    fuel_standardFuelReserve_unit?: number;
    //
    perf_climbSpeed_ktas?: number;
    perf_climbTime_minutes?: number;
    perf_cruiseSpeed_ktas?: number;
    perf_landingTime_minutes?: number;
    perf_flightTimeReserve_percent?: number;
    perf_cruiseLevel_ft?: number;
    //
    runway_type?: 'paved' | 'pavedPlusGrass';
    runway_nonCommercialMinimum_meters?: number;
    runway_commercialMinimum_meters?: number;
    //
    pricing_hourlyRate?: number;
    pricing_hourlyRateCurrency?: Currency;
    pricing_minimumHoursPerDay?: number;
    pricing_minimumHoursPerDayCurrency?: Currency;
    pricing_airplaneWaitPerDay?: number;
    pricing_airplaneWaitPerDayCurrency?: Currency;
    pricing_landingFeePerLeg?: number;
    pricing_landingFeePerLegCurrency?: Currency;
    pricing_approachFeePerLeg?: number;
    pricing_approachFeePerLegCurrency?: Currency;
    pricing_crewFlyingDayPerDay?: number;
    pricing_crewFlyingDayPerDayCurrency?: Currency;
    pricing_crewNonFlyingDayPerDay?: number;
    pricing_crewNonFlyingDayPerDayCurrency?: Currency;
    pricing_crewOvernightPerNight?: number;
    pricing_crewOvernightPerNightCurrency?: Currency;
    pricing_paxFeePerPaxPerLeg?: number;
    pricing_paxFeePerPaxPerLegCurrency?: Currency;
    pricing_ferryRate?: number;
    pricing_ferryRateCurrency?: Currency;
    pricing_dryHourCost?: number;
    pricing_dryHourCostCurrency?: Currency;
    pricing_ferryHourDryCost?: number;
    pricing_ferryHourDryCostCurrency?: Currency;
    pricing_eurocontrol?: number;
    pricing_eurocontrolCurrency?: Currency;
    pricing_copilotFlyingDay?: number;
    pricing_copilotFlyingDayCurrency?: Currency;
    pricing_copilotNonflyingDay?: number;
    pricing_copilotNonflyingDayCurrency?: Currency;
    pricing_copilotPerHour?: number;
    pricing_copilotPerHourCurrency?: Currency;
    pricing_commanderPerHour?: number;
    pricing_commanderPerHourCurrency?: Currency;
    //
    crewProfile?: 'perhour' | 'perday' | 'dayandhour';
    //
    ownerApproval?: 'yes' | 'no';
    exportEmptyLegs?: 'yes' | 'no';
    dispatchGroup?: '1' | '2';

    pricing?: PricingProfile;
    managementFees?: ManagementFees;
    leaseCharges?: LeaseCharges;
    ownerId?: string;
    customerAccountId?: string;
};

// Which keys handle as a string. All others will be a number.
export const PERF_PROFILE_STRING_KEYS: Array<keyof PerformanceProfile> = [
    'fuel_type',
    'fuel_unit',
    'runway_type',
    'ownerApproval',
    'weight_unit',
];

export function parsePerformanceProfileJson(profile: string | null | undefined): PerformanceProfile | undefined {
    if (isNone(profile)) {
        return undefined;
    }
    try {
        return JSON.parse(profile, (key, value: string) => {
            const castedKey = key as keyof PerformanceProfile;
            if (PERF_PROFILE_STRING_KEYS.includes(castedKey)) {
                return value;
            } else {
                return safeNumberOrString(value);
            }
        });
    } catch (e) {
        return undefined;
    }
}
