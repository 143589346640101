import { AircraftForFlight } from "../AircraftsDatasource";
import { DbSaveable } from "../LocalDB/types";
import * as uuid from "uuid";
import { flightReleaseToReleaseToService, FlightReleaseToService, ReleaseToService } from "../ReleaseToService/types";
import { HandSignature } from '../util/SignatureTypes';
import { FlightDocument } from '../FlightDocuments/FlightDocument';

export type Squawk = {
    id: string;
    aircraftId: string;
    flightId?: string;
    legId?: string;
    createdAt: Date;
    createdByName: string;
    description: string;
    airworthinessAffected: boolean;
    reviewedDate: string | null;
    reviewedName: string | null;
    reviewedId: string | null;
    editableByUserIds: string[];
    releaseDate: Date | null;
    releaseAircraftFlightTime: number | null;
    releaseName: string | null;
    releaseLicenseNumber: string | null;
    releaseText: string | null;
    releaseSignature: HandSignature | null;
} & DbSaveable;

export type SquawkDeferral = {
    id: string;
    squawkId: string;
    editableByUserIds: string[];
    createdAt: Date;
    createdByName: string | null;
    createdByLicenseNumber: string | null;
    createdBySignature: HandSignature | null;
    deferredUntil: Date;
    actionsRequired: SquawkDeferralActionRequired[];
    actionPerformedText: string | null;
    melType: string | null;
    text: string | null;
} & DbSaveable;

export type SquawkDeferralAndReleases = SquawkDeferral & {
    releases: ReleaseToService[];
}

export const SQUAWK_DEFERRAL_ACTION_OPERATIONAL = 'operational';
export const SQUAWK_DEFERRAL_ACTION_MAINTENANCE = 'maintenance';
export type SquawkDeferralActionRequired = typeof SQUAWK_DEFERRAL_ACTION_MAINTENANCE
    | typeof SQUAWK_DEFERRAL_ACTION_OPERATIONAL;

export type SquawkAndDeferrals = Squawk & {
    deferrals: SquawkDeferralAndReleases[],
    releases: ReleaseToService[],
}
export type SquawkAndAircraft = Squawk & {
    documents: FlightDocument[];
    deferrals: SquawkDeferralAndReleases[];
    releases: ReleaseToService[];
    aircraft: AircraftForFlight;
}

export const flightSquawksToSquawks = (flightSquawks: FlightSquawk[], editableByUserIds: string[]): [Squawk[], SquawkDeferral[], FlightDocument[], ReleaseToService[]] => {
    const allSquawks: Squawk[] = [];
    const allDocuments: FlightDocument[] = [];
    const allDeferrals: SquawkDeferral[] = [];
    const allReleases: ReleaseToService[] = [];
    flightSquawks.forEach(flightSquawk => {
        const {documents, deferrals, releases, ...squawkParams} = flightSquawk;
        const squawk: Squawk = {
            ...squawkParams,
            editableByUserIds
        };
        releases.forEach(flightRelease => {
            const [deferalRelease, releaseDocuments] = flightReleaseToReleaseToService(flightRelease);
            deferalRelease.editableByUserIds = editableByUserIds.slice();
            allReleases.push(deferalRelease);
            documents.push(...releaseDocuments);
        });
        documents.forEach(doc => doc.editableByUserIds = editableByUserIds.slice());
        allDocuments.push(...documents);
        allSquawks.push(squawk);
        const [newDeferrals, newDeferralDocs, deferralReleases] = flightDeferralToDeferral(deferrals, editableByUserIds);
        allDocuments.push(...newDeferralDocs);
        allDeferrals.push(...newDeferrals);
        allReleases.push(...deferralReleases);
    });
    return [allSquawks, allDeferrals, allDocuments, allReleases];
};

export const flightDeferralToDeferral = (flightDeferrals: FlightSquawkDeferral[], editableByUserIds: string[]): [SquawkDeferral[], FlightDocument[], ReleaseToService[]] => {
    const allDeferrals: SquawkDeferral[] = [];
    const allDocuments: FlightDocument[] = [];
    const allReleases: ReleaseToService[] = [];
    flightDeferrals.forEach(flightDeferral => {
        const {documents, releases, ...squawkDeferralParams} = flightDeferral;
        const squawkDeferral: SquawkDeferral = {
            ...squawkDeferralParams,
            editableByUserIds: editableByUserIds.slice()
        };
        releases.forEach(flightRelease => {
            const [deferalRelease, releaseDocuments] = flightReleaseToReleaseToService(flightRelease);
            deferalRelease.editableByUserIds = editableByUserIds.slice();
            allReleases.push(deferalRelease);
            documents.push(...releaseDocuments);
        });
        documents.forEach(d => d.editableByUserIds = editableByUserIds.slice());
        allDeferrals.push(squawkDeferral);

        allDocuments.push(...documents);
    });
    return [allDeferrals, allDocuments, allReleases];

};
export type FlightSquawk = Omit<Squawk, 'editableByUserIds'> & {
    documents: FlightDocument[],
    deferrals: FlightSquawkDeferral[],
    releases: FlightReleaseToService[],
}

export type FlightSquawkDeferral = Omit<SquawkDeferral, 'editableByUserIds'> & {
    documents: FlightDocument[],
    releases: FlightReleaseToService[],
}

export type EmptySquawkProps = {
    aircraftId: string;
    flightId?: string;
    legId?: string;
    picName?: string;
};

export const createEmptySquawk = ({aircraftId, flightId, legId, picName}: EmptySquawkProps): FlightSquawk => ({
    id: uuid.v4(),
    aircraftId,
    flightId,
    legId,
    createdAt: new Date(),
    createdByName: picName || "",
    description: "",
    airworthinessAffected: false,
    documents: [],
    deferrals: [],
    releases: [],
    deleted: false,
    reviewedDate: null,
    reviewedName: null,
    reviewedId: null,
    releaseDate: null,
    releaseAircraftFlightTime: null,
    releaseName: null,
    releaseLicenseNumber: null,
    releaseText: null,
    releaseSignature: null,
});

export type EmptySquawkDeferralProps = {
    squawkId: string;
    createdByName?: string;
}
export const createEmptySquawkDeferral = ({squawkId, createdByName}: EmptySquawkDeferralProps): FlightSquawkDeferral => {
    return {
        id: uuid.v4(),
        squawkId,
        createdAt: new Date(),
        createdByName: createdByName || "",
        createdByLicenseNumber: "",
        createdBySignature: null,
        deferredUntil: new Date(),
        melType: null,
        text: null,
        actionsRequired: [],
        actionPerformedText: null,
        deleted: false,
        documents: [],
        releases: [],
    }
};
