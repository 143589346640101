import { CreateSquawkDeferralInput } from "../../../API";
import { SquawkDeferral, SquawkDeferralActionRequired } from "../../Squawks/types";
import { DbSyncerUpDefinition, getStandardSyncerFields } from "../syncHelpers";

export const squawkDeferralItemFields = `
    id
    deleted
    editableByUserIds
    squawkId
    createdAt
    createdByName
    createdByLicenseNumber
    createdBySignature
    text
    deferredUntil
    melType
    actionsRequired
    actionPerformedText
    _version
`;


let counter = 0;

export const squawkDeferralToDb: DbSyncerUpDefinition = (squawkDeferral: SquawkDeferral) => {
    const c = ++counter;
    const [commandName, inputType] = !squawkDeferral._version
        ? ['createSquawkDeferral', 'CreateSquawkDeferralInput']
        : ['updateSquawkDeferral', 'UpdateSquawkDeferralInput'];
    const input: CreateSquawkDeferralInput = {
        id: squawkDeferral.id,
        deleted: squawkDeferral.deleted,
        squawkId: squawkDeferral.squawkId,
        createdAt: squawkDeferral.createdAt.toISOString(),
        createdByName: squawkDeferral.createdByName,
        createdByLicenseNumber: squawkDeferral.createdByLicenseNumber,
        createdBySignature: squawkDeferral.createdBySignature ? JSON.stringify(squawkDeferral.createdBySignature) : null,
        text: squawkDeferral.text,
        deferredUntil: squawkDeferral.deferredUntil.toISOString(),
        melType: squawkDeferral.melType,
        actionsRequired: squawkDeferral.actionsRequired.length ? squawkDeferral.actionsRequired : null,
        actionPerformedText: squawkDeferral.actionPerformedText,
        _version: squawkDeferral._version
    };
    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, squawkDeferralItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: squawkDeferralFromDb,
        queryName: 'getSquawkDeferral'
    }
};

export const squawkDeferralFromDb = (input: CreateSquawkDeferralInput): SquawkDeferral => {
    return {
        id: input.id!,
        editableByUserIds: input.editableByUserIds || [],
        createdAt: new Date(input.createdAt!),
        createdByName: input.createdByName || null,
        createdByLicenseNumber: input.createdByLicenseNumber || null,
        createdBySignature: input.createdBySignature ? JSON.parse(input.createdBySignature) : null,
        text: input.text || null,
        deleted: input.deleted,
        melType: input.melType || null,
        actionsRequired: input.actionsRequired ? (input.actionsRequired as SquawkDeferralActionRequired[]) : [],
        actionPerformedText: input.actionPerformedText || null,
        squawkId: input.squawkId,
        _version: input._version!,
        deferredUntil: new Date(input.deferredUntil),
    }
};
