export const CREW_ROLE_PF = 'PF';
export const CREW_ROLE_PM = 'PM';

export const CREW_ROLE_FI = 'FI';
export const CREW_ROLE_FE = 'FE';

export const CREW_ROLE_LTI = 'LTI';
export const CREW_ROLE_LTE = 'LTE';

export const CREW_ROLE_STUDENT_SOLO = 'STUDENT-SOLO';
export const CREW_ROLE_STUDENT_DUAL = 'STUDENT-DUAL';
export const CREW_ROLE_SPIC = 'SPIC';
export const CREW_ROLE_PICUS = 'PICUS';
export const CREW_ROLE_SICUS = 'SICUS';
export const CREW_ROLE_SUPERVISING_INSTRUCTOR = 'SUPERVISING-INSTRUCTOR';

export type CrewRoleTypes =
    | typeof CREW_ROLE_PF
    | typeof CREW_ROLE_PM
    | typeof CREW_ROLE_FI
    | typeof CREW_ROLE_FE
    | typeof CREW_ROLE_LTI
    | typeof CREW_ROLE_LTE
    | typeof CREW_ROLE_STUDENT_SOLO
    | typeof CREW_ROLE_STUDENT_DUAL
    | typeof CREW_ROLE_SPIC
    | typeof CREW_ROLE_PICUS
    | typeof CREW_ROLE_SICUS
    | typeof CREW_ROLE_SUPERVISING_INSTRUCTOR;

export const getAllRoles = (): CrewRoleTypes[] => [
    CREW_ROLE_PF,
    CREW_ROLE_PM,
    CREW_ROLE_FI,
    CREW_ROLE_FE,
    CREW_ROLE_LTI,
    CREW_ROLE_LTE,
    CREW_ROLE_STUDENT_SOLO,
    CREW_ROLE_STUDENT_DUAL,
    CREW_ROLE_SPIC,
    CREW_ROLE_PICUS,
    CREW_ROLE_SICUS,
    CREW_ROLE_SUPERVISING_INSTRUCTOR,
];

export const getPfPmRoles = (): CrewRoleTypes[] => [CREW_ROLE_PF, CREW_ROLE_PM];
export const getPicRoles = (isAto: boolean, isCatAble: boolean): CrewRoleTypes[] => {
    if (isAto) {
        return [CREW_ROLE_STUDENT_SOLO, CREW_ROLE_FI, CREW_ROLE_FE];
    } else if (isCatAble) {
        return [CREW_ROLE_LTI, CREW_ROLE_LTE];
    } else {
        return [];
    }
};
export const getSicRoles = (isAto: boolean, picRoles: CrewRoleTypes[], isMcc: boolean): CrewRoleTypes[] => {
    if (isAto) {
        if (picRoles.some(isRoleInstructorLike)) {
            return [CREW_ROLE_STUDENT_DUAL, CREW_ROLE_SPIC];
        } else if (picRoles.includes(CREW_ROLE_STUDENT_SOLO)) {
            return [CREW_ROLE_SUPERVISING_INSTRUCTOR];
        } else {
            return [];
        }
    } else if (picRoles.some(isRoleLineTrainingInstructorLike)) {
        if (isMcc) {
            return [CREW_ROLE_PICUS, CREW_ROLE_SICUS];
        } else {
            return [CREW_ROLE_PICUS];
        }
    } else {
        return [];
    }
};

export const isRoleInstructorLike = (role: CrewRoleTypes): boolean => {
    return [CREW_ROLE_FI, CREW_ROLE_FE].includes(role);
};

export const isRoleLineTrainingInstructorLike = (role: CrewRoleTypes): boolean => {
    return [CREW_ROLE_LTI, CREW_ROLE_LTE].includes(role);
};
