import { DbSaveable } from "../LocalDB/types";
import * as uuid from "uuid";
import { HandSignature } from '../util/SignatureTypes';
import { FlightDocument } from '../FlightDocuments/FlightDocument';

export type ReleaseToService = DbSaveable & {
    id: string;
    aircraftId: string;
    squawkId: string | null;
    deferralId: string | null;
    createdAt: Date;
    releaseType: ReleaseToServiceType;
    releasedByName: string | null;
    releasedByLicenseNumber: string | null;
    releasedBySignature: HandSignature | null;
    releasedByOrganization: string | null;
    maintenanceRecordId: string | null;
    text: string | null;
    aircraftFlightTime: number;
};

export const RELEASE_TYPE_IN_APP = 'in-app';
export const RELEASE_TYPE_BY_DOCUMENT = 'by-document';
export type ReleaseToServiceType = typeof RELEASE_TYPE_IN_APP | typeof RELEASE_TYPE_BY_DOCUMENT;

export type EmptyReleaseToServiceProps =
    Partial<ReleaseToService>
    & Pick<ReleaseToService, 'aircraftId' | 'releaseType' | 'aircraftFlightTime'>;
export const createEmptyReleaseToService = (props: EmptyReleaseToServiceProps): ReleaseToService => {
    return {
        id: uuid.v4(),
        deleted: false,
        aircraftId: props.aircraftId,
        squawkId: props.squawkId || null,
        deferralId: props.deferralId || null,
        maintenanceRecordId: props.maintenanceRecordId || null,
        createdAt: props.createdAt || new Date(),
        releaseType: props.releaseType,
        releasedByName: props.releasedByName || null,
        releasedByLicenseNumber: props.releasedByLicenseNumber || null,
        releasedBySignature: props.releasedBySignature || null,
        releasedByOrganization: props.releasedByOrganization || null,
        text: props.text || null,
        aircraftFlightTime: props.aircraftFlightTime,
    }
};

export type FlightReleaseToService = Omit<ReleaseToService, 'editableByUserIds'> & {
    documents: FlightDocument[]
}

export const createEmptyFlightReleaseToService = (props: EmptyReleaseToServiceProps): FlightReleaseToService => {
    const release = createEmptyReleaseToService(props);
    return {
        ...release,
        documents: []
    }
};

export const flightReleaseToReleaseToService = (flightReleaseToService: FlightReleaseToService): [ReleaseToService, FlightDocument[]] => {
    const {documents, ...release} = flightReleaseToService;
    return [release, documents];
};
