export type Resource<T> = {
    read: () => T;
    readMaxQuality: () => T;
    refresh: () => void;
    loadMore: () => Promise<boolean>; // true if next data is still available ("load more" button shouldn't be disabled
    promise: Promise<T>;
    sourceQualityIndex: number;
    maxQualityIndex: number;
    refreshId: number;
    getStatus: () => PromiseStatus;
    getCreationTime: () => number;
    getResolveTime: () => number | null;
}
type PromiseStatus = "pending" | "success" | "refreshing" | "error";

export const wrapPromise = <T>(promise: Promise<T>,
                               refreshRequested: () => void,
                               onLoadMoreRequested: () => Promise<boolean>,
                               sourceQualityIndex: number,
                               maxQualityIndex: number,
                               refreshId: number): Resource<T> => {
    let status: PromiseStatus = "pending";
    let result: T | any;
    let creationTime = Date.now();
    let resolveTime: number | null = null;

    let suspender = promise.then(
        r => {
            status = "success";
            result = r;
            resolveTime = Date.now();
        },
        e => {
            status = "error";
            result = e;
            resolveTime = Date.now();
        }
    );

    return {
        read(): T {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw (result);
            } else if (status === "success" || status === "refreshing") {
                return (result as T);
            }
            throw new Error("Unspecified status");
        },
        readMaxQuality(): T {
            if (sourceQualityIndex === maxQualityIndex) {
                return this.read();
            } else {
                throw suspender;
            }
        },
        refresh: () => {
            if (status === 'success') {
                status = 'refreshing';
                return refreshRequested()
            }
        },
        loadMore: onLoadMoreRequested,
        promise,
        sourceQualityIndex,
        maxQualityIndex,
        refreshId,
        getStatus: () => status,
        getCreationTime: () => creationTime,
        getResolveTime: () => resolveTime,
    };
};

export const wrapVariable = <T>(input: T): Resource<T> => {
    const read = () => input;
    const now = Date.now();
    return {
        read,
        readMaxQuality: read,
        refresh: () => null,
        loadMore: async () => false,
        promise: Promise.resolve(input),
        sourceQualityIndex: 99,
        maxQualityIndex: 99,
        refreshId: 0,
        getStatus: () => 'success',
        getCreationTime: () => now,
        getResolveTime: () => now,
    }
};

export const loadMoreNotSupported = (): Promise<boolean> => {
    throw new Error('Load more not supported on this method');
}
