import React from "react";
import { formClasses } from '../../utils/BEM';
import InputTextField from '../../components/FormFields/InputTextField';
import InputCheckboxField from '../../components/FormFields/InputCheckboxField';

export type LoginFormData = {
    username: string;
    password: string;
    rememberPassword: boolean;
};
type LoginFormProps<T> = {
    busy: boolean;
    formData: T;
    setFormData: (formData: T) => void;

}

function LoginForm<T extends LoginFormData>({busy, formData, setFormData}: LoginFormProps<T>) {
    return (
        <>
            <InputTextField label={'E-Mail'}
                            type={'email'}
                            name={'username'}
                            autoComplete={'username'}
                            value={formData.username}
                            onChange={username => setFormData({...formData, username})}
            />
            <InputTextField label={'Password'}
                            type={'password'}
                            name={'password'}
                            autoComplete={'current-password'}
                            value={formData.password}
                            onChange={password => setFormData({...formData, password})}
            />
            <div {...formClasses('fieldlike')}>
                <InputCheckboxField fieldModifiers={{'single-line': true}}
                                    inputLabel={formData.rememberPassword ? 'Yes' : 'No'}
                                    value={formData.rememberPassword}
                                    onChange={(rememberPassword) => setFormData({...formData, rememberPassword})}
                                    label={'Remember password'}/>
            </div>
            <div {...formClasses('fieldlike', undefined, 'text-end')} >
                <button {...formClasses('button')} disabled={busy}>Log in</button>
            </div>
        </>
    );
}

export default LoginForm;
