import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { formClasses } from "../../utils/BEM";
import { useId } from "../../utils/idGenerator";
import BEMHelper from "react-bem-helper";
import Suggester, { Suggestable } from "./Suggester";
import * as accents from "remove-accents";
import { FORM_SEVERITY_CAUTION, FormErrorLike, getFormErrorSeverity } from './FormErrors';
import FieldErrorMessage from './FieldErrorMessage';
import { ColorKey, COLORS } from "stylekit";
import classNames from "classnames";

export interface GenericFieldProps {
    label: string | null;
    tinyLabel?: string;
    placeholder?: string;
    idPrefix?: string;
    defaultSmall?: boolean;
    defaultTiny?: boolean;
    onChange?: (newValue: string) => void;
    onChangeComplete?: (newValue: string) => void;
    control: (props: PropsPassedToControl) => JSX.Element;
    disabled?: boolean;
    controlModifiers?: BEMHelper.PredicateSet;
    fieldModifiers?: BEMHelper.PredicateSet;
    inputModifiers?: BEMHelper.PredicateSet;
    autoFocus?: boolean;
    value?: string;
    suggestions?: Suggestable[];
    suggestionsNegativeTabIndex?: boolean;
    errorClass?: boolean;
    error?: FormErrorLike;
    forceErrorDisplay?: boolean;
    autoComplete?: 'on' | 'off' | 'username' | 'current-password';
    name?: string;
    bgColor?: ColorKey;
    pointerEventsOff?: boolean;
}

export type PropsPassedToControl = {
    className: string;
    value: string;
    id: string;
    onChange: (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    onFocus: () => void;
    onBlur: () => void;
    autoFocus?: boolean;
    disabled?: boolean;
    error?: FormErrorLike;
    placeholder?: string;
}

const GenericField: FunctionComponent<GenericFieldProps> = (props) => {
    const [innerValue, setInnerValue] = useState(props.value || "");
    const value = props.value !== undefined ? props.value : innerValue;
    const [mutated, setMutated] = useState(false);
    const [focused, setFocused] = useState(false);
    const [focusedCount, setFocusedCount] = useState(0);
    const [blurredCount, setBlurredCount] = useState(0);
    const [touched, setTouched] = useState(false);
    const onChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        onNewValue(evt.target.value);
    };
    const onNewValue = (newValue: string) => {
        setInnerValue(newValue);
        if (!mutated) {
            setMutated(true);
        }
        props.onChange && props.onChange(newValue);
    };
    const onFocus = () => {
        setFocused(true);
        setFocusedCount(focusedCount + 1);
        setTouched(true);
    };
    const onBlur = () => {
        setFocused(false);
        setBlurredCount(blurredCount + 1);
        props.onChangeComplete && props.onChangeComplete(value);
    };
    const [id] = useId(props.idPrefix);
    const tiny = !!(props.defaultTiny && (!focused && !innerValue));
    const small = !!(props.defaultSmall && (!focused && !innerValue));
    const label = tiny && props.tinyLabel ? props.tinyLabel : props.label;
    const canShowErrors = blurredCount > 0 || props.forceErrorDisplay;
    const showErrors = canShowErrors && (props.error || props.errorClass);
    const errorIsCaution = props.error && getFormErrorSeverity(props.error) === FORM_SEVERITY_CAUTION;
    const modifiers = {
        ...props.fieldModifiers,
        mutated,
        focused,
        touched,
        tiny,
        small,
        'no-label': label === null,
        error: !!(showErrors),
        'error-caution': !!(showErrors && errorIsCaution),
    };

    const onSuggestionSelected = (suggestion: string) => {
        setTouched(true);
        onNewValue(suggestion);
        props.onChangeComplete && props.onChangeComplete(suggestion);
    };

    return (
        <div className={classNames({...formClasses('field', {...modifiers})}.className, {
            'pe-none': props.pointerEventsOff,
        })}
            data-has-error={props.error ? getFormErrorSeverity(props.error) : undefined}
        >
            {label !== null && (
                <div {...formClasses('field-label')}>
                    <label htmlFor={id}>{label}</label>
                </div>
            )}
            <div {...formClasses('field-input', props.inputModifiers)}>
                {props.control({
                    ...formClasses('control', {...modifiers, ...props.controlModifiers}),
                    ...{
                        onChange,
                        value,
                        onFocus,
                        onBlur,
                        id,
                        autoFocus: props.autoFocus,
                        disabled: props.disabled,
                        placeholder: props.placeholder,
                        autoComplete: props.autoComplete,
                        name: props.name,
                        style: {
                            backgroundColor: props.bgColor ? COLORS[props.bgColor ?? ''] : undefined,
                        },
                    }
                })}
                {canShowErrors && (
                    <FieldErrorMessage error={props.error}/>
                )}
                {(props.suggestions && props.suggestions.length > 0) && (
                    <Suggester options={props.suggestions}
                               onSelected={onSuggestionSelected}
                               negativeTabIndex={props.suggestionsNegativeTabIndex}
                    />
                )}
            </div>

        </div>
    )
};

export const standardSuggesterFilter = (currentValue: string, availableValues: string[]): string[] => {
    if (currentValue.length < 2) {
        return [];
    }
    const inputClean = accents.remove(currentValue).toLowerCase();
    const regex = new RegExp(`\\b${inputClean}`, 'i');
    return availableValues.filter(av => {
        const clean = accents.remove(av).toLowerCase();
        return clean.match(regex) && av !== currentValue;
    });
};

export default GenericField;
