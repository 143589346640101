import { FlightOps } from './FlightOpsTypes';
import { CREW_ROLE_SUPERVISING_INSTRUCTOR, CrewRoleTypes } from './CrewRoleTypes';
import { CountableChange } from '../CountableChanges/types';
import { DbSaveable } from '../LocalDB/types';
import { HandSignature } from '../util/SignatureTypes';
import { InLegLogbook } from '../Logbook/InLegLogbook';
import { InputSources } from '../../utils/InputSources';

export const FLIGHT_LEG_PHASE_START = 'start';
export const FLIGHT_LEG_PHASE_FINISH = 'end';

export type FlightLegPhase = typeof FLIGHT_LEG_PHASE_START | typeof FLIGHT_LEG_PHASE_FINISH;

export interface StartedFlightLeg extends DbSaveable {
    id: string;
    createdAt: Date;
    flightId: string;
    aircraftId: string;
    airportOffIcao: string;
    ops: FlightOps | undefined;
    securityCheckDate: Date | null;
    securityCheckName: string | null;
    preflightCheckDate: Date | null;
    preflightCheckName: string | null;
    picName: string;
    picId: string | null;
    picRoles: CrewRoleTypes[];
    sicName: string | null;
    sicId: string | null;
    sicRoles: CrewRoleTypes[];
    deicing: Deicing | null;
    notes: string;
    startSignature: HandSignature | null;
    phase: FlightLegPhase;
    startedAt: Date;
    upliftCountableChangeId: string;
    editableByUserIds: string[];
    inputSources: InputSources;
    timeOut: Date; // so that secondary index on DynamoDB table works
    reservationId?: string;
    reservationEventId?: string;
    ticketId?: string | null;
}

export type WithLastCountableChange = {
    lastCountableChange: CountableChange;
};

export interface FinishedFlightLeg extends StartedFlightLeg {
    phase: typeof FLIGHT_LEG_PHASE_FINISH;
    airportOnIcao: string;
    timeOff: Date;
    timeOn: Date;
    timeIn: Date;
    logbookFields: InLegLogbook | null;
    finishNotes: string;
    finishSignature: HandSignature | null;
    consumptionCountableChangeId: string;
}

export type FlightLeg = StartedFlightLeg | FinishedFlightLeg;
export const isLegFinished = (leg: FlightLeg): leg is FinishedFlightLeg => leg.phase === FLIGHT_LEG_PHASE_FINISH;

export type Deicing = {
    startTime: Date | null;
    fluidType: string;
    ratio: string;
    holdOverTime: Date | null;
};

export function isSupervisingInstructorOnLeg(leg: FinishedFlightLeg, crewId: string | undefined): boolean {
    return leg.sicId === crewId && leg.sicRoles && leg.sicRoles.includes(CREW_ROLE_SUPERVISING_INSTRUCTOR);
}
