import { CompleteFlight } from '../Flights/FlightsDatasource';
import { CreateLogbookRecordInput } from '../../API';
import { WithId } from '../LocalDB/types';
import { isLegFinished } from '../FlightLegs/FlightLeg';
import { ContactInput } from '../../APIExtensions';
import { getLogbookRecordsByLegId, putLogbookRecord } from '../LogbookRecords/LogbookRecordsDatasource';
import { inLegLogbookToRecord } from './InLegLogbook';
import * as uuid from 'uuid';
import { getContactByCrewId } from '../Contacts/ContactsDatasource';
import { toMap } from '../../utils/arrayUtils';

export async function saveFlightLogbookRecords(flight: CompleteFlight): Promise<void> {
    const getContactCache = new Map<string, Promise<ContactInput | null>>();
    const getCachedContact = (crewId: string): Promise<ContactInput | null> => {
        let maybePromise = getContactCache.get(crewId);
        if (!maybePromise) {
            maybePromise = getContactByCrewId(crewId);
            getContactCache.set(crewId, maybePromise);
        }
        return maybePromise;
    }
    await Promise.all(flight.legs.map(async leg => {
        if (!isLegFinished(leg)) {
            return;
        }
        if (!leg.logbookFields) {
            return;
        }
        const existingLogbookRecords = await getLogbookRecordsByLegId(leg.id);
        const existingRecordContactIds = existingLogbookRecords.map(lr => lr.contactId);
        const crewIds = [leg.picId, leg.sicId]
            .filter(Boolean) as string[];
        const contacts = (await Promise.all(crewIds.map(getCachedContact)))
            .filter(Boolean) as ContactInput[];
        const contactIds = contacts.map(c => c.id);
        const userIds = contacts.map(c => c.userId).filter(Boolean) as string[];
        const contactsMap = toMap(contacts);
        const promises: Promise<void>[] = [];
        for (const contactId of contactIds) {
            if (existingRecordContactIds.includes(contactId)) {
                continue;
            }
            const crewId = contactsMap.get(contactId)?.crewId;
            if (!crewId) {
                continue;
            }
            const record: CreateLogbookRecordInput & WithId = {
                ...inLegLogbookToRecord(leg.logbookFields, leg),
                id: uuid.v4(),
                contactId,
                crewId,
                editableByUserIds: userIds,
            };
            promises.push(putLogbookRecord(record));
        }
        await Promise.all(promises);
    }));

}
