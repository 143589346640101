export type Some<T> = NonNullable<T>;
export type None = null | undefined;
export type Maybe<T> = T | None;

/**
 * Checks if a value is not null or undefined.
 */
export function isSome<T>(value: T): value is Some<T> {
    return value !== null && value !== undefined;
}
/**
 * Checks if value is null or undefined.
 */
export function isNone(value: unknown): value is None {
    return value === null || value === undefined;
}
