import { CreateSigningKeyChangeInput } from '../../API';
import { getDb } from '../LocalDB/LocalDB';
import { getDbSyncer } from '../LocalDB/DbSyncer';
import { WithId } from '../LocalDB/types';

export const putSigningChange = async (input: CreateSigningKeyChangeInput & WithId) => {
    const table = getDb().signingKeyChanges;
    await table.put(input);
    await getDbSyncer().markToSync(table, input.id);
};
