import Dexie from 'dexie';

export const getStandardItemHandler = <T extends { id: string, deleted: boolean }>(table: Dexie.Table, transform?: (input: any) => any): (items: T[]) => Promise<void> => {
    return async (items: T[]) => {
        const toDelete = items.filter(i => i.deleted);
        let toPut = items.filter(i => !i.deleted);
        if (transform) {
            toPut = toPut.map(transform);
        }
        await Promise.all([
            table.bulkDelete(toDelete.map(i => i.id)),
            table.bulkPut(toPut)
        ]);
    }
};
