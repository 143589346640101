export const aircraftMaintenanceLimitExtensionItemFields = `id
deleted
createdAt
maintenanceLimitId
maintenanceLimitVersion
maintenanceRecordId
createdByUserId
extendedByName
validUntilDate
validUntilCountableValues
_version
_deleted
_lastChangedAt
updatedAt`;
