import { CreateAircraftMaintenanceLimitInput } from '../../API';
import { WithId } from '../../Datasource/LocalDB/types';
import * as uuid from 'uuid';
import { AircraftCountableType } from '../../Datasource/CountableChanges/types';
import { AircraftMaintenanceLimitExtensionMode } from './AircraftMaintenanceLimitExtensionMode';
import { MaintenanceTaskCalendarUnit } from '../MaintenancePrograms/MaintenanceTaskCalendarUnit';

export const createEmptyMaintenanceLimit = (aircraftId: string): CreateAircraftMaintenanceLimitInput & WithId => {
    return {
        id: uuid.v4(),
        deleted: false,
        createdAt: new Date().toISOString(),
        aircraftId,
        name: '',
        validUntilDate: null,
        validUntilDateToleranceSeconds: null,
        validUntilCountableTypes: null,
        validUntilCountableValues: null,
        validUntilCountableValuesTolerance: null,
    }
};

export type AircraftMaintenanceLimit =
    Omit<CreateAircraftMaintenanceLimitInput & WithId, 'createdAt' | 'customWarningRules' | 'limitExtensionRules' | 'validUntilDate'>
    & {
    createdAt: Date;
    customWarningRules: AircraftMaintenanceLimitCustomWarningRules;
    limitExtensionRules: AircraftMaintenanceLimitExtensionRules;
    validUntilDate: Date | null;
    validUntilCountableTypes: AircraftCountableType[] | null;
}

export type AircraftMaintenanceLimitExtensionRules = {
    extendableByPilot?: boolean;
    mode?: AircraftMaintenanceLimitExtensionMode,
    dateExtension?: {
        amount: number;
        unit: MaintenanceTaskCalendarUnit;
        untilEndOfUnit: boolean;
        fixedIntervalReferenceDate?: Date;
    }
    countableValuesExtension?: (number | null)[];
}
export type AircraftMaintenanceLimitCustomWarningRules = {
    validUntilDateCautionSeconds?: number;
}

export function aircraftMaintenanceLimitFromDb(input: CreateAircraftMaintenanceLimitInput & WithId): AircraftMaintenanceLimit {
    return {
        id: input.id,
        deleted: input.deleted,
        createdAt: new Date(input.createdAt!),
        aircraftId: input.aircraftId,
        name: input.name,
        validUntilDateToleranceSeconds: input.validUntilDateToleranceSeconds,
        validUntilCountableTypes: input.validUntilCountableTypes as AircraftCountableType[] | null,
        validUntilCountableValues: input.validUntilCountableValues,
        validUntilCountableValuesTolerance: input.validUntilCountableValuesTolerance,
        _version: input._version,
        validUntilDate: input.validUntilDate ? new Date(input.validUntilDate) : null,
        customWarningRules: input.customWarningRules ? JSON.parse(input.customWarningRules) : {},
        limitExtensionRules: input.limitExtensionRules ? limitExtensionRulesFromDb(input.limitExtensionRules) : {},
        maintenanceProgramTaskWid: input.maintenanceProgramTaskWid,
    };
}

export function aircraftMaintenanceLimitToDb(record: AircraftMaintenanceLimit): CreateAircraftMaintenanceLimitInput & WithId {
    return {
        ...record,
        createdAt: record.createdAt.toISOString(),
        validUntilDate: record.validUntilDate ? record.validUntilDate.toISOString() : null,
        customWarningRules: Object.keys(record.customWarningRules).length ? JSON.stringify(record.customWarningRules) : null,
        limitExtensionRules: Object.keys(record.limitExtensionRules).length ? JSON.stringify(record.limitExtensionRules) : null,
    };
}

function limitExtensionRulesFromDb(json: string): AircraftMaintenanceLimitExtensionRules {
    const out: AircraftMaintenanceLimitExtensionRules = JSON.parse(json);
    if (out.dateExtension?.fixedIntervalReferenceDate) {
        out.dateExtension.fixedIntervalReferenceDate = new Date(out.dateExtension.fixedIntervalReferenceDate);
    }
    return out;
}
