import { Auth } from 'aws-amplify';

const LOCAL_STORAGE_KEY = 'alol_backend_off';

export enum AppEnvironment {
    TLB= "tlb",
    OPS= 'ops',
}

let stringIsEnvironment = function (value: string): value is AppEnvironment {
    return value === AppEnvironment.OPS || value === AppEnvironment.TLB;
};
export const getPreferredEnvironment = (): AppEnvironment | undefined  => {
    const value = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if(value && stringIsEnvironment(value)) {
        return value;
    }
    return undefined;
};

export const setPreferredEnvironment = (value: AppEnvironment | undefined) => {
    if (value) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, value);
    } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
};

export const signOut = () => {
    setPreferredEnvironment(undefined);
    Auth.signOut();
}
