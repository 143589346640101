import {useState} from "react";

type IdGenerator = () => string;
const existingGenerators = new Map<string, IdGenerator>();


export const createIdGenerator = (prefix: string): IdGenerator => {
    if (existingGenerators.has(prefix)) {
        return existingGenerators.get(prefix)!;
    }

    let id = 0;
    const generator = () => {
        return `prefix-${++id}`;
    };
    existingGenerators.set(prefix, generator);
    return generator;
};

export const useId = (prefix = 'rnd'): [string] => {
    const generator = createIdGenerator(prefix);
    const [id] = useState(generator());
    return [id];
};

const defaultGenerator = createIdGenerator('rnd');
export default defaultGenerator;
