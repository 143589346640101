import React, { FunctionComponent } from "react";
import { formClasses } from "../../utils/BEM";

export interface Suggestable {
    toString: () => string;
    readonly value?: string;
    readonly classModifiers?: string[];
}

export class SuggestionValue implements Suggestable {
    readonly value: string;
    readonly classModifiers: string[] | undefined;
    private _string: string;

    constructor(string: string, value: string, classModifiers?: string[]) {
        this._string = string;
        this.value = value;
        this.classModifiers = classModifiers;
    }

    toString() {
        return this._string;
    }
}

type SuggesterProps = {
    options: Suggestable[];
    onSelected: (value: string, index: number) => void;
    classModifiers?: string[];
    negativeTabIndex?: boolean;
}

const Suggester: FunctionComponent<SuggesterProps> = ({options, onSelected, classModifiers, negativeTabIndex}) => {
    return (
        <div {...formClasses('suggester', classModifiers)}>
            {options.map((option, i) => (
                <button {...formClasses('suggester-item', option.classModifiers)}
                        key={i}
                        onClick={(evt) => {
                            evt.preventDefault();
                            const value = option.value !== undefined ? option.value : option.toString();
                            onSelected(value, i);
                        }}
                        tabIndex={negativeTabIndex ? -1 : undefined}
                >
                    {option.toString()}
                </button>
            ))}
        </div>
    )
};

export default Suggester;
