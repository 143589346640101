import { DbSyncerUpDefinition, getStandardSyncerFields } from "../syncHelpers";
import {
    AIRCRAFT_COUNTABLE_FLIGHT_TIME,
    AIRCRAFT_COUNTABLE_TACH_TIME,
    AircraftCountableType,
    AircraftUpliftableType,
    CountableChange,
    CountableChangeType
} from "../../CountableChanges/types";
import { CreateCountableChangeInput } from "../../../API";
import { DbCreated } from '../types';

let counter = 0;

export const countableChangeItemFields = `
    id
    deleted
    editableByUserIds
    aircraftId
    flightId
    legId
    createdAt
    createdByName
    place
    type
    changeTypes
    changeValues
    countableTypes
    countableValuesBefore
    countableValuesAfter
    _version
`.trim();

export const countableChangeSyncerDefinition: DbSyncerUpDefinition = (countableChange: CountableChange) => {
    const c = ++counter;
    const [commandName, inputType] = !countableChange._version
        ? ['createCountableChange', 'CreateCountableChangeInput']
        : ['updateCountableChange', 'UpdateCountableChangeInput'];
    const input = countableChangeToDb(countableChange);
    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, countableChangeItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: countableChangeFromDb,
        getNeedsResync,
        queryName: "getCountableChange"
    };
};

export const countableChangeToDb = (countableChange: CountableChange): CreateCountableChangeInput => {
    return {
        id: countableChange.id,
        deleted: countableChange.deleted,
        aircraftId: countableChange.aircraftId,
        flightId: countableChange.flightId,
        legId: countableChange.legId,
        createdAt: countableChange.createdAt.toISOString(),
        createdByName: countableChange.createdByName,
        place: countableChange.place,
        type: countableChange.type as any,
        // note: countableChange.note, // todo
        changeTypes: countableChange.changeTypes.length ? countableChange.changeTypes : null,
        changeValues: countableChange.changeValues.length ? countableChange.changeValues : null,
        countableTypes: countableChange.countableTypes,
        countableValuesBefore: countableChange.countableValuesBefore,
        countableValuesAfter: countableChange.countableValuesAfter,
        _version: countableChange._version,
    };
};

const migrateTypes = <T extends AircraftCountableType | AircraftUpliftableType>(inputTypes: T[], aircraftId: string): T[] => {
    if (aircraftId !== "98a19ab5-292c-4d96-8721-56295bdab607") { // OK-TEST
        return inputTypes;
    }
    return inputTypes.map(type => type === AIRCRAFT_COUNTABLE_TACH_TIME ? AIRCRAFT_COUNTABLE_FLIGHT_TIME : type) as any;
};

const getNeedsResync = (remote: CreateCountableChangeInput, local: CountableChange): boolean => {
    if (remote.changeTypes) {
        if (local.changeTypes.length !== remote.changeTypes.length) {
            return true;
        }
    }
    if (remote.countableTypes) {
        if (local.countableTypes.length !== remote.countableTypes.length) {
            return true;
        }
    }
    return false;
};

export const countableChangeFromDb = (input: CreateCountableChangeInput & DbCreated): CountableChange => {
    const changeTypes = input.changeTypes ? (input.changeTypes as (AircraftUpliftableType | AircraftCountableType)[]) : [];
    const changeTypesDeduped = Array.from(new Set(changeTypes));
    const countableTypes = input.countableTypes ? input.countableTypes as AircraftCountableType[] : [];
    const countableTypesDeduped = Array.from(new Set(countableTypes));
    const changeValues = input.changeValues ? input.changeValues : [];
    const countableValuesBefore = input.countableValuesBefore;
    const countableValuesAfter = input.countableValuesAfter;
    return {
        id: input.id,
        deleted: input.deleted,
        _version: input._version,
        editableByUserIds: input.editableByUserIds || [],
        aircraftId: input.aircraftId,
        flightId: input.flightId || undefined,
        legId: input.legId || undefined,
        createdAt: new Date(input.createdAt!),
        createdByName: input.createdByName,
        place: input.place || undefined,
        type: input.type as CountableChangeType,
        // note: input.note, // todo
        changeTypes: migrateTypes(changeTypesDeduped, input.aircraftId),
        changeValues: deduplicate(changeTypesDeduped, changeTypes, changeValues),
        countableTypes: migrateTypes(countableTypesDeduped, input.aircraftId),
        countableValuesBefore: deduplicate(countableTypesDeduped, countableTypes, countableValuesBefore),
        countableValuesAfter: deduplicate(countableTypesDeduped, countableTypes, countableValuesAfter),
    };
};

const deduplicate = <T extends number[] | (number | null)[]>(uniqueTypes: string[], types: string[], values: T): T => {
    return uniqueTypes.map(uniqueType => {
        const index = types.lastIndexOf(uniqueType);
        return values[index];
    }) as T;
};
