import * as uuid from 'uuid';
import { ErrorLog } from "./types";
import { getDb } from "../../Datasource/LocalDB/LocalDB";
import { API } from 'aws-amplify';
import { createErrorReport } from '../../graphql/mutations';
import { CreateErrorReportInput } from '../../API';
import { QueryResult } from '../../Admin/Datasources/QueryFactory';

export const logErrorLocal = (message: string, place: string, additionalData?: any) => {
    const log: ErrorLog = {
        id: uuid.v4(),
        message,
        place,
        date: new Date(),
        additionalData
    };
    getDb().errorLogs.put(log);
};

export const logErrorRemote = async (message: string, place: string, additionalData?: any) => {
    if (window.location.hostname === 'localhost') {
        return;
    }
    const input: CreateErrorReportInput = {
        message,
        place,
        data: JSON.stringify(additionalData)
    };
    const query = createErrorReport;
    const variables = {input};
    const result = await API.graphql({query, variables}) as QueryResult<any>;
    if (result.errors && result.errors.length) {
        throw result.errors;
    }
    await API.graphql({query, variables});
}

export const logError = (message: string, place: string, additionalData?: any) => {
    if ((typeof message as any) === 'string' && message.startsWith('Network Error')) {
        return;
    }
    try {
        logErrorLocal(message, place, additionalData);
    } catch (e) {
        console.error('Error when locally saving error');
        console.error(e);
    }
    try {
        logErrorRemote(message, place, additionalData);
    } catch (e) {
        console.error('Error when remotely saving error');
        console.error(e);
    }
}

export const logErrorEvent = (event: ErrorEvent) => {
    const data = {
        lineno: event.lineno,
        colno: event.colno,
        filename: event.filename,
        errorStack: event.error?.stack
    }
    logError(event.message, 'error-event', data);
}
