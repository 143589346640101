import { CreateSignedDataInput } from '../../API';
import { WithId } from '../LocalDB/types';
import { getDb } from '../LocalDB/LocalDB';
import { getDbSyncer } from '../LocalDB/DbSyncer';
import { sign } from '../../utils/Signing/SignatureGenerator';
import * as  uuid from 'uuid';

export const putSignedData = async (input: CreateSignedDataInput & WithId) => {
    const table = getDb().signedData;
    await Promise.all([
        table.put(input),
        getDbSyncer().markToSync(table, input.id)
    ]);
};

export const signAndPut = async (data: string, optionalData: Pick<CreateSignedDataInput, 'flightId'>) => {
    const signature = sign(data);
    const input: CreateSignedDataInput & WithId = {
        id: uuid.v4(),
        data: data,
        deviceSignature: signature.deviceSignature,
        temporarySignature: signature.temporarySignature,
        deviceKeyId: signature.deviceKeyUuid,
        temporaryKeyId: signature.temporaryKeyUuid,
        createdAt: new Date().toISOString(),
        flightId: optionalData.flightId
    };
    return putSignedData(input);
};
