import {
    COUNTABLE_CHANGE_CONSUMPTION,
    COUNTABLE_CHANGE_CORRECTION,
    COUNTABLE_CHANGE_UPLIFT,
    CountableChange
} from "./types";
import { getDb } from "../LocalDB/LocalDB";
import { FlightLeg, isLegFinished } from "../FlightLegs/FlightLeg";
import { getAircraft } from "../AircraftsDatasource";
import { getInitialCountableChange } from "./CountableChange";
import { getDbSyncer } from "../LocalDB/DbSyncer";
import { toMap } from '../../utils/arrayUtils';

export const getCountableChange = async (id: string): Promise<CountableChange | null> => {
    return (await getDb().countableChanges.get(id)) || null;
};

export const getCountableChanges = async (ids: string[]): Promise<(CountableChange | undefined)[]> => {
    return getDb().countableChanges.bulkGet(ids);
};

export const getCountableChangesForLeg = async (legId: string): Promise<CountableChange[]> => {
    return await getDb().countableChanges.where({legId}).toArray();
};

export const getLastCountableChangeForLeg = async (leg: FlightLeg): Promise<CountableChange> => {
    const maybeCountableChange = isLegFinished(leg)
        ? await getCountableChange(leg.consumptionCountableChangeId)
        : await getCountableChange(leg.upliftCountableChangeId);
    if (maybeCountableChange) {
        return maybeCountableChange;
    }
    const aircraft = await getAircraft(leg.aircraftId);
    if (!aircraft) {
        throw new Error("Trying to get last countable change for non-existing aircraft");
    }
    return getInitialCountableChange(aircraft, leg.editableByUserIds);
};

export const getLastCountableChangeForAircraft = async (aircraftId: string): Promise<CountableChange> => {
    const db = getDb();
    const [changes, legs, flights] = await Promise.all([
        db.countableChanges.where({aircraftId}).toArray(),
        db.legs.where({aircraftId}).toArray(),
        db.flights.where({aircraftId}).toArray(),
    ]);
    const legsMap = toMap(legs);
    const flightsMap = toMap(flights);
    const combined = changes.map(countableChange => {
        const leg = countableChange.legId ? legsMap.get(countableChange.legId) : undefined;
        const flight = countableChange.flightId ? flightsMap.get(countableChange.flightId) : undefined;
        return {countableChange, leg, flight};
    });
    const valid = combined.filter(a => {
        return !a.countableChange.deleted
            && (!a.leg || !a.leg.deleted)
            && (!a.flight || !a.flight.deleted)
    });
    valid.sort((a, b) => {
        const refA = a.leg ? a.leg.timeOut : a.countableChange.createdAt;
        const refB = b.leg ? b.leg.timeOut : b.countableChange.createdAt;
        const diff = refB.getTime() - refA.getTime();
        if(diff) {
            return diff;
        }
        const typeIndexA = countableChangeTypeSuccession.indexOf(a.countableChange.type);
        const typeIndexB = countableChangeTypeSuccession.indexOf(b.countableChange.type);
        return typeIndexB - typeIndexA;
    });
    if(valid.length) {
        return valid[0].countableChange;
    } else {
        const aircraft = await getAircraft(aircraftId);
        if (!aircraft) {
            throw new Error("Trying to get last countable change for non-existing aircraft");
        }
        return getInitialCountableChange(aircraft);
    }
};

export const putCountableChange = async (countableChange: CountableChange): Promise<void> => {
    let db = getDb();
    await db.countableChanges.put(countableChange);
    getDbSyncer().markToSync(db.countableChanges, countableChange.id);
};

const countableChangeTypeSuccession = [
    COUNTABLE_CHANGE_CORRECTION,
    COUNTABLE_CHANGE_UPLIFT,
    COUNTABLE_CHANGE_CONSUMPTION
];
