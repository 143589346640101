export enum FlightOps {
    CAT = 'CAT',
    SPO = 'SPO',
    ATO_NCC = 'ATO/NCC',
    ATO_NCO = 'ATO/NCO',
    NCC = 'NCC',
    NCO = 'NCO',
}

export const getAllFlightOpsTypes = (): FlightOps[] => [
    FlightOps.CAT,
    FlightOps.ATO_NCC,
    FlightOps.ATO_NCO,
    FlightOps.NCC,
    FlightOps.NCO,
    FlightOps.SPO,
];

export const stringIsOps = (maybeOps: string): maybeOps is FlightOps => {
    return getAllFlightOpsTypes().includes(maybeOps as any);
};

export const isOpsAto = (ops: FlightOps) => ops === FlightOps.ATO_NCO || ops === FlightOps.ATO_NCC;

export function isOpsCommercial(ops: FlightOps) {
    return isOpsAto(ops) || ops === FlightOps.CAT;
}

export function isOpsCommercialOrComplex(ops: FlightOps) {
    return isOpsCommercial(ops) || ops === FlightOps.NCC;
}

export function isOpsCatOrComplex(ops: FlightOps) {
    return ops === FlightOps.CAT || ops === FlightOps.NCC;
}

export const flightOpsLabels = new Map<FlightOps, string>([
    [FlightOps.CAT, 'CAT'],
    [FlightOps.SPO, 'SPO'],
    [FlightOps.ATO_NCC, 'ATO/NCC'],
    [FlightOps.ATO_NCO, 'ATO/NCO'],
    [FlightOps.NCC, 'NCC'],
    [FlightOps.NCO, 'NCO'],
]);
