import BEMHelper from 'react-bem-helper';

export type FieldDetailedValueGroup<CT extends string = string> = {
    label: string;
    values: FieldDetailedValue<CT>[];
};

export type FieldDetailedValue<CT extends string = string> = {
    value: CT;
    label?: string;
    labelComponent?: React.ReactNode;
    disabled?: boolean;
    group?: string;
    classModifiers?: BEMHelper.PredicateSet | string[];
    title?: string;
};

export function generateLabels<T extends string>(labelMap: Map<T, string>): FieldDetailedValue<T>[] {
    return Array.from(labelMap.entries()).map(([value, label]) => ({ value, label }));
}

export function generateFieldDetailedValueMap(values: FieldDetailedValue[]): Map<string, FieldDetailedValue> {
    return new Map(values.map((value) => [value.value, value]));
}

export function mapStringsToFieldDetailedValues(
    strings: string[],
    options: FieldDetailedValue[]
): FieldDetailedValue[] {
    return strings.map((value) => options.find((o) => o.value === value)).filter(Boolean) as FieldDetailedValue[];
}
