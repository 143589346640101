/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const userSetForgottenPassword = /* GraphQL */ `mutation UserSetForgottenPassword($userId: ID!, $newPassword: String!) {
  userSetForgottenPassword(userId: $userId, newPassword: $newPassword) {
    statusCode
    responseData
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserSetForgottenPasswordMutationVariables,
  APITypes.UserSetForgottenPasswordMutation
>;
export const userDisable = /* GraphQL */ `mutation UserDisable($userId: ID!) {
  userDisable(userId: $userId) {
    statusCode
    responseData
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserDisableMutationVariables,
  APITypes.UserDisableMutation
>;
export const userEnable = /* GraphQL */ `mutation UserEnable($userId: ID!) {
  userEnable(userId: $userId) {
    statusCode
    responseData
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserEnableMutationVariables,
  APITypes.UserEnableMutation
>;
export const userCreate = /* GraphQL */ `mutation UserCreate($email: String!, $tempPassword: String!, $phone: String) {
  userCreate(email: $email, tempPassword: $tempPassword, phone: $phone) {
    statusCode
    responseData
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserCreateMutationVariables,
  APITypes.UserCreateMutation
>;
export const userAddToGroup = /* GraphQL */ `mutation UserAddToGroup($userId: ID!, $groupName: String!) {
  userAddToGroup(userId: $userId, groupName: $groupName) {
    statusCode
    responseData
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserAddToGroupMutationVariables,
  APITypes.UserAddToGroupMutation
>;
export const userRemoveFromGroup = /* GraphQL */ `mutation UserRemoveFromGroup($userId: ID!, $groupName: String!) {
  userRemoveFromGroup(userId: $userId, groupName: $groupName) {
    statusCode
    responseData
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserRemoveFromGroupMutationVariables,
  APITypes.UserRemoveFromGroupMutation
>;
export const sendToBackup = /* GraphQL */ `mutation SendToBackup($contactDocumentId: ID, $documentSnippetId: ID) {
  sendToBackup(
    contactDocumentId: $contactDocumentId
    documentSnippetId: $documentSnippetId
  ) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendToBackupMutationVariables,
  APITypes.SendToBackupMutation
>;
export const createAirportDatabaseExport = /* GraphQL */ `mutation CreateAirportDatabaseExport {
  createAirportDatabaseExport {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAirportDatabaseExportMutationVariables,
  APITypes.CreateAirportDatabaseExportMutation
>;
export const sendEmail = /* GraphQL */ `mutation SendEmail(
  $toAddresses: [String!]!
  $ccAddresses: [String!]
  $bccAddresses: [String!]
  $subject: String!
  $messageHtml: String!
  $fromAddress: String!
  $messageText: String
  $attachmentKeys: [String!]
  $attachmentNames: [String]
  $replyToAddresses: [String!]
) {
  sendEmail(
    toAddresses: $toAddresses
    ccAddresses: $ccAddresses
    bccAddresses: $bccAddresses
    subject: $subject
    messageHtml: $messageHtml
    fromAddress: $fromAddress
    messageText: $messageText
    attachmentKeys: $attachmentKeys
    attachmentNames: $attachmentNames
    replyToAddresses: $replyToAddresses
  ) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendEmailMutationVariables,
  APITypes.SendEmailMutation
>;
export const sendSms = /* GraphQL */ `mutation SendSms($toNumbers: [String!]!, $content: String!) {
  sendSms(toNumbers: $toNumbers, content: $content) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendSmsMutationVariables,
  APITypes.SendSmsMutation
>;
export const createCrew = /* GraphQL */ `mutation CreateCrew(
  $input: CreateCrewInput!
  $condition: ModelCrewConditionInput
) {
  createCrew(input: $input, condition: $condition) {
    id
    deleted
    userId
    name
    homeBaseIcaos
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCrewMutationVariables,
  APITypes.CreateCrewMutation
>;
export const updateCrew = /* GraphQL */ `mutation UpdateCrew(
  $input: UpdateCrewInput!
  $condition: ModelCrewConditionInput
) {
  updateCrew(input: $input, condition: $condition) {
    id
    deleted
    userId
    name
    homeBaseIcaos
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCrewMutationVariables,
  APITypes.UpdateCrewMutation
>;
export const deleteCrew = /* GraphQL */ `mutation DeleteCrew(
  $input: DeleteCrewInput!
  $condition: ModelCrewConditionInput
) {
  deleteCrew(input: $input, condition: $condition) {
    id
    deleted
    userId
    name
    homeBaseIcaos
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCrewMutationVariables,
  APITypes.DeleteCrewMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    deleted
    name
    type
    aircraftId
    legsPic {
      nextToken
      startedAt
      __typename
    }
    legsSic {
      nextToken
      startedAt
      __typename
    }
    dutyItems {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    deleted
    name
    type
    aircraftId
    legsPic {
      nextToken
      startedAt
      __typename
    }
    legsSic {
      nextToken
      startedAt
      __typename
    }
    dutyItems {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    deleted
    name
    type
    aircraftId
    legsPic {
      nextToken
      startedAt
      __typename
    }
    legsSic {
      nextToken
      startedAt
      __typename
    }
    dutyItems {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createAircraft = /* GraphQL */ `mutation CreateAircraft(
  $input: CreateAircraftInput!
  $condition: ModelAircraftConditionInput
) {
  createAircraft(input: $input, condition: $condition) {
    id
    deleted
    registration
    friendlyType
    serialNumber
    imageUrl
    countableTypes
    upliftableTypes
    tags
    category
    userId
    logbookParameters
    aircraftTypeId
    maintenanceProgramId
    manufactureDate
    isReservationResource
    calendarId
    performanceProfile
    baseAirportIcao
    payload
    crewComposition
    callsign
    flights {
      nextToken
      startedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    aircraftDocuments {
      nextToken
      startedAt
      __typename
    }
    aircraftMaintenanceLimits {
      nextToken
      startedAt
      __typename
    }
    aircraftMaintenanceIntervalChanges {
      nextToken
      startedAt
      __typename
    }
    maintenanceWorkOrders {
      nextToken
      startedAt
      __typename
    }
    maintenanceRecords {
      nextToken
      startedAt
      __typename
    }
    maintenanceProgram {
      id
      deleted
      title
      revision
      issueDate
      effectiveDate
      approvedBy
      tasks
      nextDueRules
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    aircraftType {
      id
      manufacturer
      type
      category
      engineType
      engineCount
      multiPilot
      highPerformance
      complex
      variants
      deleted
      crewQualificationId
      logbookTimeInTypesNames
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservations {
      nextToken
      startedAt
      __typename
    }
    settlementTickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAircraftMutationVariables,
  APITypes.CreateAircraftMutation
>;
export const updateAircraft = /* GraphQL */ `mutation UpdateAircraft(
  $input: UpdateAircraftInput!
  $condition: ModelAircraftConditionInput
) {
  updateAircraft(input: $input, condition: $condition) {
    id
    deleted
    registration
    friendlyType
    serialNumber
    imageUrl
    countableTypes
    upliftableTypes
    tags
    category
    userId
    logbookParameters
    aircraftTypeId
    maintenanceProgramId
    manufactureDate
    isReservationResource
    calendarId
    performanceProfile
    baseAirportIcao
    payload
    crewComposition
    callsign
    flights {
      nextToken
      startedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    aircraftDocuments {
      nextToken
      startedAt
      __typename
    }
    aircraftMaintenanceLimits {
      nextToken
      startedAt
      __typename
    }
    aircraftMaintenanceIntervalChanges {
      nextToken
      startedAt
      __typename
    }
    maintenanceWorkOrders {
      nextToken
      startedAt
      __typename
    }
    maintenanceRecords {
      nextToken
      startedAt
      __typename
    }
    maintenanceProgram {
      id
      deleted
      title
      revision
      issueDate
      effectiveDate
      approvedBy
      tasks
      nextDueRules
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    aircraftType {
      id
      manufacturer
      type
      category
      engineType
      engineCount
      multiPilot
      highPerformance
      complex
      variants
      deleted
      crewQualificationId
      logbookTimeInTypesNames
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservations {
      nextToken
      startedAt
      __typename
    }
    settlementTickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAircraftMutationVariables,
  APITypes.UpdateAircraftMutation
>;
export const deleteAircraft = /* GraphQL */ `mutation DeleteAircraft(
  $input: DeleteAircraftInput!
  $condition: ModelAircraftConditionInput
) {
  deleteAircraft(input: $input, condition: $condition) {
    id
    deleted
    registration
    friendlyType
    serialNumber
    imageUrl
    countableTypes
    upliftableTypes
    tags
    category
    userId
    logbookParameters
    aircraftTypeId
    maintenanceProgramId
    manufactureDate
    isReservationResource
    calendarId
    performanceProfile
    baseAirportIcao
    payload
    crewComposition
    callsign
    flights {
      nextToken
      startedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    aircraftDocuments {
      nextToken
      startedAt
      __typename
    }
    aircraftMaintenanceLimits {
      nextToken
      startedAt
      __typename
    }
    aircraftMaintenanceIntervalChanges {
      nextToken
      startedAt
      __typename
    }
    maintenanceWorkOrders {
      nextToken
      startedAt
      __typename
    }
    maintenanceRecords {
      nextToken
      startedAt
      __typename
    }
    maintenanceProgram {
      id
      deleted
      title
      revision
      issueDate
      effectiveDate
      approvedBy
      tasks
      nextDueRules
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    aircraftType {
      id
      manufacturer
      type
      category
      engineType
      engineCount
      multiPilot
      highPerformance
      complex
      variants
      deleted
      crewQualificationId
      logbookTimeInTypesNames
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservations {
      nextToken
      startedAt
      __typename
    }
    settlementTickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAircraftMutationVariables,
  APITypes.DeleteAircraftMutation
>;
export const createAircraftType = /* GraphQL */ `mutation CreateAircraftType(
  $input: CreateAircraftTypeInput!
  $condition: ModelAircraftTypeConditionInput
) {
  createAircraftType(input: $input, condition: $condition) {
    id
    manufacturer
    type
    category
    engineType
    engineCount
    multiPilot
    highPerformance
    complex
    variants
    deleted
    crewQualificationId
    logbookTimeInTypesNames
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAircraftTypeMutationVariables,
  APITypes.CreateAircraftTypeMutation
>;
export const updateAircraftType = /* GraphQL */ `mutation UpdateAircraftType(
  $input: UpdateAircraftTypeInput!
  $condition: ModelAircraftTypeConditionInput
) {
  updateAircraftType(input: $input, condition: $condition) {
    id
    manufacturer
    type
    category
    engineType
    engineCount
    multiPilot
    highPerformance
    complex
    variants
    deleted
    crewQualificationId
    logbookTimeInTypesNames
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAircraftTypeMutationVariables,
  APITypes.UpdateAircraftTypeMutation
>;
export const deleteAircraftType = /* GraphQL */ `mutation DeleteAircraftType(
  $input: DeleteAircraftTypeInput!
  $condition: ModelAircraftTypeConditionInput
) {
  deleteAircraftType(input: $input, condition: $condition) {
    id
    manufacturer
    type
    category
    engineType
    engineCount
    multiPilot
    highPerformance
    complex
    variants
    deleted
    crewQualificationId
    logbookTimeInTypesNames
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAircraftTypeMutationVariables,
  APITypes.DeleteAircraftTypeMutation
>;
export const createFlight = /* GraphQL */ `mutation CreateFlight(
  $input: CreateFlightInput!
  $condition: ModelFlightConditionInput
) {
  createFlight(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    aircraftId
    acceptanceNotes
    picName
    picId
    state
    reservationId
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    legs {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlightMutationVariables,
  APITypes.CreateFlightMutation
>;
export const updateFlight = /* GraphQL */ `mutation UpdateFlight(
  $input: UpdateFlightInput!
  $condition: ModelFlightConditionInput
) {
  updateFlight(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    aircraftId
    acceptanceNotes
    picName
    picId
    state
    reservationId
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    legs {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlightMutationVariables,
  APITypes.UpdateFlightMutation
>;
export const deleteFlight = /* GraphQL */ `mutation DeleteFlight(
  $input: DeleteFlightInput!
  $condition: ModelFlightConditionInput
) {
  deleteFlight(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    aircraftId
    acceptanceNotes
    picName
    picId
    state
    reservationId
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    legs {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlightMutationVariables,
  APITypes.DeleteFlightMutation
>;
export const createLeg = /* GraphQL */ `mutation CreateLeg(
  $input: CreateLegInput!
  $condition: ModelLegConditionInput
) {
  createLeg(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    flightId
    aircraftId
    reservationId
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    reservationEventId
    airportOffIcao
    ops
    securityCheckDate
    securityCheckName
    preflightCheckDate
    preflightCheckName
    picName
    picId
    picRoles
    sicName
    sicId
    sicRoles
    deicing
    notes
    startSignature
    phase
    startedAt
    upliftCountableChangeId
    upliftCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    airportOnIcao
    timeOut
    timeOff
    timeOn
    timeIn
    finishNotes
    finishSignature
    consumptionCountableChangeId
    logbookFields
    inputSources
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    externalDocuments {
      nextToken
      startedAt
      __typename
    }
    flight {
      id
      deleted
      editableByUserIds
      createdAt
      aircraftId
      acceptanceNotes
      picName
      picId
      state
      reservationId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    ticketId
    ticket {
      id
      createdAt
      updatedAt
      removed
      name
      reservationDate
      status
      flightType
      type
      priceTable
      year
      number
      note
      totalPrice
      currency
      contactId
      customerAccountId
      aircraftId
      accountingMonthId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    settlementTicketId
    settlementTicket {
      id
      createdAt
      updatedAt
      removed
      name
      reservationDate
      status
      flightType
      type
      priceTable
      year
      number
      note
      totalPrice
      currency
      contactId
      customerAccountId
      aircraftId
      accountingMonthId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLegMutationVariables,
  APITypes.CreateLegMutation
>;
export const updateLeg = /* GraphQL */ `mutation UpdateLeg(
  $input: UpdateLegInput!
  $condition: ModelLegConditionInput
) {
  updateLeg(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    flightId
    aircraftId
    reservationId
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    reservationEventId
    airportOffIcao
    ops
    securityCheckDate
    securityCheckName
    preflightCheckDate
    preflightCheckName
    picName
    picId
    picRoles
    sicName
    sicId
    sicRoles
    deicing
    notes
    startSignature
    phase
    startedAt
    upliftCountableChangeId
    upliftCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    airportOnIcao
    timeOut
    timeOff
    timeOn
    timeIn
    finishNotes
    finishSignature
    consumptionCountableChangeId
    logbookFields
    inputSources
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    externalDocuments {
      nextToken
      startedAt
      __typename
    }
    flight {
      id
      deleted
      editableByUserIds
      createdAt
      aircraftId
      acceptanceNotes
      picName
      picId
      state
      reservationId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    ticketId
    ticket {
      id
      createdAt
      updatedAt
      removed
      name
      reservationDate
      status
      flightType
      type
      priceTable
      year
      number
      note
      totalPrice
      currency
      contactId
      customerAccountId
      aircraftId
      accountingMonthId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    settlementTicketId
    settlementTicket {
      id
      createdAt
      updatedAt
      removed
      name
      reservationDate
      status
      flightType
      type
      priceTable
      year
      number
      note
      totalPrice
      currency
      contactId
      customerAccountId
      aircraftId
      accountingMonthId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLegMutationVariables,
  APITypes.UpdateLegMutation
>;
export const deleteLeg = /* GraphQL */ `mutation DeleteLeg(
  $input: DeleteLegInput!
  $condition: ModelLegConditionInput
) {
  deleteLeg(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    flightId
    aircraftId
    reservationId
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    reservationEventId
    airportOffIcao
    ops
    securityCheckDate
    securityCheckName
    preflightCheckDate
    preflightCheckName
    picName
    picId
    picRoles
    sicName
    sicId
    sicRoles
    deicing
    notes
    startSignature
    phase
    startedAt
    upliftCountableChangeId
    upliftCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    airportOnIcao
    timeOut
    timeOff
    timeOn
    timeIn
    finishNotes
    finishSignature
    consumptionCountableChangeId
    logbookFields
    inputSources
    countableChanges {
      nextToken
      startedAt
      __typename
    }
    squawks {
      nextToken
      startedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    externalDocuments {
      nextToken
      startedAt
      __typename
    }
    flight {
      id
      deleted
      editableByUserIds
      createdAt
      aircraftId
      acceptanceNotes
      picName
      picId
      state
      reservationId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    ticketId
    ticket {
      id
      createdAt
      updatedAt
      removed
      name
      reservationDate
      status
      flightType
      type
      priceTable
      year
      number
      note
      totalPrice
      currency
      contactId
      customerAccountId
      aircraftId
      accountingMonthId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    settlementTicketId
    settlementTicket {
      id
      createdAt
      updatedAt
      removed
      name
      reservationDate
      status
      flightType
      type
      priceTable
      year
      number
      note
      totalPrice
      currency
      contactId
      customerAccountId
      aircraftId
      accountingMonthId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLegMutationVariables,
  APITypes.DeleteLegMutation
>;
export const createSquawk = /* GraphQL */ `mutation CreateSquawk(
  $input: CreateSquawkInput!
  $condition: ModelSquawkConditionInput
) {
  createSquawk(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    flightId
    legId
    createdAt
    createdByName
    title
    description
    companyDescription
    airworthinessAffected
    deferralDate
    deferralText
    reviewedDate
    reviewedName
    reviewedId
    releaseDate
    releaseAircraftFlightTime
    releaseName
    releaseLicenseNumber
    releaseText
    releaseSignature
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    squawkDeferrals {
      nextToken
      startedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSquawkMutationVariables,
  APITypes.CreateSquawkMutation
>;
export const updateSquawk = /* GraphQL */ `mutation UpdateSquawk(
  $input: UpdateSquawkInput!
  $condition: ModelSquawkConditionInput
) {
  updateSquawk(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    flightId
    legId
    createdAt
    createdByName
    title
    description
    companyDescription
    airworthinessAffected
    deferralDate
    deferralText
    reviewedDate
    reviewedName
    reviewedId
    releaseDate
    releaseAircraftFlightTime
    releaseName
    releaseLicenseNumber
    releaseText
    releaseSignature
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    squawkDeferrals {
      nextToken
      startedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSquawkMutationVariables,
  APITypes.UpdateSquawkMutation
>;
export const deleteSquawk = /* GraphQL */ `mutation DeleteSquawk(
  $input: DeleteSquawkInput!
  $condition: ModelSquawkConditionInput
) {
  deleteSquawk(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    flightId
    legId
    createdAt
    createdByName
    title
    description
    companyDescription
    airworthinessAffected
    deferralDate
    deferralText
    reviewedDate
    reviewedName
    reviewedId
    releaseDate
    releaseAircraftFlightTime
    releaseName
    releaseLicenseNumber
    releaseText
    releaseSignature
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    squawkDeferrals {
      nextToken
      startedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSquawkMutationVariables,
  APITypes.DeleteSquawkMutation
>;
export const createSquawkDeferral = /* GraphQL */ `mutation CreateSquawkDeferral(
  $input: CreateSquawkDeferralInput!
  $condition: ModelSquawkDeferralConditionInput
) {
  createSquawkDeferral(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    squawkId
    createdAt
    createdByName
    createdByLicenseNumber
    createdBySignature
    text
    deferredUntil
    melType
    actionsRequired
    actionPerformedText
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSquawkDeferralMutationVariables,
  APITypes.CreateSquawkDeferralMutation
>;
export const updateSquawkDeferral = /* GraphQL */ `mutation UpdateSquawkDeferral(
  $input: UpdateSquawkDeferralInput!
  $condition: ModelSquawkDeferralConditionInput
) {
  updateSquawkDeferral(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    squawkId
    createdAt
    createdByName
    createdByLicenseNumber
    createdBySignature
    text
    deferredUntil
    melType
    actionsRequired
    actionPerformedText
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSquawkDeferralMutationVariables,
  APITypes.UpdateSquawkDeferralMutation
>;
export const deleteSquawkDeferral = /* GraphQL */ `mutation DeleteSquawkDeferral(
  $input: DeleteSquawkDeferralInput!
  $condition: ModelSquawkDeferralConditionInput
) {
  deleteSquawkDeferral(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    squawkId
    createdAt
    createdByName
    createdByLicenseNumber
    createdBySignature
    text
    deferredUntil
    melType
    actionsRequired
    actionPerformedText
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSquawkDeferralMutationVariables,
  APITypes.DeleteSquawkDeferralMutation
>;
export const createReleaseToService = /* GraphQL */ `mutation CreateReleaseToService(
  $input: CreateReleaseToServiceInput!
  $condition: ModelReleaseToServiceConditionInput
) {
  createReleaseToService(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    squawkId
    deferralId
    maintenanceRecordId
    createdAt
    releaseType
    releasedByName
    releasedByOrganization
    releasedByLicenseNumber
    releasedBySignature
    text
    aircraftFlightTime
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReleaseToServiceMutationVariables,
  APITypes.CreateReleaseToServiceMutation
>;
export const updateReleaseToService = /* GraphQL */ `mutation UpdateReleaseToService(
  $input: UpdateReleaseToServiceInput!
  $condition: ModelReleaseToServiceConditionInput
) {
  updateReleaseToService(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    squawkId
    deferralId
    maintenanceRecordId
    createdAt
    releaseType
    releasedByName
    releasedByOrganization
    releasedByLicenseNumber
    releasedBySignature
    text
    aircraftFlightTime
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReleaseToServiceMutationVariables,
  APITypes.UpdateReleaseToServiceMutation
>;
export const deleteReleaseToService = /* GraphQL */ `mutation DeleteReleaseToService(
  $input: DeleteReleaseToServiceInput!
  $condition: ModelReleaseToServiceConditionInput
) {
  deleteReleaseToService(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    squawkId
    deferralId
    maintenanceRecordId
    createdAt
    releaseType
    releasedByName
    releasedByOrganization
    releasedByLicenseNumber
    releasedBySignature
    text
    aircraftFlightTime
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReleaseToServiceMutationVariables,
  APITypes.DeleteReleaseToServiceMutation
>;
export const createCountableChange = /* GraphQL */ `mutation CreateCountableChange(
  $input: CreateCountableChangeInput!
  $condition: ModelCountableChangeConditionInput
) {
  createCountableChange(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    flightId
    legId
    createdAt
    createdByName
    place
    type
    note
    changeTypes
    changeValues
    countableTypes
    countableValuesBefore
    countableValuesAfter
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    flight {
      id
      deleted
      editableByUserIds
      createdAt
      aircraftId
      acceptanceNotes
      picName
      picId
      state
      reservationId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    leg {
      id
      deleted
      editableByUserIds
      createdAt
      flightId
      aircraftId
      reservationId
      reservationEventId
      airportOffIcao
      ops
      securityCheckDate
      securityCheckName
      preflightCheckDate
      preflightCheckName
      picName
      picId
      picRoles
      sicName
      sicId
      sicRoles
      deicing
      notes
      startSignature
      phase
      startedAt
      upliftCountableChangeId
      airportOnIcao
      timeOut
      timeOff
      timeOn
      timeIn
      finishNotes
      finishSignature
      consumptionCountableChangeId
      logbookFields
      inputSources
      ticketId
      settlementTicketId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCountableChangeMutationVariables,
  APITypes.CreateCountableChangeMutation
>;
export const updateCountableChange = /* GraphQL */ `mutation UpdateCountableChange(
  $input: UpdateCountableChangeInput!
  $condition: ModelCountableChangeConditionInput
) {
  updateCountableChange(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    flightId
    legId
    createdAt
    createdByName
    place
    type
    note
    changeTypes
    changeValues
    countableTypes
    countableValuesBefore
    countableValuesAfter
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    flight {
      id
      deleted
      editableByUserIds
      createdAt
      aircraftId
      acceptanceNotes
      picName
      picId
      state
      reservationId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    leg {
      id
      deleted
      editableByUserIds
      createdAt
      flightId
      aircraftId
      reservationId
      reservationEventId
      airportOffIcao
      ops
      securityCheckDate
      securityCheckName
      preflightCheckDate
      preflightCheckName
      picName
      picId
      picRoles
      sicName
      sicId
      sicRoles
      deicing
      notes
      startSignature
      phase
      startedAt
      upliftCountableChangeId
      airportOnIcao
      timeOut
      timeOff
      timeOn
      timeIn
      finishNotes
      finishSignature
      consumptionCountableChangeId
      logbookFields
      inputSources
      ticketId
      settlementTicketId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCountableChangeMutationVariables,
  APITypes.UpdateCountableChangeMutation
>;
export const deleteCountableChange = /* GraphQL */ `mutation DeleteCountableChange(
  $input: DeleteCountableChangeInput!
  $condition: ModelCountableChangeConditionInput
) {
  deleteCountableChange(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    aircraftId
    flightId
    legId
    createdAt
    createdByName
    place
    type
    note
    changeTypes
    changeValues
    countableTypes
    countableValuesBefore
    countableValuesAfter
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    flight {
      id
      deleted
      editableByUserIds
      createdAt
      aircraftId
      acceptanceNotes
      picName
      picId
      state
      reservationId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    leg {
      id
      deleted
      editableByUserIds
      createdAt
      flightId
      aircraftId
      reservationId
      reservationEventId
      airportOffIcao
      ops
      securityCheckDate
      securityCheckName
      preflightCheckDate
      preflightCheckName
      picName
      picId
      picRoles
      sicName
      sicId
      sicRoles
      deicing
      notes
      startSignature
      phase
      startedAt
      upliftCountableChangeId
      airportOnIcao
      timeOut
      timeOff
      timeOn
      timeIn
      finishNotes
      finishSignature
      consumptionCountableChangeId
      logbookFields
      inputSources
      ticketId
      settlementTicketId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCountableChangeMutationVariables,
  APITypes.DeleteCountableChangeMutation
>;
export const createFlightDocument = /* GraphQL */ `mutation CreateFlightDocument(
  $input: CreateFlightDocumentInput!
  $condition: ModelFlightDocumentConditionInput
) {
  createFlightDocument(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    aircraftId
    flightId
    legId
    squawkId
    deferralId
    releaseToServiceId
    maintenanceRecordId
    contentKey
    type
    phase
    title
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlightDocumentMutationVariables,
  APITypes.CreateFlightDocumentMutation
>;
export const updateFlightDocument = /* GraphQL */ `mutation UpdateFlightDocument(
  $input: UpdateFlightDocumentInput!
  $condition: ModelFlightDocumentConditionInput
) {
  updateFlightDocument(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    aircraftId
    flightId
    legId
    squawkId
    deferralId
    releaseToServiceId
    maintenanceRecordId
    contentKey
    type
    phase
    title
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlightDocumentMutationVariables,
  APITypes.UpdateFlightDocumentMutation
>;
export const deleteFlightDocument = /* GraphQL */ `mutation DeleteFlightDocument(
  $input: DeleteFlightDocumentInput!
  $condition: ModelFlightDocumentConditionInput
) {
  deleteFlightDocument(input: $input, condition: $condition) {
    id
    deleted
    editableByUserIds
    createdAt
    aircraftId
    flightId
    legId
    squawkId
    deferralId
    releaseToServiceId
    maintenanceRecordId
    contentKey
    type
    phase
    title
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlightDocumentMutationVariables,
  APITypes.DeleteFlightDocumentMutation
>;
export const createExternalDocument = /* GraphQL */ `mutation CreateExternalDocument(
  $input: CreateExternalDocumentInput!
  $condition: ModelExternalDocumentConditionInput
) {
  createExternalDocument(input: $input, condition: $condition) {
    id
    createdAt
    identifyingData
    contentData
    flightDocumentId
    flightId
    legId
    reservationId
    reservationEventId
    notIdentified
    contentKey
    deleted
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExternalDocumentMutationVariables,
  APITypes.CreateExternalDocumentMutation
>;
export const updateExternalDocument = /* GraphQL */ `mutation UpdateExternalDocument(
  $input: UpdateExternalDocumentInput!
  $condition: ModelExternalDocumentConditionInput
) {
  updateExternalDocument(input: $input, condition: $condition) {
    id
    createdAt
    identifyingData
    contentData
    flightDocumentId
    flightId
    legId
    reservationId
    reservationEventId
    notIdentified
    contentKey
    deleted
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExternalDocumentMutationVariables,
  APITypes.UpdateExternalDocumentMutation
>;
export const deleteExternalDocument = /* GraphQL */ `mutation DeleteExternalDocument(
  $input: DeleteExternalDocumentInput!
  $condition: ModelExternalDocumentConditionInput
) {
  deleteExternalDocument(input: $input, condition: $condition) {
    id
    createdAt
    identifyingData
    contentData
    flightDocumentId
    flightId
    legId
    reservationId
    reservationEventId
    notIdentified
    contentKey
    deleted
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExternalDocumentMutationVariables,
  APITypes.DeleteExternalDocumentMutation
>;
export const createAircraftDocument = /* GraphQL */ `mutation CreateAircraftDocument(
  $input: CreateAircraftDocumentInput!
  $condition: ModelAircraftDocumentConditionInput
) {
  createAircraftDocument(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    aircraftId
    contentKey
    name
    documentNumber
    issueDate
    validUntilDate
    validUntilDateToleranceSeconds
    validUntilCountableTypes
    validUntilCountableValues
    validUntilCountableValuesTolerance
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAircraftDocumentMutationVariables,
  APITypes.CreateAircraftDocumentMutation
>;
export const updateAircraftDocument = /* GraphQL */ `mutation UpdateAircraftDocument(
  $input: UpdateAircraftDocumentInput!
  $condition: ModelAircraftDocumentConditionInput
) {
  updateAircraftDocument(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    aircraftId
    contentKey
    name
    documentNumber
    issueDate
    validUntilDate
    validUntilDateToleranceSeconds
    validUntilCountableTypes
    validUntilCountableValues
    validUntilCountableValuesTolerance
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAircraftDocumentMutationVariables,
  APITypes.UpdateAircraftDocumentMutation
>;
export const deleteAircraftDocument = /* GraphQL */ `mutation DeleteAircraftDocument(
  $input: DeleteAircraftDocumentInput!
  $condition: ModelAircraftDocumentConditionInput
) {
  deleteAircraftDocument(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    aircraftId
    contentKey
    name
    documentNumber
    issueDate
    validUntilDate
    validUntilDateToleranceSeconds
    validUntilCountableTypes
    validUntilCountableValues
    validUntilCountableValuesTolerance
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAircraftDocumentMutationVariables,
  APITypes.DeleteAircraftDocumentMutation
>;
export const createAircraftMaintenanceLimit = /* GraphQL */ `mutation CreateAircraftMaintenanceLimit(
  $input: CreateAircraftMaintenanceLimitInput!
  $condition: ModelAircraftMaintenanceLimitConditionInput
) {
  createAircraftMaintenanceLimit(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    aircraftId
    name
    validUntilDate
    validUntilDateToleranceSeconds
    validUntilCountableTypes
    validUntilCountableValues
    validUntilCountableValuesTolerance
    customWarningRules
    limitExtensionRules
    maintenanceProgramTaskWid
    extensions {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAircraftMaintenanceLimitMutationVariables,
  APITypes.CreateAircraftMaintenanceLimitMutation
>;
export const updateAircraftMaintenanceLimit = /* GraphQL */ `mutation UpdateAircraftMaintenanceLimit(
  $input: UpdateAircraftMaintenanceLimitInput!
  $condition: ModelAircraftMaintenanceLimitConditionInput
) {
  updateAircraftMaintenanceLimit(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    aircraftId
    name
    validUntilDate
    validUntilDateToleranceSeconds
    validUntilCountableTypes
    validUntilCountableValues
    validUntilCountableValuesTolerance
    customWarningRules
    limitExtensionRules
    maintenanceProgramTaskWid
    extensions {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAircraftMaintenanceLimitMutationVariables,
  APITypes.UpdateAircraftMaintenanceLimitMutation
>;
export const deleteAircraftMaintenanceLimit = /* GraphQL */ `mutation DeleteAircraftMaintenanceLimit(
  $input: DeleteAircraftMaintenanceLimitInput!
  $condition: ModelAircraftMaintenanceLimitConditionInput
) {
  deleteAircraftMaintenanceLimit(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    aircraftId
    name
    validUntilDate
    validUntilDateToleranceSeconds
    validUntilCountableTypes
    validUntilCountableValues
    validUntilCountableValuesTolerance
    customWarningRules
    limitExtensionRules
    maintenanceProgramTaskWid
    extensions {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAircraftMaintenanceLimitMutationVariables,
  APITypes.DeleteAircraftMaintenanceLimitMutation
>;
export const createAircraftMaintenanceLimitExtension = /* GraphQL */ `mutation CreateAircraftMaintenanceLimitExtension(
  $input: CreateAircraftMaintenanceLimitExtensionInput!
  $condition: ModelAircraftMaintenanceLimitExtensionConditionInput
) {
  createAircraftMaintenanceLimitExtension(
    input: $input
    condition: $condition
  ) {
    id
    deleted
    createdAt
    maintenanceLimitId
    maintenanceLimitVersion
    maintenanceRecordId
    createdByUserId
    extendedByName
    validUntilDate
    validUntilCountableValues
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAircraftMaintenanceLimitExtensionMutationVariables,
  APITypes.CreateAircraftMaintenanceLimitExtensionMutation
>;
export const updateAircraftMaintenanceLimitExtension = /* GraphQL */ `mutation UpdateAircraftMaintenanceLimitExtension(
  $input: UpdateAircraftMaintenanceLimitExtensionInput!
  $condition: ModelAircraftMaintenanceLimitExtensionConditionInput
) {
  updateAircraftMaintenanceLimitExtension(
    input: $input
    condition: $condition
  ) {
    id
    deleted
    createdAt
    maintenanceLimitId
    maintenanceLimitVersion
    maintenanceRecordId
    createdByUserId
    extendedByName
    validUntilDate
    validUntilCountableValues
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAircraftMaintenanceLimitExtensionMutationVariables,
  APITypes.UpdateAircraftMaintenanceLimitExtensionMutation
>;
export const deleteAircraftMaintenanceLimitExtension = /* GraphQL */ `mutation DeleteAircraftMaintenanceLimitExtension(
  $input: DeleteAircraftMaintenanceLimitExtensionInput!
  $condition: ModelAircraftMaintenanceLimitExtensionConditionInput
) {
  deleteAircraftMaintenanceLimitExtension(
    input: $input
    condition: $condition
  ) {
    id
    deleted
    createdAt
    maintenanceLimitId
    maintenanceLimitVersion
    maintenanceRecordId
    createdByUserId
    extendedByName
    validUntilDate
    validUntilCountableValues
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAircraftMaintenanceLimitExtensionMutationVariables,
  APITypes.DeleteAircraftMaintenanceLimitExtensionMutation
>;
export const createMaintenanceProgram = /* GraphQL */ `mutation CreateMaintenanceProgram(
  $input: CreateMaintenanceProgramInput!
  $condition: ModelMaintenanceProgramConditionInput
) {
  createMaintenanceProgram(input: $input, condition: $condition) {
    id
    deleted
    title
    revision
    issueDate
    effectiveDate
    approvedBy
    tasks
    nextDueRules
    maintenanceTasks {
      nextToken
      startedAt
      __typename
    }
    aircrafts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMaintenanceProgramMutationVariables,
  APITypes.CreateMaintenanceProgramMutation
>;
export const updateMaintenanceProgram = /* GraphQL */ `mutation UpdateMaintenanceProgram(
  $input: UpdateMaintenanceProgramInput!
  $condition: ModelMaintenanceProgramConditionInput
) {
  updateMaintenanceProgram(input: $input, condition: $condition) {
    id
    deleted
    title
    revision
    issueDate
    effectiveDate
    approvedBy
    tasks
    nextDueRules
    maintenanceTasks {
      nextToken
      startedAt
      __typename
    }
    aircrafts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMaintenanceProgramMutationVariables,
  APITypes.UpdateMaintenanceProgramMutation
>;
export const deleteMaintenanceProgram = /* GraphQL */ `mutation DeleteMaintenanceProgram(
  $input: DeleteMaintenanceProgramInput!
  $condition: ModelMaintenanceProgramConditionInput
) {
  deleteMaintenanceProgram(input: $input, condition: $condition) {
    id
    deleted
    title
    revision
    issueDate
    effectiveDate
    approvedBy
    tasks
    nextDueRules
    maintenanceTasks {
      nextToken
      startedAt
      __typename
    }
    aircrafts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMaintenanceProgramMutationVariables,
  APITypes.DeleteMaintenanceProgramMutation
>;
export const createMaintenanceProgramTask = /* GraphQL */ `mutation CreateMaintenanceProgramTask(
  $input: CreateMaintenanceProgramTaskInput!
  $condition: ModelMaintenanceProgramTaskConditionInput
) {
  createMaintenanceProgramTask(input: $input, condition: $condition) {
    id
    wid
    deleted
    createdAt
    maintenanceProgramId
    title
    description
    type
    taskClass
    isPerformableByPilot
    taskNumber
    groups
    ataNumber
    intervals
    completesTaskIds
    requiresTaskIds
    applicableToAircraftIds
    applicabilityNote
    applicabilityGroup
    files
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMaintenanceProgramTaskMutationVariables,
  APITypes.CreateMaintenanceProgramTaskMutation
>;
export const updateMaintenanceProgramTask = /* GraphQL */ `mutation UpdateMaintenanceProgramTask(
  $input: UpdateMaintenanceProgramTaskInput!
  $condition: ModelMaintenanceProgramTaskConditionInput
) {
  updateMaintenanceProgramTask(input: $input, condition: $condition) {
    id
    wid
    deleted
    createdAt
    maintenanceProgramId
    title
    description
    type
    taskClass
    isPerformableByPilot
    taskNumber
    groups
    ataNumber
    intervals
    completesTaskIds
    requiresTaskIds
    applicableToAircraftIds
    applicabilityNote
    applicabilityGroup
    files
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMaintenanceProgramTaskMutationVariables,
  APITypes.UpdateMaintenanceProgramTaskMutation
>;
export const deleteMaintenanceProgramTask = /* GraphQL */ `mutation DeleteMaintenanceProgramTask(
  $input: DeleteMaintenanceProgramTaskInput!
  $condition: ModelMaintenanceProgramTaskConditionInput
) {
  deleteMaintenanceProgramTask(input: $input, condition: $condition) {
    id
    wid
    deleted
    createdAt
    maintenanceProgramId
    title
    description
    type
    taskClass
    isPerformableByPilot
    taskNumber
    groups
    ataNumber
    intervals
    completesTaskIds
    requiresTaskIds
    applicableToAircraftIds
    applicabilityNote
    applicabilityGroup
    files
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMaintenanceProgramTaskMutationVariables,
  APITypes.DeleteMaintenanceProgramTaskMutation
>;
export const createAircraftMaintenanceIntervalChange = /* GraphQL */ `mutation CreateAircraftMaintenanceIntervalChange(
  $input: CreateAircraftMaintenanceIntervalChangeInput!
  $condition: ModelAircraftMaintenanceIntervalChangeConditionInput
) {
  createAircraftMaintenanceIntervalChange(
    input: $input
    condition: $condition
  ) {
    id
    deleted
    aircraftId
    createdAt
    maintenanceProgramId
    maintenanceRecordId
    maintenanceEventId
    intervals
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAircraftMaintenanceIntervalChangeMutationVariables,
  APITypes.CreateAircraftMaintenanceIntervalChangeMutation
>;
export const updateAircraftMaintenanceIntervalChange = /* GraphQL */ `mutation UpdateAircraftMaintenanceIntervalChange(
  $input: UpdateAircraftMaintenanceIntervalChangeInput!
  $condition: ModelAircraftMaintenanceIntervalChangeConditionInput
) {
  updateAircraftMaintenanceIntervalChange(
    input: $input
    condition: $condition
  ) {
    id
    deleted
    aircraftId
    createdAt
    maintenanceProgramId
    maintenanceRecordId
    maintenanceEventId
    intervals
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAircraftMaintenanceIntervalChangeMutationVariables,
  APITypes.UpdateAircraftMaintenanceIntervalChangeMutation
>;
export const deleteAircraftMaintenanceIntervalChange = /* GraphQL */ `mutation DeleteAircraftMaintenanceIntervalChange(
  $input: DeleteAircraftMaintenanceIntervalChangeInput!
  $condition: ModelAircraftMaintenanceIntervalChangeConditionInput
) {
  deleteAircraftMaintenanceIntervalChange(
    input: $input
    condition: $condition
  ) {
    id
    deleted
    aircraftId
    createdAt
    maintenanceProgramId
    maintenanceRecordId
    maintenanceEventId
    intervals
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAircraftMaintenanceIntervalChangeMutationVariables,
  APITypes.DeleteAircraftMaintenanceIntervalChangeMutation
>;
export const createDutyItem = /* GraphQL */ `mutation CreateDutyItem(
  $input: CreateDutyItemInput!
  $condition: ModelDutyItemConditionInput
) {
  createDutyItem(input: $input, condition: $condition) {
    id
    editableByUserIds
    deleted
    userId
    crewId
    state
    dutyPeriodId
    forceRestAfter
    note
    type
    startDate
    endDate
    sectorCount
    endPlaceIcao
    blockTimeMinutes
    plannedFdpExtension
    flightLegIds
    logbookRecordIds
    linkedDutyItemIds
    reports {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDutyItemMutationVariables,
  APITypes.CreateDutyItemMutation
>;
export const updateDutyItem = /* GraphQL */ `mutation UpdateDutyItem(
  $input: UpdateDutyItemInput!
  $condition: ModelDutyItemConditionInput
) {
  updateDutyItem(input: $input, condition: $condition) {
    id
    editableByUserIds
    deleted
    userId
    crewId
    state
    dutyPeriodId
    forceRestAfter
    note
    type
    startDate
    endDate
    sectorCount
    endPlaceIcao
    blockTimeMinutes
    plannedFdpExtension
    flightLegIds
    logbookRecordIds
    linkedDutyItemIds
    reports {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDutyItemMutationVariables,
  APITypes.UpdateDutyItemMutation
>;
export const deleteDutyItem = /* GraphQL */ `mutation DeleteDutyItem(
  $input: DeleteDutyItemInput!
  $condition: ModelDutyItemConditionInput
) {
  deleteDutyItem(input: $input, condition: $condition) {
    id
    editableByUserIds
    deleted
    userId
    crewId
    state
    dutyPeriodId
    forceRestAfter
    note
    type
    startDate
    endDate
    sectorCount
    endPlaceIcao
    blockTimeMinutes
    plannedFdpExtension
    flightLegIds
    logbookRecordIds
    linkedDutyItemIds
    reports {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDutyItemMutationVariables,
  APITypes.DeleteDutyItemMutation
>;
export const createContact = /* GraphQL */ `mutation CreateContact(
  $input: CreateContactInput!
  $condition: ModelContactConditionInput
) {
  createContact(input: $input, condition: $condition) {
    id
    userId
    isActiveCrew
    aircraftVisiblityLimitations
    crewId
    hasDutyTracking
    fullReadAccessToAircrafts
    deleted
    nameOfficialFirst
    nameOfficialLast
    name
    notes
    isReservationResource
    isCustomer
    calendarId
    baseAirportIcaos
    dispatchGroup
    accessRights
    contactInformations {
      nextToken
      startedAt
      __typename
    }
    contactDocuments {
      nextToken
      startedAt
      __typename
    }
    documentSnippets {
      nextToken
      startedAt
      __typename
    }
    crewQualifications {
      nextToken
      startedAt
      __typename
    }
    tags {
      nextToken
      startedAt
      __typename
    }
    crewRoles {
      nextToken
      startedAt
      __typename
    }
    logbookRecords {
      nextToken
      startedAt
      __typename
    }
    reservations {
      nextToken
      startedAt
      __typename
    }
    reports {
      nextToken
      startedAt
      __typename
    }
    hazardsAsAuthor {
      nextToken
      startedAt
      __typename
    }
    hazardsAsApprover {
      nextToken
      startedAt
      __typename
    }
    hazardsAsResponsiblePerson {
      nextToken
      startedAt
      __typename
    }
    hazardsAsEffectivePerson {
      nextToken
      startedAt
      __typename
    }
    customerAccounts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactMutationVariables,
  APITypes.CreateContactMutation
>;
export const updateContact = /* GraphQL */ `mutation UpdateContact(
  $input: UpdateContactInput!
  $condition: ModelContactConditionInput
) {
  updateContact(input: $input, condition: $condition) {
    id
    userId
    isActiveCrew
    aircraftVisiblityLimitations
    crewId
    hasDutyTracking
    fullReadAccessToAircrafts
    deleted
    nameOfficialFirst
    nameOfficialLast
    name
    notes
    isReservationResource
    isCustomer
    calendarId
    baseAirportIcaos
    dispatchGroup
    accessRights
    contactInformations {
      nextToken
      startedAt
      __typename
    }
    contactDocuments {
      nextToken
      startedAt
      __typename
    }
    documentSnippets {
      nextToken
      startedAt
      __typename
    }
    crewQualifications {
      nextToken
      startedAt
      __typename
    }
    tags {
      nextToken
      startedAt
      __typename
    }
    crewRoles {
      nextToken
      startedAt
      __typename
    }
    logbookRecords {
      nextToken
      startedAt
      __typename
    }
    reservations {
      nextToken
      startedAt
      __typename
    }
    reports {
      nextToken
      startedAt
      __typename
    }
    hazardsAsAuthor {
      nextToken
      startedAt
      __typename
    }
    hazardsAsApprover {
      nextToken
      startedAt
      __typename
    }
    hazardsAsResponsiblePerson {
      nextToken
      startedAt
      __typename
    }
    hazardsAsEffectivePerson {
      nextToken
      startedAt
      __typename
    }
    customerAccounts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactMutationVariables,
  APITypes.UpdateContactMutation
>;
export const deleteContact = /* GraphQL */ `mutation DeleteContact(
  $input: DeleteContactInput!
  $condition: ModelContactConditionInput
) {
  deleteContact(input: $input, condition: $condition) {
    id
    userId
    isActiveCrew
    aircraftVisiblityLimitations
    crewId
    hasDutyTracking
    fullReadAccessToAircrafts
    deleted
    nameOfficialFirst
    nameOfficialLast
    name
    notes
    isReservationResource
    isCustomer
    calendarId
    baseAirportIcaos
    dispatchGroup
    accessRights
    contactInformations {
      nextToken
      startedAt
      __typename
    }
    contactDocuments {
      nextToken
      startedAt
      __typename
    }
    documentSnippets {
      nextToken
      startedAt
      __typename
    }
    crewQualifications {
      nextToken
      startedAt
      __typename
    }
    tags {
      nextToken
      startedAt
      __typename
    }
    crewRoles {
      nextToken
      startedAt
      __typename
    }
    logbookRecords {
      nextToken
      startedAt
      __typename
    }
    reservations {
      nextToken
      startedAt
      __typename
    }
    reports {
      nextToken
      startedAt
      __typename
    }
    hazardsAsAuthor {
      nextToken
      startedAt
      __typename
    }
    hazardsAsApprover {
      nextToken
      startedAt
      __typename
    }
    hazardsAsResponsiblePerson {
      nextToken
      startedAt
      __typename
    }
    hazardsAsEffectivePerson {
      nextToken
      startedAt
      __typename
    }
    customerAccounts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactMutationVariables,
  APITypes.DeleteContactMutation
>;
export const createContactDocument = /* GraphQL */ `mutation CreateContactDocument(
  $input: CreateContactDocumentInput!
  $condition: ModelContactDocumentConditionInput
) {
  createContactDocument(input: $input, condition: $condition) {
    id
    deleted
    deletedPages
    editableByUserId
    createdAt
    contactId
    crewQualificationId
    type
    contentKey
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactDocumentMutationVariables,
  APITypes.CreateContactDocumentMutation
>;
export const updateContactDocument = /* GraphQL */ `mutation UpdateContactDocument(
  $input: UpdateContactDocumentInput!
  $condition: ModelContactDocumentConditionInput
) {
  updateContactDocument(input: $input, condition: $condition) {
    id
    deleted
    deletedPages
    editableByUserId
    createdAt
    contactId
    crewQualificationId
    type
    contentKey
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactDocumentMutationVariables,
  APITypes.UpdateContactDocumentMutation
>;
export const deleteContactDocument = /* GraphQL */ `mutation DeleteContactDocument(
  $input: DeleteContactDocumentInput!
  $condition: ModelContactDocumentConditionInput
) {
  deleteContactDocument(input: $input, condition: $condition) {
    id
    deleted
    deletedPages
    editableByUserId
    createdAt
    contactId
    crewQualificationId
    type
    contentKey
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactDocumentMutationVariables,
  APITypes.DeleteContactDocumentMutation
>;
export const createContactInformation = /* GraphQL */ `mutation CreateContactInformation(
  $input: CreateContactInformationInput!
  $condition: ModelContactInformationConditionInput
) {
  createContactInformation(input: $input, condition: $condition) {
    id
    deleted
    accessibleByUserIds
    type
    priority
    userId
    contactId
    nameFirst
    nameMiddle
    nameLast
    companyName
    vatNumber
    contactType
    identificationNumber
    displayName
    dateOfBirth
    placeOfBirth
    passportNumber
    nationality
    passportExpirtyDate
    contactPhone
    contactEmail
    contactAddressStreet
    contactAddressStreetNumber
    contactAddressCity
    contactAddressState
    contactAddressZipCode
    contactAddressCountry
    communicationPreferenceTags
    preferredLocales
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactInformationMutationVariables,
  APITypes.CreateContactInformationMutation
>;
export const updateContactInformation = /* GraphQL */ `mutation UpdateContactInformation(
  $input: UpdateContactInformationInput!
  $condition: ModelContactInformationConditionInput
) {
  updateContactInformation(input: $input, condition: $condition) {
    id
    deleted
    accessibleByUserIds
    type
    priority
    userId
    contactId
    nameFirst
    nameMiddle
    nameLast
    companyName
    vatNumber
    contactType
    identificationNumber
    displayName
    dateOfBirth
    placeOfBirth
    passportNumber
    nationality
    passportExpirtyDate
    contactPhone
    contactEmail
    contactAddressStreet
    contactAddressStreetNumber
    contactAddressCity
    contactAddressState
    contactAddressZipCode
    contactAddressCountry
    communicationPreferenceTags
    preferredLocales
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactInformationMutationVariables,
  APITypes.UpdateContactInformationMutation
>;
export const deleteContactInformation = /* GraphQL */ `mutation DeleteContactInformation(
  $input: DeleteContactInformationInput!
  $condition: ModelContactInformationConditionInput
) {
  deleteContactInformation(input: $input, condition: $condition) {
    id
    deleted
    accessibleByUserIds
    type
    priority
    userId
    contactId
    nameFirst
    nameMiddle
    nameLast
    companyName
    vatNumber
    contactType
    identificationNumber
    displayName
    dateOfBirth
    placeOfBirth
    passportNumber
    nationality
    passportExpirtyDate
    contactPhone
    contactEmail
    contactAddressStreet
    contactAddressStreetNumber
    contactAddressCity
    contactAddressState
    contactAddressZipCode
    contactAddressCountry
    communicationPreferenceTags
    preferredLocales
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactInformationMutationVariables,
  APITypes.DeleteContactInformationMutation
>;
export const createCrewQualification = /* GraphQL */ `mutation CreateCrewQualification(
  $input: CreateCrewQualificationInput!
  $condition: ModelCrewQualificationConditionInput
) {
  createCrewQualification(input: $input, condition: $condition) {
    id
    searchId
    deleted
    contactId
    userId
    qualificationTypeId
    title
    qualificationData
    dependsOnQualificationId
    licenseNumber
    issuedDate
    validUntilDate
    noHighlighWhenExpired
    isWaived
    contactDocuments {
      nextToken
      startedAt
      __typename
    }
    documentSnippets {
      nextToken
      startedAt
      __typename
    }
    dependingQualifications {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCrewQualificationMutationVariables,
  APITypes.CreateCrewQualificationMutation
>;
export const updateCrewQualification = /* GraphQL */ `mutation UpdateCrewQualification(
  $input: UpdateCrewQualificationInput!
  $condition: ModelCrewQualificationConditionInput
) {
  updateCrewQualification(input: $input, condition: $condition) {
    id
    searchId
    deleted
    contactId
    userId
    qualificationTypeId
    title
    qualificationData
    dependsOnQualificationId
    licenseNumber
    issuedDate
    validUntilDate
    noHighlighWhenExpired
    isWaived
    contactDocuments {
      nextToken
      startedAt
      __typename
    }
    documentSnippets {
      nextToken
      startedAt
      __typename
    }
    dependingQualifications {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCrewQualificationMutationVariables,
  APITypes.UpdateCrewQualificationMutation
>;
export const deleteCrewQualification = /* GraphQL */ `mutation DeleteCrewQualification(
  $input: DeleteCrewQualificationInput!
  $condition: ModelCrewQualificationConditionInput
) {
  deleteCrewQualification(input: $input, condition: $condition) {
    id
    searchId
    deleted
    contactId
    userId
    qualificationTypeId
    title
    qualificationData
    dependsOnQualificationId
    licenseNumber
    issuedDate
    validUntilDate
    noHighlighWhenExpired
    isWaived
    contactDocuments {
      nextToken
      startedAt
      __typename
    }
    documentSnippets {
      nextToken
      startedAt
      __typename
    }
    dependingQualifications {
      nextToken
      startedAt
      __typename
    }
    reminders {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCrewQualificationMutationVariables,
  APITypes.DeleteCrewQualificationMutation
>;
export const createCrewQualificationType = /* GraphQL */ `mutation CreateCrewQualificationType(
  $input: CreateCrewQualificationTypeInput!
  $condition: ModelCrewQualificationTypeConditionInput
) {
  createCrewQualificationType(input: $input, condition: $condition) {
    id
    title
    category
    dependsOnTypeIds
    deleted
    defaultValidity
    hasFixedTitle
    hasNoLicenseNumber
    hasNoIssueDate
    description
    files
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCrewQualificationTypeMutationVariables,
  APITypes.CreateCrewQualificationTypeMutation
>;
export const updateCrewQualificationType = /* GraphQL */ `mutation UpdateCrewQualificationType(
  $input: UpdateCrewQualificationTypeInput!
  $condition: ModelCrewQualificationTypeConditionInput
) {
  updateCrewQualificationType(input: $input, condition: $condition) {
    id
    title
    category
    dependsOnTypeIds
    deleted
    defaultValidity
    hasFixedTitle
    hasNoLicenseNumber
    hasNoIssueDate
    description
    files
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCrewQualificationTypeMutationVariables,
  APITypes.UpdateCrewQualificationTypeMutation
>;
export const deleteCrewQualificationType = /* GraphQL */ `mutation DeleteCrewQualificationType(
  $input: DeleteCrewQualificationTypeInput!
  $condition: ModelCrewQualificationTypeConditionInput
) {
  deleteCrewQualificationType(input: $input, condition: $condition) {
    id
    title
    category
    dependsOnTypeIds
    deleted
    defaultValidity
    hasFixedTitle
    hasNoLicenseNumber
    hasNoIssueDate
    description
    files
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCrewQualificationTypeMutationVariables,
  APITypes.DeleteCrewQualificationTypeMutation
>;
export const createDocumentSnippet = /* GraphQL */ `mutation CreateDocumentSnippet(
  $input: CreateDocumentSnippetInput!
  $condition: ModelDocumentSnippetConditionInput
) {
  createDocumentSnippet(input: $input, condition: $condition) {
    id
    name
    contactId
    crewQualificationId
    deleted
    contentKey
    pages {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentSnippetMutationVariables,
  APITypes.CreateDocumentSnippetMutation
>;
export const updateDocumentSnippet = /* GraphQL */ `mutation UpdateDocumentSnippet(
  $input: UpdateDocumentSnippetInput!
  $condition: ModelDocumentSnippetConditionInput
) {
  updateDocumentSnippet(input: $input, condition: $condition) {
    id
    name
    contactId
    crewQualificationId
    deleted
    contentKey
    pages {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentSnippetMutationVariables,
  APITypes.UpdateDocumentSnippetMutation
>;
export const deleteDocumentSnippet = /* GraphQL */ `mutation DeleteDocumentSnippet(
  $input: DeleteDocumentSnippetInput!
  $condition: ModelDocumentSnippetConditionInput
) {
  deleteDocumentSnippet(input: $input, condition: $condition) {
    id
    name
    contactId
    crewQualificationId
    deleted
    contentKey
    pages {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentSnippetMutationVariables,
  APITypes.DeleteDocumentSnippetMutation
>;
export const createDocumentSnippetPage = /* GraphQL */ `mutation CreateDocumentSnippetPage(
  $input: CreateDocumentSnippetPageInput!
  $condition: ModelDocumentSnippetPageConditionInput
) {
  createDocumentSnippetPage(input: $input, condition: $condition) {
    id
    documentSnippetId
    page
    sourceContactDocumentId
    sourceContentKey
    sourcePage
    definition
    contentKey
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentSnippetPageMutationVariables,
  APITypes.CreateDocumentSnippetPageMutation
>;
export const updateDocumentSnippetPage = /* GraphQL */ `mutation UpdateDocumentSnippetPage(
  $input: UpdateDocumentSnippetPageInput!
  $condition: ModelDocumentSnippetPageConditionInput
) {
  updateDocumentSnippetPage(input: $input, condition: $condition) {
    id
    documentSnippetId
    page
    sourceContactDocumentId
    sourceContentKey
    sourcePage
    definition
    contentKey
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentSnippetPageMutationVariables,
  APITypes.UpdateDocumentSnippetPageMutation
>;
export const deleteDocumentSnippetPage = /* GraphQL */ `mutation DeleteDocumentSnippetPage(
  $input: DeleteDocumentSnippetPageInput!
  $condition: ModelDocumentSnippetPageConditionInput
) {
  deleteDocumentSnippetPage(input: $input, condition: $condition) {
    id
    documentSnippetId
    page
    sourceContactDocumentId
    sourceContentKey
    sourcePage
    definition
    contentKey
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentSnippetPageMutationVariables,
  APITypes.DeleteDocumentSnippetPageMutation
>;
export const createAirport = /* GraphQL */ `mutation CreateAirport(
  $input: CreateAirportInput!
  $condition: ModelAirportConditionInput
) {
  createAirport(input: $input, condition: $condition) {
    id
    icao
    icaoFirstOne
    icaoFirstTwo
    name
    latitude
    longitude
    public
    closed
    instrument
    instrumentLowestCeilingFt
    instrumentLowestRvrM
    night
    elevation
    timezone
    dataSources
    priceProfile
    suitableToAlternate
    providersData
    runways {
      nextToken
      startedAt
      __typename
    }
    categorizations {
      nextToken
      startedAt
      __typename
    }
    dispatchNotes {
      nextToken
      startedAt
      __typename
    }
    dispatchNoteCustomPages {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAirportMutationVariables,
  APITypes.CreateAirportMutation
>;
export const updateAirport = /* GraphQL */ `mutation UpdateAirport(
  $input: UpdateAirportInput!
  $condition: ModelAirportConditionInput
) {
  updateAirport(input: $input, condition: $condition) {
    id
    icao
    icaoFirstOne
    icaoFirstTwo
    name
    latitude
    longitude
    public
    closed
    instrument
    instrumentLowestCeilingFt
    instrumentLowestRvrM
    night
    elevation
    timezone
    dataSources
    priceProfile
    suitableToAlternate
    providersData
    runways {
      nextToken
      startedAt
      __typename
    }
    categorizations {
      nextToken
      startedAt
      __typename
    }
    dispatchNotes {
      nextToken
      startedAt
      __typename
    }
    dispatchNoteCustomPages {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAirportMutationVariables,
  APITypes.UpdateAirportMutation
>;
export const deleteAirport = /* GraphQL */ `mutation DeleteAirport(
  $input: DeleteAirportInput!
  $condition: ModelAirportConditionInput
) {
  deleteAirport(input: $input, condition: $condition) {
    id
    icao
    icaoFirstOne
    icaoFirstTwo
    name
    latitude
    longitude
    public
    closed
    instrument
    instrumentLowestCeilingFt
    instrumentLowestRvrM
    night
    elevation
    timezone
    dataSources
    priceProfile
    suitableToAlternate
    providersData
    runways {
      nextToken
      startedAt
      __typename
    }
    categorizations {
      nextToken
      startedAt
      __typename
    }
    dispatchNotes {
      nextToken
      startedAt
      __typename
    }
    dispatchNoteCustomPages {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAirportMutationVariables,
  APITypes.DeleteAirportMutation
>;
export const createAirportRunway = /* GraphQL */ `mutation CreateAirportRunway(
  $input: CreateAirportRunwayInput!
  $condition: ModelAirportRunwayConditionInput
) {
  createAirportRunway(input: $input, condition: $condition) {
    id
    airportId
    designationLow
    designationHigh
    surface
    lighted
    closed
    instrumentApproachPrecision
    instrumentApproachNonPrecision
    length
    width
    toraLow
    todaLow
    asdaLow
    ldaLow
    toraHigh
    todaHigh
    asdaHigh
    ldaHigh
    headingTrueLow
    headingTrueHigh
    thresholdLatitudeLow
    thresholdLongitudeLow
    thresholdLatitudeHigh
    thresholdLongitudeHigh
    thresholdElevationLow
    thresholdElevationHigh
    dataSources
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAirportRunwayMutationVariables,
  APITypes.CreateAirportRunwayMutation
>;
export const updateAirportRunway = /* GraphQL */ `mutation UpdateAirportRunway(
  $input: UpdateAirportRunwayInput!
  $condition: ModelAirportRunwayConditionInput
) {
  updateAirportRunway(input: $input, condition: $condition) {
    id
    airportId
    designationLow
    designationHigh
    surface
    lighted
    closed
    instrumentApproachPrecision
    instrumentApproachNonPrecision
    length
    width
    toraLow
    todaLow
    asdaLow
    ldaLow
    toraHigh
    todaHigh
    asdaHigh
    ldaHigh
    headingTrueLow
    headingTrueHigh
    thresholdLatitudeLow
    thresholdLongitudeLow
    thresholdLatitudeHigh
    thresholdLongitudeHigh
    thresholdElevationLow
    thresholdElevationHigh
    dataSources
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAirportRunwayMutationVariables,
  APITypes.UpdateAirportRunwayMutation
>;
export const deleteAirportRunway = /* GraphQL */ `mutation DeleteAirportRunway(
  $input: DeleteAirportRunwayInput!
  $condition: ModelAirportRunwayConditionInput
) {
  deleteAirportRunway(input: $input, condition: $condition) {
    id
    airportId
    designationLow
    designationHigh
    surface
    lighted
    closed
    instrumentApproachPrecision
    instrumentApproachNonPrecision
    length
    width
    toraLow
    todaLow
    asdaLow
    ldaLow
    toraHigh
    todaHigh
    asdaHigh
    ldaHigh
    headingTrueLow
    headingTrueHigh
    thresholdLatitudeLow
    thresholdLongitudeLow
    thresholdLatitudeHigh
    thresholdLongitudeHigh
    thresholdElevationLow
    thresholdElevationHigh
    dataSources
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAirportRunwayMutationVariables,
  APITypes.DeleteAirportRunwayMutation
>;
export const createAirportCategorization = /* GraphQL */ `mutation CreateAirportCategorization(
  $input: CreateAirportCategorizationInput!
  $condition: ModelAirportCategorizationConditionInput
) {
  createAirportCategorization(input: $input, condition: $condition) {
    id
    name
    icao
    categorizationData
    pilotNotes {
      nextToken
      startedAt
      __typename
    }
    airports {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAirportCategorizationMutationVariables,
  APITypes.CreateAirportCategorizationMutation
>;
export const updateAirportCategorization = /* GraphQL */ `mutation UpdateAirportCategorization(
  $input: UpdateAirportCategorizationInput!
  $condition: ModelAirportCategorizationConditionInput
) {
  updateAirportCategorization(input: $input, condition: $condition) {
    id
    name
    icao
    categorizationData
    pilotNotes {
      nextToken
      startedAt
      __typename
    }
    airports {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAirportCategorizationMutationVariables,
  APITypes.UpdateAirportCategorizationMutation
>;
export const deleteAirportCategorization = /* GraphQL */ `mutation DeleteAirportCategorization(
  $input: DeleteAirportCategorizationInput!
  $condition: ModelAirportCategorizationConditionInput
) {
  deleteAirportCategorization(input: $input, condition: $condition) {
    id
    name
    icao
    categorizationData
    pilotNotes {
      nextToken
      startedAt
      __typename
    }
    airports {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAirportCategorizationMutationVariables,
  APITypes.DeleteAirportCategorizationMutation
>;
export const createAirportDispatchNote = /* GraphQL */ `mutation CreateAirportDispatchNote(
  $input: CreateAirportDispatchNoteInput!
  $condition: ModelAirportDispatchNoteConditionInput
) {
  createAirportDispatchNote(input: $input, condition: $condition) {
    id
    airportId
    createdDate
    formData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAirportDispatchNoteMutationVariables,
  APITypes.CreateAirportDispatchNoteMutation
>;
export const updateAirportDispatchNote = /* GraphQL */ `mutation UpdateAirportDispatchNote(
  $input: UpdateAirportDispatchNoteInput!
  $condition: ModelAirportDispatchNoteConditionInput
) {
  updateAirportDispatchNote(input: $input, condition: $condition) {
    id
    airportId
    createdDate
    formData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAirportDispatchNoteMutationVariables,
  APITypes.UpdateAirportDispatchNoteMutation
>;
export const deleteAirportDispatchNote = /* GraphQL */ `mutation DeleteAirportDispatchNote(
  $input: DeleteAirportDispatchNoteInput!
  $condition: ModelAirportDispatchNoteConditionInput
) {
  deleteAirportDispatchNote(input: $input, condition: $condition) {
    id
    airportId
    createdDate
    formData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAirportDispatchNoteMutationVariables,
  APITypes.DeleteAirportDispatchNoteMutation
>;
export const createCustomPage = /* GraphQL */ `mutation CreateCustomPage(
  $input: CreateCustomPageInput!
  $condition: ModelCustomPageConditionInput
) {
  createCustomPage(input: $input, condition: $condition) {
    id
    title
    content
    htmlContent
    pilotNoteAirportCategorizationId
    dispatchNoteAirportId
    createdByUserId
    createdDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomPageMutationVariables,
  APITypes.CreateCustomPageMutation
>;
export const updateCustomPage = /* GraphQL */ `mutation UpdateCustomPage(
  $input: UpdateCustomPageInput!
  $condition: ModelCustomPageConditionInput
) {
  updateCustomPage(input: $input, condition: $condition) {
    id
    title
    content
    htmlContent
    pilotNoteAirportCategorizationId
    dispatchNoteAirportId
    createdByUserId
    createdDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomPageMutationVariables,
  APITypes.UpdateCustomPageMutation
>;
export const deleteCustomPage = /* GraphQL */ `mutation DeleteCustomPage(
  $input: DeleteCustomPageInput!
  $condition: ModelCustomPageConditionInput
) {
  deleteCustomPage(input: $input, condition: $condition) {
    id
    title
    content
    htmlContent
    pilotNoteAirportCategorizationId
    dispatchNoteAirportId
    createdByUserId
    createdDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomPageMutationVariables,
  APITypes.DeleteCustomPageMutation
>;
export const createSigningKeyChange = /* GraphQL */ `mutation CreateSigningKeyChange(
  $input: CreateSigningKeyChangeInput!
  $condition: ModelSigningKeyChangeConditionInput
) {
  createSigningKeyChange(input: $input, condition: $condition) {
    id
    devicePublicKey
    deviceKeyId
    temporaryPublicKey
    temporaryKeyId
    temporaryKeySignature
    signedByKeyId
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSigningKeyChangeMutationVariables,
  APITypes.CreateSigningKeyChangeMutation
>;
export const updateSigningKeyChange = /* GraphQL */ `mutation UpdateSigningKeyChange(
  $input: UpdateSigningKeyChangeInput!
  $condition: ModelSigningKeyChangeConditionInput
) {
  updateSigningKeyChange(input: $input, condition: $condition) {
    id
    devicePublicKey
    deviceKeyId
    temporaryPublicKey
    temporaryKeyId
    temporaryKeySignature
    signedByKeyId
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSigningKeyChangeMutationVariables,
  APITypes.UpdateSigningKeyChangeMutation
>;
export const deleteSigningKeyChange = /* GraphQL */ `mutation DeleteSigningKeyChange(
  $input: DeleteSigningKeyChangeInput!
  $condition: ModelSigningKeyChangeConditionInput
) {
  deleteSigningKeyChange(input: $input, condition: $condition) {
    id
    devicePublicKey
    deviceKeyId
    temporaryPublicKey
    temporaryKeyId
    temporaryKeySignature
    signedByKeyId
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSigningKeyChangeMutationVariables,
  APITypes.DeleteSigningKeyChangeMutation
>;
export const createSignedData = /* GraphQL */ `mutation CreateSignedData(
  $input: CreateSignedDataInput!
  $condition: ModelSignedDataConditionInput
) {
  createSignedData(input: $input, condition: $condition) {
    id
    data
    deviceSignature
    temporarySignature
    deviceKeyId
    temporaryKeyId
    createdAt
    flightId
    userId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSignedDataMutationVariables,
  APITypes.CreateSignedDataMutation
>;
export const updateSignedData = /* GraphQL */ `mutation UpdateSignedData(
  $input: UpdateSignedDataInput!
  $condition: ModelSignedDataConditionInput
) {
  updateSignedData(input: $input, condition: $condition) {
    id
    data
    deviceSignature
    temporarySignature
    deviceKeyId
    temporaryKeyId
    createdAt
    flightId
    userId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSignedDataMutationVariables,
  APITypes.UpdateSignedDataMutation
>;
export const deleteSignedData = /* GraphQL */ `mutation DeleteSignedData(
  $input: DeleteSignedDataInput!
  $condition: ModelSignedDataConditionInput
) {
  deleteSignedData(input: $input, condition: $condition) {
    id
    data
    deviceSignature
    temporarySignature
    deviceKeyId
    temporaryKeyId
    createdAt
    flightId
    userId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSignedDataMutationVariables,
  APITypes.DeleteSignedDataMutation
>;
export const createMailingText = /* GraphQL */ `mutation CreateMailingText(
  $input: CreateMailingTextInput!
  $condition: ModelMailingTextConditionInput
) {
  createMailingText(input: $input, condition: $condition) {
    id
    type
    createdDate
    title
    customPageEditorContent
    subject
    smsContent
    htmlContent
    customData
    attachmentKeys
    attachmentNames
    locale
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMailingTextMutationVariables,
  APITypes.CreateMailingTextMutation
>;
export const updateMailingText = /* GraphQL */ `mutation UpdateMailingText(
  $input: UpdateMailingTextInput!
  $condition: ModelMailingTextConditionInput
) {
  updateMailingText(input: $input, condition: $condition) {
    id
    type
    createdDate
    title
    customPageEditorContent
    subject
    smsContent
    htmlContent
    customData
    attachmentKeys
    attachmentNames
    locale
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMailingTextMutationVariables,
  APITypes.UpdateMailingTextMutation
>;
export const deleteMailingText = /* GraphQL */ `mutation DeleteMailingText(
  $input: DeleteMailingTextInput!
  $condition: ModelMailingTextConditionInput
) {
  deleteMailingText(input: $input, condition: $condition) {
    id
    type
    createdDate
    title
    customPageEditorContent
    subject
    smsContent
    htmlContent
    customData
    attachmentKeys
    attachmentNames
    locale
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMailingTextMutationVariables,
  APITypes.DeleteMailingTextMutation
>;
export const createMailingReminder = /* GraphQL */ `mutation CreateMailingReminder(
  $input: CreateMailingReminderInput!
  $condition: ModelMailingReminderConditionInput
) {
  createMailingReminder(input: $input, condition: $condition) {
    id
    crewQualificationId
    squawkId
    sentDate
    mailingTextId
    customData
    method
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMailingReminderMutationVariables,
  APITypes.CreateMailingReminderMutation
>;
export const updateMailingReminder = /* GraphQL */ `mutation UpdateMailingReminder(
  $input: UpdateMailingReminderInput!
  $condition: ModelMailingReminderConditionInput
) {
  updateMailingReminder(input: $input, condition: $condition) {
    id
    crewQualificationId
    squawkId
    sentDate
    mailingTextId
    customData
    method
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMailingReminderMutationVariables,
  APITypes.UpdateMailingReminderMutation
>;
export const deleteMailingReminder = /* GraphQL */ `mutation DeleteMailingReminder(
  $input: DeleteMailingReminderInput!
  $condition: ModelMailingReminderConditionInput
) {
  deleteMailingReminder(input: $input, condition: $condition) {
    id
    crewQualificationId
    squawkId
    sentDate
    mailingTextId
    customData
    method
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMailingReminderMutationVariables,
  APITypes.DeleteMailingReminderMutation
>;
export const createErrorReport = /* GraphQL */ `mutation CreateErrorReport(
  $input: CreateErrorReportInput!
  $condition: ModelErrorReportConditionInput
) {
  createErrorReport(input: $input, condition: $condition) {
    id
    userId
    message
    place
    data
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateErrorReportMutationVariables,
  APITypes.CreateErrorReportMutation
>;
export const updateErrorReport = /* GraphQL */ `mutation UpdateErrorReport(
  $input: UpdateErrorReportInput!
  $condition: ModelErrorReportConditionInput
) {
  updateErrorReport(input: $input, condition: $condition) {
    id
    userId
    message
    place
    data
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateErrorReportMutationVariables,
  APITypes.UpdateErrorReportMutation
>;
export const deleteErrorReport = /* GraphQL */ `mutation DeleteErrorReport(
  $input: DeleteErrorReportInput!
  $condition: ModelErrorReportConditionInput
) {
  deleteErrorReport(input: $input, condition: $condition) {
    id
    userId
    message
    place
    data
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteErrorReportMutationVariables,
  APITypes.DeleteErrorReportMutation
>;
export const createLogbookRecord = /* GraphQL */ `mutation CreateLogbookRecord(
  $input: CreateLogbookRecordInput!
  $condition: ModelLogbookRecordConditionInput
) {
  createLogbookRecord(input: $input, condition: $condition) {
    id
    contactId
    crewId
    editableByUserIds
    date
    deleted
    legId
    departurePlace
    departureTime
    arrivalPlace
    arrivalTime
    aircraftMake
    aircraftRegistration
    airplaneSeMeMcc
    totalFlightTime
    picName
    landingsTotal
    landingsNight
    nightTime
    ifrTime
    picTime
    copilotTime
    dualTime
    instructorTime
    isFstd
    fstdTime
    remarks
    crossCountryTime
    instrumentActualTime
    instrumentSimulatedTime
    instrumentApproachPlace
    instrumentApproachType
    instrumentApproachCount
    instrumentApproachDetails
    holdings
    customData
    signature
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLogbookRecordMutationVariables,
  APITypes.CreateLogbookRecordMutation
>;
export const updateLogbookRecord = /* GraphQL */ `mutation UpdateLogbookRecord(
  $input: UpdateLogbookRecordInput!
  $condition: ModelLogbookRecordConditionInput
) {
  updateLogbookRecord(input: $input, condition: $condition) {
    id
    contactId
    crewId
    editableByUserIds
    date
    deleted
    legId
    departurePlace
    departureTime
    arrivalPlace
    arrivalTime
    aircraftMake
    aircraftRegistration
    airplaneSeMeMcc
    totalFlightTime
    picName
    landingsTotal
    landingsNight
    nightTime
    ifrTime
    picTime
    copilotTime
    dualTime
    instructorTime
    isFstd
    fstdTime
    remarks
    crossCountryTime
    instrumentActualTime
    instrumentSimulatedTime
    instrumentApproachPlace
    instrumentApproachType
    instrumentApproachCount
    instrumentApproachDetails
    holdings
    customData
    signature
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLogbookRecordMutationVariables,
  APITypes.UpdateLogbookRecordMutation
>;
export const deleteLogbookRecord = /* GraphQL */ `mutation DeleteLogbookRecord(
  $input: DeleteLogbookRecordInput!
  $condition: ModelLogbookRecordConditionInput
) {
  deleteLogbookRecord(input: $input, condition: $condition) {
    id
    contactId
    crewId
    editableByUserIds
    date
    deleted
    legId
    departurePlace
    departureTime
    arrivalPlace
    arrivalTime
    aircraftMake
    aircraftRegistration
    airplaneSeMeMcc
    totalFlightTime
    picName
    landingsTotal
    landingsNight
    nightTime
    ifrTime
    picTime
    copilotTime
    dualTime
    instructorTime
    isFstd
    fstdTime
    remarks
    crossCountryTime
    instrumentActualTime
    instrumentSimulatedTime
    instrumentApproachPlace
    instrumentApproachType
    instrumentApproachCount
    instrumentApproachDetails
    holdings
    customData
    signature
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLogbookRecordMutationVariables,
  APITypes.DeleteLogbookRecordMutation
>;
export const createHandWrittenSignature = /* GraphQL */ `mutation CreateHandWrittenSignature(
  $input: CreateHandWrittenSignatureInput!
  $condition: ModelHandWrittenSignatureConditionInput
) {
  createHandWrittenSignature(input: $input, condition: $condition) {
    id
    userId
    contactId
    crewId
    signature
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHandWrittenSignatureMutationVariables,
  APITypes.CreateHandWrittenSignatureMutation
>;
export const updateHandWrittenSignature = /* GraphQL */ `mutation UpdateHandWrittenSignature(
  $input: UpdateHandWrittenSignatureInput!
  $condition: ModelHandWrittenSignatureConditionInput
) {
  updateHandWrittenSignature(input: $input, condition: $condition) {
    id
    userId
    contactId
    crewId
    signature
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHandWrittenSignatureMutationVariables,
  APITypes.UpdateHandWrittenSignatureMutation
>;
export const deleteHandWrittenSignature = /* GraphQL */ `mutation DeleteHandWrittenSignature(
  $input: DeleteHandWrittenSignatureInput!
  $condition: ModelHandWrittenSignatureConditionInput
) {
  deleteHandWrittenSignature(input: $input, condition: $condition) {
    id
    userId
    contactId
    crewId
    signature
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHandWrittenSignatureMutationVariables,
  APITypes.DeleteHandWrittenSignatureMutation
>;
export const createContact2Tag = /* GraphQL */ `mutation CreateContact2Tag(
  $input: CreateContact2TagInput!
  $condition: ModelContact2TagConditionInput
) {
  createContact2Tag(input: $input, condition: $condition) {
    id
    contactId
    tagId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    tag {
      id
      title
      description
      color
      type
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContact2TagMutationVariables,
  APITypes.CreateContact2TagMutation
>;
export const updateContact2Tag = /* GraphQL */ `mutation UpdateContact2Tag(
  $input: UpdateContact2TagInput!
  $condition: ModelContact2TagConditionInput
) {
  updateContact2Tag(input: $input, condition: $condition) {
    id
    contactId
    tagId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    tag {
      id
      title
      description
      color
      type
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContact2TagMutationVariables,
  APITypes.UpdateContact2TagMutation
>;
export const deleteContact2Tag = /* GraphQL */ `mutation DeleteContact2Tag(
  $input: DeleteContact2TagInput!
  $condition: ModelContact2TagConditionInput
) {
  deleteContact2Tag(input: $input, condition: $condition) {
    id
    contactId
    tagId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    tag {
      id
      title
      description
      color
      type
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContact2TagMutationVariables,
  APITypes.DeleteContact2TagMutation
>;
export const createTag = /* GraphQL */ `mutation CreateTag(
  $input: CreateTagInput!
  $condition: ModelTagConditionInput
) {
  createTag(input: $input, condition: $condition) {
    id
    title
    description
    color
    type
    contacts {
      nextToken
      startedAt
      __typename
    }
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagMutationVariables,
  APITypes.CreateTagMutation
>;
export const updateTag = /* GraphQL */ `mutation UpdateTag(
  $input: UpdateTagInput!
  $condition: ModelTagConditionInput
) {
  updateTag(input: $input, condition: $condition) {
    id
    title
    description
    color
    type
    contacts {
      nextToken
      startedAt
      __typename
    }
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagMutationVariables,
  APITypes.UpdateTagMutation
>;
export const deleteTag = /* GraphQL */ `mutation DeleteTag(
  $input: DeleteTagInput!
  $condition: ModelTagConditionInput
) {
  deleteTag(input: $input, condition: $condition) {
    id
    title
    description
    color
    type
    contacts {
      nextToken
      startedAt
      __typename
    }
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagMutationVariables,
  APITypes.DeleteTagMutation
>;
export const createCrewRole = /* GraphQL */ `mutation CreateCrewRole(
  $input: CreateCrewRoleInput!
  $condition: ModelCrewRoleConditionInput
) {
  createCrewRole(input: $input, condition: $condition) {
    id
    title
    description
    ops
    aircraftTypeId
    crewFunction
    requirements
    deleted
    contacts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCrewRoleMutationVariables,
  APITypes.CreateCrewRoleMutation
>;
export const updateCrewRole = /* GraphQL */ `mutation UpdateCrewRole(
  $input: UpdateCrewRoleInput!
  $condition: ModelCrewRoleConditionInput
) {
  updateCrewRole(input: $input, condition: $condition) {
    id
    title
    description
    ops
    aircraftTypeId
    crewFunction
    requirements
    deleted
    contacts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCrewRoleMutationVariables,
  APITypes.UpdateCrewRoleMutation
>;
export const deleteCrewRole = /* GraphQL */ `mutation DeleteCrewRole(
  $input: DeleteCrewRoleInput!
  $condition: ModelCrewRoleConditionInput
) {
  deleteCrewRole(input: $input, condition: $condition) {
    id
    title
    description
    ops
    aircraftTypeId
    crewFunction
    requirements
    deleted
    contacts {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCrewRoleMutationVariables,
  APITypes.DeleteCrewRoleMutation
>;
export const createContact2CrewRole = /* GraphQL */ `mutation CreateContact2CrewRole(
  $input: CreateContact2CrewRoleInput!
  $condition: ModelContact2CrewRoleConditionInput
) {
  createContact2CrewRole(input: $input, condition: $condition) {
    id
    contactId
    crewRoleId
    roleEarliestExpiration
    rolePrivileges
    rolePrivilegeEarliestExpiration
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    crewRole {
      id
      title
      description
      ops
      aircraftTypeId
      crewFunction
      requirements
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    created
    isNotActive
    isInTraining
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContact2CrewRoleMutationVariables,
  APITypes.CreateContact2CrewRoleMutation
>;
export const updateContact2CrewRole = /* GraphQL */ `mutation UpdateContact2CrewRole(
  $input: UpdateContact2CrewRoleInput!
  $condition: ModelContact2CrewRoleConditionInput
) {
  updateContact2CrewRole(input: $input, condition: $condition) {
    id
    contactId
    crewRoleId
    roleEarliestExpiration
    rolePrivileges
    rolePrivilegeEarliestExpiration
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    crewRole {
      id
      title
      description
      ops
      aircraftTypeId
      crewFunction
      requirements
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    created
    isNotActive
    isInTraining
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContact2CrewRoleMutationVariables,
  APITypes.UpdateContact2CrewRoleMutation
>;
export const deleteContact2CrewRole = /* GraphQL */ `mutation DeleteContact2CrewRole(
  $input: DeleteContact2CrewRoleInput!
  $condition: ModelContact2CrewRoleConditionInput
) {
  deleteContact2CrewRole(input: $input, condition: $condition) {
    id
    contactId
    crewRoleId
    roleEarliestExpiration
    rolePrivileges
    rolePrivilegeEarliestExpiration
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    crewRole {
      id
      title
      description
      ops
      aircraftTypeId
      crewFunction
      requirements
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    created
    isNotActive
    isInTraining
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContact2CrewRoleMutationVariables,
  APITypes.DeleteContact2CrewRoleMutation
>;
export const createDocumentSegment = /* GraphQL */ `mutation CreateDocumentSegment(
  $input: CreateDocumentSegmentInput!
  $condition: ModelDocumentSegmentConditionInput
) {
  createDocumentSegment(input: $input, condition: $condition) {
    id
    code
    name
    version
    content
    htmlContent
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentSegmentMutationVariables,
  APITypes.CreateDocumentSegmentMutation
>;
export const updateDocumentSegment = /* GraphQL */ `mutation UpdateDocumentSegment(
  $input: UpdateDocumentSegmentInput!
  $condition: ModelDocumentSegmentConditionInput
) {
  updateDocumentSegment(input: $input, condition: $condition) {
    id
    code
    name
    version
    content
    htmlContent
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentSegmentMutationVariables,
  APITypes.UpdateDocumentSegmentMutation
>;
export const deleteDocumentSegment = /* GraphQL */ `mutation DeleteDocumentSegment(
  $input: DeleteDocumentSegmentInput!
  $condition: ModelDocumentSegmentConditionInput
) {
  deleteDocumentSegment(input: $input, condition: $condition) {
    id
    code
    name
    version
    content
    htmlContent
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentSegmentMutationVariables,
  APITypes.DeleteDocumentSegmentMutation
>;
export const createMaintenanceRecord = /* GraphQL */ `mutation CreateMaintenanceRecord(
  $input: CreateMaintenanceRecordInput!
  $condition: ModelMaintenanceRecordConditionInput
) {
  createMaintenanceRecord(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    aircraftId
    relatedReleaseToServiceIds
    relatedSquawkIds
    title
    hasReleaseToService
    crsFlightDocumentIds
    workReportFlightDocumentIds
    camoOrderFlightDocumentIds
    beforeMaintenanceCountableChangeId
    maintenanceCountableChangeId
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    beforeMaintenanceCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    beforeMaintenanceCountableValuesOverrides
    maintenanceCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    afterMaintenanceCountableValuesOverrides
    displayInAircraftLogIds
    displayInAircraftLogTypes
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    maintenanceIntervalChangeData
    maintenanceEventId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMaintenanceRecordMutationVariables,
  APITypes.CreateMaintenanceRecordMutation
>;
export const updateMaintenanceRecord = /* GraphQL */ `mutation UpdateMaintenanceRecord(
  $input: UpdateMaintenanceRecordInput!
  $condition: ModelMaintenanceRecordConditionInput
) {
  updateMaintenanceRecord(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    aircraftId
    relatedReleaseToServiceIds
    relatedSquawkIds
    title
    hasReleaseToService
    crsFlightDocumentIds
    workReportFlightDocumentIds
    camoOrderFlightDocumentIds
    beforeMaintenanceCountableChangeId
    maintenanceCountableChangeId
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    beforeMaintenanceCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    beforeMaintenanceCountableValuesOverrides
    maintenanceCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    afterMaintenanceCountableValuesOverrides
    displayInAircraftLogIds
    displayInAircraftLogTypes
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    maintenanceIntervalChangeData
    maintenanceEventId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMaintenanceRecordMutationVariables,
  APITypes.UpdateMaintenanceRecordMutation
>;
export const deleteMaintenanceRecord = /* GraphQL */ `mutation DeleteMaintenanceRecord(
  $input: DeleteMaintenanceRecordInput!
  $condition: ModelMaintenanceRecordConditionInput
) {
  deleteMaintenanceRecord(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    aircraftId
    relatedReleaseToServiceIds
    relatedSquawkIds
    title
    hasReleaseToService
    crsFlightDocumentIds
    workReportFlightDocumentIds
    camoOrderFlightDocumentIds
    beforeMaintenanceCountableChangeId
    maintenanceCountableChangeId
    flightDocuments {
      nextToken
      startedAt
      __typename
    }
    beforeMaintenanceCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    beforeMaintenanceCountableValuesOverrides
    maintenanceCountableChange {
      id
      deleted
      editableByUserIds
      aircraftId
      flightId
      legId
      createdAt
      createdByName
      place
      type
      note
      changeTypes
      changeValues
      countableTypes
      countableValuesBefore
      countableValuesAfter
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    afterMaintenanceCountableValuesOverrides
    displayInAircraftLogIds
    displayInAircraftLogTypes
    releaseToServices {
      nextToken
      startedAt
      __typename
    }
    maintenanceIntervalChangeData
    maintenanceEventId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMaintenanceRecordMutationVariables,
  APITypes.DeleteMaintenanceRecordMutation
>;
export const createMaintenanceWorkOrder = /* GraphQL */ `mutation CreateMaintenanceWorkOrder(
  $input: CreateMaintenanceWorkOrderInput!
  $condition: ModelMaintenanceWorkOrderConditionInput
) {
  createMaintenanceWorkOrder(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    aircraftId
    maintenanceEventId
    maintenanceStartDate
    data
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMaintenanceWorkOrderMutationVariables,
  APITypes.CreateMaintenanceWorkOrderMutation
>;
export const updateMaintenanceWorkOrder = /* GraphQL */ `mutation UpdateMaintenanceWorkOrder(
  $input: UpdateMaintenanceWorkOrderInput!
  $condition: ModelMaintenanceWorkOrderConditionInput
) {
  updateMaintenanceWorkOrder(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    aircraftId
    maintenanceEventId
    maintenanceStartDate
    data
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMaintenanceWorkOrderMutationVariables,
  APITypes.UpdateMaintenanceWorkOrderMutation
>;
export const deleteMaintenanceWorkOrder = /* GraphQL */ `mutation DeleteMaintenanceWorkOrder(
  $input: DeleteMaintenanceWorkOrderInput!
  $condition: ModelMaintenanceWorkOrderConditionInput
) {
  deleteMaintenanceWorkOrder(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    aircraftId
    maintenanceEventId
    maintenanceStartDate
    data
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMaintenanceWorkOrderMutationVariables,
  APITypes.DeleteMaintenanceWorkOrderMutation
>;
export const createAircraftLogEvent = /* GraphQL */ `mutation CreateAircraftLogEvent(
  $input: CreateAircraftLogEventInput!
  $condition: ModelAircraftLogEventConditionInput
) {
  createAircraftLogEvent(input: $input, condition: $condition) {
    id
    aircraftId
    createdAt
    logType
    eventType
    beforeCountableChangeId
    eventData
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAircraftLogEventMutationVariables,
  APITypes.CreateAircraftLogEventMutation
>;
export const updateAircraftLogEvent = /* GraphQL */ `mutation UpdateAircraftLogEvent(
  $input: UpdateAircraftLogEventInput!
  $condition: ModelAircraftLogEventConditionInput
) {
  updateAircraftLogEvent(input: $input, condition: $condition) {
    id
    aircraftId
    createdAt
    logType
    eventType
    beforeCountableChangeId
    eventData
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAircraftLogEventMutationVariables,
  APITypes.UpdateAircraftLogEventMutation
>;
export const deleteAircraftLogEvent = /* GraphQL */ `mutation DeleteAircraftLogEvent(
  $input: DeleteAircraftLogEventInput!
  $condition: ModelAircraftLogEventConditionInput
) {
  deleteAircraftLogEvent(input: $input, condition: $condition) {
    id
    aircraftId
    createdAt
    logType
    eventType
    beforeCountableChangeId
    eventData
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAircraftLogEventMutationVariables,
  APITypes.DeleteAircraftLogEventMutation
>;
export const createControlledDocument = /* GraphQL */ `mutation CreateControlledDocument(
  $input: CreateControlledDocumentInput!
  $condition: ModelControlledDocumentConditionInput
) {
  createControlledDocument(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    title
    files
    documentData
    accessRights
    isForTlb
    deviceIds {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateControlledDocumentMutationVariables,
  APITypes.CreateControlledDocumentMutation
>;
export const updateControlledDocument = /* GraphQL */ `mutation UpdateControlledDocument(
  $input: UpdateControlledDocumentInput!
  $condition: ModelControlledDocumentConditionInput
) {
  updateControlledDocument(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    title
    files
    documentData
    accessRights
    isForTlb
    deviceIds {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateControlledDocumentMutationVariables,
  APITypes.UpdateControlledDocumentMutation
>;
export const deleteControlledDocument = /* GraphQL */ `mutation DeleteControlledDocument(
  $input: DeleteControlledDocumentInput!
  $condition: ModelControlledDocumentConditionInput
) {
  deleteControlledDocument(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    title
    files
    documentData
    accessRights
    isForTlb
    deviceIds {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteControlledDocumentMutationVariables,
  APITypes.DeleteControlledDocumentMutation
>;
export const createControlledDocument2DeviceId = /* GraphQL */ `mutation CreateControlledDocument2DeviceId(
  $input: CreateControlledDocument2DeviceIdInput!
  $condition: ModelControlledDocument2DeviceIdConditionInput
) {
  createControlledDocument2DeviceId(input: $input, condition: $condition) {
    id
    createdAt
    controlledDocumentId
    deviceId
    controlledDocument {
      id
      createdAt
      deleted
      title
      files
      documentData
      accessRights
      isForTlb
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateControlledDocument2DeviceIdMutationVariables,
  APITypes.CreateControlledDocument2DeviceIdMutation
>;
export const updateControlledDocument2DeviceId = /* GraphQL */ `mutation UpdateControlledDocument2DeviceId(
  $input: UpdateControlledDocument2DeviceIdInput!
  $condition: ModelControlledDocument2DeviceIdConditionInput
) {
  updateControlledDocument2DeviceId(input: $input, condition: $condition) {
    id
    createdAt
    controlledDocumentId
    deviceId
    controlledDocument {
      id
      createdAt
      deleted
      title
      files
      documentData
      accessRights
      isForTlb
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateControlledDocument2DeviceIdMutationVariables,
  APITypes.UpdateControlledDocument2DeviceIdMutation
>;
export const deleteControlledDocument2DeviceId = /* GraphQL */ `mutation DeleteControlledDocument2DeviceId(
  $input: DeleteControlledDocument2DeviceIdInput!
  $condition: ModelControlledDocument2DeviceIdConditionInput
) {
  deleteControlledDocument2DeviceId(input: $input, condition: $condition) {
    id
    createdAt
    controlledDocumentId
    deviceId
    controlledDocument {
      id
      createdAt
      deleted
      title
      files
      documentData
      accessRights
      isForTlb
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteControlledDocument2DeviceIdMutationVariables,
  APITypes.DeleteControlledDocument2DeviceIdMutation
>;
export const createControlledDocumentNotification = /* GraphQL */ `mutation CreateControlledDocumentNotification(
  $input: CreateControlledDocumentNotificationInput!
  $condition: ModelControlledDocumentNotificationConditionInput
) {
  createControlledDocumentNotification(input: $input, condition: $condition) {
    id
    controlledDocumentId
    contactId
    createdAt
    readAt
    state
    revision
    controlledDocument {
      id
      createdAt
      deleted
      title
      files
      documentData
      accessRights
      isForTlb
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateControlledDocumentNotificationMutationVariables,
  APITypes.CreateControlledDocumentNotificationMutation
>;
export const updateControlledDocumentNotification = /* GraphQL */ `mutation UpdateControlledDocumentNotification(
  $input: UpdateControlledDocumentNotificationInput!
  $condition: ModelControlledDocumentNotificationConditionInput
) {
  updateControlledDocumentNotification(input: $input, condition: $condition) {
    id
    controlledDocumentId
    contactId
    createdAt
    readAt
    state
    revision
    controlledDocument {
      id
      createdAt
      deleted
      title
      files
      documentData
      accessRights
      isForTlb
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateControlledDocumentNotificationMutationVariables,
  APITypes.UpdateControlledDocumentNotificationMutation
>;
export const deleteControlledDocumentNotification = /* GraphQL */ `mutation DeleteControlledDocumentNotification(
  $input: DeleteControlledDocumentNotificationInput!
  $condition: ModelControlledDocumentNotificationConditionInput
) {
  deleteControlledDocumentNotification(input: $input, condition: $condition) {
    id
    controlledDocumentId
    contactId
    createdAt
    readAt
    state
    revision
    controlledDocument {
      id
      createdAt
      deleted
      title
      files
      documentData
      accessRights
      isForTlb
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteControlledDocumentNotificationMutationVariables,
  APITypes.DeleteControlledDocumentNotificationMutation
>;
export const createDevice = /* GraphQL */ `mutation CreateDevice(
  $input: CreateDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  createDevice(input: $input, condition: $condition) {
    id
    createdAt
    deviceId
    assignments
    deviceData
    name
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceMutationVariables,
  APITypes.CreateDeviceMutation
>;
export const updateDevice = /* GraphQL */ `mutation UpdateDevice(
  $input: UpdateDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  updateDevice(input: $input, condition: $condition) {
    id
    createdAt
    deviceId
    assignments
    deviceData
    name
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceMutationVariables,
  APITypes.UpdateDeviceMutation
>;
export const deleteDevice = /* GraphQL */ `mutation DeleteDevice(
  $input: DeleteDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  deleteDevice(input: $input, condition: $condition) {
    id
    createdAt
    deviceId
    assignments
    deviceData
    name
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceMutationVariables,
  APITypes.DeleteDeviceMutation
>;
export const createReservationResource = /* GraphQL */ `mutation CreateReservationResource(
  $input: CreateReservationResourceInput!
  $condition: ModelReservationResourceConditionInput
) {
  createReservationResource(input: $input, condition: $condition) {
    id
    title
    type
    reservationId
    aircraftId
    contactId
    externalId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReservationResourceMutationVariables,
  APITypes.CreateReservationResourceMutation
>;
export const updateReservationResource = /* GraphQL */ `mutation UpdateReservationResource(
  $input: UpdateReservationResourceInput!
  $condition: ModelReservationResourceConditionInput
) {
  updateReservationResource(input: $input, condition: $condition) {
    id
    title
    type
    reservationId
    aircraftId
    contactId
    externalId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReservationResourceMutationVariables,
  APITypes.UpdateReservationResourceMutation
>;
export const deleteReservationResource = /* GraphQL */ `mutation DeleteReservationResource(
  $input: DeleteReservationResourceInput!
  $condition: ModelReservationResourceConditionInput
) {
  deleteReservationResource(input: $input, condition: $condition) {
    id
    title
    type
    reservationId
    aircraftId
    contactId
    externalId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReservationResourceMutationVariables,
  APITypes.DeleteReservationResourceMutation
>;
export const createReservation = /* GraphQL */ `mutation CreateReservation(
  $input: CreateReservationInput!
  $condition: ModelReservationConditionInput
) {
  createReservation(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    isActive
    dateFrom
    dateTo
    resources {
      nextToken
      startedAt
      __typename
    }
    contactId
    isExternal
    reservationData
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservationCode
    flightType
    quotationId
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    aircraftId
    notifications
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReservationMutationVariables,
  APITypes.CreateReservationMutation
>;
export const updateReservation = /* GraphQL */ `mutation UpdateReservation(
  $input: UpdateReservationInput!
  $condition: ModelReservationConditionInput
) {
  updateReservation(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    isActive
    dateFrom
    dateTo
    resources {
      nextToken
      startedAt
      __typename
    }
    contactId
    isExternal
    reservationData
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservationCode
    flightType
    quotationId
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    aircraftId
    notifications
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReservationMutationVariables,
  APITypes.UpdateReservationMutation
>;
export const deleteReservation = /* GraphQL */ `mutation DeleteReservation(
  $input: DeleteReservationInput!
  $condition: ModelReservationConditionInput
) {
  deleteReservation(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    isActive
    dateFrom
    dateTo
    resources {
      nextToken
      startedAt
      __typename
    }
    contactId
    isExternal
    reservationData
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    reservationCode
    flightType
    quotationId
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    aircraftId
    notifications
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReservationMutationVariables,
  APITypes.DeleteReservationMutation
>;
export const createTrendMonitoringDocument = /* GraphQL */ `mutation CreateTrendMonitoringDocument(
  $input: CreateTrendMonitoringDocumentInput!
  $condition: ModelTrendMonitoringDocumentConditionInput
) {
  createTrendMonitoringDocument(input: $input, condition: $condition) {
    id
    createdAt
    aircraftId
    contentKey
    title
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTrendMonitoringDocumentMutationVariables,
  APITypes.CreateTrendMonitoringDocumentMutation
>;
export const updateTrendMonitoringDocument = /* GraphQL */ `mutation UpdateTrendMonitoringDocument(
  $input: UpdateTrendMonitoringDocumentInput!
  $condition: ModelTrendMonitoringDocumentConditionInput
) {
  updateTrendMonitoringDocument(input: $input, condition: $condition) {
    id
    createdAt
    aircraftId
    contentKey
    title
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTrendMonitoringDocumentMutationVariables,
  APITypes.UpdateTrendMonitoringDocumentMutation
>;
export const deleteTrendMonitoringDocument = /* GraphQL */ `mutation DeleteTrendMonitoringDocument(
  $input: DeleteTrendMonitoringDocumentInput!
  $condition: ModelTrendMonitoringDocumentConditionInput
) {
  deleteTrendMonitoringDocument(input: $input, condition: $condition) {
    id
    createdAt
    aircraftId
    contentKey
    title
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTrendMonitoringDocumentMutationVariables,
  APITypes.DeleteTrendMonitoringDocumentMutation
>;
export const createQuotationResource = /* GraphQL */ `mutation CreateQuotationResource(
  $input: CreateQuotationResourceInput!
  $condition: ModelQuotationResourceConditionInput
) {
  createQuotationResource(input: $input, condition: $condition) {
    id
    title
    type
    quotationId
    aircraftId
    contactId
    externalId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuotationResourceMutationVariables,
  APITypes.CreateQuotationResourceMutation
>;
export const updateQuotationResource = /* GraphQL */ `mutation UpdateQuotationResource(
  $input: UpdateQuotationResourceInput!
  $condition: ModelQuotationResourceConditionInput
) {
  updateQuotationResource(input: $input, condition: $condition) {
    id
    title
    type
    quotationId
    aircraftId
    contactId
    externalId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuotationResourceMutationVariables,
  APITypes.UpdateQuotationResourceMutation
>;
export const deleteQuotationResource = /* GraphQL */ `mutation DeleteQuotationResource(
  $input: DeleteQuotationResourceInput!
  $condition: ModelQuotationResourceConditionInput
) {
  deleteQuotationResource(input: $input, condition: $condition) {
    id
    title
    type
    quotationId
    aircraftId
    contactId
    externalId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    deleted
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuotationResourceMutationVariables,
  APITypes.DeleteQuotationResourceMutation
>;
export const createQuotation = /* GraphQL */ `mutation CreateQuotation(
  $input: CreateQuotationInput!
  $condition: ModelQuotationConditionInput
) {
  createQuotation(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    isActive
    dateFrom
    dateTo
    resources {
      nextToken
      startedAt
      __typename
    }
    contactId
    isExternal
    quotationData
    quotationCalculation
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    quotationCode
    flightType
    aircraftId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuotationMutationVariables,
  APITypes.CreateQuotationMutation
>;
export const updateQuotation = /* GraphQL */ `mutation UpdateQuotation(
  $input: UpdateQuotationInput!
  $condition: ModelQuotationConditionInput
) {
  updateQuotation(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    isActive
    dateFrom
    dateTo
    resources {
      nextToken
      startedAt
      __typename
    }
    contactId
    isExternal
    quotationData
    quotationCalculation
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    quotationCode
    flightType
    aircraftId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuotationMutationVariables,
  APITypes.UpdateQuotationMutation
>;
export const deleteQuotation = /* GraphQL */ `mutation DeleteQuotation(
  $input: DeleteQuotationInput!
  $condition: ModelQuotationConditionInput
) {
  deleteQuotation(input: $input, condition: $condition) {
    id
    createdAt
    deleted
    isActive
    dateFrom
    dateTo
    resources {
      nextToken
      startedAt
      __typename
    }
    contactId
    isExternal
    quotationData
    quotationCalculation
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    quotationCode
    flightType
    aircraftId
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuotationMutationVariables,
  APITypes.DeleteQuotationMutation
>;
export const createTrendMonitoringExceeding = /* GraphQL */ `mutation CreateTrendMonitoringExceeding(
  $input: CreateTrendMonitoringExceedingInput!
  $condition: ModelTrendMonitoringExceedingConditionInput
) {
  createTrendMonitoringExceeding(input: $input, condition: $condition) {
    id
    documentId
    dateFrom
    dateTo
    startValue
    endValue
    minValue
    topValue
    limitValue
    propertyName
    description
    document {
      id
      createdAt
      aircraftId
      contentKey
      title
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTrendMonitoringExceedingMutationVariables,
  APITypes.CreateTrendMonitoringExceedingMutation
>;
export const updateTrendMonitoringExceeding = /* GraphQL */ `mutation UpdateTrendMonitoringExceeding(
  $input: UpdateTrendMonitoringExceedingInput!
  $condition: ModelTrendMonitoringExceedingConditionInput
) {
  updateTrendMonitoringExceeding(input: $input, condition: $condition) {
    id
    documentId
    dateFrom
    dateTo
    startValue
    endValue
    minValue
    topValue
    limitValue
    propertyName
    description
    document {
      id
      createdAt
      aircraftId
      contentKey
      title
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTrendMonitoringExceedingMutationVariables,
  APITypes.UpdateTrendMonitoringExceedingMutation
>;
export const deleteTrendMonitoringExceeding = /* GraphQL */ `mutation DeleteTrendMonitoringExceeding(
  $input: DeleteTrendMonitoringExceedingInput!
  $condition: ModelTrendMonitoringExceedingConditionInput
) {
  deleteTrendMonitoringExceeding(input: $input, condition: $condition) {
    id
    documentId
    dateFrom
    dateTo
    startValue
    endValue
    minValue
    topValue
    limitValue
    propertyName
    description
    document {
      id
      createdAt
      aircraftId
      contentKey
      title
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTrendMonitoringExceedingMutationVariables,
  APITypes.DeleteTrendMonitoringExceedingMutation
>;
export const createSequence = /* GraphQL */ `mutation CreateSequence(
  $input: CreateSequenceInput!
  $condition: ModelSequenceConditionInput
) {
  createSequence(input: $input, condition: $condition) {
    id
    lastNumber
    code
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSequenceMutationVariables,
  APITypes.CreateSequenceMutation
>;
export const updateSequence = /* GraphQL */ `mutation UpdateSequence(
  $input: UpdateSequenceInput!
  $condition: ModelSequenceConditionInput
) {
  updateSequence(input: $input, condition: $condition) {
    id
    lastNumber
    code
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSequenceMutationVariables,
  APITypes.UpdateSequenceMutation
>;
export const deleteSequence = /* GraphQL */ `mutation DeleteSequence(
  $input: DeleteSequenceInput!
  $condition: ModelSequenceConditionInput
) {
  deleteSequence(input: $input, condition: $condition) {
    id
    lastNumber
    code
    created
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSequenceMutationVariables,
  APITypes.DeleteSequenceMutation
>;
export const createBankAccount = /* GraphQL */ `mutation CreateBankAccount(
  $input: CreateBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  createBankAccount(input: $input, condition: $condition) {
    id
    created
    currency
    account
    name
    iban
    swift
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBankAccountMutationVariables,
  APITypes.CreateBankAccountMutation
>;
export const updateBankAccount = /* GraphQL */ `mutation UpdateBankAccount(
  $input: UpdateBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  updateBankAccount(input: $input, condition: $condition) {
    id
    created
    currency
    account
    name
    iban
    swift
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBankAccountMutationVariables,
  APITypes.UpdateBankAccountMutation
>;
export const deleteBankAccount = /* GraphQL */ `mutation DeleteBankAccount(
  $input: DeleteBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  deleteBankAccount(input: $input, condition: $condition) {
    id
    created
    currency
    account
    name
    iban
    swift
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBankAccountMutationVariables,
  APITypes.DeleteBankAccountMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    createdAt
    number
    issueDate
    dueDate
    contactId
    type
    invoiceData
    quotationId
    reservationId
    contentKey
    fileTitle
    created
    aircraftId
    state
    auditData
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    createdAt
    number
    issueDate
    dueDate
    contactId
    type
    invoiceData
    quotationId
    reservationId
    contentKey
    fileTitle
    created
    aircraftId
    state
    auditData
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    createdAt
    number
    issueDate
    dueDate
    contactId
    type
    invoiceData
    quotationId
    reservationId
    contentKey
    fileTitle
    created
    aircraftId
    state
    auditData
    quotation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      quotationData
      quotationCalculation
      quotationCode
      flightType
      aircraftId
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createCustomFilter = /* GraphQL */ `mutation CreateCustomFilter(
  $input: CreateCustomFilterInput!
  $condition: ModelCustomFilterConditionInput
) {
  createCustomFilter(input: $input, condition: $condition) {
    id
    userId
    name
    createdAt
    filterData
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomFilterMutationVariables,
  APITypes.CreateCustomFilterMutation
>;
export const updateCustomFilter = /* GraphQL */ `mutation UpdateCustomFilter(
  $input: UpdateCustomFilterInput!
  $condition: ModelCustomFilterConditionInput
) {
  updateCustomFilter(input: $input, condition: $condition) {
    id
    userId
    name
    createdAt
    filterData
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomFilterMutationVariables,
  APITypes.UpdateCustomFilterMutation
>;
export const deleteCustomFilter = /* GraphQL */ `mutation DeleteCustomFilter(
  $input: DeleteCustomFilterInput!
  $condition: ModelCustomFilterConditionInput
) {
  deleteCustomFilter(input: $input, condition: $condition) {
    id
    userId
    name
    createdAt
    filterData
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomFilterMutationVariables,
  APITypes.DeleteCustomFilterMutation
>;
export const createExchangeRate = /* GraphQL */ `mutation CreateExchangeRate(
  $input: CreateExchangeRateInput!
  $condition: ModelExchangeRateConditionInput
) {
  createExchangeRate(input: $input, condition: $condition) {
    id
    date
    exchangeRateData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExchangeRateMutationVariables,
  APITypes.CreateExchangeRateMutation
>;
export const updateExchangeRate = /* GraphQL */ `mutation UpdateExchangeRate(
  $input: UpdateExchangeRateInput!
  $condition: ModelExchangeRateConditionInput
) {
  updateExchangeRate(input: $input, condition: $condition) {
    id
    date
    exchangeRateData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExchangeRateMutationVariables,
  APITypes.UpdateExchangeRateMutation
>;
export const deleteExchangeRate = /* GraphQL */ `mutation DeleteExchangeRate(
  $input: DeleteExchangeRateInput!
  $condition: ModelExchangeRateConditionInput
) {
  deleteExchangeRate(input: $input, condition: $condition) {
    id
    date
    exchangeRateData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExchangeRateMutationVariables,
  APITypes.DeleteExchangeRateMutation
>;
export const createAvinodeQuoteRequest = /* GraphQL */ `mutation CreateAvinodeQuoteRequest(
  $input: CreateAvinodeQuoteRequestInput!
  $condition: ModelAvinodeQuoteRequestConditionInput
) {
  createAvinodeQuoteRequest(input: $input, condition: $condition) {
    id
    date
    state
    externalId
    quoteRequestData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAvinodeQuoteRequestMutationVariables,
  APITypes.CreateAvinodeQuoteRequestMutation
>;
export const updateAvinodeQuoteRequest = /* GraphQL */ `mutation UpdateAvinodeQuoteRequest(
  $input: UpdateAvinodeQuoteRequestInput!
  $condition: ModelAvinodeQuoteRequestConditionInput
) {
  updateAvinodeQuoteRequest(input: $input, condition: $condition) {
    id
    date
    state
    externalId
    quoteRequestData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAvinodeQuoteRequestMutationVariables,
  APITypes.UpdateAvinodeQuoteRequestMutation
>;
export const deleteAvinodeQuoteRequest = /* GraphQL */ `mutation DeleteAvinodeQuoteRequest(
  $input: DeleteAvinodeQuoteRequestInput!
  $condition: ModelAvinodeQuoteRequestConditionInput
) {
  deleteAvinodeQuoteRequest(input: $input, condition: $condition) {
    id
    date
    state
    externalId
    quoteRequestData
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAvinodeQuoteRequestMutationVariables,
  APITypes.DeleteAvinodeQuoteRequestMutation
>;
export const createIntegrationLastLoadedRecord = /* GraphQL */ `mutation CreateIntegrationLastLoadedRecord(
  $input: CreateIntegrationLastLoadedRecordInput!
  $condition: ModelIntegrationLastLoadedRecordConditionInput
) {
  createIntegrationLastLoadedRecord(input: $input, condition: $condition) {
    id
    key
    value
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIntegrationLastLoadedRecordMutationVariables,
  APITypes.CreateIntegrationLastLoadedRecordMutation
>;
export const updateIntegrationLastLoadedRecord = /* GraphQL */ `mutation UpdateIntegrationLastLoadedRecord(
  $input: UpdateIntegrationLastLoadedRecordInput!
  $condition: ModelIntegrationLastLoadedRecordConditionInput
) {
  updateIntegrationLastLoadedRecord(input: $input, condition: $condition) {
    id
    key
    value
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIntegrationLastLoadedRecordMutationVariables,
  APITypes.UpdateIntegrationLastLoadedRecordMutation
>;
export const deleteIntegrationLastLoadedRecord = /* GraphQL */ `mutation DeleteIntegrationLastLoadedRecord(
  $input: DeleteIntegrationLastLoadedRecordInput!
  $condition: ModelIntegrationLastLoadedRecordConditionInput
) {
  deleteIntegrationLastLoadedRecord(input: $input, condition: $condition) {
    id
    key
    value
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIntegrationLastLoadedRecordMutationVariables,
  APITypes.DeleteIntegrationLastLoadedRecordMutation
>;
export const createMessagingMessage = /* GraphQL */ `mutation CreateMessagingMessage(
  $input: CreateMessagingMessageInput!
  $condition: ModelMessagingMessageConditionInput
) {
  createMessagingMessage(input: $input, condition: $condition) {
    id
    createdAt
    authorName
    textRepresentation
    messageData
    userId
    replyToMessageId
    reservationId
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    replies {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessagingMessageMutationVariables,
  APITypes.CreateMessagingMessageMutation
>;
export const updateMessagingMessage = /* GraphQL */ `mutation UpdateMessagingMessage(
  $input: UpdateMessagingMessageInput!
  $condition: ModelMessagingMessageConditionInput
) {
  updateMessagingMessage(input: $input, condition: $condition) {
    id
    createdAt
    authorName
    textRepresentation
    messageData
    userId
    replyToMessageId
    reservationId
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    replies {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessagingMessageMutationVariables,
  APITypes.UpdateMessagingMessageMutation
>;
export const deleteMessagingMessage = /* GraphQL */ `mutation DeleteMessagingMessage(
  $input: DeleteMessagingMessageInput!
  $condition: ModelMessagingMessageConditionInput
) {
  deleteMessagingMessage(input: $input, condition: $condition) {
    id
    createdAt
    authorName
    textRepresentation
    messageData
    userId
    replyToMessageId
    reservationId
    reservation {
      id
      createdAt
      deleted
      isActive
      dateFrom
      dateTo
      contactId
      isExternal
      reservationData
      reservationCode
      flightType
      quotationId
      aircraftId
      notifications
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    replies {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessagingMessageMutationVariables,
  APITypes.DeleteMessagingMessageMutation
>;
export const createDutyReport = /* GraphQL */ `mutation CreateDutyReport(
  $input: CreateDutyReportInput!
  $condition: ModelDutyReportConditionInput
) {
  createDutyReport(input: $input, condition: $condition) {
    id
    createdAt
    editableByUserId
    registration
    typeOfExceedance
    typeOfDuty
    currentFdpStart
    currentFdpEnd
    acftType
    description
    airportOffIcao
    route
    airportOnIcao
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDutyReportMutationVariables,
  APITypes.CreateDutyReportMutation
>;
export const updateDutyReport = /* GraphQL */ `mutation UpdateDutyReport(
  $input: UpdateDutyReportInput!
  $condition: ModelDutyReportConditionInput
) {
  updateDutyReport(input: $input, condition: $condition) {
    id
    createdAt
    editableByUserId
    registration
    typeOfExceedance
    typeOfDuty
    currentFdpStart
    currentFdpEnd
    acftType
    description
    airportOffIcao
    route
    airportOnIcao
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDutyReportMutationVariables,
  APITypes.UpdateDutyReportMutation
>;
export const deleteDutyReport = /* GraphQL */ `mutation DeleteDutyReport(
  $input: DeleteDutyReportInput!
  $condition: ModelDutyReportConditionInput
) {
  deleteDutyReport(input: $input, condition: $condition) {
    id
    createdAt
    editableByUserId
    registration
    typeOfExceedance
    typeOfDuty
    currentFdpStart
    currentFdpEnd
    acftType
    description
    airportOffIcao
    route
    airportOnIcao
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDutyReportMutationVariables,
  APITypes.DeleteDutyReportMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    id
    status
    reportType
    createdAt
    updatedAt
    flight
    editableByUserId
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    anonymous
    userMessage
    assets
    hazards {
      nextToken
      startedAt
      __typename
    }
    complianceFindings {
      nextToken
      startedAt
      __typename
    }
    severity
    probability
    occurrenceType
    occurrenceTitle
    occurrenceCategory
    aircraftDamageType
    aircraftDamageDescription
    otherDamage
    otherDamageDescription
    personInjuryType
    personInjuryDescription
    dutyType
    dutyStartDate
    dutyEndDate
    dutyItemId
    dutyItem {
      id
      editableByUserIds
      deleted
      userId
      crewId
      state
      dutyPeriodId
      forceRestAfter
      note
      type
      startDate
      endDate
      sectorCount
      endPlaceIcao
      blockTimeMinutes
      plannedFdpExtension
      flightLegIds
      logbookRecordIds
      linkedDutyItemIds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    dutyAuthor
    dutyAircrafts
    fdpType
    fdpFirstDepLoc
    fdpLastLndLoc
    fdpRoute
    fdpSplitBreakStart
    fdpSplitBreakEnd
    fdpSectors
    fdpActual
    fdpMax
    fdpIncrease
    previousDutyEndDate
    previousDutyDuration
    restRequired
    actualFdpStart
    actualFdpStartLoc
    actualRest
    restReduceBy
    affectedPerformance
    reportedToOperations
    cancelledDuty
    cancelledDutyReason
    fatigueComment
    internalMessage
    internalMessageHtml
    managerFeedback
    managerFeedbackHtml
    feedbackUpdatedBy
    feedbackUpdateDate
    area
    areaOps
    reportedToCAA
    reportedToUZPLN
    reportedToUZPLNAssets
    reportableOccurrence
    reportableOccurrenceCategory
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    id
    status
    reportType
    createdAt
    updatedAt
    flight
    editableByUserId
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    anonymous
    userMessage
    assets
    hazards {
      nextToken
      startedAt
      __typename
    }
    complianceFindings {
      nextToken
      startedAt
      __typename
    }
    severity
    probability
    occurrenceType
    occurrenceTitle
    occurrenceCategory
    aircraftDamageType
    aircraftDamageDescription
    otherDamage
    otherDamageDescription
    personInjuryType
    personInjuryDescription
    dutyType
    dutyStartDate
    dutyEndDate
    dutyItemId
    dutyItem {
      id
      editableByUserIds
      deleted
      userId
      crewId
      state
      dutyPeriodId
      forceRestAfter
      note
      type
      startDate
      endDate
      sectorCount
      endPlaceIcao
      blockTimeMinutes
      plannedFdpExtension
      flightLegIds
      logbookRecordIds
      linkedDutyItemIds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    dutyAuthor
    dutyAircrafts
    fdpType
    fdpFirstDepLoc
    fdpLastLndLoc
    fdpRoute
    fdpSplitBreakStart
    fdpSplitBreakEnd
    fdpSectors
    fdpActual
    fdpMax
    fdpIncrease
    previousDutyEndDate
    previousDutyDuration
    restRequired
    actualFdpStart
    actualFdpStartLoc
    actualRest
    restReduceBy
    affectedPerformance
    reportedToOperations
    cancelledDuty
    cancelledDutyReason
    fatigueComment
    internalMessage
    internalMessageHtml
    managerFeedback
    managerFeedbackHtml
    feedbackUpdatedBy
    feedbackUpdateDate
    area
    areaOps
    reportedToCAA
    reportedToUZPLN
    reportedToUZPLNAssets
    reportableOccurrence
    reportableOccurrenceCategory
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const deleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    id
    status
    reportType
    createdAt
    updatedAt
    flight
    editableByUserId
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    anonymous
    userMessage
    assets
    hazards {
      nextToken
      startedAt
      __typename
    }
    complianceFindings {
      nextToken
      startedAt
      __typename
    }
    severity
    probability
    occurrenceType
    occurrenceTitle
    occurrenceCategory
    aircraftDamageType
    aircraftDamageDescription
    otherDamage
    otherDamageDescription
    personInjuryType
    personInjuryDescription
    dutyType
    dutyStartDate
    dutyEndDate
    dutyItemId
    dutyItem {
      id
      editableByUserIds
      deleted
      userId
      crewId
      state
      dutyPeriodId
      forceRestAfter
      note
      type
      startDate
      endDate
      sectorCount
      endPlaceIcao
      blockTimeMinutes
      plannedFdpExtension
      flightLegIds
      logbookRecordIds
      linkedDutyItemIds
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    dutyAuthor
    dutyAircrafts
    fdpType
    fdpFirstDepLoc
    fdpLastLndLoc
    fdpRoute
    fdpSplitBreakStart
    fdpSplitBreakEnd
    fdpSectors
    fdpActual
    fdpMax
    fdpIncrease
    previousDutyEndDate
    previousDutyDuration
    restRequired
    actualFdpStart
    actualFdpStartLoc
    actualRest
    restReduceBy
    affectedPerformance
    reportedToOperations
    cancelledDuty
    cancelledDutyReason
    fatigueComment
    internalMessage
    internalMessageHtml
    managerFeedback
    managerFeedbackHtml
    feedbackUpdatedBy
    feedbackUpdateDate
    area
    areaOps
    reportedToCAA
    reportedToUZPLN
    reportedToUZPLNAssets
    reportableOccurrence
    reportableOccurrenceCategory
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const createHazard = /* GraphQL */ `mutation CreateHazard(
  $input: CreateHazardInput!
  $condition: ModelHazardConditionInput
) {
  createHazard(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    status
    title
    hazard
    unsafeEvent
    reportId
    report {
      id
      status
      reportType
      createdAt
      updatedAt
      flight
      editableByUserId
      contactId
      anonymous
      userMessage
      assets
      severity
      probability
      occurrenceType
      occurrenceTitle
      occurrenceCategory
      aircraftDamageType
      aircraftDamageDescription
      otherDamage
      otherDamageDescription
      personInjuryType
      personInjuryDescription
      dutyType
      dutyStartDate
      dutyEndDate
      dutyItemId
      dutyAuthor
      dutyAircrafts
      fdpType
      fdpFirstDepLoc
      fdpLastLndLoc
      fdpRoute
      fdpSplitBreakStart
      fdpSplitBreakEnd
      fdpSectors
      fdpActual
      fdpMax
      fdpIncrease
      previousDutyEndDate
      previousDutyDuration
      restRequired
      actualFdpStart
      actualFdpStartLoc
      actualRest
      restReduceBy
      affectedPerformance
      reportedToOperations
      cancelledDuty
      cancelledDutyReason
      fatigueComment
      internalMessage
      internalMessageHtml
      managerFeedback
      managerFeedbackHtml
      feedbackUpdatedBy
      feedbackUpdateDate
      area
      areaOps
      reportedToCAA
      reportedToUZPLN
      reportedToUZPLNAssets
      reportableOccurrence
      reportableOccurrenceCategory
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    editableByUserId
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    threadsRaw
    consequencesRaw
    outcome
    severity
    probability
    riskLevel
    approvedById
    approvedBy {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    review
    additionalMitigationMeasures
    mitigationMeasure
    monitoringRequired
    monitoringReview
    whenAt
    responsiblePersonId
    responsiblePerson {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    mitigationEffectivenessReview
    effective
    effectivePersonId
    effectivePerson {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    assessedByPersonIds
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHazardMutationVariables,
  APITypes.CreateHazardMutation
>;
export const updateHazard = /* GraphQL */ `mutation UpdateHazard(
  $input: UpdateHazardInput!
  $condition: ModelHazardConditionInput
) {
  updateHazard(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    status
    title
    hazard
    unsafeEvent
    reportId
    report {
      id
      status
      reportType
      createdAt
      updatedAt
      flight
      editableByUserId
      contactId
      anonymous
      userMessage
      assets
      severity
      probability
      occurrenceType
      occurrenceTitle
      occurrenceCategory
      aircraftDamageType
      aircraftDamageDescription
      otherDamage
      otherDamageDescription
      personInjuryType
      personInjuryDescription
      dutyType
      dutyStartDate
      dutyEndDate
      dutyItemId
      dutyAuthor
      dutyAircrafts
      fdpType
      fdpFirstDepLoc
      fdpLastLndLoc
      fdpRoute
      fdpSplitBreakStart
      fdpSplitBreakEnd
      fdpSectors
      fdpActual
      fdpMax
      fdpIncrease
      previousDutyEndDate
      previousDutyDuration
      restRequired
      actualFdpStart
      actualFdpStartLoc
      actualRest
      restReduceBy
      affectedPerformance
      reportedToOperations
      cancelledDuty
      cancelledDutyReason
      fatigueComment
      internalMessage
      internalMessageHtml
      managerFeedback
      managerFeedbackHtml
      feedbackUpdatedBy
      feedbackUpdateDate
      area
      areaOps
      reportedToCAA
      reportedToUZPLN
      reportedToUZPLNAssets
      reportableOccurrence
      reportableOccurrenceCategory
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    editableByUserId
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    threadsRaw
    consequencesRaw
    outcome
    severity
    probability
    riskLevel
    approvedById
    approvedBy {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    review
    additionalMitigationMeasures
    mitigationMeasure
    monitoringRequired
    monitoringReview
    whenAt
    responsiblePersonId
    responsiblePerson {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    mitigationEffectivenessReview
    effective
    effectivePersonId
    effectivePerson {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    assessedByPersonIds
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHazardMutationVariables,
  APITypes.UpdateHazardMutation
>;
export const deleteHazard = /* GraphQL */ `mutation DeleteHazard(
  $input: DeleteHazardInput!
  $condition: ModelHazardConditionInput
) {
  deleteHazard(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    status
    title
    hazard
    unsafeEvent
    reportId
    report {
      id
      status
      reportType
      createdAt
      updatedAt
      flight
      editableByUserId
      contactId
      anonymous
      userMessage
      assets
      severity
      probability
      occurrenceType
      occurrenceTitle
      occurrenceCategory
      aircraftDamageType
      aircraftDamageDescription
      otherDamage
      otherDamageDescription
      personInjuryType
      personInjuryDescription
      dutyType
      dutyStartDate
      dutyEndDate
      dutyItemId
      dutyAuthor
      dutyAircrafts
      fdpType
      fdpFirstDepLoc
      fdpLastLndLoc
      fdpRoute
      fdpSplitBreakStart
      fdpSplitBreakEnd
      fdpSectors
      fdpActual
      fdpMax
      fdpIncrease
      previousDutyEndDate
      previousDutyDuration
      restRequired
      actualFdpStart
      actualFdpStartLoc
      actualRest
      restReduceBy
      affectedPerformance
      reportedToOperations
      cancelledDuty
      cancelledDutyReason
      fatigueComment
      internalMessage
      internalMessageHtml
      managerFeedback
      managerFeedbackHtml
      feedbackUpdatedBy
      feedbackUpdateDate
      area
      areaOps
      reportedToCAA
      reportedToUZPLN
      reportedToUZPLNAssets
      reportableOccurrence
      reportableOccurrenceCategory
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    editableByUserId
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    threadsRaw
    consequencesRaw
    outcome
    severity
    probability
    riskLevel
    approvedById
    approvedBy {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    review
    additionalMitigationMeasures
    mitigationMeasure
    monitoringRequired
    monitoringReview
    whenAt
    responsiblePersonId
    responsiblePerson {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    mitigationEffectivenessReview
    effective
    effectivePersonId
    effectivePerson {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    assessedByPersonIds
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHazardMutationVariables,
  APITypes.DeleteHazardMutation
>;
export const createComplianceFinding = /* GraphQL */ `mutation CreateComplianceFinding(
  $input: CreateComplianceFindingInput!
  $condition: ModelComplianceFindingConditionInput
) {
  createComplianceFinding(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    concept
    headline
    status
    progress
    findingSource
    findingDate
    auditReference
    auditOrFindingReference
    findingClassification
    area
    category
    description
    descriptionHtml
    files
    correctiveActionRequired
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    correctiveActionProposalDueDate
    rootCauseAnalysis
    rootCauseAnalysisDate
    correctiveActionProposal
    correctiveActionProposalDate
    rootCauseCorrection
    correctiveActionPlanAccepted
    correctiveActionPlan
    correctiveActionImplementation
    correctiveActionImplementationDate
    rootCauseCorrectionImplementation
    rootCauseCorrectionImplementationDate
    correctiveActionImplementationAccepted
    correctiveActionImplementationDescription
    reportId
    report {
      id
      status
      reportType
      createdAt
      updatedAt
      flight
      editableByUserId
      contactId
      anonymous
      userMessage
      assets
      severity
      probability
      occurrenceType
      occurrenceTitle
      occurrenceCategory
      aircraftDamageType
      aircraftDamageDescription
      otherDamage
      otherDamageDescription
      personInjuryType
      personInjuryDescription
      dutyType
      dutyStartDate
      dutyEndDate
      dutyItemId
      dutyAuthor
      dutyAircrafts
      fdpType
      fdpFirstDepLoc
      fdpLastLndLoc
      fdpRoute
      fdpSplitBreakStart
      fdpSplitBreakEnd
      fdpSectors
      fdpActual
      fdpMax
      fdpIncrease
      previousDutyEndDate
      previousDutyDuration
      restRequired
      actualFdpStart
      actualFdpStartLoc
      actualRest
      restReduceBy
      affectedPerformance
      reportedToOperations
      cancelledDuty
      cancelledDutyReason
      fatigueComment
      internalMessage
      internalMessageHtml
      managerFeedback
      managerFeedbackHtml
      feedbackUpdatedBy
      feedbackUpdateDate
      area
      areaOps
      reportedToCAA
      reportedToUZPLN
      reportedToUZPLNAssets
      reportableOccurrence
      reportableOccurrenceCategory
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    auditId
    audit {
      id
      auditNumber
      createdAt
      updatedAt
      auditProgressStartTimeStamp
      removed
      name
      auditDate
      type
      area
      status
      auditPlanId
      checklistId
      checklistRevision
      periodicity
      responsiblePerson
      otherResponsiblePersons
      files
      note
      responsibleEntity
      aircraft
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    auditPerforms {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateComplianceFindingMutationVariables,
  APITypes.CreateComplianceFindingMutation
>;
export const updateComplianceFinding = /* GraphQL */ `mutation UpdateComplianceFinding(
  $input: UpdateComplianceFindingInput!
  $condition: ModelComplianceFindingConditionInput
) {
  updateComplianceFinding(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    concept
    headline
    status
    progress
    findingSource
    findingDate
    auditReference
    auditOrFindingReference
    findingClassification
    area
    category
    description
    descriptionHtml
    files
    correctiveActionRequired
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    correctiveActionProposalDueDate
    rootCauseAnalysis
    rootCauseAnalysisDate
    correctiveActionProposal
    correctiveActionProposalDate
    rootCauseCorrection
    correctiveActionPlanAccepted
    correctiveActionPlan
    correctiveActionImplementation
    correctiveActionImplementationDate
    rootCauseCorrectionImplementation
    rootCauseCorrectionImplementationDate
    correctiveActionImplementationAccepted
    correctiveActionImplementationDescription
    reportId
    report {
      id
      status
      reportType
      createdAt
      updatedAt
      flight
      editableByUserId
      contactId
      anonymous
      userMessage
      assets
      severity
      probability
      occurrenceType
      occurrenceTitle
      occurrenceCategory
      aircraftDamageType
      aircraftDamageDescription
      otherDamage
      otherDamageDescription
      personInjuryType
      personInjuryDescription
      dutyType
      dutyStartDate
      dutyEndDate
      dutyItemId
      dutyAuthor
      dutyAircrafts
      fdpType
      fdpFirstDepLoc
      fdpLastLndLoc
      fdpRoute
      fdpSplitBreakStart
      fdpSplitBreakEnd
      fdpSectors
      fdpActual
      fdpMax
      fdpIncrease
      previousDutyEndDate
      previousDutyDuration
      restRequired
      actualFdpStart
      actualFdpStartLoc
      actualRest
      restReduceBy
      affectedPerformance
      reportedToOperations
      cancelledDuty
      cancelledDutyReason
      fatigueComment
      internalMessage
      internalMessageHtml
      managerFeedback
      managerFeedbackHtml
      feedbackUpdatedBy
      feedbackUpdateDate
      area
      areaOps
      reportedToCAA
      reportedToUZPLN
      reportedToUZPLNAssets
      reportableOccurrence
      reportableOccurrenceCategory
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    auditId
    audit {
      id
      auditNumber
      createdAt
      updatedAt
      auditProgressStartTimeStamp
      removed
      name
      auditDate
      type
      area
      status
      auditPlanId
      checklistId
      checklistRevision
      periodicity
      responsiblePerson
      otherResponsiblePersons
      files
      note
      responsibleEntity
      aircraft
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    auditPerforms {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateComplianceFindingMutationVariables,
  APITypes.UpdateComplianceFindingMutation
>;
export const deleteComplianceFinding = /* GraphQL */ `mutation DeleteComplianceFinding(
  $input: DeleteComplianceFindingInput!
  $condition: ModelComplianceFindingConditionInput
) {
  deleteComplianceFinding(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    concept
    headline
    status
    progress
    findingSource
    findingDate
    auditReference
    auditOrFindingReference
    findingClassification
    area
    category
    description
    descriptionHtml
    files
    correctiveActionRequired
    contactId
    contact {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    correctiveActionProposalDueDate
    rootCauseAnalysis
    rootCauseAnalysisDate
    correctiveActionProposal
    correctiveActionProposalDate
    rootCauseCorrection
    correctiveActionPlanAccepted
    correctiveActionPlan
    correctiveActionImplementation
    correctiveActionImplementationDate
    rootCauseCorrectionImplementation
    rootCauseCorrectionImplementationDate
    correctiveActionImplementationAccepted
    correctiveActionImplementationDescription
    reportId
    report {
      id
      status
      reportType
      createdAt
      updatedAt
      flight
      editableByUserId
      contactId
      anonymous
      userMessage
      assets
      severity
      probability
      occurrenceType
      occurrenceTitle
      occurrenceCategory
      aircraftDamageType
      aircraftDamageDescription
      otherDamage
      otherDamageDescription
      personInjuryType
      personInjuryDescription
      dutyType
      dutyStartDate
      dutyEndDate
      dutyItemId
      dutyAuthor
      dutyAircrafts
      fdpType
      fdpFirstDepLoc
      fdpLastLndLoc
      fdpRoute
      fdpSplitBreakStart
      fdpSplitBreakEnd
      fdpSectors
      fdpActual
      fdpMax
      fdpIncrease
      previousDutyEndDate
      previousDutyDuration
      restRequired
      actualFdpStart
      actualFdpStartLoc
      actualRest
      restReduceBy
      affectedPerformance
      reportedToOperations
      cancelledDuty
      cancelledDutyReason
      fatigueComment
      internalMessage
      internalMessageHtml
      managerFeedback
      managerFeedbackHtml
      feedbackUpdatedBy
      feedbackUpdateDate
      area
      areaOps
      reportedToCAA
      reportedToUZPLN
      reportedToUZPLNAssets
      reportableOccurrence
      reportableOccurrenceCategory
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    auditId
    audit {
      id
      auditNumber
      createdAt
      updatedAt
      auditProgressStartTimeStamp
      removed
      name
      auditDate
      type
      area
      status
      auditPlanId
      checklistId
      checklistRevision
      periodicity
      responsiblePerson
      otherResponsiblePersons
      files
      note
      responsibleEntity
      aircraft
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    auditPerforms {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteComplianceFindingMutationVariables,
  APITypes.DeleteComplianceFindingMutation
>;
export const createChecklist = /* GraphQL */ `mutation CreateChecklist(
  $input: CreateChecklistInput!
  $condition: ModelChecklistConditionInput
) {
  createChecklist(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    current
    revisions
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChecklistMutationVariables,
  APITypes.CreateChecklistMutation
>;
export const updateChecklist = /* GraphQL */ `mutation UpdateChecklist(
  $input: UpdateChecklistInput!
  $condition: ModelChecklistConditionInput
) {
  updateChecklist(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    current
    revisions
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChecklistMutationVariables,
  APITypes.UpdateChecklistMutation
>;
export const deleteChecklist = /* GraphQL */ `mutation DeleteChecklist(
  $input: DeleteChecklistInput!
  $condition: ModelChecklistConditionInput
) {
  deleteChecklist(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    current
    revisions
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChecklistMutationVariables,
  APITypes.DeleteChecklistMutation
>;
export const createAuditPlan = /* GraphQL */ `mutation CreateAuditPlan(
  $input: CreateAuditPlanInput!
  $condition: ModelAuditPlanConditionInput
) {
  createAuditPlan(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    year
    removed
    audits {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAuditPlanMutationVariables,
  APITypes.CreateAuditPlanMutation
>;
export const updateAuditPlan = /* GraphQL */ `mutation UpdateAuditPlan(
  $input: UpdateAuditPlanInput!
  $condition: ModelAuditPlanConditionInput
) {
  updateAuditPlan(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    year
    removed
    audits {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAuditPlanMutationVariables,
  APITypes.UpdateAuditPlanMutation
>;
export const deleteAuditPlan = /* GraphQL */ `mutation DeleteAuditPlan(
  $input: DeleteAuditPlanInput!
  $condition: ModelAuditPlanConditionInput
) {
  deleteAuditPlan(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    year
    removed
    audits {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAuditPlanMutationVariables,
  APITypes.DeleteAuditPlanMutation
>;
export const createAudit = /* GraphQL */ `mutation CreateAudit(
  $input: CreateAuditInput!
  $condition: ModelAuditConditionInput
) {
  createAudit(input: $input, condition: $condition) {
    id
    auditNumber
    createdAt
    updatedAt
    auditProgressStartTimeStamp
    removed
    name
    auditDate
    type
    area
    status
    auditPlanId
    auditPlan {
      id
      createdAt
      updatedAt
      year
      removed
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checklistId
    checklistRevision
    periodicity
    responsiblePerson
    otherResponsiblePersons
    auditPerforms {
      nextToken
      startedAt
      __typename
    }
    files
    note
    responsibleEntity
    complianceFindings {
      nextToken
      startedAt
      __typename
    }
    aircraft
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAuditMutationVariables,
  APITypes.CreateAuditMutation
>;
export const updateAudit = /* GraphQL */ `mutation UpdateAudit(
  $input: UpdateAuditInput!
  $condition: ModelAuditConditionInput
) {
  updateAudit(input: $input, condition: $condition) {
    id
    auditNumber
    createdAt
    updatedAt
    auditProgressStartTimeStamp
    removed
    name
    auditDate
    type
    area
    status
    auditPlanId
    auditPlan {
      id
      createdAt
      updatedAt
      year
      removed
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checklistId
    checklistRevision
    periodicity
    responsiblePerson
    otherResponsiblePersons
    auditPerforms {
      nextToken
      startedAt
      __typename
    }
    files
    note
    responsibleEntity
    complianceFindings {
      nextToken
      startedAt
      __typename
    }
    aircraft
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAuditMutationVariables,
  APITypes.UpdateAuditMutation
>;
export const deleteAudit = /* GraphQL */ `mutation DeleteAudit(
  $input: DeleteAuditInput!
  $condition: ModelAuditConditionInput
) {
  deleteAudit(input: $input, condition: $condition) {
    id
    auditNumber
    createdAt
    updatedAt
    auditProgressStartTimeStamp
    removed
    name
    auditDate
    type
    area
    status
    auditPlanId
    auditPlan {
      id
      createdAt
      updatedAt
      year
      removed
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checklistId
    checklistRevision
    periodicity
    responsiblePerson
    otherResponsiblePersons
    auditPerforms {
      nextToken
      startedAt
      __typename
    }
    files
    note
    responsibleEntity
    complianceFindings {
      nextToken
      startedAt
      __typename
    }
    aircraft
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAuditMutationVariables,
  APITypes.DeleteAuditMutation
>;
export const createAuditPerform = /* GraphQL */ `mutation CreateAuditPerform(
  $input: CreateAuditPerformInput!
  $condition: ModelAuditPerformConditionInput
) {
  createAuditPerform(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    groupItem
    compliance
    note
    files
    auditId
    audit {
      id
      auditNumber
      createdAt
      updatedAt
      auditProgressStartTimeStamp
      removed
      name
      auditDate
      type
      area
      status
      auditPlanId
      checklistId
      checklistRevision
      periodicity
      responsiblePerson
      otherResponsiblePersons
      files
      note
      responsibleEntity
      aircraft
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    complianceFindingId
    complianceFinding {
      id
      createdAt
      updatedAt
      removed
      concept
      headline
      status
      progress
      findingSource
      findingDate
      auditReference
      auditOrFindingReference
      findingClassification
      area
      category
      description
      descriptionHtml
      files
      correctiveActionRequired
      contactId
      correctiveActionProposalDueDate
      rootCauseAnalysis
      rootCauseAnalysisDate
      correctiveActionProposal
      correctiveActionProposalDate
      rootCauseCorrection
      correctiveActionPlanAccepted
      correctiveActionPlan
      correctiveActionImplementation
      correctiveActionImplementationDate
      rootCauseCorrectionImplementation
      rootCauseCorrectionImplementationDate
      correctiveActionImplementationAccepted
      correctiveActionImplementationDescription
      reportId
      auditId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAuditPerformMutationVariables,
  APITypes.CreateAuditPerformMutation
>;
export const updateAuditPerform = /* GraphQL */ `mutation UpdateAuditPerform(
  $input: UpdateAuditPerformInput!
  $condition: ModelAuditPerformConditionInput
) {
  updateAuditPerform(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    groupItem
    compliance
    note
    files
    auditId
    audit {
      id
      auditNumber
      createdAt
      updatedAt
      auditProgressStartTimeStamp
      removed
      name
      auditDate
      type
      area
      status
      auditPlanId
      checklistId
      checklistRevision
      periodicity
      responsiblePerson
      otherResponsiblePersons
      files
      note
      responsibleEntity
      aircraft
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    complianceFindingId
    complianceFinding {
      id
      createdAt
      updatedAt
      removed
      concept
      headline
      status
      progress
      findingSource
      findingDate
      auditReference
      auditOrFindingReference
      findingClassification
      area
      category
      description
      descriptionHtml
      files
      correctiveActionRequired
      contactId
      correctiveActionProposalDueDate
      rootCauseAnalysis
      rootCauseAnalysisDate
      correctiveActionProposal
      correctiveActionProposalDate
      rootCauseCorrection
      correctiveActionPlanAccepted
      correctiveActionPlan
      correctiveActionImplementation
      correctiveActionImplementationDate
      rootCauseCorrectionImplementation
      rootCauseCorrectionImplementationDate
      correctiveActionImplementationAccepted
      correctiveActionImplementationDescription
      reportId
      auditId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAuditPerformMutationVariables,
  APITypes.UpdateAuditPerformMutation
>;
export const deleteAuditPerform = /* GraphQL */ `mutation DeleteAuditPerform(
  $input: DeleteAuditPerformInput!
  $condition: ModelAuditPerformConditionInput
) {
  deleteAuditPerform(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    groupItem
    compliance
    note
    files
    auditId
    audit {
      id
      auditNumber
      createdAt
      updatedAt
      auditProgressStartTimeStamp
      removed
      name
      auditDate
      type
      area
      status
      auditPlanId
      checklistId
      checklistRevision
      periodicity
      responsiblePerson
      otherResponsiblePersons
      files
      note
      responsibleEntity
      aircraft
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    complianceFindingId
    complianceFinding {
      id
      createdAt
      updatedAt
      removed
      concept
      headline
      status
      progress
      findingSource
      findingDate
      auditReference
      auditOrFindingReference
      findingClassification
      area
      category
      description
      descriptionHtml
      files
      correctiveActionRequired
      contactId
      correctiveActionProposalDueDate
      rootCauseAnalysis
      rootCauseAnalysisDate
      correctiveActionProposal
      correctiveActionProposalDate
      rootCauseCorrection
      correctiveActionPlanAccepted
      correctiveActionPlan
      correctiveActionImplementation
      correctiveActionImplementationDate
      rootCauseCorrectionImplementation
      rootCauseCorrectionImplementationDate
      correctiveActionImplementationAccepted
      correctiveActionImplementationDescription
      reportId
      auditId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAuditPerformMutationVariables,
  APITypes.DeleteAuditPerformMutation
>;
export const createTicket = /* GraphQL */ `mutation CreateTicket(
  $input: CreateTicketInput!
  $condition: ModelTicketConditionInput
) {
  createTicket(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    name
    reservationDate
    status
    flightType
    type
    priceTable
    year
    number
    note
    totalPrice
    currency
    contactId
    customer {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    customerAccountId
    customerAccount {
      id
      createdAt
      updatedAt
      removed
      locked
      isDefault
      name
      currency
      balance
      contactId
      description
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    legs {
      nextToken
      startedAt
      __typename
    }
    settlementTicketLegs {
      nextToken
      startedAt
      __typename
    }
    accountingMonthId
    accountingMonth {
      id
      createdAt
      updatedAt
      removed
      closed
      name
      monthStartDate
      monthEndDate
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTicketMutationVariables,
  APITypes.CreateTicketMutation
>;
export const updateTicket = /* GraphQL */ `mutation UpdateTicket(
  $input: UpdateTicketInput!
  $condition: ModelTicketConditionInput
) {
  updateTicket(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    name
    reservationDate
    status
    flightType
    type
    priceTable
    year
    number
    note
    totalPrice
    currency
    contactId
    customer {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    customerAccountId
    customerAccount {
      id
      createdAt
      updatedAt
      removed
      locked
      isDefault
      name
      currency
      balance
      contactId
      description
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    legs {
      nextToken
      startedAt
      __typename
    }
    settlementTicketLegs {
      nextToken
      startedAt
      __typename
    }
    accountingMonthId
    accountingMonth {
      id
      createdAt
      updatedAt
      removed
      closed
      name
      monthStartDate
      monthEndDate
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTicketMutationVariables,
  APITypes.UpdateTicketMutation
>;
export const deleteTicket = /* GraphQL */ `mutation DeleteTicket(
  $input: DeleteTicketInput!
  $condition: ModelTicketConditionInput
) {
  deleteTicket(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    name
    reservationDate
    status
    flightType
    type
    priceTable
    year
    number
    note
    totalPrice
    currency
    contactId
    customer {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    customerAccountId
    customerAccount {
      id
      createdAt
      updatedAt
      removed
      locked
      isDefault
      name
      currency
      balance
      contactId
      description
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraft {
      id
      deleted
      registration
      friendlyType
      serialNumber
      imageUrl
      countableTypes
      upliftableTypes
      tags
      category
      userId
      logbookParameters
      aircraftTypeId
      maintenanceProgramId
      manufactureDate
      isReservationResource
      calendarId
      performanceProfile
      baseAirportIcao
      payload
      crewComposition
      callsign
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    legs {
      nextToken
      startedAt
      __typename
    }
    settlementTicketLegs {
      nextToken
      startedAt
      __typename
    }
    accountingMonthId
    accountingMonth {
      id
      createdAt
      updatedAt
      removed
      closed
      name
      monthStartDate
      monthEndDate
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTicketMutationVariables,
  APITypes.DeleteTicketMutation
>;
export const createCustomerAccount = /* GraphQL */ `mutation CreateCustomerAccount(
  $input: CreateCustomerAccountInput!
  $condition: ModelCustomerAccountConditionInput
) {
  createCustomerAccount(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    locked
    isDefault
    name
    currency
    balance
    contactId
    customer {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    description
    tickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerAccountMutationVariables,
  APITypes.CreateCustomerAccountMutation
>;
export const updateCustomerAccount = /* GraphQL */ `mutation UpdateCustomerAccount(
  $input: UpdateCustomerAccountInput!
  $condition: ModelCustomerAccountConditionInput
) {
  updateCustomerAccount(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    locked
    isDefault
    name
    currency
    balance
    contactId
    customer {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    description
    tickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerAccountMutationVariables,
  APITypes.UpdateCustomerAccountMutation
>;
export const deleteCustomerAccount = /* GraphQL */ `mutation DeleteCustomerAccount(
  $input: DeleteCustomerAccountInput!
  $condition: ModelCustomerAccountConditionInput
) {
  deleteCustomerAccount(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    locked
    isDefault
    name
    currency
    balance
    contactId
    customer {
      id
      userId
      isActiveCrew
      aircraftVisiblityLimitations
      crewId
      hasDutyTracking
      fullReadAccessToAircrafts
      deleted
      nameOfficialFirst
      nameOfficialLast
      name
      notes
      isReservationResource
      isCustomer
      calendarId
      baseAirportIcaos
      dispatchGroup
      accessRights
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    description
    tickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerAccountMutationVariables,
  APITypes.DeleteCustomerAccountMutation
>;
export const createAccountingMonth = /* GraphQL */ `mutation CreateAccountingMonth(
  $input: CreateAccountingMonthInput!
  $condition: ModelAccountingMonthConditionInput
) {
  createAccountingMonth(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    closed
    name
    monthStartDate
    monthEndDate
    tickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountingMonthMutationVariables,
  APITypes.CreateAccountingMonthMutation
>;
export const updateAccountingMonth = /* GraphQL */ `mutation UpdateAccountingMonth(
  $input: UpdateAccountingMonthInput!
  $condition: ModelAccountingMonthConditionInput
) {
  updateAccountingMonth(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    closed
    name
    monthStartDate
    monthEndDate
    tickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountingMonthMutationVariables,
  APITypes.UpdateAccountingMonthMutation
>;
export const deleteAccountingMonth = /* GraphQL */ `mutation DeleteAccountingMonth(
  $input: DeleteAccountingMonthInput!
  $condition: ModelAccountingMonthConditionInput
) {
  deleteAccountingMonth(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    removed
    closed
    name
    monthStartDate
    monthEndDate
    tickets {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountingMonthMutationVariables,
  APITypes.DeleteAccountingMonthMutation
>;
