import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';
import {
    aircraftMaintenanceLimitExtensionItemFields
} from '../../../Admin/Datasources/AircraftMaintenanceLimitExtensions';
import {
    AircraftMaintenanceLimitExtension,
    aircraftMaintenanceLimitExtensionFromDb,
    aircraftMaintenanceLimitExtensionToDb
} from '../../../Admin/AircraftMaintenanceLimitExtensions/AircraftMaintenanceLimitExtension';

let counter = 0;

export const aircraftMaintenanceLimitExtensionSyncerDefinition: DbSyncerUpDefinition = (record: AircraftMaintenanceLimitExtension) => {
    const c = ++counter;
    const [commandName, inputType] = !record._version
        ? ['createAircraftMaintenanceLimitExtension', 'CreateAircraftMaintenanceLimitExtensionInput']
        : ['updateAircraftMaintenanceLimitExtension', 'UpdateAircraftMaintenanceLimitExtensionInput'];
    const {
        mutationName,
        inputKey,
        query
    } = getStandardSyncerFields(commandName, c, aircraftMaintenanceLimitExtensionItemFields);
    const input = aircraftMaintenanceLimitExtensionToDb(record);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: aircraftMaintenanceLimitExtensionFromDb,
        queryName: 'getAircraftMaintenanceLimitExtension',
    };
};
