import { getDb } from '../LocalDB/LocalDB';
import { ReleaseToService } from './types';
import { getDbSyncer } from '../LocalDB/DbSyncer';

export const getReleaseToServicesForSquawk = async (squawkId: string): Promise<ReleaseToService[]> => {
    return getDb().releaseToServices.where({squawkId}).toArray();
};

export type GetReleaseToServiceParams = [string];
export const getReleaseToService = async (id: string): Promise<ReleaseToService | undefined> => {
    return getDb().releaseToServices.get(id);
};


export const saveReleaseToService = async (release: ReleaseToService): Promise<void> => {
    const db = getDb();
    await db.releaseToServices.put(release);
    getDbSyncer().markToSync(db.releaseToServices, release.id);
};

export const getReleaseToServicesForAircraft = async (aircraftId: string): Promise<ReleaseToService[]> => {
    return getDb().releaseToServices.where({aircraftId}).toArray();
};
