import { FlightLegPhase } from '../FlightLegs/FlightLeg';
import { getDb } from '../LocalDB/LocalDB';
import { getDbSyncer } from '../LocalDB/DbSyncer';
import { toMap } from '../../utils/arrayUtils';
import { FlightDocument } from './FlightDocument';

export const saveFlightDocuments = async (documents: FlightDocument[]): Promise<void> => {
    await injectDocumentRemoteData(documents);
    const documentsTable = getDb().documents;
    await documentsTable.bulkPut(documents);
    getDbSyncer().markToSync(documentsTable, ...documents.map(d => d.id));
};
/**
 * Makes sure that if document was saved on the background, it will not have to be reuploaded again
 * @param documents
 */
const injectDocumentRemoteData = async (documents: FlightDocument[]) => {
    const ids = documents.map(d => d.id);
    const existingDoc = (await getDb().documents.bulkGet(ids)).filter(Boolean);
    const existingDocsById = toMap(existingDoc);
    documents.forEach(doc => {
        const existing = existingDocsById.get(doc.id);
        if (existing) {
            doc._version = existing._version;
            doc.contentKey = existing.contentKey;
        }
    });
};

export const getFlightDocumentsForLeg = async (legId: string, phase: FlightLegPhase): Promise<FlightDocument[]> => {
    const allDocuments = await getDb().documents
        .where({legId, phase})
        .toArray();

    return allDocuments.filter(doc => !doc.squawkId);
};

export const getFlightDocumentsForFlight = async (flightId: string): Promise<FlightDocument[]> => {
    return getDb().documents.where({flightId}).toArray();
};

export const getFlightDocumentsForFlightCount = async (flightId: string): Promise<number> => {
    return getDb().documents.where({flightId}).count();
};

export const getFlightDocumentsForSquawk = async (squawkId: string): Promise<FlightDocument[]> => {
    return getDb().documents.where({squawkId}).toArray();
};

export const getFlightDocumentsForRelease = async (releaseToServiceId: string): Promise<FlightDocument[]> => {
    const docs = await getDb().documents.where({releaseToServiceId}).toArray();
    return loadFlightDocumentsWithSameMxRecord(docs);
};

async function loadFlightDocumentsWithSameMxRecord(documents: FlightDocument[]): Promise<FlightDocument[]> {
    const docsById = toMap(documents);
    const maintenanceRecordIds = documents.map(doc => doc.maintenanceRecordId).filter(Boolean);
    const uniqueMXRIds = Array.from(new Set(maintenanceRecordIds));
    if(uniqueMXRIds.length) {
        await Promise.all(uniqueMXRIds.map(async (maintenanceRecordId) => {
            const docsForRelease = await getDb().documents.where({maintenanceRecordId}).toArray();
            toMap(docsForRelease, docsById);
        }))
    }
    return Array.from(docsById.values());
}
