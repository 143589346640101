import { AircraftCountableType, COUNTABLE_CHANGE_CORRECTION, CountableChange, CountableChangeType } from "./types";
import { Aircraft } from "../AircraftsDatasource";
import * as uuid from 'uuid';

export const setConsumption = (countableChange: CountableChange, changeType: AircraftCountableType, changeValue: number | null): CountableChange => {
    const stateIndex = countableChange.countableTypes.indexOf(changeType);
    const changeTypes = countableChange.changeTypes.slice();
    const changeIndex = changeTypes.indexOf(changeType);
    const changeValues = countableChange.changeValues.slice();
    if (changeIndex >= 0) {
        if (changeValue !== null) {
            changeValues[changeIndex] = changeValue;
        } else {
            changeValues.splice(changeIndex, 1);
            changeTypes.splice(changeIndex, 1);
        }
    } else {
        if (changeValue !== null) {
            changeValues.push(changeValue);
            changeTypes.push(changeType);
        }
    }
    const countableValuesAfter = countableChange.countableValuesAfter.slice();
    if (stateIndex >= 0) {
        countableValuesAfter[stateIndex] = countableChange.countableValuesBefore[stateIndex] + (changeValue || 0);
    }
    return {...countableChange, changeTypes, changeValues, countableValuesAfter};
};

export const getInitialCountableChange = (aircraft: Aircraft, editableByUserIds?: string[]): CountableChange => {
    const zeroes = new Array(aircraft.countableTypes.length).fill(0);
    return {
        id: uuid.v4(),
        editableByUserIds: editableByUserIds || [],
        countableTypes: aircraft.countableTypes,
        countableValuesBefore: zeroes,
        countableValuesAfter: zeroes,
        changeValues: [],
        changeTypes: [],
        aircraftId: aircraft.id,
        createdAt: new Date(),
        createdByName: "(system initial status)",
        type: COUNTABLE_CHANGE_CORRECTION,
        deleted: false
    }
};

type GetDerivedCountableChangeParams = {
    type: CountableChangeType;
    appliedByName: string;
    place: string;
    flightId?: string;
    legId?: string;
}

export const getDerivedCountableChange = (lastCountableChange: CountableChange, params: GetDerivedCountableChangeParams, editableByUserIds?: string[]): CountableChange => {
    return {
        id: uuid.v4(),
        type: params.type,
        createdByName: params.appliedByName,
        createdAt: new Date(),
        aircraftId: lastCountableChange.aircraftId,
        place: params.place,
        flightId: params.flightId,
        legId: params.legId,
        changeTypes: [],
        changeValues: [],
        countableValuesBefore: lastCountableChange.countableValuesAfter.slice(),
        countableTypes: lastCountableChange.countableTypes.slice(),
        countableValuesAfter: lastCountableChange.countableValuesAfter.slice(),
        deleted: false,
        editableByUserIds: editableByUserIds || [],
    }
};

export const getCountableBeforeAfter = (counableChange: CountableChange, type: AircraftCountableType): [number, number, number] | [null, null, null] => {
    const index = counableChange.countableTypes.indexOf(type);
    if (index >= 0) {
        const before = counableChange.countableValuesBefore[index];
        const after = counableChange.countableValuesAfter[index];
        return [before, after, index];
    } else {
        return [null, null, null];
    }
};

export const getCountableChangeTypeHuman = (type: CountableChangeType) => {
    switch (type) {
        case "consumption":
            return "consumption after flight";
        case "uplift":
            return "uplift";
        case "correction":
            return "correction";

    }
};

export const areCountableChangesCompatible = (cc1: CountableChange, cc2: CountableChange) => {
    return cc1.countableTypes.join('-') === cc2.countableTypes.join('-');
};
