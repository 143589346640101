import { Flight, FLIGHT_STATE_FINISHED } from './Flight';
import { getDb } from '../LocalDB/LocalDB';
import {
    Aircraft,
    AircraftForFlight,
    getAircraftForFlight,
    getAircraftsByIdLocalAndOnline,
} from '../AircraftsDatasource';
import { FlightLeg, isLegFinished, WithLastCountableChange } from '../FlightLegs/FlightLeg';
import { deleteFlightLeg, getCrewFlightLegsOnline, getFlightLegsForFlight } from '../FlightLegs/FlightLegDatasource';
import { getDbSyncer } from '../LocalDB/DbSyncer';
import { getFlightDocumentsForFlightCount } from '../FlightDocuments/FlightDocumentsDatasource';
import { API, graphqlOperation } from 'aws-amplify';
import { CreateAircraftInput, CreateCountableChangeInput, CreateFlightInput, CreateLegInput } from '../../API';
import { flightItemFields, flightTransform } from '../LocalDB/Transformers/flights';
import { sanitizeId } from '../datasourceUtils';
import { aircraftItemFields } from '../LocalDB/Transformers/aircrafts';
import { flightLegFromDb, flightLegItemFields } from '../LocalDB/Transformers/flightLegs';
import { countableChangeFromDb, countableChangeItemFields } from '../LocalDB/Transformers/countableChanges';
import { signAndPut } from '../SignedData/SignedDataDatasource';
import { CountableChange } from '../CountableChanges/types';
import { Squawk } from '../Squawks/types';
import { ReleaseToService } from '../ReleaseToService/types';
import { DbCreated } from '../LocalDB/types';
import { saveFlightLogbookRecords } from '../Logbook/SaveFlightLogbookRecords';
import { AircraftMaintenanceLimit } from '../../Admin/AircraftMaintenanceLimits/AircraftMaintenanceLimit';

export const putFlight = async (flight: Flight): Promise<void> => {
    const db = getDb();
    await Promise.all([db.flights.put(flight), getDbSyncer().markToSync(db.flights, flight.id)]);
};

export interface FlightAndAircraft extends Flight {
    aircraft: AircraftForFlight | null;
}

export interface FlightAndAircraftAndLegs extends Flight {
    aircraft: Aircraft | null;
    legs: FlightLeg[];
}

export interface CompleteFlight extends Flight {
    aircraft: AircraftForFlight | null;
    legs: FlightLeg[];
    flightDocumentsCount: number;
}

export type GetOpenFlightsForUserParams = [string];
export const getOpenFlightsForUser = async (
    userId: GetOpenFlightsForUserParams[0]
): Promise<FlightAndAircraftAndLegs[]> => {
    const flights = await getDb()
        .flights.filter((f) => f.state !== FLIGHT_STATE_FINISHED && f.editableByUserIds.includes(userId) && !f.deleted)
        .toArray();
    const flightForUsers = flights.map(async (flight): Promise<FlightAndAircraftAndLegs> => {
        const [aircraft, legs] = await Promise.all([
            getAircraftForFlight(flight.aircraftId),
            getFlightLegsForFlight(flight.id),
        ]);
        return { ...flight, aircraft, legs };
    });
    return Promise.all(flightForUsers);
};

export type GetAllFlightsForCrewParams = [string];
export const getAllFlightsForCrew = async (
    crewId: GetAllFlightsForCrewParams[0]
): Promise<FlightAndAircraftAndLegs[]> => {
    const db = getDb();
    const flightLegs = await db.legs.filter((l) => !l.deleted && (l.picId === crewId || l.sicId === crewId)).toArray();
    const flightIds = new Set(flightLegs.map((l) => l.flightId));
    const aircraftIds = new Set(flightLegs.map((l) => l.aircraftId));
    const [flights, aircrafts] = await Promise.all([
        db.flights.bulkGet(Array.from(flightIds)),
        db.aircrafts.bulkGet(Array.from(aircraftIds)),
    ]);
    const validFlights = flights.filter((f) => f && !f.deleted);
    const flightsAndLegs = validFlights.map(
        (flight): FlightAndAircraftAndLegs => ({
            ...flight,
            aircraft: aircrafts.find((ac) => ac?.id === flight.aircraftId) || null,
            legs: flightLegs.filter((l) => l.flightId === flight.id),
        })
    );
    sortFlightsAndlegs(flightsAndLegs);
    return flightsAndLegs;
};

export const getAllFlightsForCrewOnline = async (
    crewId: GetAllFlightsForCrewParams[0]
): Promise<FlightAndAircraftAndLegs[]> => {
    const flightLegs = (await getCrewFlightLegsOnline(crewId)).filter((l) => !l.deleted);
    const flightIds = Array.from(new Set(flightLegs.map((l) => l.flightId)));
    const aircraftIds = Array.from(new Set(flightLegs.map((l) => l.aircraftId)));

    const [flights, aircrafts] = await Promise.all([
        getFlightsByIdsLocalAndOnline(flightIds),
        getAircraftsByIdLocalAndOnline(aircraftIds),
    ]);

    const flightsAndLegs = flights.map((flight): FlightAndAircraftAndLegs => {
        const legs = flightLegs.filter((l) => l.flightId === flight.id && l.timeOut);
        legs.sort((a, b) => a.timeOut.getTime() - b.timeOut.getTime());
        return {
            ...flight,
            aircraft: aircrafts.find((ac) => ac.id === flight.aircraftId) || null,
            legs,
        };
    });
    sortFlightsAndlegs(flightsAndLegs);
    return flightsAndLegs;
};
export type GetFlightsAndLegsForAircraftParams = [string];
export const getFlightsAndLegsForAircraft = async (
    aircraftId: GetFlightsAndLegsForAircraftParams[0]
): Promise<FlightAndAircraftAndLegs[]> => {
    const db = getDb();
    const legsPromise = db.legs
        .where({ aircraftId })
        .filter((l) => !l.deleted)
        .toArray();
    const flightsPromise = db.flights
        .where({ aircraftId })
        .filter((l) => !l.deleted)
        .toArray();
    const aircraftPromise = db.aircrafts.get(aircraftId);
    const [flightLegs, flights, aircraft] = await Promise.all([legsPromise, flightsPromise, aircraftPromise]);
    if (!aircraft) {
        throw new Error('Trying to get flights for non-existing aircraft');
    }
    const flightsAndLegs = flights.map((flight): FlightAndAircraftAndLegs => {
        const legs = flightLegs.filter((l) => l.flightId === flight.id && l.timeOut);
        legs.sort((a, b) => a.timeOut.getTime() - b.timeOut.getTime());
        return {
            ...flight,
            aircraft,
            legs,
        };
    });
    sortFlightsAndlegs(flightsAndLegs);
    return flightsAndLegs;
};

const sortFlightsAndlegs = (flights: (Flight & { legs: FlightLeg[] })[]) => {
    flights.sort((a, b) => {
        const aRef = a.legs[0] ? a.legs[0].timeOut : a.createdAt;
        const bRef = b.legs[0] ? b.legs[0].timeOut : b.createdAt;
        return bRef.getTime() - aRef.getTime();
    });
};

export const getFlightsByIdsLocalAndOnline = async (flightIds: string[]): Promise<Flight[]> => {
    const offlineFlights = (await getFlightsByIds(flightIds).then((f) => f.filter(Boolean))) as Flight[];
    const offlineFlightIds = new Set(offlineFlights.map((f) => f.id));
    const flightIdsToRequest = flightIds.filter((fId) => !offlineFlightIds.has(fId));
    const onlineFlights = await getFlightsByIdsOnline(flightIdsToRequest);
    return [...offlineFlights, ...onlineFlights].filter((f) => !f.deleted);
};

export const getFlightsByIds = async (flightIds: string[]): Promise<(Flight | null)[]> => {
    return getDb().flights.bulkGet(flightIds);
};

export const getFlightById = async (flightId: string): Promise<Flight | undefined> => {
    return getDb().flights.get(flightId);
};

export const getFlightsByIdsOnline = async (flightIds: string[]): Promise<Flight[]> => {
    if (flightIds.length === 0) {
        return [];
    }
    const flightsQuery = `query getFlights {
        ${flightIds.map(
            (flightId, index) => `
        getFlight${index}: getFlight(id: "${sanitizeId(flightId)}") {
            ${flightItemFields}
        }
        `
        )}
    }`;
    const gqlo = graphqlOperation(flightsQuery);
    const flightsResult = (await API.graphql(gqlo)) as { data: { [key: string]: CreateFlightInput | null } };
    return (Object.values(flightsResult.data).filter(Boolean) as CreateFlightInput[]).map((flight) =>
        flightTransform(flight)
    );
};

export type GetCompleteFlightParams = [string];
export const getCompleteFlight = async (flightId: GetCompleteFlightParams[0]): Promise<CompleteFlight | null> => {
    const [flight, legs, flightDocumentsCount] = await Promise.all([
        getDb().flights.get(flightId),
        getFlightLegsForFlight(flightId),
        getFlightDocumentsForFlightCount(flightId),
    ]);
    if (!flight) {
        return null;
    }
    if (legs) {
        sortFlightLegs(legs);
    }
    const aircraft = await getAircraftForFlight(flight.aircraftId);
    return { ...flight, aircraft, legs: legs || [], flightDocumentsCount: flightDocumentsCount || 0 };
};

export type FlightForExport = {
    aircraft: Aircraft | undefined;
    aircraftMaintenanceLimits: AircraftMaintenanceLimit[] | undefined;
    flight: Flight | undefined;
    legs: FlightLeg[] | undefined;
    countableChanges: CountableChange[] | undefined;
    squawks: Squawk[] | undefined;
    releaseToServices: ReleaseToService[] | undefined;
};
export const getFlightForExport = async (flightId: string, aircraftId: string): Promise<FlightForExport> => {
    const db = getDb();
    const [aircraft, aircraftMaintenanceLimits, flight, legs, countableChanges, squawks, releaseToServices] =
        await Promise.all([
            db.aircrafts.get(aircraftId)!,
            db.aircraftMaintenanceLimits.where({ aircraftId }).toArray(),
            db.flights.get(flightId)!,
            db.legs.where({ flightId }).toArray(),
            db.countableChanges.where({ flightId }).toArray(),
            db.squawks.where({ aircraftId }).toArray(),
            db.releaseToServices.where({ aircraftId }).toArray(),
        ]);

    return {
        aircraft,
        aircraftMaintenanceLimits,
        flight,
        legs,
        countableChanges,
        squawks,
        releaseToServices,
    };
};

export const getCompleteFlightOnline = async (flightId: GetCompleteFlightParams[0]): Promise<CompleteFlight | null> => {
    const query = `query completeFlight($flightId: ID!) {
        getFlight(id: $flightId) {
            ${flightItemFields}
            aircraft {
                ${aircraftItemFields}
            }
            legs {
                items {
                    ${flightLegItemFields}
                    countableChanges {
                        items {
                            ${countableChangeItemFields}
                        }
                    }
                }
            }
        }
    }`;
    const gqlo = graphqlOperation(query, { flightId });
    const flightResult = (await API.graphql(gqlo)) as {
        data: {
            getFlight:
                | null
                | (CreateFlightInput & {
                      aircraft: CreateAircraftInput;
                      legs: {
                          items: (CreateLegInput & {
                              countableChanges: {
                                  items: (CreateCountableChangeInput & DbCreated)[];
                              };
                          })[];
                      };
                  });
        };
    };

    if (flightResult.data.getFlight === null) {
        return null;
    } else {
        const flight = flightTransform(flightResult.data.getFlight);
        const legs = flightResult.data.getFlight.legs.items.map((legInput): FlightLeg & WithLastCountableChange => {
            const leg = flightLegFromDb(legInput);
            const ccIdToFind = legInput.consumptionCountableChangeId || legInput.upliftCountableChangeId;
            const ccToUse = legInput.countableChanges.items.find((cc) => cc.id === ccIdToFind);
            if (!ccToUse) {
                throw new Error('Cannot find required countable change for one of the legs');
            }
            return {
                ...leg,
                lastCountableChange: countableChangeFromDb(ccToUse),
            };
        });
        return {
            ...flight,
            legs,
            aircraft: await getAircraftForFlight(flight.aircraftId),
            flightDocumentsCount: 0,
        };
    }
};

const sortFlightLegs = function (legs: FlightLeg[]) {
    legs.sort((a, b) => {
        return getLegReferenceTime(a).getTime() - getLegReferenceTime(b).getTime();
    });
};

const getLegReferenceTime = (leg: FlightLeg): Date => {
    if (isLegFinished(leg)) {
        return leg.timeOut;
    } else {
        return leg.createdAt;
    }
};

export const getFlightsForAircraft = async (aircraftId: string): Promise<Flight[]> => {
    return getDb().flights.where('aircraftId').equals(aircraftId).sortBy('createdAt');
};

export const finishFlight = async (flight: CompleteFlight, waitForSync: boolean = false): Promise<void> => {
    const bareFlight = createBareFlight(flight);
    bareFlight.state = FLIGHT_STATE_FINISHED;
    const flightForExport = await getFlightForExport(flight.id, flight.aircraftId);
    await Promise.all([
        signAndPut(JSON.stringify(flightForExport), { flightId: flight.id }),
        putFlight(bareFlight),
        saveFlightLogbookRecords(flight),
    ]);
    if (waitForSync) {
        await getDbSyncer().sync();
    }
};

export const deleteFlight = async (flightId: string): Promise<void> => {
    const flight = await getCompleteFlight(flightId);
    if (!flight) {
        throw new Error(`Flight to delete not found: ${flightId}`);
    }
    await Promise.all(flight.legs.map((l) => deleteFlightLeg(l.id)));
    flight.deleted = true;
    const db = getDb();
    await db.flights.put(flight);
    getDbSyncer().markToSync(db.flights, flightId);
};

export const createBareFlight = <T extends Flight>(flight: T): Flight => {
    const {
        id,
        createdAt,
        aircraftId,
        acceptanceNotes,
        picName,
        picId,
        editableByUserIds,
        state,
        reservationId,
        deleted,
        _version,
    } = flight;
    return {
        id,
        createdAt,
        aircraftId,
        acceptanceNotes,
        picName,
        picId,
        editableByUserIds,
        state,
        reservationId,
        deleted,
        _version,
    };
};
