import { DbSaveable } from '../LocalDB/types';
import { numberToPrecision } from '../../utils/NumberToPrecision';
import { decimalToHoursMinutes } from '../../utils/timeUtils';

export const COUNTABLE_CHANGE_CONSUMPTION = 'consumption';
export const COUNTABLE_CHANGE_UPLIFT = 'uplift';
export const COUNTABLE_CHANGE_CORRECTION = 'correction';
export const COUNTABLE_CHANGE_MAINTENANCE = 'maintenance';

export type CountableChangeType =
    | typeof COUNTABLE_CHANGE_CONSUMPTION
    | typeof COUNTABLE_CHANGE_UPLIFT
    | typeof COUNTABLE_CHANGE_CORRECTION
    | typeof COUNTABLE_CHANGE_MAINTENANCE;

export const AIRCRAFT_COUNTABLE_TACH_TIME = 'TACH_TIME';
export const AIRCRAFT_COUNTABLE_HOBBS_TIME = 'HOBBS_TIME';
export const AIRCRAFT_COUNTABLE_FLIGHT_TIME = 'FLIGHT_TIME';
export const AIRCRAFT_COUNTABLE_ANY_FLIGHT_TIME = 'ANY_FLIGHT_TIME'; // virtual for available of tach/flight/hobbs time
export const AIRCRAFT_COUNTABLE_LANDINGS = 'LANDINGS';
export const AIRCRAFT_COUNTABLE_CYCLES = 'CYCLES';
export const AIRCRAFT_COUNTABLE_CYCLES_L = 'CYCLES_L';
export const AIRCRAFT_COUNTABLE_CYCLES_R = 'CYCLES_R';
export const AIRCRAFT_COUNTABLE_FUEL_GAL = 'FUEL_GAL';

export const AIRCRAFT_COUNTABLE_FUEL_KG = 'FUEL_KG';
export const AIRCRAFT_COUNTABLE_FUEL_LB = 'FUEL_LB';
export const AIRCRAFT_COUNTABLE_OIL_LEGACY = 'OIL';
export const AIRCRAFT_COUNTABLE_OIL_QUARTS = 'OIL_QUARTS';
export const AIRCRAFT_COUNTABLE_OIL_PERCENT = 'OIL_PERCENT';
export const AIRCRAFT_COUNTABLE_OIL_L_LEGACY = 'OIL_L';
export const AIRCRAFT_COUNTABLE_OIL_R_LEGACY = 'OIL_R';
export const AIRCRAFT_COUNTABLE_OIL_L_QUARTS = 'OIL_L_QUARTS';
export const AIRCRAFT_COUNTABLE_OIL_R_QUARTS = 'OIL_R_QUARTS';
export const AIRCRAFT_COUNTABLE_OIL_L_PERCENT = 'OIL_L_PERCENT';
export const AIRCRAFT_COUNTABLE_OIL_R_PERCENT = 'OIL_R_PERCENT';
export const AIRCRAFT_COUNTABLE_PRIST = 'PRIST'; // uplift only
export const AIRCRAFT_COUNTABLE_TKS = 'TKS';
export const AIRCRAFT_COUNTABLE_OXYGEN = 'OXYGEN';
export const AIRCRAFT_COUNTABLE_FUEL_LITERS = 'FUEL_LITERS';
export type AircraftCountableType =
    | typeof AIRCRAFT_COUNTABLE_TACH_TIME
    | typeof AIRCRAFT_COUNTABLE_FLIGHT_TIME
    | typeof AIRCRAFT_COUNTABLE_HOBBS_TIME
    | typeof AIRCRAFT_COUNTABLE_ANY_FLIGHT_TIME
    | typeof AIRCRAFT_COUNTABLE_LANDINGS
    | typeof AIRCRAFT_COUNTABLE_CYCLES
    | typeof AIRCRAFT_COUNTABLE_CYCLES_L
    | typeof AIRCRAFT_COUNTABLE_CYCLES_R
    | typeof AIRCRAFT_COUNTABLE_FUEL_GAL
    | typeof AIRCRAFT_COUNTABLE_FUEL_LB
    | typeof AIRCRAFT_COUNTABLE_FUEL_KG
    | typeof AIRCRAFT_COUNTABLE_OIL_LEGACY
    | typeof AIRCRAFT_COUNTABLE_OIL_L_LEGACY
    | typeof AIRCRAFT_COUNTABLE_OIL_R_LEGACY
    | typeof AIRCRAFT_COUNTABLE_OIL_QUARTS
    | typeof AIRCRAFT_COUNTABLE_OIL_L_QUARTS
    | typeof AIRCRAFT_COUNTABLE_OIL_R_QUARTS
    | typeof AIRCRAFT_COUNTABLE_OIL_PERCENT
    | typeof AIRCRAFT_COUNTABLE_OIL_L_PERCENT
    | typeof AIRCRAFT_COUNTABLE_OIL_R_PERCENT
    | typeof AIRCRAFT_COUNTABLE_TKS
    | typeof AIRCRAFT_COUNTABLE_OXYGEN
    | typeof AIRCRAFT_COUNTABLE_FUEL_LITERS;

export const AIRCRAFT_COUNTABLES: AircraftCountableType[] = [
    AIRCRAFT_COUNTABLE_TACH_TIME,
    AIRCRAFT_COUNTABLE_FLIGHT_TIME,
    AIRCRAFT_COUNTABLE_HOBBS_TIME,
    AIRCRAFT_COUNTABLE_LANDINGS,
    AIRCRAFT_COUNTABLE_CYCLES,
    AIRCRAFT_COUNTABLE_CYCLES_L,
    AIRCRAFT_COUNTABLE_CYCLES_R,
    AIRCRAFT_COUNTABLE_FUEL_GAL,
    AIRCRAFT_COUNTABLE_FUEL_LB,
    AIRCRAFT_COUNTABLE_FUEL_KG,
    AIRCRAFT_COUNTABLE_FUEL_LITERS,
    AIRCRAFT_COUNTABLE_OIL_QUARTS,
    AIRCRAFT_COUNTABLE_OIL_L_QUARTS,
    AIRCRAFT_COUNTABLE_OIL_R_QUARTS,
    AIRCRAFT_COUNTABLE_OIL_PERCENT,
    AIRCRAFT_COUNTABLE_OIL_L_PERCENT,
    AIRCRAFT_COUNTABLE_OIL_R_PERCENT,
    AIRCRAFT_COUNTABLE_TKS,
    AIRCRAFT_COUNTABLE_OXYGEN,
];

export type AircraftUpliftableType =
    | typeof AIRCRAFT_COUNTABLE_OIL_LEGACY
    | typeof AIRCRAFT_COUNTABLE_OIL_L_LEGACY
    | typeof AIRCRAFT_COUNTABLE_OIL_R_LEGACY
    | typeof AIRCRAFT_COUNTABLE_OIL_QUARTS
    | typeof AIRCRAFT_COUNTABLE_OIL_L_QUARTS
    | typeof AIRCRAFT_COUNTABLE_OIL_R_QUARTS
    | typeof AIRCRAFT_COUNTABLE_PRIST
    | typeof AIRCRAFT_COUNTABLE_FUEL_LITERS
    | typeof AIRCRAFT_COUNTABLE_TKS
    | typeof AIRCRAFT_COUNTABLE_OXYGEN;

export const AIRCRAFT_UPLIFTABLES: (AircraftCountableType | AircraftUpliftableType)[] = [
    AIRCRAFT_COUNTABLE_OIL_QUARTS,
    AIRCRAFT_COUNTABLE_OIL_L_QUARTS,
    AIRCRAFT_COUNTABLE_OIL_R_QUARTS,
    AIRCRAFT_COUNTABLE_PRIST,
    AIRCRAFT_COUNTABLE_FUEL_LITERS,
    AIRCRAFT_COUNTABLE_TKS,
    AIRCRAFT_COUNTABLE_OXYGEN,
];

export const AIRCRAFT_FLIGHT_TIME_COUNTABLES: AircraftCountableType[] = [
    AIRCRAFT_COUNTABLE_FLIGHT_TIME,
    AIRCRAFT_COUNTABLE_TACH_TIME,
];

export type AircraftFuelCountableType =
    | typeof AIRCRAFT_COUNTABLE_FUEL_LB
    | typeof AIRCRAFT_COUNTABLE_FUEL_KG
    | typeof AIRCRAFT_COUNTABLE_FUEL_GAL
    | typeof AIRCRAFT_COUNTABLE_FUEL_LITERS;

export const AIRCRAFT_FUEL_COUNTABLES: AircraftFuelCountableType[] = [
    AIRCRAFT_COUNTABLE_FUEL_LB,
    AIRCRAFT_COUNTABLE_FUEL_KG,
    AIRCRAFT_COUNTABLE_FUEL_GAL,
    AIRCRAFT_COUNTABLE_FUEL_LITERS,
];

export function countableTypeIsFuel(ct: string): ct is AircraftFuelCountableType {
    return (AIRCRAFT_FUEL_COUNTABLES as string[]).includes(ct);
}

export const FUEL_LITERS_TO_GAL = 0.264172;
export const FUEL_LITERS_TO_LB = 1.77;
export const FUEL_LITERS_TO_KG = 0.8;
export const AIRCRAFT_IGNORED_CONSUMABLES_ON_INPUT: (AircraftCountableType | AircraftUpliftableType)[] = [
    AIRCRAFT_COUNTABLE_TACH_TIME,
    AIRCRAFT_COUNTABLE_FLIGHT_TIME,
    AIRCRAFT_COUNTABLE_HOBBS_TIME,
    AIRCRAFT_COUNTABLE_LANDINGS,
    AIRCRAFT_COUNTABLE_CYCLES,
    AIRCRAFT_COUNTABLE_CYCLES_L,
    AIRCRAFT_COUNTABLE_CYCLES_R,
];

export const AIRCRAFT_IGNORED_CONSUMABLES_ON_DISPLAY: (AircraftCountableType | AircraftUpliftableType)[] = [
    AIRCRAFT_COUNTABLE_TACH_TIME,
    AIRCRAFT_COUNTABLE_FLIGHT_TIME,
    AIRCRAFT_COUNTABLE_HOBBS_TIME,
    AIRCRAFT_COUNTABLE_CYCLES,
    AIRCRAFT_COUNTABLE_CYCLES_L,
    AIRCRAFT_COUNTABLE_CYCLES_R,
];

export type CountableChange = {
    id: string;
    editableByUserIds: string[];
    aircraftId: string;
    flightId?: string;
    legId?: string;
    createdAt: Date;
    createdByName: string;
    place?: string;
    type: CountableChangeType;
    note?: string;
    changeTypes: (AircraftUpliftableType | AircraftCountableType)[];
    changeValues: (number | null)[];
    countableTypes: AircraftCountableType[];
    countableValuesBefore: number[];
    countableValuesAfter: number[];
} & DbSaveable;
export const TAG_FUEL_BEFORE_UPLIFT = 'FUEL_IS_BEFORE_UPLIFT';
export const TAG_LABELS_FOR_UPLIFT = 'TAG_LABELS_FOR_UPLIFT';
export const TAG_LABELS_FOR_CONSUMABLES = 'TAG_LABELS_FOR_CONSUMABLES';
export type CountablesOverviewTags =
    | typeof TAG_FUEL_BEFORE_UPLIFT
    | typeof TAG_LABELS_FOR_UPLIFT
    | typeof TAG_LABELS_FOR_CONSUMABLES;

export const getLabelForCountable = (
    type: AircraftCountableType | AircraftUpliftableType,
    tags: CountablesOverviewTags[] = []
): string => {
    switch (type) {
        case 'TACH_TIME':
            return 'Tach time';
        case 'HOBBS_TIME':
            return 'Hobbs time';
        case AIRCRAFT_COUNTABLE_FLIGHT_TIME:
            return 'Flight time';
        case AIRCRAFT_COUNTABLE_ANY_FLIGHT_TIME:
            return 'Flight hours';
        case 'LANDINGS':
            return 'Landings';
        case 'CYCLES':
            return 'Number of cycles';
        case AIRCRAFT_COUNTABLE_CYCLES_L:
            return 'Number of cycles (L)';
        case AIRCRAFT_COUNTABLE_CYCLES_R:
            return 'Number of cycles (R)';
        case AIRCRAFT_COUNTABLE_FUEL_GAL:
        case AIRCRAFT_COUNTABLE_FUEL_LB:
        case AIRCRAFT_COUNTABLE_FUEL_KG:
        case AIRCRAFT_COUNTABLE_FUEL_LITERS:
            let fuelString;
            if (tags.includes(TAG_FUEL_BEFORE_UPLIFT)) {
                fuelString = 'Fuel before uplift';
            } else if (tags.includes(TAG_LABELS_FOR_CONSUMABLES) || tags.includes(TAG_LABELS_FOR_UPLIFT)) {
                fuelString = 'Fuel';
            } else {
                fuelString = 'Fuel on board';
            }

            return `${fuelString} (${getFuelUnit(type)})`;

        case 'FUEL_LITERS':
            return 'Fuel (liters)';
        case 'OIL':
            return 'Oil';
        case 'OIL_L':
            return 'Oil Left';
        case 'OIL_R':
            return 'Oil Right';
        case 'OIL_QUARTS':
            return 'Oil (quarts)';
        case 'OIL_L_QUARTS':
            return 'Oil Left (quarts)';
        case 'OIL_R_QUARTS':
            return 'Oil Right (quarts)';
        case 'OIL_PERCENT':
            return 'Oil (percent)';
        case 'OIL_L_PERCENT':
            return 'Oil Left (percent)';
        case 'OIL_R_PERCENT':
            return 'Oil Right (percent)';
        case 'PRIST':
            return 'Prist';
        case 'TKS':
            return 'TKS (gallons)';
        case 'OXYGEN':
            return 'Oxygen';
    }
};

const getFuelUnit = (
    type:
        | typeof AIRCRAFT_COUNTABLE_FUEL_GAL
        | typeof AIRCRAFT_COUNTABLE_FUEL_LB
        | typeof AIRCRAFT_COUNTABLE_FUEL_KG
        | typeof AIRCRAFT_COUNTABLE_FUEL_LITERS
): string => {
    if (type === AIRCRAFT_COUNTABLE_FUEL_GAL) {
        return 'USG';
    }
    if (type === AIRCRAFT_COUNTABLE_FUEL_LB) {
        return 'Lbs';
    }
    if (type === AIRCRAFT_COUNTABLE_FUEL_KG) {
        return 'Kg';
    }
    if (type === AIRCRAFT_COUNTABLE_FUEL_LITERS) {
        return 'L';
    }
    throw new Error(`Unknown fuel unit ${type}`);
};

export const getIsCountableOil = (type: AircraftCountableType | AircraftUpliftableType): boolean => {
    return type.includes('OIL');
};
export const getLabelForCountableShort = (type: AircraftCountableType | AircraftUpliftableType): string => {
    switch (type) {
        case AIRCRAFT_COUNTABLE_FUEL_LITERS:
        case AIRCRAFT_COUNTABLE_FUEL_LB:
        case AIRCRAFT_COUNTABLE_FUEL_GAL:
        case AIRCRAFT_COUNTABLE_FUEL_KG:
            return 'Fuel';
        case AIRCRAFT_COUNTABLE_CYCLES:
            return 'Cycles';
        case AIRCRAFT_COUNTABLE_CYCLES_L:
            return 'Cycles (L)';
        case AIRCRAFT_COUNTABLE_CYCLES_R:
            return 'Cycles (R)';
        case AIRCRAFT_COUNTABLE_LANDINGS:
            return 'Landings';
        case AIRCRAFT_COUNTABLE_HOBBS_TIME:
            return 'Hobbs';
        case AIRCRAFT_COUNTABLE_TACH_TIME:
            return 'Tach';
        case AIRCRAFT_COUNTABLE_TKS:
            return 'TKS';
        default:
            return getLabelForCountable(type);
    }
};
// noinspection JSUnusedLocalSymbols
export const getUnitForCountable = (
    type: AircraftCountableType | AircraftUpliftableType,
    tags: CountablesOverviewTags[] = []
): string | null => {
    switch (type) {
        case 'FUEL_GAL':
        case 'TKS':
            return 'usg';
        case 'PRIST':
        case 'FUEL_LITERS':
            return 'l';
        case AIRCRAFT_COUNTABLE_FUEL_KG:
            return 'kg';
        case 'TACH_TIME':
        case 'HOBBS_TIME':
            return 'hours';
        case 'FUEL_LB':
            return 'lb';
        case 'OIL':
        case 'OIL_L':
        case 'OIL_R':
            return 'quarts/%';
        case 'OIL_QUARTS':
        case 'OIL_L_QUARTS':
        case 'OIL_R_QUARTS':
            return 'quarts';
        case 'OIL_PERCENT':
        case 'OIL_L_PERCENT':
        case 'OIL_R_PERCENT':
            return '%';
        case AIRCRAFT_COUNTABLE_OXYGEN:
            return 'PSI';
        default:
            return null;
    }
};

export const COUNTABLE_BEHAVIOR_ADDITIVE = 'additive';
export const COUNTABLE_BEHAVIOR_ABSOLUTE = 'absolute';
export const COUNTABLE_BEHAVIOR_INDETERMINATE = 'indeterminate';

export type COUNTABLE_BEHAVIOR =
    | typeof COUNTABLE_BEHAVIOR_ADDITIVE
    | typeof COUNTABLE_BEHAVIOR_ABSOLUTE
    | typeof COUNTABLE_BEHAVIOR_INDETERMINATE;

export const COUNTABLE_TYPES_ADDITIVE: AircraftCountableType[] = [
    AIRCRAFT_COUNTABLE_LANDINGS,
    AIRCRAFT_COUNTABLE_CYCLES,
    AIRCRAFT_COUNTABLE_CYCLES_L,
    AIRCRAFT_COUNTABLE_CYCLES_R,
    AIRCRAFT_COUNTABLE_FLIGHT_TIME,
];

export const COUNTABLE_TYPES_ABSOLUTE: AircraftCountableType[] = [
    AIRCRAFT_COUNTABLE_TACH_TIME,
    AIRCRAFT_COUNTABLE_HOBBS_TIME,
];
export const getCountableTypeBehavior = (type: AircraftCountableType | AircraftUpliftableType): COUNTABLE_BEHAVIOR => {
    if ((COUNTABLE_TYPES_ABSOLUTE as string[]).includes(type)) {
        return COUNTABLE_BEHAVIOR_ABSOLUTE;
    }
    if ((COUNTABLE_TYPES_ADDITIVE as string[]).includes(type)) {
        return COUNTABLE_BEHAVIOR_ADDITIVE;
    }
    return COUNTABLE_BEHAVIOR_INDETERMINATE;
};

export const getFormattedValueForCountable = (
    value: number,
    type: AircraftCountableType | AircraftUpliftableType
): string => {
    if (type === AIRCRAFT_COUNTABLE_FLIGHT_TIME) {
        return decimalToHoursMinutes(value);
    } else {
        return numberToPrecision(value, 1);
    }
};
