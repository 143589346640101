import React from "react";
import { formClasses } from '../../utils/BEM';
import InputTextField from '../../components/FormFields/InputTextField';

export type CompleteNewPasswordFormData = { resetPassword: string };
type CompleteNewPasswordFormProps<T> = {
    busy: boolean;
    formData: T;
    setFormData: (formData: T) => void;

}

function CompleteNewPasswordForm<T extends CompleteNewPasswordFormData>({
                                                                            busy,
                                                                            formData,
                                                                            setFormData
                                                                        }: CompleteNewPasswordFormProps<T>) {
    return (
        <>
            <InputTextField label={'New Password'}
                            type={'password'}
                            name={'password'}
                            autoComplete={'current-password'}
                            value={formData.resetPassword}
                            onChange={resetPassword => setFormData({
                                ...formData,
                                resetPassword
                            })}
            />
            <div {...formClasses('fieldlike', undefined, 'text-end')} >
                <button {...formClasses('button')} disabled={busy}>Set & Log In</button>
            </div>
        </>
    );
}

export default CompleteNewPasswordForm;
