import { CountableChange } from '../CountableChanges/types';
import { DbSaveable } from '../LocalDB/types';

export const FLIGHT_STATE_ACCEPTED = 'accepted';
export const FLIGHT_STATE_FINISHED = 'finished';
export type FlightState = typeof FLIGHT_STATE_ACCEPTED | typeof FLIGHT_STATE_FINISHED;

export interface Flight extends DbSaveable {
    id: string;
    createdAt: Date;
    aircraftId: string;
    reservationId?: string;
    acceptanceNotes: string;
    picName: string;
    picId: string | null;
    editableByUserIds: string[];
    state: FlightState;
}

export interface AcceptedFlight extends Flight {
    state: typeof FLIGHT_STATE_ACCEPTED;
    acceptedCountables: CountableChange;
}
