import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';

export enum ComplianceMode {
    compliance = 'compliance',
    experimental = 'experimental',
}

export type ComplianceModeState = ComplianceMode;

const LOCAL_STORAGE_KEY = 'alol_compliance_mode';

export const complianceModeInitialState = window.localStorage.getItem(LOCAL_STORAGE_KEY) === ComplianceMode.compliance
    ? ComplianceMode.compliance
    : ComplianceMode.experimental;

export const ComplianceModeActionType = 'ComplianceMode';
export type ComplianceModeActionTypes = {
    type: typeof ComplianceModeActionType,
    complianceMode: ComplianceMode
};
export const ComplianceModeReducer = (state: ComplianceModeState | undefined, action: ComplianceModeActionTypes | any) => {
    if (action.type === ComplianceModeActionType) {
        switch (action.complianceMode) {
            case ComplianceMode.compliance:
            case ComplianceMode.experimental:
                persistState(action.complianceMode);
                return action.complianceMode;
        }
    }
    return state || complianceModeInitialState;
};

function persistState(state: ComplianceModeState) {
    if (state === ComplianceMode.compliance) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, ComplianceMode.compliance);
    } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
}

export function useComplianceModeState(): ComplianceModeState {
    return useSelector<RootState, ComplianceModeState>(s => s.complianceMode);
}
