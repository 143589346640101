export const saveCredentials = async (username: string, password: string) => {
    if ('credentials' in navigator) {
        try {
            const cred = new (window as any).PasswordCredential({
                id: username,
                password
            });
            await navigator.credentials.store(cred);
        } catch (e) {
            console.error(e);
        }
    }
};

export const getCredentials = async (): Promise<{ username: string; password: string } | null> => {
    try {
        if ('credentials' in navigator) {
            const credential = await (navigator.credentials.get as any)({password: true, mediation: 'required'});
            if (credential) {
                return {username: credential.id, password: credential.password};
            }
        }
        return null;
    } catch (e) {
        console.error(e);
        return null;
    }
};
