import { FLIGHT_LEG_PHASE_FINISH, FlightLeg, FlightLegPhase, WithLastCountableChange } from './FlightLeg';
import { getDb } from '../LocalDB/LocalDB';
import { Squawk, SquawkDeferral } from '../Squawks/types';
import { getSquawkDeferralsForSquawk, getSquawksForLeg } from '../Squawks/SquawksDatasource';
import { COUNTABLE_CHANGE_UPLIFT, CountableChange } from '../CountableChanges/types';
import { getCountableChangesForLeg, getLastCountableChangeForLeg } from '../CountableChanges/CountableChangeDatasource';
import { getFlightDocumentsForLeg } from '../FlightDocuments/FlightDocumentsDatasource';
import { getDbSyncer } from '../LocalDB/DbSyncer';
import { Aircraft, getAircraft } from '../AircraftsDatasource';
import { CreateLegInput } from '../../API';
import { flightLegFromDb, flightLegItemFields } from '../LocalDB/Transformers/flightLegs';
import { API, graphqlOperation } from 'aws-amplify';
import { sanitizeId } from '../datasourceUtils';
import { FlightDocument } from '../FlightDocuments/FlightDocument';

export const saveFlightLeg = async (
    leg: FlightLeg,
    phase: FlightLegPhase,
    countableChange: CountableChange,
    documents: FlightDocument[],
    squawks?: Squawk[],
    squawkDeferrals?: SquawkDeferral[]
): Promise<void> => {
    const db = getDb();
    await db.transaction(
        'rw',
        [db.legs, db.countableChanges, db.documents, db.squawks, db.squawkDeferrals, db.syncItems],
        async () => {
            const [existingDocuments, existingSquawks, existingCountableChanges] = await Promise.all([
                getFlightDocumentsForLeg(leg.id, phase),
                getSquawksForLeg(leg.id),
                getCountableChangesForLeg(leg.id),
            ]);

            const documentIntersection = intersectItems(existingDocuments, documents);
            // documents can not be updated, so no need to PUT them
            const promises: Promise<any>[] = [];
            const onlyLocalDocumentIds: string[] = [];
            if (documentIntersection.oldOnly.length) {
                documentIntersection.oldOnly.forEach((doc) => {
                    if (doc._version !== undefined) {
                        doc.deleted = true;
                    } else {
                        onlyLocalDocumentIds.push(doc.id);
                    }
                });
                promises.push(db.documents.where('id').anyOf(onlyLocalDocumentIds).delete());
            }
            if (documentIntersection.newOnly.length) {
                promises.push(db.documents.bulkPut(documentIntersection.newOnly));
            }
            let squawkIntersection = null;
            const onlyLocalSquawkIds: string[] = [];
            let squawkDeferralIntersection = null;
            const onlyLocalSquawkDeferralIds: string[] = [];
            if (squawks !== undefined) {
                squawkIntersection = intersectItems(existingSquawks, squawks);
                const squawksToPut = [...squawkIntersection.newOnly, ...squawkIntersection.both];
                if (squawkIntersection.oldOnly.length) {
                    squawkIntersection.oldOnly.forEach((squawk) => {
                        if (squawk._version !== undefined) {
                            squawk.deleted = true;
                        } else {
                            onlyLocalSquawkIds.push(squawk.id);
                        }
                    });
                    promises.push(db.squawks.where('id').anyOf(onlyLocalSquawkIds).delete());
                }
                if (squawksToPut.length) {
                    promises.push(db.squawks.bulkPut(squawksToPut));
                }
                if (squawkDeferrals !== undefined) {
                    const existingSquawkDeferrals = (
                        await Promise.all(squawks.map((s) => getSquawkDeferralsForSquawk(s.id)))
                    ).flat();
                    squawkDeferralIntersection = intersectItems(existingSquawkDeferrals, squawkDeferrals);
                    const squawksDeferralsToPut = [
                        ...squawkDeferralIntersection.newOnly,
                        ...squawkDeferralIntersection.both,
                    ];
                    if (squawkDeferralIntersection.oldOnly.length) {
                        squawkDeferralIntersection.oldOnly.forEach((squawkDeferral) => {
                            if (squawkDeferral._version !== undefined) {
                                squawkDeferral.deleted = true;
                            } else {
                                onlyLocalSquawkDeferralIds.push(squawkDeferral.id);
                            }
                        });
                        promises.push(db.squawkDeferrals.where('id').anyOf(onlyLocalSquawkDeferralIds).delete());
                    }
                    if (squawksDeferralsToPut.length) {
                        promises.push(db.squawkDeferrals.bulkPut(squawksDeferralsToPut));
                    }
                }
            }
            let upliftToSync: string | null = null;
            if (phase === FLIGHT_LEG_PHASE_FINISH) {
                const uplift = existingCountableChanges.find((cc) => cc.type === COUNTABLE_CHANGE_UPLIFT);
                if (uplift) {
                    const valuesDiffer = uplift.countableValuesAfter.some((value, index) => {
                        return value !== countableChange.countableValuesBefore[index];
                    });
                    const typesAreSame = uplift.countableTypes.join('-') === countableChange.countableTypes.join('-');
                    if (valuesDiffer && typesAreSame) {
                        uplift.countableValuesAfter = countableChange.countableValuesBefore.slice();
                        promises.push(db.countableChanges.put(uplift));
                        upliftToSync = uplift.id;
                    }
                }
            }

            promises.push(db.countableChanges.put(countableChange));
            promises.push(db.legs.put(leg));
            await Promise.all(promises);
            const dbSyncer = getDbSyncer();
            dbSyncer.markToSync(db.legs, leg.id);
            const documentsToSync = getIdsFromIntersection(documentIntersection).filter(
                (id) => !onlyLocalDocumentIds.includes(id)
            );
            dbSyncer.markToSync(db.documents, ...documentsToSync);
            if (squawkIntersection !== null) {
                const squawksToSync = getIdsFromIntersection(squawkIntersection).filter(
                    (id) => !onlyLocalSquawkIds.includes(id)
                );
                dbSyncer.markToSync(db.squawks, ...squawksToSync);
            }
            if (squawkDeferralIntersection !== null) {
                const squawkDeferralsToSync = getIdsFromIntersection(squawkDeferralIntersection).filter(
                    (id) => !onlyLocalSquawkDeferralIds.includes(id)
                );
                dbSyncer.markToSync(db.squawkDeferrals, ...squawkDeferralsToSync);
            }
            dbSyncer.markToSync(db.countableChanges, countableChange.id);
            if (upliftToSync !== null) {
                dbSyncer.markToSync(db.countableChanges, upliftToSync);
            }
        }
    );
};

const toIdSet = (input: { id: string }[]): Set<string> => {
    return new Set<string>(input.map((i) => i.id));
};

type Intersection<T extends { id: string }> = {
    oldOnly: T[];
    newOnly: T[];
    both: T[];
};
const intersectItems = <T extends { id: string }>(oldItems: T[], newItems: T[]): Intersection<T> => {
    const oldOnly: T[] = [];
    const newOnly: T[] = [];
    const both: T[] = [];
    const oldIds = toIdSet(oldItems);
    const newIds = toIdSet(newItems);
    for (const item of oldItems) {
        if (!newIds.has(item.id)) {
            oldOnly.push(item);
        }
    }
    for (const item of newItems) {
        if (oldIds.has(item.id)) {
            both.push(item);
        } else {
            newOnly.push(item);
        }
    }
    return { oldOnly, newOnly, both };
};

const getIdsFromIntersection = <T extends { id: string }>(intersection: Intersection<T>): string[] => {
    return [
        ...intersection.oldOnly.map((i) => i.id),
        ...intersection.newOnly.map((i) => i.id),
        ...intersection.both.map((i) => i.id),
    ];
};

export const getFlightLegsForFlight = async (flightId: string): Promise<(FlightLeg & WithLastCountableChange)[]> => {
    const legs = (await getDb().legs.where('flightId').equals(flightId).toArray()).filter((leg) => !leg.deleted);
    const lastCountables = await Promise.all(legs.map(getLastCountableChangeForLeg));
    return legs.map((leg, index) => ({
        ...leg,
        lastCountableChange: lastCountables[index],
    }));
};

export type GetFlightLegParams = [string];

export const getFlightLeg = async (legId: GetFlightLegParams[0]): Promise<FlightLeg | null> => {
    const maybeLeg = await getDb().legs.get(legId);
    return maybeLeg || null;
};

export const getFlightLegsById = async (legIds: GetFlightLegParams[0][]): Promise<(FlightLeg | null)[]> => {
    return getDb().legs.bulkGet(legIds);
};

export type FlightLegAndAircraft = { leg: FlightLeg; aircraft: Aircraft };

export const getFlightLegAndAircraft = async (legId: GetFlightLegParams[0]): Promise<FlightLegAndAircraft | null> => {
    const leg = await getFlightLeg(legId);
    if (!leg) {
        return null;
    }
    const aircraft = await getAircraft(leg.aircraftId);
    if (!aircraft) {
        return null;
    }
    return { leg, aircraft };
};

export const deleteFlightLeg = async (legId: string): Promise<void> => {
    const db = getDb();
    await db.transaction('rw', db.legs, db.countableChanges, db.documents, db.squawks, db.syncItems, async () => {
        const leg = await db.legs.get(legId);
        if (!leg) {
            return;
        }
        const [existingDocuments, existingCountableChanges, existingSquawks] = await Promise.all([
            db.documents.where({ legId }).toArray(),
            db.countableChanges.where({ legId }).toArray(),
            db.squawks.where({ legId }).toArray(),
        ]);
        leg.deleted = true;
        existingDocuments.forEach((doc) => (doc.deleted = true));
        existingCountableChanges.forEach((cc) => (cc.deleted = true));
        existingSquawks.forEach((s) => (s.deleted = true));

        await Promise.all([
            db.legs.put(leg),
            db.countableChanges.bulkPut(existingCountableChanges),
            db.squawks.bulkPut(existingSquawks),
            db.documents.bulkPut(existingDocuments),
        ]);
        const dbSyncer = getDbSyncer();
        dbSyncer.markToSync(db.documents, ...existingDocuments.map((d) => d.id));
        dbSyncer.markToSync(db.squawks, ...existingSquawks.map((s) => s.id));
        dbSyncer.markToSync(db.countableChanges, ...existingCountableChanges.map((cc) => cc.id));
        dbSyncer.markToSync(db.legs, legId);
    });
};

export const getCrewFlightLegsOnline = async (crewId: string): Promise<FlightLeg[]> => {
    const legsQuery = `query ListLegs($crewId: ID) {
         legsByPicId(picId: $crewId, limit: 400, sortDirection: DESC) {
            items {
                ${flightLegItemFields}
            }
        }
        legsBySicId(sicId: $crewId, limit: 400, sortDirection: DESC) {
            items {
                ${flightLegItemFields}
            }
        }
    }`;
    const gqlo = graphqlOperation(legsQuery, { crewId: crewId });
    const legsResult = (await API.graphql(gqlo)) as {
        data: { legsByPicId: { items: CreateLegInput[] }; legsBySicId: { items: CreateLegInput[] } };
    };
    const legInputs = [...legsResult.data.legsByPicId.items, ...legsResult.data.legsBySicId.items];
    return legInputs.map(flightLegFromDb) as FlightLeg[];
};

export const getFlightLegsByIdOnline = async (ids: GetFlightLegParams[0][]): Promise<FlightLeg[]> => {
    if (!ids.length) {
        return [];
    }

    const queryItems = ids.map(
        (id, index) => `
    getLeg${index}: getLeg(id: "${sanitizeId(id)}") {
        ${flightLegItemFields}
    }`
    );
    const query = `
        query GetFlightLegs {
            ${queryItems.join('\n')}
        } 
    `;

    const gqlo = graphqlOperation(query);
    const legsResult = (await API.graphql(gqlo)) as { data: { [key: string]: CreateLegInput } };
    const legInputs = Object.values(legsResult.data);
    console.log(legInputs);
    return legInputs.map(flightLegFromDb) as FlightLeg[];
};
