import {
    flightDeferralToDeferral,
    FlightSquawkDeferral,
    Squawk,
    SquawkAndAircraft,
    SquawkAndDeferrals,
    SquawkDeferral,
    SquawkDeferralAndReleases
} from "./types";
import { getDb } from "../LocalDB/LocalDB";
import { getAircraftForFlight } from "../AircraftsDatasource";
import { getDbSyncer } from "../LocalDB/DbSyncer";
import { getFlightDocumentsForSquawk } from "../FlightDocuments/FlightDocumentsDatasource";
import { ReleaseToService } from "../ReleaseToService/types";
import { getReleaseToServicesForSquawk } from "../ReleaseToService/ReleaseToServiceDatasource";
import { getUserIdForAircraft } from "../Users/UserDatasource";

export const getSquawksForAircraft = async (aircraftId: string): Promise<SquawkAndDeferrals[]> => {
    const db = getDb();
    const squawks = await db.squawks
        .where({aircraftId: aircraftId})
        .sortBy('createdAt');
    const squawkIds = squawks.map(s => s.id);
    const [deferrals, releases] = await Promise.all([
        db.squawkDeferrals.where('squawkId').anyOf(squawkIds).toArray(),
        db.releaseToServices.where('squawkId').anyOf(squawkIds).toArray()
    ]);
    const deferralsAndReleases = injectReleasesToDeferrals(deferrals, releases);
    return squawks.map((squawk): SquawkAndDeferrals => {
        return {
            ...squawk,
            deferrals: deferralsAndReleases.filter(d => d.squawkId === squawk.id),
            releases: releases.filter(r => r.squawkId === squawk.id && r.deferralId === null)
        }
    });
};

const injectReleasesToDeferrals = (deferrals: SquawkDeferral[], releases: ReleaseToService[]): SquawkDeferralAndReleases[] => {
    return deferrals.map((deferral): SquawkDeferralAndReleases => {
        return {
            ...deferral,
            releases: releases.filter(r => r.deferralId === deferral.id)
        }
    })
};

export const getSquawksForLeg = async (legId: string): Promise<Squawk[]> => {
    return getDb().squawks
        .where({legId: legId})
        .sortBy('createdAt');
};

export const getSquawkDeferralsForSquawk = async (squawkId: string): Promise<SquawkDeferral[]> => {
    return getDb().squawkDeferrals
        .where({squawkId})
        .toArray();
};

export type GetSquawkParams = [string];


export const getSquawk = async (squawkId: GetSquawkParams[0]): Promise<Squawk | null> => {
    const maybeSquawk = await getDb().squawks.get(squawkId);
    return maybeSquawk || null;
};

export type GetSquawkAndAircraftParams = GetSquawkParams;
export const getSquawkAndAircraft = async (squawkId: GetSquawkAndAircraftParams[0]): Promise<SquawkAndAircraft | null> => {
    const [squawk, documents, deferrals, releases] = await Promise.all([
        getSquawk(squawkId),
        getFlightDocumentsForSquawk(squawkId),
        getSquawkDeferralsForSquawk(squawkId),
        getReleaseToServicesForSquawk(squawkId),
    ]);
    if (!squawk) {
        return null;
    }
    const aircraft = await getAircraftForFlight(squawk.aircraftId);
    if (!aircraft) {
        return null;
    }
    return {
        ...squawk,
        documents: documents!,
        deferrals: injectReleasesToDeferrals(deferrals!, releases!),
        releases: releases!.filter(r => r.deferralId === null),
        aircraft
    };
};

export const saveSquawk = async (squawk: Squawk): Promise<void> => {
    const db = getDb();
    await db.squawks.put(squawk);
    getDbSyncer().markToSync(db.squawks, squawk.id);
};

export const getPureSquawk = (squawk: Squawk): Squawk => {
    const {
        id,
        aircraftId,
        flightId,
        legId,
        createdAt,
        createdByName,
        description,
        airworthinessAffected,
        deleted,
        editableByUserIds,
        reviewedDate,
        reviewedName,
        reviewedId,
        releaseDate,
        releaseAircraftFlightTime,
        releaseName,
        releaseLicenseNumber,
        releaseText,
        releaseSignature,
        _version
    } = squawk;
    return {
        id,
        aircraftId,
        flightId,
        legId,
        createdAt,
        createdByName,
        description,
        airworthinessAffected,
        deleted,
        editableByUserIds: editableByUserIds.slice(),
        reviewedDate,
        reviewedName,
        reviewedId,
        releaseDate,
        releaseAircraftFlightTime,
        releaseName,
        releaseLicenseNumber,
        releaseText,
        releaseSignature,
        _version
    };
};

export const saveDeferral = async (flightDeferral: FlightSquawkDeferral, squawk: Squawk, userId: string): Promise<void> => {
    const editableByUserIds = [userId];
    const aircraftUserId = await getUserIdForAircraft(squawk.aircraftId);
    if (aircraftUserId) {
        editableByUserIds.push(aircraftUserId);
    }
    const [deferrals, documents, releaseToServices] = flightDeferralToDeferral([flightDeferral], editableByUserIds);
    const db = getDb();
    await db.transaction('rw', [db.squawkDeferrals, db.releaseToServices, db.documents, db.syncItems], async () => {
        await Promise.all([
            db.squawkDeferrals.bulkPut(deferrals),
            db.documents.bulkPut(documents),
            db.releaseToServices.bulkPut(releaseToServices),
        ]);
        const dbSyncer = getDbSyncer();
        dbSyncer.markToSync(db.squawkDeferrals, ...deferrals.map(d => d.id));
        dbSyncer.markToSync(db.documents, ...documents.map(d => d.id));
        dbSyncer.markToSync(db.releaseToServices, ...releaseToServices.map(d => d.id));
    })
};
