import { AcceptedFlight, Flight, FlightState } from '../../Flights/Flight';
import { CreateFlightInput } from '../../../API';
import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';

let counter = 0;

export const flightItemFields = `
    id
    deleted
    editableByUserIds
    createdAt
    aircraftId
    acceptanceNotes
    picName
    picId
    state
    reservationId
    _version
`.trim();

export const flightToDb: DbSyncerUpDefinition = (flight: Flight | AcceptedFlight) => {
    const c = ++counter;
    const [commandName, inputType] = !flight._version
        ? ['createFlight', 'CreateFlightInput']
        : ['updateFlight', 'UpdateFlightInput'];
    const input: CreateFlightInput = {
        id: flight.id,
        deleted: flight.deleted,
        createdAt: flight.createdAt.toISOString(),
        aircraftId: flight.aircraftId,
        acceptanceNotes: flight.acceptanceNotes,
        picName: flight.picName,
        picId: flight.picId,
        state: flight.state,
        reservationId: flight.reservationId ? flight.reservationId : flight._version ? null : undefined,
        _version: flight._version,
    };

    const { mutationName, inputKey, query } = getStandardSyncerFields(commandName, c, flightItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: flightTransform,
        queryName: 'getFlight',
    };
};

export const flightTransform = (input: CreateFlightInput): Flight | AcceptedFlight => {
    return {
        id: input.id!,
        deleted: input.deleted,
        createdAt: new Date(input.createdAt!),
        aircraftId: input.aircraftId,
        acceptanceNotes: input.acceptanceNotes,
        picName: input.picName,
        picId: input.picId!,
        editableByUserIds: input.editableByUserIds ? input.editableByUserIds : [],
        state: input.state as FlightState,
        reservationId: input.reservationId || undefined,
        _version: input._version!,
    };
};
