export const FORM_SEVERITY_WARNING = 'warning';
export const FORM_SEVERITY_CAUTION = 'caution';
export type FormSeverity = typeof FORM_SEVERITY_WARNING | typeof FORM_SEVERITY_CAUTION;

export type FormErrorLike = FormError | string | boolean;

export type FormError = {
    severity: FormSeverity,
    text: string
}

export const getFormErrorSeverity = (formError: FormErrorLike): FormSeverity => {
    if ('boolean' === typeof formError) {
        return FORM_SEVERITY_WARNING;
    }
    if ('string' === typeof formError) {
        return FORM_SEVERITY_WARNING;
    }
    return formError.severity;
}

export const scrollToFirstError = () => {
    const errorElement = document.querySelector('*[data-has-error]');
    if (!errorElement) {
        return;
    }
    setTimeout(() => {
        errorElement.scrollIntoView({
            behavior: 'smooth'
        })
    }, 50); // allow for react redraw

}

export function confirmErrors(errors: { [key: string]: FormErrorLike }): boolean | null {
    const errorEntries = Object.entries(errors);
    if (errorEntries.length) {
        const errorText = errorEntries
            .map(([key, error]): string => {
                const errorText = 'string' === typeof error
                    ? error
                    : 'boolean' === typeof error
                        ? 'is unexpected value'
                        : error.text
                return `- ${key}: ${errorText}`
            })
            .join('\n');
        return window.confirm(`Some values are unusual. Confirm you have double-checked them?\n\n${errorText}`);
    }
    return null;
}
