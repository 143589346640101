import { DbSyncerUpDefinition, DbSyncSubscription, SyncableTable, wrapHandlerWithAccumulator } from './syncHelpers';
import { getDb } from './LocalDB';
import { flightItemFields, flightToDb, flightTransform } from './Transformers/flights';
import { flightLegFromDb, flightLegItemFields, flightLegSyncerDefinition } from './Transformers/flightLegs';
import {
    countableChangeFromDb,
    countableChangeItemFields,
    countableChangeSyncerDefinition
} from './Transformers/countableChanges';
import { squawkFromDb, squawkItemFields, squawkSyncerDefinition } from './Transformers/squawks';
import {
    flightDocumentItemFields,
    flightDocumentItemHandler,
    flightDocumentToDb
} from './Transformers/flightDocuments';
import { aircraftFromDb, aircraftItemFields } from './Transformers/aircrafts';
import { squawkDeferralFromDb, squawkDeferralItemFields, squawkDeferralToDb } from './Transformers/squawkDeferrals';
import {
    releaseToServiceFromDb,
    releaseToServiceItemFields,
    releaseToServiceSyncerDefinition
} from './Transformers/releaseToServices';
import { dutyItemItemFields, dutyItemToDb, dutyItemTransform } from './Transformers/dutyItems';
import { signingKeyChangeToDb } from './Transformers/signingKeyChanges';
import { signedDataToDb } from './Transformers/signedDatas';
import { contactItemFields } from './Transformers/contacts';
import { ContactInput } from '../../APIExtensions';
import { logbookRecordItemFields, logbookRecordToDb } from './Transformers/logbookRecords';
import { getStandardItemHandler } from './standardItemHandler';
import { aircraftMaintenanceLimitFromDb } from '../../Admin/AircraftMaintenanceLimits/AircraftMaintenanceLimit';
import {
    aircraftMaintenanceLimitExtensionItemFields
} from '../../Admin/Datasources/AircraftMaintenanceLimitExtensions';
import {
    aircraftMaintenanceLimitExtensionFromDb
} from '../../Admin/AircraftMaintenanceLimitExtensions/AircraftMaintenanceLimitExtension';
import { CreateAircraftMaintenanceLimitExtensionInput, CreateAircraftMaintenanceLimitInput } from '../../API';
import { DbCreated, DbItems } from './types';
import { aircraftMaintenanceLimitExtensionSyncerDefinition } from './Transformers/aircraftMaintenanceLimitExtensions';

export const aircraftsResyncSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncAircraft(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${aircraftItemFields}
                flights(limit:10, sortDirection: DESC) {
                  items {
                    ${flightItemFields}
                    legs(limit: 99) {
                      items {
                        ${flightLegItemFields}
                      }
                    }
                    flightDocuments(limit:10, sortDirection: DESC) {
                      items {
                        ${flightDocumentItemFields}
                      }
                    }
                  }
                }
                squawks(limit:50, sortDirection: DESC) {
                  items {
                    ${squawkItemFields}
                    squawkDeferrals(limit: 100) {
                        items {
                            ${squawkDeferralItemFields}
                        }
                    }
                    flightDocuments(limit:100, sortDirection: DESC) {
                      items {
                        ${flightDocumentItemFields}
                      }
                    }
                  }
                }
                countableChanges(limit:20, sortDirection: DESC) {
                  items {
                    ${countableChangeItemFields}
                  }
                }
                releaseToServices(limit: 100) {
                  items {
                    ${releaseToServiceItemFields}
                    flightDocuments(limit:100, sortDirection: DESC) {
                      items {
                        ${flightDocumentItemFields}
                      }
                    }
                  }
                }
              }
            }`;
    }),
    key: 'syncAircraft',
    onNewItems: (async (items) => {
        const aircrafts = items.map(aircraftFromDb);
        const dbHandlers = getDbHandlers();
        const {
            flightHandler,
            legHandler,
            aircraftHandler,
            squawkHandler,
            squawkDeferralHandler,
            releaseToServiceHandler,
            countableChangeChandler,
            flightDocumentHandler
        } = dbHandlers;
        const promises: Promise<void>[] = [];
        promises.push(aircraftHandler(aircrafts));
        items.forEach(aircraft => {
            if (aircraft.flights.items && aircraft.flights.items.length) {
                const flights = aircraft.flights.items.map(flightTransform);
                aircraft.flights.items.forEach((flight: any) => {
                    if (flight.legs && flight.legs.items && flight.legs.items.length) {
                        const legs = flight.legs.items.map(flightLegFromDb);
                        promises.push(legHandler(legs));
                    }
                    if (flight.flightDocuments && flight.flightDocuments.items && flight.flightDocuments.items.length) {
                        promises.push(flightDocumentHandler(flight.flightDocuments.items));
                    }
                });
                promises.push(flightHandler(flights));
            }
            if (aircraft.squawks.items && aircraft.squawks.items.length) {
                const squawks = aircraft.squawks.items.map(squawkFromDb);
                promises.push(squawkHandler(squawks));
                aircraft.squawks.items.forEach((squawk: any) => {
                    if (squawk.flightDocuments.items && squawk.flightDocuments.items.length) {
                        promises.push(flightDocumentHandler(squawk.flightDocuments.items));
                    }
                    if (squawk.squawkDeferrals.items && squawk.squawkDeferrals.items.length) {
                        const squawkDeferrals = squawk.squawkDeferrals.items.map(squawkDeferralFromDb);
                        promises.push(squawkDeferralHandler(squawkDeferrals));
                    }
                })
            }
            if (aircraft.countableChanges.items && aircraft.countableChanges.items.length) {
                const countableChanges = aircraft.countableChanges.items.map(countableChangeFromDb);
                promises.push(countableChangeChandler(countableChanges));
            }
            if (aircraft.releaseToServices.items && aircraft.releaseToServices.items.length) {
                const releaseToServices = aircraft.releaseToServices.items.map(releaseToServiceFromDb);
                promises.push(releaseToServiceHandler(releaseToServices));
                for(const releaseToService of aircraft.releaseToServices.items) {
                    if (releaseToService.flightDocuments.items && releaseToService.flightDocuments.items.length) {
                        promises.push(flightDocumentHandler(releaseToService.flightDocuments.items));
                    }
                }
            }
        });
        Object.values(dbHandlers).forEach(handlerWrapper => handlerWrapper.start());
        await Promise.all(promises);
    })
};

const getDbHandlers = function () {
    const db = getDb();
    const flightHandler = wrapHandlerWithAccumulator(getStandardItemHandler(db.flights));
    const legHandler = wrapHandlerWithAccumulator(getStandardItemHandler(db.legs));
    const aircraftHandler = wrapHandlerWithAccumulator(getStandardItemHandler(db.aircrafts));
    const squawkHandler = wrapHandlerWithAccumulator(getStandardItemHandler(db.squawks));
    const countableChangeChandler = wrapHandlerWithAccumulator(getStandardItemHandler(db.countableChanges));
    const squawkDeferralHandler = wrapHandlerWithAccumulator(getStandardItemHandler(db.squawkDeferrals));
    const releaseToServiceHandler = wrapHandlerWithAccumulator(getStandardItemHandler(db.releaseToServices));
    const flightDocumentHandler = wrapHandlerWithAccumulator(flightDocumentItemHandler);
    return {
        flightHandler,
        legHandler,
        aircraftHandler,
        squawkHandler,
        squawkDeferralHandler,
        releaseToServiceHandler,
        countableChangeChandler,
        flightDocumentHandler
    };
};


export const aircraftsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncAircraft(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${aircraftItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncAircraft',
    onNewItems: (getStandardItemHandler(getDb().aircrafts, aircraftFromDb))
};


export const flightsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncFlights(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${flightItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncFlights',
    onNewItems: (getStandardItemHandler(getDb().flights, flightTransform))
};


export const flightDocumentsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncFlightDocuments(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${flightDocumentItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncFlightDocuments',
    onNewItems: flightDocumentItemHandler
};


export const flightLegsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncLegs(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${flightLegItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncLegs',
    onNewItems: (getStandardItemHandler(getDb().legs, flightLegFromDb))
};


export const squawksSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncSquawks(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${squawkItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncSquawks',
    onNewItems: (getStandardItemHandler(getDb().squawks, squawkFromDb))
};

export const squawkDeferralsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncSquawkDeferrals(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${squawkDeferralItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncSquawkDeferrals',
    onNewItems: (getStandardItemHandler(getDb().squawkDeferrals, squawkDeferralFromDb))
};


export const releaseToServicesSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncReleaseToServices(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${releaseToServiceItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncReleaseToServices',
    onNewItems: (getStandardItemHandler(getDb().releaseToServices, releaseToServiceFromDb))
};


export const countableChangesSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncCountableChanges(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${countableChangeItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncCountableChanges',
    onNewItems: (getStandardItemHandler(getDb().countableChanges, countableChangeFromDb))
};

const aircraftDocumentItemFields = `id
    deleted
    createdAt
    aircraftId
    contentKey
    name
    documentNumber
    issueDate
    validUntilDate
    validUntilDateToleranceSeconds
    validUntilCountableTypes
    validUntilCountableValues
    validUntilCountableValuesTolerance
    _version`;

export const aircraftDocumentsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncAircraftDocuments(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString},filter:{deleted: {eq: false}}) {
              items {
                ${aircraftDocumentItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncAircraftDocuments',
    onNewItems: getStandardItemHandler(getDb().aircraftDocuments)
};
const aircraftMaintenanceLimitItemFields = `id
    deleted
    createdAt
    aircraftId
    name
    validUntilDate
    validUntilDateToleranceSeconds
    validUntilCountableTypes
    validUntilCountableValues
    validUntilCountableValuesTolerance
    customWarningRules
    limitExtensionRules
    _version`;
export const aircraftMaintenanceLimitsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncAircraftMaintenanceLimits(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString},filter:{deleted: {eq: false}}) {
              items {
                ${aircraftMaintenanceLimitItemFields}
                extensions(sortDirection: DESC) {
                    items {
                        ${aircraftMaintenanceLimitExtensionItemFields}
                    }
                }
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncAircraftMaintenanceLimits',
    onNewItems: async (items: (CreateAircraftMaintenanceLimitInput & DbCreated & { extensions: DbItems<CreateAircraftMaintenanceLimitExtensionInput> })[]) => {
        const itemsBare: (CreateAircraftMaintenanceLimitInput & DbCreated)[] = [];
        const extensionsBare: (CreateAircraftMaintenanceLimitExtensionInput & DbCreated)[] = [];
        for (const item of items) {
            const {extensions, ...bare} = item;
            itemsBare.push(bare);
            extensionsBare.push(...extensions.items);
        }
        await Promise.all([
            getStandardItemHandler(getDb().aircraftMaintenanceLimitExtensions, aircraftMaintenanceLimitExtensionFromDb)(extensionsBare),
            getStandardItemHandler(getDb().aircraftMaintenanceLimits, aircraftMaintenanceLimitFromDb)(itemsBare)
        ]);
    }
};


export const aircraftMaintenanceLimitExtensionsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncAircraftMaintenanceLimitExtensions(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString},filter:{deleted: {eq: false}}) {
              items {
                ${aircraftMaintenanceLimitExtensionItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncAircraftMaintenanceLimitExtensions',
    onNewItems: getStandardItemHandler(getDb().aircraftMaintenanceLimitExtensions, aircraftMaintenanceLimitExtensionFromDb)
};


export const dutyItemsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncDutyItems(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString},filter:{deleted: {eq: false}}) {
              items {
                ${dutyItemItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncDutyItems',
    onNewItems: getStandardItemHandler(getDb().dutyItems, dutyItemTransform)
};

export const contactsResyncSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            listContactsByIsCrew(isActiveCrew: "x",limit:9999,nextToken:${nextTokenString}) {
              items {
                ${contactItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'listContactsByIsCrew',
    onNewItems: (items) => getStandardItemHandler(getDb().contacts)(items),

};


export const contactsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncContacts(lastSync:$lastSync,limit:9999,nextToken:${nextTokenString}) {
              items {
                ${contactItemFields}
              }
              nextToken
              startedAt
            }`;
    }),
    key: 'syncContacts',
    onNewItems: (items: ContactInput[]) => {
        const crewables = items.filter(i => i.isActiveCrew);
        return getStandardItemHandler(getDb().contacts)(crewables);
    },
};

export const logbookRecordsSubscription: DbSyncSubscription = {
    getQuery: ((nextToken) => {
        const nextTokenString = nextToken ? `"${nextToken}"` : `null`;
        return `
            syncLogbookRecords(lastSync:$lastSync,limit:20,nextToken:${nextTokenString}) {
              items {
                ${logbookRecordItemFields}
              }
              startedAt
            }`;
    }),
    key: 'syncLogbookRecords',
    onNewItems: getStandardItemHandler(getDb().logbookRecords)
};


export const dbSyncerUpDefintions: Map<SyncableTable, DbSyncerUpDefinition> = new Map<SyncableTable, DbSyncerUpDefinition>();

dbSyncerUpDefintions.set('flights', flightToDb);
dbSyncerUpDefintions.set('legs', flightLegSyncerDefinition);
dbSyncerUpDefintions.set('countableChanges', countableChangeSyncerDefinition);
dbSyncerUpDefintions.set('squawks', squawkSyncerDefinition);
dbSyncerUpDefintions.set('squawkDeferrals', squawkDeferralToDb);
dbSyncerUpDefintions.set('releaseToServices', releaseToServiceSyncerDefinition);
dbSyncerUpDefintions.set('documents', flightDocumentToDb);
dbSyncerUpDefintions.set("dutyItems", dutyItemToDb);
dbSyncerUpDefintions.set("signingKeyChanges", signingKeyChangeToDb);
dbSyncerUpDefintions.set("signedData", signedDataToDb);
dbSyncerUpDefintions.set("logbookRecords", logbookRecordToDb);
dbSyncerUpDefintions.set("aircraftMaintenanceLimitExtensions", aircraftMaintenanceLimitExtensionSyncerDefinition);

export const dbSyncerResyncSubscriptions: DbSyncSubscription[] = [
    contactsResyncSubscription,
    aircraftsResyncSubscription,
    aircraftDocumentsSubscription,
    aircraftMaintenanceLimitsSubscription,
    dutyItemsSubscription,
    logbookRecordsSubscription,
];

export const dbSyncerUpdateSubscriptions: DbSyncSubscription[] = [
    contactsSubscription,
    aircraftsSubscription,
    aircraftDocumentsSubscription,
    aircraftMaintenanceLimitsSubscription,
    aircraftMaintenanceLimitExtensionsSubscription,
    countableChangesSubscription,
    flightLegsSubscription,
    flightDocumentsSubscription,
    flightsSubscription,
    squawksSubscription,
    releaseToServicesSubscription,
    squawkDeferralsSubscription,
    dutyItemsSubscription,
    logbookRecordsSubscription,
];
