import { MaintenanceTaskCalendarUnit } from '../Admin/MaintenancePrograms/MaintenanceTaskCalendarUnit';
import { roundNumber } from './numbers';

export const decimalToHoursMinutes = (input: number): string => {
    let hours = Math.floor(input);
    let minutes = Math.abs(Math.round((input % 1) * 60)); // abs to prevent double minus, as in -1:-05 -> -1:05
    if (minutes >= 60) {
        hours += 1;
        minutes -= 60;
    }
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

const DAY = 86400 * 1e3;

/**
 * Sets date of first date in datesList to be same as referenceDate. All other dates in datesLists will be forced
 * to be in ascending order and less than 24 hours apart, ie. when #1 == 23:00 and #2 == 01:00, #2 will be a day after
 * #1.
 * @param referenceDate
 * @param datesList
 */
export const setTimesToStartDate = (referenceDate: Date, ...datesList: (Date | null)[]): void => {
    let lastValidDate = referenceDate;
    datesList.forEach((date) => {
        if (date === null) {
            return;
        }
        while (date.getTime() < lastValidDate.getTime()) {
            date.setTime(date.getTime() + DAY);
        }
        while (date.getTime() >= lastValidDate.getTime() + DAY) {
            date.setTime(date.getTime() - DAY);
        }
        lastValidDate = date;
    });
};

export const getMidnightDate = (date: Date): Date => {
    const midnightUTC = new Date(date.getTime());
    midnightUTC.setUTCHours(0, 0, 0, 0);
    return midnightUTC;
};

export const dateDifferenceToHumanHoursMinutes = (d1: Date, d2: Date): string => {
    return minutesToHumanHoursMinutes(getMinutesDiffFromDates(d1, d2));
};

export const getMinutesDiffFromDates = (d1: Date, d2: Date) => {
    return Math.floor(d2.getTime() / 60000) - Math.floor(d1.getTime() / 60000);
};

export const minutesToHumanHoursMinutes = (minutesDiff: number): string => {
    const hours = Math.floor(minutesDiff / 60);
    const minutes = minutesDiff % 60;
    return hours.toString() + ':' + minutes.toString().padStart(2, '0');
};

export const minutesToHumanDaysHoursMinutes = (minutesDiff: number, daysOnlyWhenAbove = 0): string => {
    const days = Math.floor(minutesDiff / (60 * 24));
    if (days > daysOnlyWhenAbove) {
        const remainingHours = Math.floor((minutesDiff % (60 * 24)) / 60);
        return `${days}d ${remainingHours}h`;
    } else {
        return minutesToHumanHoursMinutes(minutesDiff);
    }
};

export const getDatesOverlap = (start1: Date, end1: Date, start2: Date, end2: Date): boolean => {
    const isBefore = start1 < start2 && end1 <= start2;
    const isAfter = start1 >= end2 && end1 > end2;
    return !(isBefore || isAfter);
};

export const getValidityClassName = (validity: Date): string => {
    const now = Date.now();
    if (validity.getTime() < now) {
        return 'danger';
    }
    if (validity.getTime() < now + 30 * 86400 * 1e3) {
        // 1 month
        return 'warning';
    }
    return 'success';
};

export const toDateString = (date: Date): string => {
    return date.toLocaleDateString('en-GB', { timeZone: 'Etc/UTC' });
};

export const toDateStringShort = (date: Date): string => {
    return date.toLocaleDateString('en-GB', { timeZone: 'Etc/UTC' }).substr(0, 5);
};

export const toDateTimeString = (date: Date): string => {
    return date.toLocaleString('en-GB', { timeZone: 'Etc/UTC' });
};

export const toTimeString = (date: Date): string => {
    return date.toLocaleTimeString('en-GB', { timeZone: 'Etc/UTC' });
};

export const toTimeStringShort = (date: Date, timeZone?: string): string => {
    timeZone = timeZone || 'Etc/UTC';
    return date.toLocaleTimeString('en-GB', { timeZone }).substr(0, 5);
};

export const toTimeStringShortRounded = (date: Date): string => {
    let h = date.getUTCHours();
    let m = date.getUTCMinutes();
    if (date.getUTCSeconds() >= 30) {
        m += 1;
    }
    if (m >= 60) {
        h += 1;
        m = 0;
    }
    if (h > 24) {
        h = h % 24;
    }
    return h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0');
};

export const VALIDITY_PERIOD_YEAR = 'year';
export const VALIDITY_PERIOD_MONTH = 'month';
export const VALIDITY_PERIOD_DAY = 'day';
export type ValidityPeriod = typeof VALIDITY_PERIOD_YEAR | typeof VALIDITY_PERIOD_MONTH | typeof VALIDITY_PERIOD_DAY;
export const getDateFromDate = (
    referenceDate: Date,
    length: number,
    unit: ValidityPeriod | MaintenanceTaskCalendarUnit,
    untilEnd: boolean,
    fromStart?: boolean
): Date => {
    const date = new Date(referenceDate.getTime());
    switch (unit) {
        case VALIDITY_PERIOD_YEAR:
            date.setUTCFullYear(date.getUTCFullYear() + length);
            break;
        case VALIDITY_PERIOD_MONTH:
            date.setUTCMonth(date.getUTCMonth() + length);
            break;
        case VALIDITY_PERIOD_DAY:
            date.setUTCDate(date.getUTCDate() + length);
            break;
    }
    if (untilEnd) {
        switch (unit) {
            case VALIDITY_PERIOD_YEAR:
                date.setUTCMonth(11, 31);
                date.setUTCHours(12, 0, 0, 0);
                break;
            case VALIDITY_PERIOD_MONTH:
                date.setUTCMonth(date.getUTCMonth() + 1, 0);
                date.setUTCHours(12, 0, 0, 0);
                break;
            case VALIDITY_PERIOD_DAY:
                date.setUTCHours(23, 59, 59, 0);
                break;
        }
    }
    if (fromStart) {
        switch (unit) {
            case VALIDITY_PERIOD_YEAR:
                date.setUTCMonth(0, 1);
                date.setUTCHours(12, 0, 0, 0);
                break;
            case VALIDITY_PERIOD_MONTH:
                date.setUTCDate(1);
                date.setUTCHours(12, 0, 0, 0);
                break;
            case VALIDITY_PERIOD_DAY:
                date.setUTCHours(0, 0, 0, 0);
                break;
        }
    }
    return date;
};

export function timestampToDate(timestampSeconds: number): Date {
    const out = new Date();
    out.setTime(timestampSeconds * 1e3);
    return out;
}

export function getTurnaroundTimeHuman(date1: Date, date2: Date): string {
    const turnaroundMinutes = Math.round(Math.abs(date2.getTime() - date1.getTime()) / 1e3 / 60);
    const turnaroundDays = Math.floor(turnaroundMinutes / 60 / 24);
    const turnaroundHours = Math.floor(turnaroundMinutes / 60) % 24;
    const turnaroundMinutesRemainder = turnaroundMinutes % 60;
    if (turnaroundDays) {
        return `${turnaroundDays}d ${turnaroundHours}h`;
    } else if (turnaroundHours) {
        return `${turnaroundHours}h ${turnaroundMinutesRemainder}m`;
    } else {
        return `${turnaroundMinutesRemainder}m`;
    }
}

export function minutesToTimeString(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = roundNumber(minutes % 60, 0);

    return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
}
