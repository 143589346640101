export type MainMenuState = {
    visible: boolean;
}

export const mainMenuInitialState: MainMenuState = {
    visible: false
};

export const ACTION_MAIN_MENU_SHOW = 'MAIN_MENU_SHOW';
export const ACTION_MAIN_MENU_HIDE = 'MAIN_MENU_HIDE';
export const ACTION_MAIN_MENU_TOGGLE = 'MAIN_MENU_TOGGLE';

export const MainMenuReducer = (state: MainMenuState | undefined = mainMenuInitialState, action: MainMenuActionTypes | any = undefined): MainMenuState => {
    switch (action.type) {
        case "MAIN_MENU_SHOW":
            return {visible: true};
        case "MAIN_MENU_HIDE":
            return {visible: false};
        case "MAIN_MENU_TOGGLE":
            return {visible: !state.visible};
        default:
            return state
    }
};

export type MainMenuActionTypes = {
    type: typeof ACTION_MAIN_MENU_SHOW | typeof ACTION_MAIN_MENU_HIDE | typeof ACTION_MAIN_MENU_TOGGLE
}
