import { Storage } from 'aws-amplify';
import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';
import { FlightLegPhase } from '../../FlightLegs/FlightLeg';
import { CreateFlightDocumentInput } from '../../../API';
import * as mime from 'mime';
import { getDb } from '../LocalDB';
import { logError } from '../../../utils/ErrorLog/ErrorLog';
import {
    FlightDocument,
    FlightDocumentType,
    isFlightDocumentSaved,
    LocalFlightDocument,
    SavedFlightDocument
} from '../../FlightDocuments/FlightDocument';
import { getLocalFlightDocumentContent } from '../../FlightDocuments/LocalDocumentContent';
import { DbCreated } from '../types';
import { getStandardItemHandler } from '../standardItemHandler';

let counter = 0;

export const flightDocumentItemFields = `
    id
    deleted
    editableByUserIds
    createdAt
    aircraftId
    flightId
    legId
    squawkId
    deferralId
    releaseToServiceId
    maintenanceRecordId
    contentKey
    type
    phase
    title
    _version
`.trim();

async function uploadDocument(document: LocalFlightDocument): Promise<string> {
    try {
        const content = await getLocalFlightDocumentContent(document.id);
        if (!content) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('Cannot find local document content when uploading new document');
        }
        const suffix = mime.getExtension(content.type);
        const filename = document.id + '.' + suffix;
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        const result = await Storage.put(filename, content, {
            contentType: content.type,
            expires
        }) as { key: string };
        const newDocument: SavedFlightDocument = {
            ...document,
            contentKey: result.key
        };
        await getDb().documents.put(newDocument);
        return result.key;
    } catch (e: any) {
        logError(e.message, "flightDocuments/upload");
        throw e;
    }
}

export const flightDocumentToDb: DbSyncerUpDefinition = (document: FlightDocument) => {
    const c = ++counter;
    const [commandName, inputType] = !document._version
        ? ['createFlightDocument', 'CreateFlightDocumentInput']
        : ['updateFlightDocument', 'UpdateFlightDocumentInput'];
    const input = async (): Promise<CreateFlightDocumentInput> => {
        const contentKey = isFlightDocumentSaved(document)
            ? document.contentKey
            : await uploadDocument(document);
        // noinspection UnnecessaryLocalVariableJS
        const input: CreateFlightDocumentInput = {
            id: document.id,
            deleted: document.deleted,
            createdAt: document.createdAt.toISOString(),
            aircraftId: document.aircraftId,
            flightId: document.flightId,
            legId: document.legId,
            squawkId: document.squawkId,
            deferralId: document.deferralId,
            releaseToServiceId: document.releaseToServiceId,
            maintenanceRecordId: document.maintenanceRecordId,
            contentKey,
            type: document.type,
            phase: document.phase,
            title: document.title,
            _version: document._version,
        };
        return input;
    };

    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, flightDocumentItemFields);
    const remoteToLocalTransform = flightDocumentTransform;
    return {query, input, inputKey, inputType, mutationName, remoteToLocalTransform, queryName: "getFlightDocument"};
};

export const flightDocumentTransform = (input: CreateFlightDocumentInput): SavedFlightDocument => {
    return {
        id: input.id!,
        aircraftId: input.aircraftId,
        createdAt: new Date(input.createdAt!),
        flightId: input.flightId || undefined,
        legId: input.legId || undefined,
        squawkId: input.squawkId || undefined,
        deferralId: input.deferralId || undefined,
        releaseToServiceId: input.releaseToServiceId || undefined,
        maintenanceRecordId: input.maintenanceRecordId || undefined,
        contentKey: input.contentKey,
        type: input.type as FlightDocumentType,
        phase: input.phase as FlightLegPhase,
        deleted: input.deleted,
        title: input.title,
        _version: input._version!,
        editableByUserIds: input.editableByUserIds || [],
    }
};


export const flightDocumentItemHandler = async (items: (CreateFlightDocumentInput & DbCreated)[]): Promise<void> => {
    const db = getDb();
    const standardHanlingPromise = getStandardItemHandler(db.documents, flightDocumentTransform)(items);
    const toDelete = items.filter(i => i.deleted);
    const deletePromise = db.localDocumentContents.bulkDelete(toDelete.map(i => i.id!));
    await Promise.all([
        standardHanlingPromise,
        deletePromise
    ]);
};
