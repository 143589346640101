import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';
import { CreateSigningKeyChangeInput } from '../../../API';
import { DbCreated, WithId } from '../types';

let counter = 0;

const signingKeyChangeItemFields = 'id';

export const signingKeyChangeToDb: DbSyncerUpDefinition = (input: CreateSigningKeyChangeInput & WithId) => {
    const c = ++counter;
    const commandName = 'createSigningKeyChange';
    const inputType = 'CreateSigningKeyChangeInput';
    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, signingKeyChangeItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: signingKeyChangeTransform,
        queryName: 'getSigningKeyChange',
        appendOnly: true,
    };
};

const signingKeyChangeTransform = (input: CreateSigningKeyChangeInput & DbCreated): { id: string, deleted: boolean } => {
    // only delete local data
    return {id: input.id, deleted: true};
};
