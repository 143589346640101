import { CreateAircraftInput } from '../../../API';
import { DbCreated } from '../types';
import { Aircraft } from '../../AircraftsDatasource';
import { AircraftCountableType, AircraftUpliftableType } from '../../CountableChanges/types';
import { AircraftTag } from '../../Aircrafts/types';
import { parsePerformanceProfileJson } from '../../../Admin/Scheduling/model/PerformanceProfileTypes';

export const aircraftItemFields = `
    id
    deleted
    registration
    friendlyType
    serialNumber
    imageUrl
    countableTypes
    upliftableTypes
    aircraftTypeId
    tags
    userId
    performanceProfile
    _version
    _deleted
    _lastChangedAt
`.trim();

export const aircraftFromDb = (input: CreateAircraftInput & DbCreated): Aircraft => {
    return {
        id: input.id,
        registration: input.registration,
        friendlyType: input.friendlyType,
        serialNumber: input.serialNumber,
        imageUrl: input.imageUrl || undefined,
        countableTypes: input.countableTypes ? (input.countableTypes as AircraftCountableType[]) : [],
        upliftableTypes: input.upliftableTypes ? (input.upliftableTypes as AircraftUpliftableType[]) : [],
        tags: input.tags ? (input.tags as AircraftTag[]) : [],
        userId: input.userId || undefined,
        aircraftTypeId: input.aircraftTypeId || null,
        deleted: input.deleted,
        performanceProfile: (input.performanceProfile && parsePerformanceProfileJson(input.performanceProfile)) || null,
        callsign: input.callsign || null,
        _version: input._version,
    };
};

export const aircraftToDb = (ac: Aircraft): CreateAircraftInput & DbCreated => {
    return {
        id: ac.id,
        deleted: ac.deleted,
        registration: ac.registration,
        friendlyType: ac.friendlyType,
        serialNumber: ac.serialNumber,
        imageUrl: ac.imageUrl,
        countableTypes: ac.countableTypes,
        upliftableTypes: ac.upliftableTypes,
        tags: ac.tags,
        category: '(not implemented)',
        aircraftTypeId: ac._version ? ac.aircraftTypeId : undefined,
        performanceProfile: ac.performanceProfile
            ? JSON.stringify(ac.performanceProfile)
            : ac._version
            ? null
            : undefined,
        callsign: ac.callsign ? ac.callsign : ac._version ? null : undefined,
        _version: ac._version || 0,
        _lastChangedAt: ac._lastChangedAt || 0,
    };
};
