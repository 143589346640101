import hexToRgba from 'hex-to-rgba';
import csscolors from 'css-color-names';
import { isNone, Maybe } from './maybe';
import { ColorKey, COLORS } from 'stylekit';

function getCssHexColor(color: string): Maybe<string> {
    // @ts-ignore
    return csscolors[color];
}

export function colorToRgba(color: string | undefined, opacity?: number) {
    if (isNone(color)) {
        return undefined;
    }
    if (color.startsWith('#') && color.length > 6) {
        return hexToRgba(color, opacity);
    }
    const cssHexColor = getCssHexColor(color);
    if (isNone(cssHexColor)) {
        return undefined;
    } else {
        return hexToRgba(cssHexColor, opacity);
    }
}

export function getRgbaColor(params: { colorKey?: ColorKey; colorRaw?: string; opacity?: number }) {
    return (
        params.colorRaw ??
        colorToRgba(params.colorKey ? COLORS[params.colorKey] ?? params.colorKey : undefined, params.opacity)
    );
}
