export function startOfString(
    text: string | undefined,
    nubmerOfChars: number,
    transform?: 'lowercase' | 'uppercase'
): string {
    const res = (text ?? '').slice(0, nubmerOfChars);

    if (transform === 'lowercase') {
        return res.toLowerCase();
    } else if (transform === 'uppercase') {
        return res.toUpperCase();
    } else {
        return res;
    }
}

export function truncate(text: string, maxLength = 40) {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}…`;
}
