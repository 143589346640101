import { getDb } from '../LocalDB/LocalDB';

export const getLocalFlightDocumentContent = async (documentId: string): Promise<Blob | null> => {
    const document = await getDb().localDocumentContents.get(documentId);
    if (!document) {
        return null;
    }
    return document.content;
}

export const putLocalFlightDocumentContent = async (documentId: string, content: Blob): Promise<void> => {
    await getDb().localDocumentContents.put({id: documentId, content});
}

export const deleteLocalFlightDocumentContent = async (documentId: string): Promise<void> => {
    await getDb().localDocumentContents.delete(documentId);
}

export type LocalDocumentContent = {
    id: string;
    content: Blob;
}
