import React, { FunctionComponent } from "react";
import { FormErrorLike } from './FormErrors';
import { formClasses } from '../../utils/BEM';

type FieldErrorMessageProps = {
    error?: FormErrorLike;
}
const FieldErrorMessage: FunctionComponent<FieldErrorMessageProps> = ({error}) => {
    if (!error) {
        return null;
    }

    return (
        <div {...formClasses('errors')}>
            {getErrorMessage(error)}
        </div>
    );
}

const getErrorMessage = (error: FormErrorLike): string => {
    if (error === false) {
        return "";
    }
    if (error === true) {
        return "Please fill this field";
    }
    if (typeof error === 'string') {
        return (
            error
        )
    }
    return error.text;
};

export default FieldErrorMessage;
