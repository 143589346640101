import { getDb } from '../LocalDB/LocalDB';
import { ContactInput } from '../../APIExtensions';
import { CreateContactInput } from '../../API';

export const getCrewableContact = async (
    userId: string
): Promise<(ContactInput & { crewId: string; name: string; hasDutyTracking: boolean }) | null> => {
    const contacts = await getDb().contacts.where({ userId }).toArray();
    const crewableContacts = contacts.filter(getContactIsCrewable) as (ContactInput & {
        crewId: string;
        name: string;
    })[];
    if (crewableContacts[0]) {
        return {
            crewId: crewableContacts[0].crewId,
            name: crewableContacts[0].name,
            hasDutyTracking: crewableContacts[0].hasDutyTracking || false,
            deleted: false,
            id: crewableContacts[0].id,
            aircraftVisiblityLimitations: crewableContacts[0].aircraftVisiblityLimitations,
            fullReadAccessToAircrafts: crewableContacts[0].fullReadAccessToAircrafts,
            accessRights: crewableContacts[0].accessRights,
        };
    } else {
        return null;
    }
};

export const getContactById = async (id: string): Promise<(ContactInput & { crewId: string }) | null> => {
    const contact = await getDb().contacts.get(id);
    if (!contact) {
        return null;
    }
    return contact as ContactInput & { crewId: string };
};

export const getContactByCrewId = async (crewId: string): Promise<(ContactInput & { crewId: string }) | null> => {
    const contacts = await getDb().contacts.where({ crewId }).toArray();
    if (contacts.length) {
        return contacts[0] as ContactInput & { crewId: string };
    } else {
        return null;
    }
};

export const getCrewableContacts = async (): Promise<ContactInput[]> => {
    return getDb().contacts.toArray();
};

export type WithCrewableUserFields = {
    crewId: string;
    name: string;
    hasDutyTracking: boolean;
};

export const getContactIsCrewable = <T extends CreateContactInput>(input: T): input is T & WithCrewableUserFields => {
    return Boolean(input.crewId && input.name);
};
