// https://getbootstrap.com/docs/5.0/customize/color/
export const COLORS = {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000000',
    gray: '#E0E0E0',
    gray128: '#808080',
    gray240: '#F0F0F0',
    grayF9: '#F9F9F9',
    greenLight: '#C6EBC9',
    greenLight2: '#e2f5e4',
    greenLight3: '#f3fbf4',
    green: '#A0D3A8',
    greenDark: '#70AF85',
    blueLight: '#9AD0EC',
    blue: '#6096B4',
    blueDark: '#1572A1',
    blueInput: '#147EFB',
    brownLight: '#EEE3CB',
    brown: '#D7C0AE',
    brownDark: '#967E76',
    purpleLight: '#D1B9E4',
    purple: '#BA94D1',
    purpleDark: '#8A6BBE',
    yellow: '#ffda6a', // yellow-300
    yellowLight: '#FCE8B3',
    yellowLight2: '#FDF5E6',
    orange: '#feb272', // orange-300
    pink: '#e685b5', // pink-300
    red: '#ea868f', // red-300
    redLight: '#f1aeb5', // red-200
    redLight2: '#f8d7da', // red-100
    //
    primary: '#0d6efd',
    warning: '#ffc107',
    danger: '#dc3545',
    success: '#198754',
    info: '#0dcaf0',
} as const;

export type ColorKey = keyof typeof COLORS;
export type Color = (typeof COLORS)[ColorKey];
