import { generateLabels } from '../../components/FormFields/FieldDetailedValue';

export enum AircraftTag {
    opsAto = 'ops_ato',
    opsCat = 'ops_cat',
    complex = 'complex',
    multiPilot = 'mcc',
    mutiPilotCat = 'mcc_cat',
    multiEngine = 'me',
}

export const AIRCRAFT_TAGS: AircraftTag[] = [
    AircraftTag.opsAto,
    AircraftTag.opsCat,
    AircraftTag.complex,
    AircraftTag.multiPilot,
    AircraftTag.mutiPilotCat,
    AircraftTag.multiEngine,
];

export const aircraftTagLabelsMap = new Map<AircraftTag, string>([
    [AircraftTag.opsAto, 'ATO'],
    [AircraftTag.opsCat, 'CAT'],
    [AircraftTag.complex, 'Complex'],
    [AircraftTag.multiPilot, 'Multi-pilot always'],
    [AircraftTag.mutiPilotCat, 'Multi-pilot in CAT'],
    [AircraftTag.multiEngine, 'ME'],
]);

export const aircraftTagLabels = generateLabels(aircraftTagLabelsMap);
