/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://y4o6cc4iujdlxb6pnoayoda57m.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "schedulingApi",
            "endpoint": "https://mlwolcu4ad.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:00e1b3d1-acd7-4797-912f-2bd351f84d9f",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_5kc0D2tOp",
    "aws_user_pools_web_client_id": "5nrcgbaskbdi9sakqh9t6vfe2c",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "alol-tlb-docs214021-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
