import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';
import '../../Bootstrap.sass';
import '../../App.sass';
import { formClasses, pageClasses } from '../../utils/BEM';
import LoadingScreenOverlay from '../../components/LoadingScreenOverlay';
import { Auth } from 'aws-amplify';
import { getCredentials, saveCredentials } from './CredentialStore';
import logo from './logo.svg';
import LoginForm, { LoginFormData } from './LoginForm';
import CompleteNewPasswordForm, { CompleteNewPasswordFormData } from './CompleteNewPasswordForm';
import { installationData } from '../../installationData';

type LoginScreenProps = {};
const LoginScreen: FunctionComponent<LoginScreenProps> = () => {
    const [useAmplify, setUseAmplify] = useState<boolean>(false);
    const [completeNewPasswordData, setCompleteNewPasswordData] = useState<any>(false);
    const [formData, setFormData] = useState<LoginFormData & CompleteNewPasswordFormData>({
        username: '',
        password: '',
        resetPassword: '',
        rememberPassword: true,
    });
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [busy, setBusy] = useState<boolean>(false);
    const login = async () => {
        try {
            setBusy(true);
            if (!completeNewPasswordData) {
                const signIn = await Auth.signIn(formData.username, formData.password);
                if (signIn.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setCompleteNewPasswordData(signIn);
                }
            } else {
                await Auth.completeNewPassword(completeNewPasswordData, formData.resetPassword, {});
            }
            if (formData.rememberPassword) {
                await saveCredentials(formData.username, formData.password);
            }
        } catch (e: any) {
            console.error(e);
            setErrorMessage(e.message);
        } finally {
            setBusy(false);
        }
    };
    useEffect(() => {
        let cancelled = false;
        (async () => {
            const maybeCreds = await getCredentials();
            if (cancelled) {
                return;
            }
            if (maybeCreds) {
                try {
                    setBusy(true);
                    await Auth.signIn(maybeCreds.username, maybeCreds.password);
                } catch (e: any) {
                    console.error(e);
                    setErrorMessage(e.message);
                } finally {
                    setBusy(false);
                }
            }
        })();
        return () => {
            cancelled = true;
        };
    }, []);
    const AmplifyLoginScreen = React.lazy(() => import('./AmplifyLoginScreen'));
    return (
        <form
            onSubmit={async (evt) => {
                evt.preventDefault();
                await login();
            }}
        >
            <div className={'container mt-4'}>
                <div className={'row justify-content-md-center'}>
                    <div className={'col-md-6 col-lg-4'}>
                        <img src={logo} alt={`${installationData.name} Logo`} className={'mb-4 border-bottom pb-4'} />
                        {!useAmplify ? (
                            <>
                                <div {...formClasses('fieldlike')}>
                                    {!errorMessage ? (
                                        <p {...pageClasses('muted')}>
                                            {!completeNewPasswordData
                                                ? `Welcome to ${installationData.name} e-flight system. Please use the form below to log in. If you don't have an account yet, please contact our dispatching.`
                                                : "You have logged in with a temporary password. Please set a new, permanent one. Please choose a password that you don't use on other services."}
                                        </p>
                                    ) : (
                                        <p className={'text-danger'}>{errorMessage}</p>
                                    )}
                                </div>
                                {!completeNewPasswordData ? (
                                    <>
                                        <LoginForm {...{ busy, formData, setFormData }} />
                                        <div {...formClasses('fieldlike', undefined, 'text-end')}>
                                            <button
                                                type={'submit'}
                                                {...pageClasses('anchor-like-button')}
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                    setUseAmplify(true);
                                                }}
                                            >
                                                Forgot password?
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <CompleteNewPasswordForm {...{ busy, formData, setFormData }} />
                                )}
                            </>
                        ) : (
                            <Suspense fallback={<LoadingScreenOverlay />}>
                                <AmplifyLoginScreen />
                            </Suspense>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default LoginScreen;
