import { User } from '../Datasource/Users/User';
import { useSelector } from 'react-redux';
import { RootState } from '../rootReducer';

export type TlbSignedUserState = {
    data: User;
    isAdmin: boolean;
    isPilotOrAircraft: boolean;
    isPilot: boolean;
    isAircraft: boolean;
    isTechnician: boolean;
    dataIsStale: boolean;
};
const LOCAL_STORAGE_KEY = 'alol_user_tlb';

export const TLB_USER_STATE_SIGNED_OUT = 'signed-out';
export const TLB_USER_STATE_PROCESSING = 'processing';
type UnsignedTlbUserState = typeof TLB_USER_STATE_SIGNED_OUT;
type ProcessingTlbUserState = typeof TLB_USER_STATE_PROCESSING;

export type TlbUserState = TlbSignedUserState | UnsignedTlbUserState | ProcessingTlbUserState;

export const tlbUserInitialState: TlbUserState = getInitialState();

export const TLB_USER_SIGN_IN = 'TLB_USER_SIGN_IN';
export const TLB_USER_SIGN_PROCESS_START = 'TLB_USER_SIGN_PROCESS_START';
export const TLB_USER_SIGN_OUT = 'TLB_USER_SIGN_OUT';
export const TLB_USER_AUTH_ERROR = 'TLB_USER_AUTH_ERROR';

export const TlbUserReducer = (
    state: TlbUserState | undefined,
    action: TlbUserActionTypes | any = undefined
): TlbUserState => {
    switch (action.type) {
        case TLB_USER_SIGN_IN:
            const data = action.data as TlbSignInData;
            persistState(data);
            const isPilot = !!data.user.userId && !data.user.aircraftId;
            const isAircraft = !!data.user.aircraftId;
            return {
                data: data.user,
                isAdmin: data.isAdmin,
                isPilotOrAircraft: data.isPilot,
                isPilot,
                isAircraft,
                isTechnician: data.isTechnician,
                dataIsStale: false,
            };
        case TLB_USER_SIGN_OUT:
            clearState();
            return TLB_USER_STATE_SIGNED_OUT;
        case TLB_USER_SIGN_PROCESS_START:
            if (state && 'object' === typeof state) {
                return state;
            } else {
                return TLB_USER_STATE_PROCESSING;
            }
        case TLB_USER_AUTH_ERROR:
            if (state && 'object' === typeof state) {
                return state;
            } else {
                return TLB_USER_STATE_SIGNED_OUT;
            }
        default:
            return state || getInitialState();
    }
};

type TlbSignInData = {
    user: User;
    isAdmin: boolean;
    isPilot: boolean;
    isTechnician: boolean;
};
export type TlbUserActionTypes =
    | {
          type: typeof TLB_USER_SIGN_IN;
          data: TlbSignInData;
      }
    | { type: typeof TLB_USER_SIGN_OUT | typeof TLB_USER_SIGN_PROCESS_START | typeof TLB_USER_AUTH_ERROR };

export const useSignedTlbUserState = (): TlbSignedUserState => {
    const state = useAnyTlbUserState();
    if (!tlbUserIsSignedIn(state)) {
        throw new Error('TLB User is not signed in');
    }
    return state;
};

export const tlbUserIsSignedIn = (state: TlbUserState): state is TlbSignedUserState => {
    return state && 'string' !== typeof state;
};

export const useAnyTlbUserState = (): TlbUserState => {
    return useSelector<RootState, TlbUserState>((state) => state.user);
};

function persistState(state: TlbSignInData) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
}

function clearState() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

function getInitialState(): TlbUserState {
    try {
        const maybeLocalStorageData = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (maybeLocalStorageData) {
            const data: TlbSignInData = JSON.parse(maybeLocalStorageData);
            const isPilot = !!data.user.userId && !data.user.aircraftId;
            const isAircraft = !!data.user.aircraftId;
            return {
                data: data.user,
                isAdmin: data.isAdmin,
                isPilotOrAircraft: data.isPilot,
                isTechnician: data.isTechnician,
                isPilot,
                isAircraft,
                dataIsStale: true,
            };
        } else {
            return TLB_USER_STATE_PROCESSING;
        }
    } catch (e) {
        console.error('Error reloading TLB User State', e);
        return TLB_USER_STATE_PROCESSING;
    }
}
