import { useSelector } from 'react-redux';
import { RootState } from '../rootReducer';

export type SupplementalDataSyncState = {
    inProgress: boolean;
    time: number | null;
};

export const supplementalDataSyncInitialState: SupplementalDataSyncState = {
    inProgress: false,
    time: null,
};

export const SUPPLEMENTAL_DATA_SYNC_CHANGE = 'SUPPLEMENTAL_DATA_SYNC_CHANGE';

export type SupplementalDataSyncActionTypes = {
    type: typeof SUPPLEMENTAL_DATA_SYNC_CHANGE;
    data: SupplementalDataSyncState;
};

export const SupplementalDataSyncReducer = (
    state: SupplementalDataSyncState | undefined = supplementalDataSyncInitialState,
    action: SupplementalDataSyncActionTypes | any = undefined
): SupplementalDataSyncState => {
    switch (action.type) {
        case SUPPLEMENTAL_DATA_SYNC_CHANGE:
            return action.data;
        default:
            return state;
    }
};

export function useSupplementalDataSyncState() {
    return useSelector<RootState, SupplementalDataSyncState>((state) => state.supplementalDataSync);
}
