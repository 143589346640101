import { CreateAircraftMaintenanceLimitExtensionInput } from '../../API';
import { WithId } from '../../Datasource/LocalDB/types';
import * as uuid from 'uuid';
import { AircraftMaintenanceLimit } from '../AircraftMaintenanceLimits/AircraftMaintenanceLimit';

export type AircraftMaintenanceLimitExtension = Omit<
    CreateAircraftMaintenanceLimitExtensionInput & WithId,
    'validUntilDate' | 'createdAt'
> & {
    createdAt: Date;
    validUntilDate: Date | null;
};

export function aircraftMaintenanceLimitExtensionFromDb(
    input: CreateAircraftMaintenanceLimitExtensionInput & WithId
): AircraftMaintenanceLimitExtension {
    return {
        ...input,
        createdAt: new Date(input.createdAt!),
        validUntilDate: input.validUntilDate ? new Date(input.validUntilDate) : null,
    };
}

export function aircraftMaintenanceLimitExtensionToDb(
    record: AircraftMaintenanceLimitExtension
): CreateAircraftMaintenanceLimitExtensionInput & WithId {
    return {
        ...record,
        createdAt: record.createdAt.toISOString(),
        validUntilDate: record.validUntilDate ? record.validUntilDate.toISOString() : null,
    };
}

export function createEmptyMaintenanceLimitExtension(
    maintenance: AircraftMaintenanceLimit,
    userId: string
): AircraftMaintenanceLimitExtension {
    return {
        id: uuid.v4(),
        deleted: false,
        createdAt: new Date(),
        maintenanceLimitId: maintenance.id,
        maintenanceLimitVersion: maintenance._version!,
        maintenanceRecordId: null,
        createdByUserId: userId,
        extendedByName: '',
        validUntilDate: null,
        validUntilCountableValues: null,
    };
}
