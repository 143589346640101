import { isNone, isSome, Maybe } from './maybe';
import { keyBy } from 'lodash';

export function last<T>(arr: T[]): T | undefined {
    return arr[arr.length - 1];
}

export function sum(input: number[]): number {
    return input.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
}

export const toMap = <T extends { id?: string | null | undefined }>(
    input: T[],
    mergeToMap?: Map<string, T>,
): Map<string, T> => {
    const map = mergeToMap ? mergeToMap : new Map<string, T>();
    for (const item of input) {
        if (item.id) {
            map.set(item.id, item);
        }
    }
    return map;
};

export function arrayOnlyValues<T>(arr: Array<Maybe<T>>): Array<T> {
    return arr.filter((item) => isSome(item)) as Array<T>;
}

/**
 * Function that returns an array of numbers.
 *
 * @param num size of array, elements are [0, ... , num - 1]
 * @param mappingFunction function to map array elements
 * @returns an array of numbers that could be mapped, or not.
 */
export function arrayOf(num: number, mappingFunction?: (x: number) => number): Array<number> {
    return isNone(mappingFunction) ? [...Array(num).keys()] : [...Array(num).keys()].map((i) => mappingFunction(i));
}

export function arrayToObjByKey<T>(arr: Array<T>, key: keyof T) {
    return keyBy(arr, key);
}

export function reduceSumArr(acc: number | undefined, val: number | undefined) {
    return (acc ?? 0) + (val ?? 0);
}
