import { Link, LinkProps, navigate } from '@reach/router';
import React from "react";

export const ADMIN_LINK_PREFIX = '/ops';

export const navigateA = (link: string) => {
    return navigate(ADMIN_LINK_PREFIX + link);
}


export function LinkA<TState>(props: React.PropsWithoutRef<LinkProps<TState>> & React.RefAttributes<HTMLAnchorElement>) {
    const {to, ...otherProps} = props;
    const toA = ADMIN_LINK_PREFIX + to;
    return (
        <Link to={toA} {...otherProps} />
    )
}
