import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';
import { DutyItem, DutyItemState, DutyItemType } from '../../Duties/types';
import { CreateDutyItemInput } from '../../../API';

let counter = 0;
export const dutyItemItemFields = `
    id
    userId
    state
    editableByUserIds
    deleted
    type
    startDate
    endDate
    forceRestAfter
    sectorCount
    endPlaceIcao
    blockTimeMinutes
    flightLegIds
    logbookRecordIds
    linkedDutyItemIds
    plannedFdpExtension
    note
    _version
`;

export const dutyItemToDb: DbSyncerUpDefinition = (dutyItem: DutyItem) => {
    const c = ++counter;
    const [commandName, inputType] = !dutyItem._version
        ? ['createDutyItem', 'CreateDutyItemInput']
        : ['updateDutyItem', 'UpdateDutyItemInput'];
    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, dutyItemItemFields);
    if (!dutyItem.userId) {
        throw new Error('Duty Item does not have userId');
    }
    const input: CreateDutyItemInput = {
        id: dutyItem.id,
        userId: dutyItem.userId,
        state: dutyItem.state,
        deleted: dutyItem.deleted,
        type: dutyItem.type,
        startDate: dutyItem.startDate.toISOString(),
        endDate: dutyItem.endDate.toISOString(),
        forceRestAfter: dutyItem.forceRestAfter,
        sectorCount: dutyItem.sectorCount,
        endPlaceIcao: dutyItem.endPlaceIcao.length ? dutyItem.endPlaceIcao : undefined,
        flightLegIds: dutyItem.flightLegIds.length ? dutyItem.flightLegIds : null,
        logbookRecordIds: dutyItem.logbookRecordIds.length ? dutyItem.logbookRecordIds : null,
        note: dutyItem.note || null,
        blockTimeMinutes: dutyItem.blockTimeMinutes,
        plannedFdpExtension: dutyItem.plannedFdpExtension,
        _version: dutyItem._version,
    };
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: dutyItemTransform,
        queryName: 'getDutyItem'
    }
};

export const dutyItemTransform = (input: CreateDutyItemInput): DutyItem => {
    return {
        id: input.id!,
        type: input.type as DutyItemType,
        state: input.state as DutyItemState,
        userId: input.userId,
        crewId: input.crewId ? input.crewId : input.userId,
        startDate: new Date(input.startDate),
        endDate: new Date(input.endDate),
        sectorCount: input.sectorCount || 0,
        forceRestAfter: input.forceRestAfter || false,
        endPlaceIcao: input.endPlaceIcao || '',
        flightLegIds: input.flightLegIds || [],
        logbookRecordIds: input.logbookRecordIds || [],
        linkedDutyItemIds: input.linkedDutyItemIds || [],
        blockTimeMinutes: input.blockTimeMinutes || undefined,
        note: input.note || null,
        _version: input._version || undefined,
        deleted: input.deleted,
        editableByUserIds: input.editableByUserIds || [],
        plannedFdpExtension: input.plannedFdpExtension || false,
    }
};
