import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';
import {
    FinishedFlightLeg,
    FLIGHT_LEG_PHASE_FINISH,
    FlightLeg,
    FlightLegPhase,
    isLegFinished,
    StartedFlightLeg,
} from '../../FlightLegs/FlightLeg';
import { CreateLegInput } from '../../../API';
import { FlightOps } from '../../FlightLegs/FlightOpsTypes';
import { CrewRoleTypes } from '../../FlightLegs/CrewRoleTypes';
import { WithId } from '../types';

let counter = 0;

export const flightLegItemFields = `
    id
    deleted
    editableByUserIds
    createdAt
    flightId
    aircraftId
    reservationId
    reservationEventId
    airportOffIcao
    ops
    securityCheckDate
    securityCheckName
    preflightCheckDate
    preflightCheckName
    picName
    picId
    picRoles
    sicName
    sicId
    sicRoles
    deicing
    notes
    startSignature
    phase
    startedAt
    upliftCountableChangeId
    airportOnIcao
    timeOut
    timeOff
    timeOn
    timeIn
    finishNotes
    finishSignature
    consumptionCountableChangeId
    _version
    logbookFields
    inputSources
    ticketId
`.trim();

export const flightLegToDb = function (leg: StartedFlightLeg | FinishedFlightLeg): CreateLegInput & WithId {
    const input: CreateLegInput & WithId = {
        id: leg.id,
        deleted: leg.deleted,
        createdAt: leg.createdAt.toISOString(),
        flightId: leg.flightId,
        aircraftId: leg.aircraftId,
        airportOffIcao: leg.airportOffIcao,
        ops: leg.ops,
        securityCheckDate: leg.securityCheckDate?.toISOString(),
        securityCheckName: leg.securityCheckName,
        preflightCheckDate: leg.preflightCheckDate?.toISOString(),
        preflightCheckName: leg.preflightCheckName,
        picName: leg.picName,
        picId: leg.picId || undefined,
        picRoles: leg.picRoles.length ? leg.picRoles : null,
        sicName: leg.sicName,
        sicId: leg.sicId || undefined,
        sicRoles: leg.sicRoles.length ? leg.sicRoles : null,
        deicing: JSON.stringify(leg.deicing),
        notes: leg.notes,
        startSignature: leg.startSignature ? JSON.stringify(leg.startSignature) : null,
        phase: leg.phase,
        startedAt: leg.startedAt.toISOString(),
        upliftCountableChangeId: leg.upliftCountableChangeId,
        timeOut: leg.timeOut.toISOString(),
        inputSources: Object.keys(leg.inputSources).length ? JSON.stringify(leg.inputSources) : null,
        reservationId: leg.reservationId || (leg._version ? null : undefined),
        reservationEventId: leg.reservationEventId || (leg._version ? null : undefined),
        ticketId: leg.ticketId ?? 'x',
        _version: leg._version,
    };
    if (isLegFinished(leg)) {
        return {
            ...input,
            airportOnIcao: leg.airportOnIcao,
            timeOut: leg.timeOut.toISOString(),
            timeOff: leg.timeOff.toISOString(),
            timeOn: leg.timeOn.toISOString(),
            timeIn: leg.timeIn.toISOString(),
            finishNotes: leg.finishNotes,
            finishSignature: leg.finishSignature ? JSON.stringify(leg.finishSignature) : null,
            consumptionCountableChangeId: leg.consumptionCountableChangeId,
            logbookFields: leg.logbookFields ? JSON.stringify(leg.logbookFields) : null,
        };
    } else {
        return input;
    }
};
export const flightLegSyncerDefinition: DbSyncerUpDefinition = (leg: FlightLeg) => {
    const c = ++counter;
    const [commandName, inputType] = !leg._version ? ['createLeg', 'CreateLegInput'] : ['updateLeg', 'UpdateLegInput'];
    let input: CreateLegInput = flightLegToDb(leg);

    const { mutationName, inputKey, query } = getStandardSyncerFields(commandName, c, flightLegItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: flightLegFromDb,
        queryName: 'getLeg',
    };
};

export const flightLegFromDb = (input: CreateLegInput): FlightLeg => {
    const startedFLightLeg: StartedFlightLeg = {
        id: input.id!,
        deleted: input.deleted,
        _version: input._version!,
        createdAt: new Date(input.createdAt!),
        flightId: input.flightId,
        aircraftId: input.aircraftId,
        airportOffIcao: input.airportOffIcao,
        ops: input.ops as FlightOps,
        securityCheckDate: input.securityCheckDate ? new Date(input.securityCheckDate) : null,
        securityCheckName: input.securityCheckName || null,
        preflightCheckDate: input.preflightCheckDate ? new Date(input.preflightCheckDate) : null,
        preflightCheckName: input.preflightCheckName || null,
        picName: input.picName,
        picId: input.picId || null,
        picRoles: input.picRoles ? (input.picRoles as CrewRoleTypes[]) : [],
        sicName: input.sicName || null,
        sicId: input.sicId || null,
        sicRoles: input.sicRoles ? (input.sicRoles as CrewRoleTypes[]) : [],
        deicing: input.deicing ? JSON.parse(input.deicing) : null,
        notes: input.notes,
        startSignature: input.startSignature ? JSON.parse(input.startSignature) : null,
        phase: input.phase as FlightLegPhase,
        startedAt: new Date(input.startedAt),
        upliftCountableChangeId: input.upliftCountableChangeId,
        editableByUserIds: input.editableByUserIds || [],
        timeOut: new Date(input.timeOut),
        inputSources: input.inputSources ? JSON.parse(input.inputSources) : {},
        reservationId: input.reservationId || undefined,
        reservationEventId: input.reservationEventId || undefined,
    };
    if (input.phase === FLIGHT_LEG_PHASE_FINISH) {
        // noinspection UnnecessaryLocalVariableJS
        const finishLeg: FinishedFlightLeg = {
            ...startedFLightLeg,
            phase: input.phase,
            airportOnIcao: input.airportOnIcao!,
            timeOut: new Date(input.timeOut!),
            timeOff: new Date(input.timeOff!),
            timeOn: new Date(input.timeOn!),
            timeIn: new Date(input.timeIn!),
            finishNotes: input.finishNotes!,
            logbookFields: input.logbookFields ? JSON.parse(input.logbookFields) : null,
            finishSignature: input.finishSignature ? JSON.parse(input.finishSignature) : null,
            consumptionCountableChangeId: input.consumptionCountableChangeId!,
        };
        return finishLeg;
    } else {
        return startedFLightLeg;
    }
};
