import Dexie from 'dexie';
import { Aircraft } from '../AircraftsDatasource';
import { Flight } from '../Flights/Flight';
import { AirportCoordinates } from '../AirportCoordinates/AirportCoordinateDatasource';
import { StartLegState } from '../../screens/Flight/StartLeg';
import { FlightLeg } from '../FlightLegs/FlightLeg';
import { FinishLegState } from '../../screens/Flight/FinishLeg';
import { Squawk, SquawkDeferral } from '../Squawks/types';
import { CountableChange } from '../CountableChanges/types';
import { SyncItem } from './syncHelpers';
import { ErrorLog } from '../../utils/ErrorLog/types';
import {
    CreateAircraftDocumentInput,
    CreateLogbookRecordInput,
    CreateSignedDataInput,
    CreateSigningKeyChangeInput,
} from '../../API';
import { DownloadedDocument } from '../AircraftDocuments/types';
import { ReleaseToService } from '../ReleaseToService/types';
import { DutyItem } from '../Duties/types';
import { ContactInput } from '../../APIExtensions';
import { WithId } from './types';
import { FlightDocument } from '../FlightDocuments/FlightDocument';
import { LocalDocumentContent } from '../FlightDocuments/LocalDocumentContent';
import { AircraftMaintenanceLimit } from '../../Admin/AircraftMaintenanceLimits/AircraftMaintenanceLimit';
import { AircraftMaintenanceLimitExtension } from '../../Admin/AircraftMaintenanceLimitExtensions/AircraftMaintenanceLimitExtension';
import { FlightSupplementalData } from '../FlightSupplementalData/FlightSupplementalDataTypes';

export const LOCAL_DB_VERSION = 38;

export class LocalDB extends Dexie {
    aircrafts: Dexie.Table<Aircraft, string>;
    aircraftDocuments: Dexie.Table<CreateAircraftDocumentInput, string>;
    aircraftDocumentsDownloaded: Dexie.Table<DownloadedDocument, string>; // to be deprecated
    aircraftMaintenanceLimits: Dexie.Table<AircraftMaintenanceLimit, string>;
    aircraftMaintenanceLimitExtensions: Dexie.Table<AircraftMaintenanceLimitExtension, string>;
    flights: Dexie.Table<Flight, string>;
    legs: Dexie.Table<FlightLeg, string>;
    documents: Dexie.Table<FlightDocument, string>;
    localDocumentContents: Dexie.Table<LocalDocumentContent, string>;
    countableChanges: Dexie.Table<CountableChange, string>;
    squawks: Dexie.Table<Squawk, string>;
    squawkDeferrals: Dexie.Table<SquawkDeferral, string>;
    releaseToServices: Dexie.Table<ReleaseToService, string>;
    dutyItems: Dexie.Table<DutyItem, string>;
    contacts: Dexie.Table<ContactInput, string>;
    logbookRecords: Dexie.Table<CreateLogbookRecordInput & WithId, string>;
    airportCoordinates: Dexie.Table<AirportCoordinates, string>;
    scratchStartLegState: Dexie.Table<StartLegState, string>;
    scratchFinishLegState: Dexie.Table<FinishLegState, string>;
    syncItems: Dexie.Table<SyncItem, string>;
    signingKeyChanges: Dexie.Table<CreateSigningKeyChangeInput, string>;
    signedData: Dexie.Table<CreateSignedDataInput, string>;
    initPromise: Promise<this>;
    errorLogs: Dexie.Table<ErrorLog, string>;
    flightSupplementalData: Dexie.Table<FlightSupplementalData, string>;

    constructor() {
        super('alol_tlb');
        this.version(LOCAL_DB_VERSION).stores({
            flights: '&id,aircraftId,reservationId',
            aircrafts: '&id,userId',
            aircraftDocuments: '&id,aircraftId',
            aircraftDocumentsDownloaded: '&id,sourceId',
            aircraftMaintenanceLimits: '&id,aircraftId',
            aircraftMaintenanceLimitExtensions: '&id,maintenanceLimitId',
            airportCoordinates: '&icao,lat,lng',
            scratchStartLegState: '&id,flightId,aircraftId',
            scratchFinishLegState: '&id,flightId,aircraftId',
            legs: '&id,flightId,aircraftId,reservationId',
            countableChanges: '&id,aircraftId,flightId,[legId+type]',
            documents: '&id,flightId,aircraftId,[legId+phase],squawkId,releaseToServiceId,maintenanceRecordId,type',
            localDocumentContents: '&id',
            squawks: '&id,aircraftId,flightId,legId',
            squawkDeferrals: '&id,squawkId',
            releaseToServices: '&id,squawkId,aircraftId',
            dutyItems: '&id,userId',
            contacts: '&id,userId,crewId',
            logbookRecords: '&id,legId,contactId,crewId',
            signingKeyChanges: '&id',
            signedData: '&id',
            syncItems: '&id',
            errorLogs: '&id',
            flightSupplementalData: '&id,type,flightId,reservationId',
        });

        this.aircrafts = this.table('aircrafts');
        this.aircraftDocuments = this.table('aircraftDocuments');
        this.aircraftDocumentsDownloaded = this.table('aircraftDocumentsDownloaded');
        this.aircraftMaintenanceLimits = this.table('aircraftMaintenanceLimits');
        this.aircraftMaintenanceLimitExtensions = this.table('aircraftMaintenanceLimitExtensions');
        this.flights = this.table('flights');
        this.legs = this.table('legs');
        this.countableChanges = this.table('countableChanges');
        this.documents = this.table('documents');
        this.localDocumentContents = this.table('localDocumentContents');
        this.squawks = this.table('squawks');
        this.squawkDeferrals = this.table('squawkDeferrals');
        this.releaseToServices = this.table('releaseToServices');
        this.dutyItems = this.table('dutyItems');
        this.contacts = this.table('contacts');
        this.logbookRecords = this.table('logbookRecords');
        this.airportCoordinates = this.table('airportCoordinates');
        this.scratchStartLegState = this.table('scratchStartLegState');
        this.scratchFinishLegState = this.table('scratchFinishLegState');
        this.signingKeyChanges = this.table('signingKeyChanges');
        this.signedData = this.table('signedData');
        this.syncItems = this.table('syncItems');
        this.errorLogs = this.table('errorLogs');
        this.flightSupplementalData = this.table('flightSupplementalData');
        this.initPromise = this.init();
    }

    async init(): Promise<this> {
        return this;
    }

    async initComplete(): Promise<this> {
        return this.initPromise;
    }

    async wipe(): Promise<this> {
        await Promise.all([
            this.aircrafts.clear(),
            this.aircraftDocuments.clear(),
            this.aircraftDocumentsDownloaded.clear(),
            this.aircraftMaintenanceLimits.clear(),
            this.aircraftMaintenanceLimitExtensions.clear(),
            this.flights.clear(),
            this.legs.clear(),
            this.countableChanges.clear(),
            this.documents.clear(),
            this.localDocumentContents.clear(),
            this.squawks.clear(),
            this.squawkDeferrals.clear(),
            this.releaseToServices.clear(),
            this.dutyItems.clear(),
            this.contacts.clear(),
            this.logbookRecords.clear(),
            this.airportCoordinates.clear(),
            this.scratchStartLegState.clear(),
            this.scratchFinishLegState.clear(),
            this.signingKeyChanges.clear(),
            this.signedData.clear(),
            this.syncItems.clear(),
            this.flightSupplementalData.clear(),
        ]);
        return this;
    }
}

let db: LocalDB | null = null;

export const getDb = (): LocalDB => {
    if (!db) {
        db = new LocalDB();
    }
    return db;
};
