import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';
import { CreateSignedDataInput, CreateSigningKeyChangeInput } from '../../../API';
import { DbCreated, WithId } from '../types';

let counter = 0;

const signedDataItemFields = 'id';

export const signedDataToDb: DbSyncerUpDefinition = (input: CreateSignedDataInput & WithId) => {
    const c = ++counter;
    const commandName = 'createSignedData';
    const inputType = 'CreateSignedDataInput';
    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, signedDataItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: signedDataTransform,
        queryName: 'getSignedData',
        appendOnly: true,
    };
};

const signedDataTransform = (input: CreateSigningKeyChangeInput & DbCreated): { id: string, deleted: boolean } => {
    // only delete local data
    return {id: input.id, deleted: true};
};
