import React, {FunctionComponent} from "react";
import {Provider} from 'react-redux';
import {createStore, Store} from 'redux';

import {RootActionTypes, rootReducer, RootState} from "../rootReducer";

export const store: Store<RootState, RootActionTypes> = createStore<RootState, RootActionTypes, null, null>(rootReducer);

const WithStore: FunctionComponent = ({children}) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

export default WithStore;
