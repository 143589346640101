import { Dispatch } from 'react';
import {
    TLB_USER_AUTH_ERROR,
    TLB_USER_SIGN_IN,
    TLB_USER_SIGN_OUT,
    TLB_USER_SIGN_PROCESS_START,
    TlbUserActionTypes
} from '../../reducers/tlbUserReducer';
import {
    COGNITO_USER_AUTH_ERROR,
    COGNITO_USER_SIGN_IN,
    COGNITO_USER_SIGN_PROCESS_START,
    CognitoUserActionTypes
} from '../../reducers/cognitoUserReducer';
import { Auth } from 'aws-amplify';
import { AppEnvironment, getPreferredEnvironment, setPreferredEnvironment } from '../BackendSwitcher';
import { ADMIN_LINK_PREFIX, navigateA } from '../../Admin/Utils/LinkPrefix';
import { getUser, getUserFromContact } from '../../Datasource/Users/UserDatasource';
import { getDbSyncer } from '../../Datasource/LocalDB/DbSyncer';
import { User } from '../../Datasource/Users/User';
import { executeQuery2 } from '../../Admin/Datasources/AdminDatasource';
import { getContactIsCrewable } from '../../Datasource/Contacts/ContactsDatasource';
import { CreateContactInput } from '../../API';
import { DbCreated } from '../../Datasource/LocalDB/types';
import { QueryFactory2 } from '../../Admin/Datasources/QueryFactory';
import { contactItemFields } from '../../Datasource/LocalDB/Transformers/contacts';

export const reauthenticate = async (dispatch: Dispatch<TlbUserActionTypes | CognitoUserActionTypes>): Promise<void> => {
    dispatch({type: TLB_USER_SIGN_PROCESS_START});
    dispatch({type: COGNITO_USER_SIGN_PROCESS_START});
    try {
        const amplifyUser = await Auth.currentAuthenticatedUser();
        const userData = amplifyUser.getSignInUserSession().getAccessToken().decodePayload();
        const cognitoGroups: string[] = userData['cognito:groups'] || [];
        const isAdmin = cognitoGroups.includes('adm');
        const isPilot = cognitoGroups.includes('usr');
        const isTechnician = cognitoGroups.includes('tch');
        const isNotPilot = isAdmin && !(isPilot || isTechnician);
        const isOnPcLike = window.screen.width > 1040;
        const userId: string = amplifyUser.getUsername();

        dispatch({type: COGNITO_USER_SIGN_IN, data: {groups: cognitoGroups, id: userId}});
        if (getPreferredEnvironment() !== AppEnvironment.TLB && (isNotPilot || isOnPcLike)) {
            setPreferredEnvironment(AppEnvironment.OPS);
            dispatch({type: TLB_USER_SIGN_OUT});
            getTlbUserDataOnline(dispatch, userId)
                .then((user) => {
                    if (user) {
                        dispatch({type: TLB_USER_SIGN_IN, data: {user, isAdmin, isPilot, isTechnician}})
                    }
                });
            if (!window.location.pathname.startsWith(ADMIN_LINK_PREFIX)) {
                navigateA('/');
            }
            return;
        }
        await tryCreateTlbUser(dispatch, amplifyUser);
    } catch (e) {
        console.error('Error when authenticating: ', e);
        if (e === 'not authenticated') {
            Auth.signOut();
        }
        dispatch({type: TLB_USER_AUTH_ERROR});
        dispatch({type: COGNITO_USER_AUTH_ERROR});

    }
};

const tryCreateTlbUser = async (dispatch: Dispatch<TlbUserActionTypes | CognitoUserActionTypes>, amplifyUser: any) => {
    const userId = amplifyUser.getUsername();
    const userData = amplifyUser.getSignInUserSession().getAccessToken().decodePayload();
    const cognitoGroups = userData['cognito:groups'] || [];
    const isAdmin = cognitoGroups.includes('adm');
    const isPilot = cognitoGroups.includes('usr');
    const isTechnician = cognitoGroups.includes('tch');
    // try it quickly from cache
    let user = await getUser(userId);
    if (user) {
        dispatch({type: TLB_USER_SIGN_IN, data: {user, isAdmin, isPilot, isTechnician}});
        return;
    }
    // otherwise, wait for full sync
    await getDbSyncer().initComplete();
    user = await getUser(userId);
    if (user) {
        dispatch({type: TLB_USER_SIGN_IN, data: {user, isAdmin, isPilot, isTechnician}});
        return;
    }
    throw new Error(`User ${userId} not found`)
};


const getTlbUserDataOnline = async (dispatch: Dispatch<TlbUserActionTypes>, userId: string): Promise<User | null> => {
    const response = await executeQuery2(getListContacsByUserIdQuery, {userId});
    if (response.data.listContactsByUserId.items.length) {
        const userData = response.data.listContactsByUserId.items[0];
        if (getContactIsCrewable(userData)) {
            return getUserFromContact(userData, userId);
        }
    }
    return null;
};


type GetListContacsByUserIdQueryReturnType = {
    listContactsByUserId: {
        items: (CreateContactInput & DbCreated)[]
    };
};

const getListContacsByUserIdQuery: QueryFactory2<GetListContacsByUserIdQueryReturnType, { userId: string; }> = (variables => {
    const query = `
        query ListContactsByUserId(
            $userId: ID!
        ) { 
            listContactsByUserId(
                userId: $userId
                limit: 1
            )
            {
                items {
                    ${contactItemFields}
                }
            }
        }
    `;
    return {query, variables};
});
