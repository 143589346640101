import { DbSyncerUpDefinition, getStandardSyncerFields } from "../syncHelpers";
import { Squawk } from "../../Squawks/types";
import { CreateSquawkInput } from "../../../API";

let counter = 0;

export const squawkItemFields = `
    id
    deleted
    editableByUserIds
    aircraftId
    flightId
    legId
    createdAt
    createdByName
    title
    description
    companyDescription
    airworthinessAffected
    deferralDate
    deferralText
    releaseDate
    releaseAircraftFlightTime
    releaseName
    releaseLicenseNumber
    releaseText
    releaseSignature
    reviewedDate
    reviewedName
    reviewedId
    _version
`.trim();


export const squawkSyncerDefinition: DbSyncerUpDefinition = (squawk: Squawk) => {
    const c = ++counter;
    const [commandName, inputType] = !squawk._version
        ? ['createSquawk', 'CreateSquawkInput']
        : ['updateSquawk', 'UpdateSquawkInput'];

    const input = squawkToDb(squawk);
    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, squawkItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: squawkFromDb,
        queryName: 'getSquawk'
    }
};

export const squawkToDb = (squawk: Squawk): CreateSquawkInput => {
    return {
        id: squawk.id,
        deleted: squawk.deleted,
        aircraftId: squawk.aircraftId,
        flightId: squawk.flightId,
        legId: squawk.legId,
        createdAt: squawk.createdAt.toISOString(),
        createdByName: squawk.createdByName,
        description: squawk.description,
        airworthinessAffected: squawk.airworthinessAffected,
        reviewedDate: squawk.reviewedDate,
        reviewedName: squawk.reviewedName,
        reviewedId: squawk.reviewedId,
        releaseDate: squawk.releaseDate ? squawk.releaseDate.toISOString() : null,
        releaseAircraftFlightTime: squawk.releaseAircraftFlightTime,
        releaseName: squawk.releaseName,
        releaseLicenseNumber: squawk.releaseLicenseNumber,
        releaseText: squawk.releaseText,
        releaseSignature: squawk.releaseSignature ? JSON.stringify(squawk.releaseSignature) : null,
        _version: squawk._version,
    };
}


export const squawkFromDb = (input: CreateSquawkInput): Squawk => {
    return {
        id: input.id!,
        deleted: input.deleted,
        _version: input._version!,
        aircraftId: input.aircraftId,
        flightId: input.flightId || undefined,
        legId: input.legId || undefined,
        createdAt: new Date(input.createdAt!),
        createdByName: input.createdByName,
        description: input.description,
        airworthinessAffected: input.airworthinessAffected,
        reviewedDate: input.reviewedDate || null,
        reviewedName: input.reviewedName || null,
        reviewedId: input.reviewedId || null,
        editableByUserIds: input.editableByUserIds || [],
        releaseDate: input.releaseDate ? new Date(input.releaseDate) : null,
        releaseAircraftFlightTime: input.releaseAircraftFlightTime || null,
        releaseName: input.releaseName || null,
        releaseLicenseNumber: input.releaseLicenseNumber || null,
        releaseText: input.releaseText || null,
        releaseSignature: input.releaseSignature ? JSON.parse(input.releaseSignature) : null,
    }
};

