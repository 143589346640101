import Dexie from 'dexie';
import { API, graphqlOperation } from 'aws-amplify';
import { getDb } from './LocalDB';
import {
    DbSyncerUpCommand,
    DbSyncSubscription,
    SyncableTable,
    SyncItem,
    syncUpCommandsToMutation,
} from './syncHelpers';
import { dbSyncerResyncSubscriptions, dbSyncerUpdateSubscriptions, dbSyncerUpDefintions } from './syncSubscriptions';
import { store } from '../../wrappers/WithStore';
import { SYNC_CHANGE, SyncState } from '../../reducers/syncReducer';
import { logError } from '../../utils/ErrorLog/ErrorLog';
import { initReauthFlow } from '../../utils/Authentication/InitReauthFlow';
import { whenOnline } from '../util/whenOnline';

type QueueItem = {
    subscription: DbSyncSubscription;
    nextToken?: string;
};

const LOCAL_STORAGE_KEY_LAST_SYNC = 'alol_synctime';
const LOCAL_STORAGE_KEY_DB_VERSION = 'alol_syncversion';
const LOCAL_DB_MAJOR_VERSION = '1';

type CurrentSyncData = {
    startTime: number;
    promise: Promise<void>;
};
export class DbSyncer {
    initPromise: Promise<this>;
    lastSyncTime: number = parseInt(window.localStorage.getItem(LOCAL_STORAGE_KEY_LAST_SYNC) || '0', 10);
    timeout: number | null = null;
    syncInterval: number | null = null;
    currentSync: CurrentSyncData | null = null;

    constructor() {
        this.initPromise = this.init();
    }

    async init(): Promise<this> {
        if (window.localStorage.getItem(LOCAL_STORAGE_KEY_DB_VERSION) !== LOCAL_DB_MAJOR_VERSION) {
            await getDb().wipe();
            window.localStorage.setItem(LOCAL_STORAGE_KEY_DB_VERSION, LOCAL_DB_MAJOR_VERSION);
            window.localStorage.removeItem(LOCAL_STORAGE_KEY_LAST_SYNC);
            this.lastSyncTime = 0;
        }
        try {
            await this.sync();
        } catch (e) {
            console.error(e);
        }
        this.syncInterval = window.setInterval(() => this.sync(), 30000);
        return this;
    }

    async initComplete(): Promise<this> {
        return this.initPromise;
    }

    async markToSync(table: Dexie.Table, ...indices: string[]) {
        if (!indices.length) {
            return;
        }
        const tableName = table.name as SyncableTable;
        if (!dbSyncerUpDefintions.has(tableName)) {
            throw new Error('Trying to sync item with no definition: ' + tableName);
        }
        const syncItems: SyncItem[] = indices.map((itemId): SyncItem => {
            const id = tableName + ':' + itemId;
            return { id, itemId, tableName };
        });
        await getDb().syncItems.bulkPut(syncItems);
        this.updateSyncState({ setLengthUp: syncItems.length });
        if (!this.timeout) {
            this.timeout = window.setTimeout(() => {
                this.timeout = null;
                return this.sync();
            }, 1000);
        }
    }

    updateSyncState(params: {
        isInProgress?: boolean;
        lengthUpIncrement?: number;
        setLengthUp?: number;
        setLengthDown?: number;
        offline?: boolean;
    }) {
        const { isInProgress, lengthUpIncrement, setLengthUp, setLengthDown } = params;
        const syncState = store.getState().sync;
        const queueLength =
            setLengthUp !== undefined
                ? setLengthUp
                : lengthUpIncrement !== undefined
                ? syncState.queueLengthUp + lengthUpIncrement
                : syncState.queueLengthUp;
        const inProgress = isInProgress !== undefined ? isInProgress : syncState.inProgress;
        const queueLengthDown = setLengthDown !== undefined ? setLengthDown : syncState.queueLengthDown;
        const time = !inProgress && queueLengthDown === 0 && queueLength === 0 ? Date.now() : syncState.time;
        const newState: SyncState = {
            inProgress,
            queueLengthUp: queueLength,
            queueLengthDown: queueLengthDown,
            time,
            offline: params.offline || false,
        };
        store.dispatch({ type: SYNC_CHANGE, data: newState });
    }

    isStale(): boolean {
        return Date.now() - this.lastSyncTime > 30000;
    }

    shouldDoCompleteResync(): boolean {
        return Date.now() - this.lastSyncTime > 60 * 60000;
    }

    async wipe() {
        this.setLastSyncTime(0);
    }

    async wipeSync() {
        this.lastSyncTime = 0;
        await this.sync(true);
    }

    sync(force?: boolean): Promise<void> {
        const startTime = Date.now();
        if (this.currentSync && startTime - this.currentSync.startTime < 29000) {
            console.warn('Other sync in progress, aborting');
            return this.currentSync.promise;
        }
        const promise = new Promise<void>(async (resolve) => {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
                this.timeout = null;
            }
            // noinspection PointlessBooleanExpressionJS - for when navigator doesn't support onLine property and it is udefined
            if (navigator.onLine === false) {
                this.updateSyncState({ offline: true });
                await whenOnline();
            }
            await this.syncUp();
            this.updateSyncState({ isInProgress: true });
            await this.syncDown(force);
            this.updateSyncState({ isInProgress: false });
            this.currentSync = null;
            resolve();
        });
        this.currentSync = { startTime, promise };
        return this.currentSync.promise;
    }

    async syncUp() {
        const db = getDb();
        const syncItems = await db.syncItems.toCollection().toArray();
        if (!syncItems.length) {
            return;
        }
        this.updateSyncState({ isInProgress: true, setLengthUp: syncItems.length });
        const commands = (
            await Promise.all(
                syncItems.map(async (si) => {
                    try {
                        return await this.syncItemToCommand(si);
                    } catch (e: any) {
                        console.error(e);
                        logError(e.toString(), 'dbsyncer/verylow/1');
                        return null;
                    }
                })
            )
        ).filter((c) => c !== null) as DbSyncerUpCommand[];
        let remoteResponse: any = { data: {} };
        let validCommands: DbSyncerUpCommand[] = commands; // may be rewritten if some commands fail to start execution
        try {
            const commandResult = await this.executeSyncUpCommands(commands);
            remoteResponse = commandResult.result;
            validCommands = commandResult.commands;
        } catch (e: any) {
            if (e === 'No current user') {
                initReauthFlow();
                return;
            }
            if (e && e.code && e.code === 'NetworkError') {
                console.warn('DbSyncer: offline');
                return;
            }
            if (e && e.errors) {
                remoteResponse = e;
            } else {
                console.error(e);
                logError(e.toString(), 'dbsyncer/verylow/2');
                throw e;
            }
        } finally {
            let successfulCommands = 0;
            await Promise.all(
                validCommands.map(async (command): Promise<void> => {
                    const index = commands.indexOf(command);
                    const syncItem = syncItems[index];
                    if (!db[syncItem.tableName]) {
                        const message = `Trying to finish syncing with non-existing table name ${syncItem.tableName}`;
                        console.error(message);
                        logError(message, 'dbsyncer/low/1');
                        return;
                    }
                    let returned = remoteResponse.data && remoteResponse.data[command.mutationName];
                    if (!returned) {
                        if (remoteResponse.errors) {
                            // if the error is because local record has no _version information, attempt forcing the _version = 1
                            // because commonly this is caused when an update succeeds, but the returned data with version information
                            // is lost or discarded
                            const commandError = remoteResponse.errors.find(
                                (e: any) => e.path && e.path[0] === command.mutationName
                            );
                            const createNotReturned =
                                commandError &&
                                commandError.errorType === 'ConditionalCheckFailedException' &&
                                !command.input._version;
                            const updateNotReturned =
                                commandError && commandError.errorType === 'InternalFailure' && command.input._version;
                            if (createNotReturned || updateNotReturned) {
                                if (!command.appendOnly) {
                                    const maybeResult = await this.attemptRetryWithVersion(command, syncItem);
                                    if (maybeResult) {
                                        returned = maybeResult;
                                        remoteResponse.errors.splice(remoteResponse.errors.indexOf(commandError), 1);
                                        successfulCommands++;
                                    }
                                    // otherwise an error will be thrown from remoteResponse.error
                                } else {
                                    // if append-only, the item already exists but is not readable. So assume the item was
                                    // returned so that it will be deleted/processed in transaction.
                                    returned = command.input;
                                    remoteResponse.errors.splice(remoteResponse.errors.indexOf(commandError), 1);
                                    successfulCommands++;
                                }
                            }
                        } else {
                            const message = `Command result not found ${command.mutationName}`;
                            logError(message, 'dbsyncer/low/2');
                            throw new Error(message);
                        }
                    }
                    if (!returned) {
                        // retry was unsuccessful
                        return;
                    }
                    await db.transaction('rw', [db[syncItem.tableName], db.syncItems], async () => {
                        const localDataStructure = command.remoteToLocalTransform(returned);
                        const needsResync = command.getNeedsResync
                            ? command.getNeedsResync(returned, localDataStructure)
                            : false;
                        const putOrDeletePromise = returned.deleted
                            ? db[syncItem.tableName].delete(returned.id)
                            : db[syncItem.tableName].put(localDataStructure);
                        const executionPromises = [putOrDeletePromise as Promise<void>];
                        if (!needsResync) {
                            executionPromises.push(db.syncItems.delete(syncItem.id));
                        }
                        await Promise.all(executionPromises);
                        if (!needsResync) {
                            successfulCommands++;
                            this.updateSyncState({ isInProgress: true, lengthUpIncrement: -1 });
                        } else {
                            const message = `Item needs resync ${syncItem.tableName}; ${syncItem.itemId}`;
                            console.warn(message);
                            logError(message, 'dbsyncer/resync');
                        }
                    });
                })
            );
            const lengthUp = Math.max(commands.length - successfulCommands, 0);
            this.updateSyncState({ isInProgress: false, setLengthUp: lengthUp });
        }
        if (remoteResponse.errors && remoteResponse.errors.length) {
            const messages = getErrorMessagesFromSyncResult(remoteResponse);
            logError(messages, 'dbsyncer/high');
            throw remoteResponse;
        }
    }

    async syncItemToCommand(syncItem: SyncItem, forceSetVersion?: number): Promise<DbSyncerUpCommand> {
        const db = getDb();
        const tableName = syncItem.tableName;
        const item = await db[tableName]?.get(syncItem.itemId);
        if (!item) {
            throw new Error(`Error when syncing, object ${syncItem.itemId} no longer exist in table ${tableName}`);
        }
        if (forceSetVersion) {
            (item as any)._version = forceSetVersion;
        }
        const definition = dbSyncerUpDefintions.get(tableName);
        if (!definition) {
            throw new Error(`No syncer up-definition exists for table ${tableName}`);
        }
        return definition(item);
    }

    async executeSyncUpCommands(
        commands: DbSyncerUpCommand[]
    ): Promise<{ result: any; commands: DbSyncerUpCommand[] }> {
        const { query, parameters, validCommands } = await syncUpCommandsToMutation(commands, 'syncUp');
        const gqlo = graphqlOperation(query, parameters);
        console.log(gqlo);
        if (validCommands.length) {
            const result = await API.graphql(gqlo);
            return { result, commands: validCommands };
        } else {
            return {
                result: {
                    data: null,
                    errors: [{ message: 'No valid commands in batch', path: '-' }],
                },
                commands: validCommands,
            };
        }
    }

    async syncDown(force?: boolean) {
        if (!this.isStale() && !force) {
            console.info('sync is not stale enough');
            return;
        }

        const dateStart = Date.now();
        const [subscriptionListToUse, lastSync] = this.shouldDoCompleteResync()
            ? [dbSyncerResyncSubscriptions, 0]
            : [dbSyncerUpdateSubscriptions, this.lastSyncTime];

        console.info(
            'Sync start, ref',
            new Date(this.lastSyncTime),
            'using ',
            subscriptionListToUse === dbSyncerResyncSubscriptions ? 'complete' : 'partial',
            'resync'
        );
        try {
            let queue: QueueItem[] = subscriptionListToUse.map((subscription) => ({
                subscription,
            }));
            const promiseList: Promise<void>[] = [];
            this.updateSyncState({ isInProgress: true, setLengthDown: queue.length });
            while (queue.length) {
                const queries = queue.map((queue) => queue.subscription.getQuery(queue.nextToken));
                const query = `query Sync($lastSync: AWSTimestamp){${queries.join(',')}}`;
                const initialResult: any = await API.graphql(graphqlOperation(query, { lastSync }));
                for (const queueItem of queue) {
                    const { nextToken, items } = initialResult.data[queueItem.subscription.key];
                    if (items.length) {
                        promiseList.push(queueItem.subscription.onNewItems(items));
                    }
                    queueItem.nextToken = nextToken || undefined;
                }
                queue = queue.filter((q) => q.nextToken);
                this.updateSyncState({ setLengthDown: queue.length });
            }
            await Promise.all(promiseList);
            console.info('Sync complete');
            this.setLastSyncTime(dateStart);
        } catch (e: any) {
            if (e === 'No current user') {
                initReauthFlow();
                return;
            }
            if (e && e.code && e.code === 'NetworkError') {
                console.warn('DbSyncer: offline');
                return;
            }
            console.error('Error in DbSyncer/Down: ', e);
            logError(getErrorMessagesFromSyncResult(e), 'dbsyncer/down');
        }
    }

    private async attemptRetryWithVersion(command: DbSyncerUpCommand, syncItem: SyncItem): Promise<any | false> {
        try {
            const query = `query getVersion {${command.queryName}(id:"${syncItem.itemId}") { _version}}`;
            const result = (await API.graphql(graphqlOperation(query))) as any;
            const resultData = result.data && result.data[command.queryName];
            if (resultData === null) {
                const message = `Retry with force-version failed for ${syncItem.tableName} - no result for record`;
                console.error(message);
                logError(message, 'dbsyncer/retry/1');
            }
            const existingVersion = resultData._version;
            const retryCommand = await this.syncItemToCommand(syncItem, existingVersion || 1);
            const retryResult = await this.executeSyncUpCommands([retryCommand]);
            const returned = retryResult.result.data && retryResult.result.data[retryCommand.mutationName];
            if (!returned) {
                console.log(retryResult);
                const message = getErrorMessagesFromSyncResult(retryResult.result);
                console.error(
                    `Retry with force-version failed for ${syncItem.tableName} ${syncItem.itemId}\n\n${message}`
                );
                console.error(retryResult, retryCommand);
                logError(message, 'dbsyncer/retry/2');
            } else {
                console.warn(`Had to force-set version for ${syncItem.tableName} ${syncItem.itemId}`);
                return returned;
            }
        } catch (e: any) {
            // log only, as this is a non-critical retry attempt
            if (e.errors) {
                const message = getErrorMessagesFromSyncResult(e);
                console.error(
                    `Retry with force-version failed for ${syncItem.tableName} ${syncItem.itemId}\n\n${message}`
                );
                logError(message, 'dbsyncer/retry-low');
            } else {
                console.error(e);
                logError(e.toString(), 'dbsyncer/retry-verylow');
            }
        }
        return false;
    }

    private setLastSyncTime(time: number) {
        this.lastSyncTime = time;
        window.localStorage.setItem(LOCAL_STORAGE_KEY_LAST_SYNC, this.lastSyncTime.toString());
    }
}

let dbSyncer: DbSyncer | null;

export const getDbSyncer = (): DbSyncer => {
    if (!dbSyncer) {
        dbSyncer = new DbSyncer();
    }
    return dbSyncer;
};

export const wipeDbSyncer = () => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_LAST_SYNC, '0');
};

const getErrorMessagesFromSyncResult = function (syncResult: any): string {
    if (syncResult.errors) {
        return syncResult.errors.map((e: any) => e.message + '@' + e.path).join('\n----\n');
    } else if (typeof syncResult === 'object') {
        try {
            return JSON.stringify(syncResult);
        } catch (e) {
            return syncResult.toString();
        }
    } else {
        return syncResult.toString();
    }
};
