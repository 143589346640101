import { MainMenuActionTypes, mainMenuInitialState, MainMenuReducer, MainMenuState } from './MainMenu/reducer';
import { Action } from 'redux';
import { tlbUserInitialState, TlbUserReducer, TlbUserState } from './reducers/tlbUserReducer';
import { syncInitialState, SyncReducer, SyncState } from './reducers/syncReducer';
import { cognitoUserInitialState, CognitoUserReducer, CognitoUserState } from './reducers/cognitoUserReducer';
import {
    complianceModeInitialState,
    ComplianceModeReducer,
    ComplianceModeState,
} from './Admin/Utils/ComplianceMode/ComplianceModeReducer';
import {
    supplementalDataSyncInitialState,
    SupplementalDataSyncReducer,
    SupplementalDataSyncState,
} from './reducers/supplementalDataSyncReducer';

export type RootState = {
    menu: MainMenuState;
    user: TlbUserState;
    cognitoUser: CognitoUserState;
    sync: SyncState;
    supplementalDataSync: SupplementalDataSyncState;
    complianceMode: ComplianceModeState;
};
export const initialState: RootState = {
    menu: mainMenuInitialState,
    user: tlbUserInitialState,
    cognitoUser: cognitoUserInitialState,
    sync: syncInitialState,
    supplementalDataSync: supplementalDataSyncInitialState,
    complianceMode: complianceModeInitialState,
};

export type RootActionTypes = Action | MainMenuActionTypes;

export const rootReducer = (state: RootState = initialState, action: RootActionTypes): RootState => {
    return {
        menu: MainMenuReducer(state.menu, action),
        user: TlbUserReducer(state.user, action),
        cognitoUser: CognitoUserReducer(state.cognitoUser, action),
        sync: SyncReducer(state.sync, action),
        supplementalDataSync: SupplementalDataSyncReducer(state.supplementalDataSync, action),
        complianceMode: ComplianceModeReducer(state.complianceMode, action),
    };
};
