import { useSelector } from 'react-redux';
import { RootState } from '../rootReducer';

export type SyncState = {
    inProgress: boolean;
    queueLengthUp: number;
    queueLengthDown: number;
    time: number | null;
    offline: boolean;
};

const LOCAL_STORAGE_KEY = 'alol_syncstate';

let maybeStoredSyncTime: number | null = null;
try {
    const ls = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (ls) {
        maybeStoredSyncTime = parseInt(ls, 10);
    }
} catch (e) {
    console.warn('When parsing stored sync state', e);
}

export const syncInitialState: SyncState = {
    inProgress: false,
    queueLengthUp: 0,
    queueLengthDown: 0,
    time: maybeStoredSyncTime ? maybeStoredSyncTime : null,
    offline: false,
};

export const SYNC_CHANGE = 'SYNC_CHANGE';
export type SyncActionTypes = {
    type: typeof SYNC_CHANGE;
    data: SyncState;
};

export const SyncReducer = (
    state: SyncState | undefined = syncInitialState,
    action: SyncActionTypes | any = undefined
): SyncState => {
    switch (action.type) {
        case SYNC_CHANGE:
            window.localStorage.setItem(LOCAL_STORAGE_KEY, action.data.time);
            return action.data;
        default:
            return state;
    }
};

export function useSyncState() {
    return useSelector<RootState, SyncState>((state) => state.sync);
}
