import { getDb } from './LocalDB/LocalDB';
import { AircraftTag } from './Aircrafts/types';
import { Flight } from './Flights/Flight';
import { getFlightsForAircraft } from './Flights/FlightsDatasource';
import { SquawkAndDeferrals } from './Squawks/types';
import { getSquawksForAircraft } from './Squawks/SquawksDatasource';
import { WithLastCountableChange } from './FlightLegs/FlightLeg';
import { getLastCountableChangeForAircraft } from './CountableChanges/CountableChangeDatasource';
import { DbCreated, DbSaveable } from './LocalDB/types';
import { AircraftCountableType, AircraftUpliftableType } from './CountableChanges/types';
import { CreateAircraftDocumentInput, CreateAircraftInput } from '../API';
import { getAircraftDocumentsForAircraft } from './AircraftDocuments/AircraftDocuments';
import { sanitizeId } from './datasourceUtils';
import { aircraftFromDb, aircraftItemFields } from './LocalDB/Transformers/aircrafts';
import { API, graphqlOperation } from 'aws-amplify';
import { ReleaseToService } from './ReleaseToService/types';
import { getReleaseToServicesForAircraft } from './ReleaseToService/ReleaseToServiceDatasource';
import { AircraftMaintenanceLimit } from '../Admin/AircraftMaintenanceLimits/AircraftMaintenanceLimit';
import { AircraftMaintenanceLimitExtension } from '../Admin/AircraftMaintenanceLimitExtensions/AircraftMaintenanceLimitExtension';
import { FlightOps } from './FlightLegs/FlightOpsTypes';
import { useEffect, useState } from 'react';
import { PerformanceProfile } from '../Admin/Scheduling/model/PerformanceProfileTypes';

export type Aircraft = {
    id: string;
    registration: string;
    friendlyType: string;
    serialNumber: string;
    imageUrl?: string;
    countableTypes: AircraftCountableType[];
    upliftableTypes: AircraftUpliftableType[];
    tags: AircraftTag[];
    userId: string | undefined;
    aircraftTypeId: string | null;
    performanceProfile: PerformanceProfile | null;
    callsign: string | null;
} & DbSaveable;

export type AircraftForPicker = Pick<Aircraft, 'id' | 'registration' | 'friendlyType' | 'serialNumber' | 'imageUrl'>;

export type AircraftForFlight = Aircraft &
    WithLastCountableChange & {
        flights: Flight[];
        squawks: SquawkAndDeferrals[];
        releases: ReleaseToService[];
        aircraftDocuments: CreateAircraftDocumentInput[];
        aircraftMaintenanceLimits: AircraftMaintenanceLimit[];
        aircraftMaintenanceLimitExtensions: AircraftMaintenanceLimitExtension[];
    };

export const getAircraftsForPicker = async (): Promise<AircraftForPicker[]> => {
    return await getDb().aircrafts.toArray();
};

export type GetAircraftParams = [string];
export const getAircraft = async (aircraftId: GetAircraftParams[0]): Promise<Aircraft | null> => {
    return (await getDb().aircrafts.get(aircraftId)) || null;
};

const getAircraftMaintenanceLimits = async (aircraftId: string): Promise<AircraftMaintenanceLimit[]> => {
    return await getDb().aircraftMaintenanceLimits.where({ aircraftId }).toArray();
};

const getAircraftMaintenanceLimitExtensionsForLimits = async (
    maintenanceLimitIds: string[]
): Promise<AircraftMaintenanceLimitExtension[]> => {
    if (maintenanceLimitIds.length === 0) {
        return [];
    }
    return await getDb()
        .aircraftMaintenanceLimitExtensions.where('maintenanceLimitId')
        .anyOf(maintenanceLimitIds)
        .toArray();
};

export type GetAircraftForFlightParams = [string];
export const getAircraftForFlight = async (
    aircraftId: GetAircraftForFlightParams[0]
): Promise<AircraftForFlight | null> => {
    const aircraft = await getAircraft(aircraftId);
    if (aircraft === null) {
        return null;
    }
    const [flights, squawks, lastCountableChange, aircraftMaintenanceLimits, releases] = await Promise.all([
        getFlightsForAircraft(aircraftId),
        getSquawksForAircraft(aircraftId),
        getLastCountableChangeForAircraft(aircraftId),
        getAircraftMaintenanceLimits(aircraftId),
        getReleaseToServicesForAircraft(aircraftId),
    ]);
    const maintenanceLimitIds = aircraftMaintenanceLimits.map((aml) => aml.id);
    const [aircraftDocuments, aircraftMaintenanceLimitExtensions] = await Promise.all([
        getAircraftDocumentsForAircraft(aircraftId),
        await getAircraftMaintenanceLimitExtensionsForLimits(maintenanceLimitIds),
    ]);
    return {
        ...aircraft,
        flights: flights,
        squawks: squawks,
        lastCountableChange: lastCountableChange,
        aircraftDocuments: aircraftDocuments,
        aircraftMaintenanceLimits: aircraftMaintenanceLimits,
        aircraftMaintenanceLimitExtensions,
        releases: releases,
    };
};

export const getAircraftsByIdLocalAndOnline = async (aircraftIds: string[]): Promise<Aircraft[]> => {
    const offlineAircrafts = (await getAircraftsById(aircraftIds).then((f) => f.filter(Boolean))) as Aircraft[];
    const offlineAircraftIds = new Set(offlineAircrafts.map((a) => a.id));
    const aircraftIdsToRequest = aircraftIds.filter((acId) => !offlineAircraftIds.has(acId));
    const onlineAircrafts = await getAircraftsByIdOnline(aircraftIdsToRequest);
    return [...offlineAircrafts, ...onlineAircrafts];
};

export const getAircraftsById = async (aircraftIds: string[]): Promise<(Aircraft | null)[]> => {
    const db = getDb();
    return db.aircrafts.bulkGet(aircraftIds);
};

export const getAircraftsByIdOnline = async (aircraftIds: string[]): Promise<Aircraft[]> => {
    if (aircraftIds.length === 0) {
        return [];
    }
    const aircraftsQuery = `query getFlights {
        ${aircraftIds.map(
            (aircraftId, index) => `
        getAircraft${index}: getAircraft(id: "${sanitizeId(aircraftId)}") {
            ${aircraftItemFields}
        }
        `
        )}
    }`;
    const gqlo = graphqlOperation(aircraftsQuery);
    const aircraftsResult = (await API.graphql(gqlo)) as {
        data: { [key: string]: (CreateAircraftInput & DbCreated) | null };
    };
    return (Object.values(aircraftsResult.data).filter(Boolean) as (CreateAircraftInput & DbCreated)[]).map(
        aircraftFromDb
    );
};

export const getAircraftForUserId = async (userId: string): Promise<Aircraft | undefined> => {
    const ac = await getDb().aircrafts.where({ userId }).toArray();
    return ac.shift();
};

export const MCC_OPTIONAL = 'optional';
export type aircraftMccOptions = true | false | typeof MCC_OPTIONAL;
export const getAircraftIsMcc = (aircraft: Aircraft, ops: FlightOps | undefined): aircraftMccOptions => {
    if (aircraft.tags.includes(AircraftTag.multiPilot)) {
        return true;
    }
    if (aircraft.tags.includes(AircraftTag.mutiPilotCat)) {
        if (ops === FlightOps.CAT) {
            return true;
        } else {
            return MCC_OPTIONAL;
        }
    }
    return false;
};

export function useAircraft(aircraftId: string): Aircraft | null | undefined {
    const [aircraft, setAircraft] = useState<Aircraft | null | undefined>(undefined);
    useEffect(() => {
        let cancelled = false;
        getAircraft(aircraftId).then((aircraft) => {
            if (!cancelled) {
                setAircraft(aircraft);
            }
        });
        return () => {
            cancelled = true;
        };
    }, [aircraftId]);
    return aircraft;
}
