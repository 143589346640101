import { sign as nsign } from 'tweetnacl';
import * as uuid from 'uuid';
import { CreateSigningKeyChangeInput } from '../../API';
import { putSigningChange } from '../../Datasource/SigningKeyChanges/SigningKeyChangeDatasource';
import { WithId } from '../../Datasource/LocalDB/types';

interface SignatureLocalStorage {
    deviceSecretKeyString: string;
    deviceKeyUuid: string;
    temporarySecretKeyString: string;
    temporaryKeyTimestamp: number;
    temporaryKeyUuid: string;
}

interface SignatureOperationalStorage extends SignatureLocalStorage {
    deviceSecretKey: Uint8Array;
    temporarySecretKey: Uint8Array;
    devicePublicKey: Uint8Array;
    temporaryPublicKey: Uint8Array;
}

type TemporaryKeyGenerationResult = {
    publicKey: Uint8Array;
    signature: Uint8Array;
    signatureKeyUuid: string;
}

export type Signature = {
    deviceSignature: string;
    temporarySignature: string;
    deviceKeyUuid: string;
    temporaryKeyUuid: string;
};

const LOCAL_STORAGE_KEY = 'alol_signature';
const encoder = new TextEncoder();

export const sign = (input: string): Signature => {
    const inputUint = stringToUint(input);
    regenerateTemporaryKey();
    const operational = getOperationalStorage();
    const deviceSignature = nsign.detached(inputUint, operational.deviceSecretKey);
    const temporarySignature = nsign.detached(inputUint, operational.temporarySecretKey);
    const {deviceKeyUuid, temporaryKeyUuid} = operational;
    return {
        deviceSignature: uintToBase64(deviceSignature),
        temporarySignature: uintToBase64(temporarySignature),
        deviceKeyUuid,
        temporaryKeyUuid
    };
};

// init-related functions
const {getOperationalStorage, setOperationalStorage} = (() => {
    const generateNewKeys = (): SignatureOperationalStorage => {
        const deviceKey = nsign.keyPair();
        const deviceKeyUuid = uuid.v4();
        const temporaryKey = nsign.keyPair();
        const temporaryKeyUuid = uuid.v4();
        const data: SignatureOperationalStorage = {
            deviceKeyUuid,
            deviceSecretKeyString: uintToBase64(deviceKey.secretKey),
            temporarySecretKeyString: uintToBase64(temporaryKey.secretKey),
            deviceSecretKey: deviceKey.secretKey,
            devicePublicKey: deviceKey.publicKey,
            temporarySecretKey: temporaryKey.secretKey,
            temporaryPublicKey: temporaryKey.publicKey,
            temporaryKeyTimestamp: Date.now(),
            temporaryKeyUuid
        };
        persistNewKeys(data);
        return data;
    };

    const persistNewKeys = (keys: SignatureOperationalStorage) => {
        updateLocalStorage(keys);
        const signature = createTemporaryKeySignature(keys.temporaryPublicKey, keys.deviceSecretKey, keys.deviceKeyUuid);
        persistToDb(keys, signature);
    };


    const init = (): SignatureOperationalStorage => {
        const maybeItem = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!maybeItem) {
            return generateNewKeys();
        }
        try {
            const json = JSON.parse(maybeItem);
            if (validateJson(json)) {
                return localStorageToOperational(json);
            } else {
                return generateNewKeys();
            }
        } catch (e) {
            console.error(e);
            return generateNewKeys();
        }
    };

    const localStorageToOperational = function (json: SignatureLocalStorage) {
        const deviceSecretKey = base64ToUint(json.deviceSecretKeyString);
        const temporarySecretKey = base64ToUint(json.temporarySecretKeyString);
        return {
            ...json,
            deviceSecretKey,
            temporarySecretKey,
            temporaryPublicKey: nsign.keyPair.fromSecretKey(temporarySecretKey).publicKey,
            devicePublicKey: nsign.keyPair.fromSecretKey(deviceSecretKey).publicKey,
        };
    };


    let operational: SignatureOperationalStorage | undefined;
    const getOperationalStorage = (): SignatureOperationalStorage => {
        if (!operational) {
            operational = init()
        }
        return operational;
    };
    const setOperationalStorage = (data: Partial<SignatureOperationalStorage>) => {
        operational = {
            ...getOperationalStorage(),
            ...data
        };
        updateLocalStorage(operational);
        return operational;
    };


    const validateJson = (json: any): json is SignatureLocalStorage => {
        const keys = [
            'deviceSecretKey',
            'deviceKeyUuid',
            'temporarySecretKey',
            'temporaryKeyTimestamp',
            'temporaryKeyUuid'
        ];
        return keys.some(key => !json[key]);
    };

    const updateLocalStorage = function (keys: SignatureOperationalStorage) {
        const {
            deviceKeyUuid,
            temporaryKeyTimestamp,
            temporaryKeyUuid,
            deviceSecretKeyString,
            temporarySecretKeyString,
        } = keys;
        const localData: SignatureLocalStorage = {
            deviceKeyUuid,
            deviceSecretKeyString,
            temporarySecretKeyString,
            temporaryKeyTimestamp,
            temporaryKeyUuid
        };
        const json = JSON.stringify(localData);
        localStorage.setItem(LOCAL_STORAGE_KEY, json);
    };

    return {getOperationalStorage, setOperationalStorage};
})();

const createTemporaryKeySignature = (temporaryPublicKey: Uint8Array, oldSecretKey: Uint8Array, oldKeyUuid: string): TemporaryKeyGenerationResult => {
    const signature = nsign.detached(temporaryPublicKey, oldSecretKey);
    return {
        publicKey: temporaryPublicKey,
        signature,
        signatureKeyUuid: oldKeyUuid
    }
};

const regenerateTemporaryKey = () => {
    const newPair = nsign.keyPair();
    const keyUuid = uuid.v4();
    const operational = getOperationalStorage();
    const oldKey = operational.temporarySecretKey;
    const oldUuid = operational.temporaryKeyUuid;
    const signature = createTemporaryKeySignature(newPair.publicKey, oldKey, oldUuid);
    const newOperational = setOperationalStorage({
        temporarySecretKeyString: uintToBase64(newPair.secretKey),
        temporaryKeyTimestamp: Date.now(),
        temporaryKeyUuid: keyUuid,
        temporarySecretKey: newPair.secretKey,
        temporaryPublicKey: newPair.publicKey
    });
    persistToDb(newOperational, signature);
};

const persistToDb = (keys: SignatureOperationalStorage, signature: TemporaryKeyGenerationResult) => {
    const keyChange: CreateSigningKeyChangeInput & WithId = {
        id: uuid.v4(),
        devicePublicKey: uintToBase64(keys.devicePublicKey),
        deviceKeyId: keys.deviceKeyUuid,
        temporaryPublicKey: uintToBase64(keys.temporaryPublicKey),
        temporaryKeyId: keys.temporaryKeyUuid,
        temporaryKeySignature: uintToBase64(signature.signature),
        signedByKeyId: signature.signatureKeyUuid,
        createdAt: new Date().toISOString()
    };
    putSigningChange(keyChange);
};

export const base64ToUint = (input: string) => {
    const ints = atob(input)
        .split("")
        .map(c => c.charCodeAt(0));
    return new Uint8Array(ints);
};

export const uintToBase64 = (input: Uint8Array) => {
    return btoa(String.fromCharCode(...Array.from(input)));
};

export const stringToUint = (input: string) => {
    return encoder.encode(input);
};
