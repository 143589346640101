import { isNone } from './maybe';

export * from './maybe';
export * from './arrayUtils';
export * from './numbers';
export * from './colors';
export * from './objectKeys';
export * from './string';
export * from './format';
export * as uuid from 'uuid';

export function isHtmlEmpty(html: string | undefined) {
    return !html?.replace(/<[^>]*>/g, '').trim();
}

export function sleepAsync(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function jsonParseSafe<T>(str: string | undefined | null, fallback: T): T {
    try {
        if (isNone(str)) {
            return fallback;
        } else {
            return JSON.parse(str ?? '');
        }
    } catch {
        return fallback;
    }
}
