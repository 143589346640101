import { getDb } from '../LocalDB/LocalDB';
import { CreateLogbookRecordInput } from '../../API';
import { WithId } from '../LocalDB/types';
import { getDbSyncer } from '../LocalDB/DbSyncer';

export async function getLogbookRecordsByLegId(legId: string): Promise<(CreateLogbookRecordInput & WithId)[]> {
    return await getDb().logbookRecords.where({legId}).toArray();
}

export async function putLogbookRecord(record: CreateLogbookRecordInput & WithId) {
    const db = getDb();
    await Promise.all([
        db.logbookRecords.put(record),
        getDbSyncer().markToSync(db.logbookRecords, record.id)
    ]);
}
