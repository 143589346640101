export type DbSyncSubscription = {
    getQuery: (nextToken?: string) => string;
    key: string;
    onNewItems: (item: any[]) => Promise<void>;
}

export type WrappedHandler<T extends {}> = ((items: T[]) => Promise<void>) & { start: () => Promise<void> };
export const wrapHandlerWithAccumulator = <T extends {}>(handler: (items: T[]) => Promise<void>): WrappedHandler<T> => {
    const accumulator: T[] = [];
    let resolver: (() => void);
    let rejecter: (params: any) => void;
    let promise = new Promise<void>((resolve, reject) => {
        resolver = resolve;
        rejecter = reject;
    });
    const wrap = (items: T[]) => {
        accumulator.push(...items);
        return promise;
    };
    wrap.start = async (): Promise<void> => {
        try {
            if (accumulator.length) {
                await handler(accumulator);
            }
            resolver();
        } catch (e) {
            rejecter(e);
            throw e;
        }

    };

    return wrap;
};

export type SyncableTable = 'aircrafts'
    | 'flights'
    | 'legs'
    | 'documents'
    | 'countableChanges'
    | 'squawks'
    | 'squawkDeferrals'
    | 'releaseToServices'
    | 'contacts'
    | 'dutyItems'
    | 'signingKeyChanges'
    | 'signedData'
    | 'logbookRecords'
    | 'aircraftMaintenanceLimitExtensions';

export type SyncItem = {
    id: string;
    tableName: SyncableTable;
    itemId: string;
}

export const getStandardSyncerFields = function (commandName: string, c: number, returnFields: string) {
    const mutationName = `${commandName}${c}`;
    const inputKey = `${commandName}Input${c}`;
    const query = `
        ${mutationName}: ${commandName}(input: $${inputKey}) {
            ${returnFields}
        }
    `;
    return {mutationName, inputKey, query};
};

export type DbSyncerUpCommand = {
    query: string;
    input: any | (() => Promise<any>);
    inputKey: string;
    inputType: string;
    mutationName: string;
    remoteToLocalTransform: (input: any) => any;
    getNeedsResync?: (remote: any, local: any) => boolean;
    queryName: string;
    appendOnly?: boolean;
};

export type DbSyncerUpDefinition = (input: any) => DbSyncerUpCommand;

export const syncUpCommandsToMutation = async (commands: DbSyncerUpCommand[], mutationName: string): Promise<{ query: string, parameters: any, validCommands: DbSyncerUpCommand[] }> => {
    const inputPairs: string[] = [];
    const mutations: string[] = [];
    const parameters: any = {};
    const validCommands: DbSyncerUpCommand[] = [];
    await Promise.all(commands.map(async c => {
        try {
            parameters[c.inputKey] = typeof c.input === "function"
                ? await c.input()
                : c.input;
            inputPairs.push(`$${c.inputKey}: ${c.inputType}!`);
            mutations.push(c.query);
            validCommands.push(c);
        } catch (e) {
            console.error(e);
        }
    }));

    const query = `
            mutation ${mutationName}(
                ${inputPairs.join('\n')}
            ) {
                ${mutations.join("\n")}
            }
        `;
    return {query, parameters, validCommands};
};
