import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { API } from 'aws-amplify';
import awsconfig from './aws-exports';
import moment from 'moment';

import WithStore from './wrappers/WithStore';
import AppSelector from './AppSelector';
import WithAuthenticatedUser from './wrappers/WithAuthenticatedUser';

Amplify.configure(awsconfig);
API.configure(awsconfig);

moment.updateLocale('en', {
    week: {
        dow: 1, // Set the First day of week to Monday
    },
});

(ReactDOM as any).createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <WithStore>
            <WithAuthenticatedUser>
                <AppSelector />
            </WithAuthenticatedUser>
        </WithStore>
    </React.StrictMode>
);
export { parsePerformanceProfileJson } from './Admin/Scheduling/model/PerformanceProfileTypes';
export { PERF_PROFILE_STRING_KEYS } from './Admin/Scheduling/model/PerformanceProfileTypes';
