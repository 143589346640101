import { API, graphqlOperation } from "@aws-amplify/api";
import { loadMoreNotSupported, Resource, wrapPromise } from "../../Datasource/Resource";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { QueryFactory, QueryFactory2, QueryNextLoadOptions, QueryResult } from './QueryFactory';

const noop = () => {
    console.error("Refreshing data is not supported on getData. Use useAdminResource if you need refresh functionality.");
    return null;
};

export const getData = <RT>(queryFactory: QueryFactory<RT>, nextToken?: string): Resource<QueryResult<RT>> => {
    const response = executeQuery<RT>(queryFactory, nextToken);
    return wrapPromise(response, noop, loadMoreNotSupported, 0, 0, 0);
};

export const executeQuery = async <RT>(queryFactory: QueryFactory<RT>, nextToken?: string): Promise<QueryResult<RT>> => {
    const query = queryFactory(nextToken);
    return (API.graphql(graphqlOperation(query))) as unknown as Promise<QueryResult<RT>>;
};

export const executeQuery2 = <T, V extends object, O = any>(queryFactory: QueryFactory2<T, V, O>, queryFactoryVariables?: V, options?: O): Promise<QueryResult<T>> => {
    const {query, variables, postProcess} = queryFactory(queryFactoryVariables, options);
    if (query === null) {
        return Promise.resolve({
            data: {} as T,
            errors: null
        });
    }
    const promise = API.graphql(({query, variables})) as unknown as Promise<QueryResult<T>>;
    if (!postProcess) {
        return promise;
    } else {
        return promise.then(postProcess);
    }
};

export const executeQuery2WithRepetitions = async <T, V extends object, O = any>(queryFactory: QueryFactory2<T, V, O>, queryFactoryVariables: V, options?: O, nextLoadOptions?: QueryNextLoadOptions<T, V>): Promise<QueryResult<T>> => {
    let variables = queryFactoryVariables;
    let output: QueryResult<T> | undefined = undefined;
    let loops = 0;
    do {
        loops += 1;
        const response = await executeQuery2(queryFactory, variables, options);
        if (nextLoadOptions) {
            const maybeNextVariables = nextLoadOptions.getNextQueryVariables(response, variables);
            output = output
                ? nextLoadOptions.mergeResult(output, response)
                : response;
            if (!maybeNextVariables) {
                return output;
            }
            variables = maybeNextVariables;
        } else {
            return response;
        }
    } while (loops < 100);
    throw new Error('Max limit for executeQuery2WithRepetition reached');
};

export const useAdminResource = <T, V extends object, O = any>(query: QueryFactory2<T, V, O>, variablesFactory: [() => V, any[]], options?: O, nextLoadOptions?: QueryNextLoadOptions<T, V>): Resource<QueryResult<T>> => {
    /* eslint-disable  react-hooks/exhaustive-deps */
    const variables = useMemo(variablesFactory[0], variablesFactory[1]);
    /* eslint-enable  react-hooks/exhaustive-deps */
    const [promise, setPromise] = useState<Promise<QueryResult<T>> | null>(null);
    const [refreshId, setRefreshId] = useState<number>(0);
    const promiseFactory = useCallback(() => {
        const promise = executeQuery2(query, variables, options);
        setPromise(promise);
    }, [query, variables, options, setPromise]);
    const onRefresh = useCallback(() => {
        promiseFactory();
        setRefreshId(rId => rId + 1);
    }, [promiseFactory]);
    const onMore = useCallback(async (): Promise<boolean> => {
        if (!nextLoadOptions) {
            throw new Error('Query did not supply nextLoad parameters');
        }
        if (!promise) {
            throw new Error('Request not yet initialized');
        }
        const data = await promise;
        const nextQueryVariables = nextLoadOptions.getNextQueryVariables(data, variables);
        if (nextQueryVariables !== null) {
            const newPromise = executeQuery2(query, nextQueryVariables, options)
                .then(newData => nextLoadOptions!.mergeResult(data, newData));
            setRefreshId(rId => rId + 1);
            setPromise(newPromise);
            return true;
        } else {
            return false;
        }
    }, [promise, nextLoadOptions, setRefreshId, setPromise, query, options, variables]);

    useEffect(() => {
        onRefresh();
    }, [onRefresh]);

    const neverPromise = useMemo(() => new Promise<QueryResult<T>>(() => null), []);
    const [resource, setResource] = useState<Resource<QueryResult<T>>>(wrapPromise(neverPromise, onRefresh, onMore, -1, 0, refreshId));
    useEffect(() => {
        let cancelled = false;
        if (promise !== null) {
            promise.then(() => {
                if (cancelled) {
                    return;
                }
                if (resource.refreshId !== refreshId) {
                    if (resource.promise !== promise) {
                        setResource(wrapPromise(promise, onRefresh, onMore, 1, 1, refreshId))
                    }
                }
            });
            return () => {
                cancelled = true;
            }
        }
    }, [promise, onRefresh, onMore, refreshId, resource, setResource]);
    useEffect(() => {
        if (nextLoadOptions && nextLoadOptions.autoload && promise !== null) {
            onMore();
        }
    }, [nextLoadOptions, promise, onMore]);
    return resource;
};

export const useMappedResource = <S, T>(source: Resource<S>, mapper: (source: S) => T): Resource<T> => {
    return useMemo(() => {
        let mapped: T | null = null;
        let mappedRefreshId: number | null = null;
        return {
            ...source,
            read(): T {
                if (!mapped || mappedRefreshId !== source.refreshId) {
                    const original = source.read();
                    mapped = mapper(original);
                    mappedRefreshId = source.refreshId;
                }
                return mapped;
            },
            readMaxQuality(): T {
                return mapper(source.readMaxQuality());
            },
            get promise() {
                return source.promise.then(mapper);
            },
        }
    }, [source, mapper]);
};
