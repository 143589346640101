import BEMHelper from "react-bem-helper";
import '../sharedStyles/page.sass'
import '../sharedStyles/form.sass'

export type BEMObject = BEMHelper<{ className: string }>
export type BEMModifiers = { [key: string]: boolean } | string[] | string;

const BEM = (name: string): BEMObject => {
    return new BEMHelper({
        name
    });
};

export const mergeClasses = (...classes: ({ className: string } | string)[]) => {
    return {
        className: classes.map(c => typeof c === 'string' ? c : c.className).join(' ')
    };
};
export default BEM;

export const pageClasses = BEM("page");
export const formClasses = BEM("form");
export const formRowClass = formClasses('fieldlike', undefined, 'row');
