import { CreateLogbookRecordInput } from '../../API';
import { LogbookInstrumentApproach } from './LogbookInstrumentApproach';
import { FinishedFlightLeg } from '../FlightLegs/FlightLeg';

type OmittedFields =
    'id'
    | 'date'
    | 'contactId'
    | 'crewId'
    | 'deleted'
    | 'legId'
    | 'departurePlace'
    | 'departureTime'
    | 'arrivalPlace'
    | 'arrivalTime'
    | 'aircraftMake'
    | 'aircraftRegistration'
    | 'airplaneSeMeMcc'
    | 'picName'
    | 'picTime'
    | 'copilotTime'
    | 'dualTime'
    | 'instructorTime'
    | 'isFstd'
    | 'instrumentApproachDetails'
    | '_version';

export type InLegLogbook = Omit<CreateLogbookRecordInput, OmittedFields> & {
    instrumentApproachDetails: LogbookInstrumentApproach[];
}

export const createEmptyInLegLogbook = (): InLegLogbook => ({
    totalFlightTime: null,
    landingsTotal: null,
    landingsNight: null,
    nightTime: null,
    ifrTime: null,
    remarks: null,
    crossCountryTime: null,
    instrumentActualTime: null,
    instrumentSimulatedTime: null,
    instrumentApproachDetails: [],
    holdings: null,
});

export function inLegLogbookToRecord(inLeg: InLegLogbook, leg: FinishedFlightLeg): Omit<CreateLogbookRecordInput, 'id' | 'contactId' | 'crewId'> {
    return {
        date: leg.timeOut.toISOString(),
        deleted: false,
        legId: leg.id,
        editableByUserIds: inLeg.editableByUserIds
    }
}
