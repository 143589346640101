import { ContactUser, User } from './User';
import { getDb } from '../LocalDB/LocalDB';
import { getCrewableContact, WithCrewableUserFields } from '../Contacts/ContactsDatasource';
import { getAircraftForUserId } from '../AircraftsDatasource';
import { CreateContactInput } from '../../API';
import { WithId } from '../LocalDB/types';

export const getUser = async (userId: string): Promise<User | null> => {
    const maybeContact = await getCrewableContact(userId);
    if (maybeContact) {
        return getUserFromContact(maybeContact, userId);
    }
    const maybeAircraft = await getAircraftForUserId(userId);
    if (maybeAircraft) {
        return {
            name: maybeAircraft.registration,
            userId,
            aircraftId: maybeAircraft.id,
        };
    }
    return null;
};

export const getUserIdForAircraft = async (aircraftId: string): Promise<string | undefined> => {
    const maybeAircraft = await getDb().aircrafts.get(aircraftId);
    if (maybeAircraft && maybeAircraft.userId) {
        return maybeAircraft.userId;
    }
    return undefined;
};

export const getUserFromContact = (
    maybeContact: CreateContactInput & WithId & WithCrewableUserFields,
    userId: string
): ContactUser => {
    let aircraftVisiblityLimitations = null;
    if (maybeContact.aircraftVisiblityLimitations) {
        try {
            aircraftVisiblityLimitations = JSON.parse(maybeContact.aircraftVisiblityLimitations);
        } catch (e) {
            console.error('Error parsing contact AC visibility limitations');
            console.error(e);
        }
    }

    return {
        name: maybeContact.name,
        contactId: maybeContact.id,
        userId: userId,
        crewId: maybeContact.crewId,
        hasDutyTracking: Boolean(maybeContact.hasDutyTracking),
        aircraftVisiblityLimitations,
        fullReadAccessToAircrafts: maybeContact.fullReadAccessToAircrafts || null,
        baseAirportIcaos: maybeContact.baseAirportIcaos || [],
    };
};
