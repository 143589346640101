import { DbSaveable } from '../LocalDB/types';
import { FlightLegPhase } from '../FlightLegs/FlightLeg';

export const DOCUMENT_TYPE_UNKNOWN = 'unknown';
export const DOCUMENT_TYPE_MFD_SCREEN = 'mfd';
export const DOCUMENT_TYPE_SQUAWK_RELEASE = 'squawk-release';
export const DOCUMENT_TYPE_SQUAWK_DEFERRAL = 'squawk-deferral';
export const DOCUMENT_TYPE_CRS = 'crs';
export const DOCUMENT_TYPE_WORK_REPORT = 'work-report';
export const DOCUMENT_TYPE_CAMO_ORDER = 'camo-order';
export type FlightDocumentType =
    typeof DOCUMENT_TYPE_UNKNOWN
    | typeof DOCUMENT_TYPE_MFD_SCREEN
    | typeof DOCUMENT_TYPE_SQUAWK_RELEASE
    | typeof DOCUMENT_TYPE_SQUAWK_DEFERRAL
    | typeof DOCUMENT_TYPE_CRS
    | typeof DOCUMENT_TYPE_WORK_REPORT
    | typeof DOCUMENT_TYPE_CAMO_ORDER;

export interface LocalFlightDocument extends DbSaveable {
    id: string;
    aircraftId: string;
    createdAt: Date;
    flightId?: string;
    legId?: string;
    squawkId?: string;
    deferralId?: string;
    releaseToServiceId?: string;
    maintenanceRecordId?: string;
    contentKey?: string;
    type: FlightDocumentType;
    phase?: FlightLegPhase;
    editableByUserIds: string[];
    title?: string | null;
}

export interface SavedFlightDocument extends LocalFlightDocument {
    contentKey: string;
}

export type FlightDocument = LocalFlightDocument | SavedFlightDocument;
export const isFlightDocumentSaved = (document: FlightDocument): document is SavedFlightDocument => {
    return document.contentKey !== undefined;
};


