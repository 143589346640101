import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';
import { CreateSignedDataInput } from '../../../API';
import { WithId } from '../types';

let counter = 0;

export const logbookRecordItemFields = `
    id
    contactId
    crewId
    editableByUserIds
    date
    deleted
    legId
    departurePlace
    departureTime
    arrivalPlace
    arrivalTime
    aircraftMake
    aircraftRegistration
    airplaneSeMeMcc
    totalFlightTime
    picName
    landingsTotal
    landingsNight
    nightTime
    ifrTime
    picTime
    copilotTime
    dualTime
    instructorTime
    isFstd
    remarks
    crossCountryTime
    instrumentActualTime
    instrumentSimulatedTime
    instrumentApproachPlace
    instrumentApproachType
    instrumentApproachCount
    instrumentApproachDetails
    holdings
    customData
    signature
    _version
`;

export const logbookRecordToDb: DbSyncerUpDefinition = (input: CreateSignedDataInput & WithId) => {
    const c = ++counter;
    const [commandName, inputType] = !input._version
        ? ['createLogbookRecord', 'CreateLogbookRecordInput']
        : ['updateLogbookRecord', 'UpdateLogbookRecordInput'];
    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, logbookRecordItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: i => i, // stored locally same as on remote
        queryName: 'getLogbookRecord',
    };
};
