import { CreateReleaseToServiceInput } from '../../../API';
import { DbSyncerUpDefinition, getStandardSyncerFields } from '../syncHelpers';
import { ReleaseToService, ReleaseToServiceType } from '../../ReleaseToService/types';
import { WithId } from '../types';

export const releaseToServiceItemFields = `
    id
    deleted
    editableByUserIds
    aircraftId
    squawkId
    deferralId
    maintenanceRecordId
    createdAt
    releaseType
    releasedByName
    releasedByLicenseNumber
    releasedBySignature
    releasedByOrganization
    text
    aircraftFlightTime
    _version
`;


let counter = 0;

export const releaseToServiceSyncerDefinition: DbSyncerUpDefinition = (releaseToService: ReleaseToService) => {
    const c = ++counter;
    const [commandName, inputType] = !releaseToService._version
        ? ['createReleaseToService', 'CreateReleaseToServiceInput']
        : ['updateReleaseToService', 'UpdateReleaseToServiceInput'];
    const input: CreateReleaseToServiceInput = releaseToServiceToDb(releaseToService);
    const {mutationName, inputKey, query} = getStandardSyncerFields(commandName, c, releaseToServiceItemFields);
    return {
        query,
        input,
        inputKey,
        inputType,
        mutationName,
        remoteToLocalTransform: releaseToServiceFromDb,
        queryName: 'getReleaseToService'
    };
};

export function releaseToServiceToDb(releaseToService: ReleaseToService): CreateReleaseToServiceInput & WithId {
    return {
        id: releaseToService.id,
        deleted: releaseToService.deleted,
        aircraftId: releaseToService.aircraftId,
        squawkId: releaseToService.squawkId,
        deferralId: releaseToService.deferralId || undefined,
        createdAt: releaseToService.createdAt.toISOString(),
        releaseType: releaseToService.releaseType,
        releasedByName: releaseToService.releasedByName,
        releasedByLicenseNumber: releaseToService.releasedByLicenseNumber,
        releasedByOrganization: releaseToService.releasedByOrganization,
        releasedBySignature: JSON.stringify(releaseToService.releasedBySignature),
        text: releaseToService.text,
        aircraftFlightTime: releaseToService.aircraftFlightTime,
        _version: releaseToService._version,
    };
}

export const releaseToServiceFromDb = (input: CreateReleaseToServiceInput): ReleaseToService => {
    return {
        id: input.id!,
        aircraftId: input.aircraftId,
        squawkId: input.squawkId || null,
        deferralId: input.deferralId || null,
        maintenanceRecordId: input.maintenanceRecordId || null,
        createdAt: new Date(input.createdAt!),
        releaseType: input.releaseType as ReleaseToServiceType,
        releasedByName: input.releasedByName || null,
        releasedByLicenseNumber: input.releasedByLicenseNumber || null,
        releasedBySignature: input.releasedBySignature ? JSON.parse(input.releasedBySignature) : null,
        releasedByOrganization: input.releasedByOrganization || null,
        text: input.text || null,
        aircraftFlightTime: input.aircraftFlightTime,
        _version: input._version!,
        deleted: input.deleted,
    }
};
