export const installationData = {
    name: 'Alpha Aviation',
    flightDetailOperatorLine:
        'Operator: Alpha Aviation s.r.o. | Hulkova 31, Prague 9, 19700 Czech Republic | ops@alpha-aviation.aero | +420 702 161 575',
};

export const camoData = {
    name: 'Alpha Aviation s.r.o.',
    approval: 'CZ.CAMO.0085',
    officialAddress: 'Na Tržišti 731\n39 470 Kamenice nad Lipou',
    vat: '28137370',
    contactAddress: 'Prague Letňany Airport (LKLT)\n197 00 Hůlkova 31, Praha 9',
    email: 'camo@alpha-aviation.aero',
    phone: '+420211150560',
    cam: 'Marek Babic',
};

export const flightBriefData = {
    opsPhone: '+420 211 150 520',
    opsEmail: 'ops@alpha-aviation.aero',
    salesPhone: '+420 211 150 510',
    salesEmail: 'sales@alpha-aviation.aero',
    invoicesEmail: 'invoices@alpha-aviation.aero',
};
