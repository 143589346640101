import React, { FunctionComponent } from "react";
import GenericField, { GenericFieldProps } from "./GenericField";

export interface InputTextFieldProps extends Omit<GenericFieldProps, 'control'> {
    type?: string;
    name?: string
}

const InputTextField: FunctionComponent<InputTextFieldProps> = (props) => {
    return <GenericField {...props}
                         control={(controlProps) => <input type={props.type || 'text'} {...controlProps} />}
                         controlModifiers={{'input': true, 'input-text': true, ...props.controlModifiers}}
    />
};

export default InputTextField;
