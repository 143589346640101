import React, { Dispatch, FunctionComponent, useEffect } from "react";
import { Hub } from "aws-amplify";
import { useDispatch } from "react-redux";
import {
    TLB_USER_SIGN_OUT,
    TLB_USER_STATE_PROCESSING,
    TlbUserActionTypes,
    useAnyTlbUserState
} from "../reducers/tlbUserReducer";
import { setPreferredEnvironment } from "../utils/BackendSwitcher";
import LoadingScreenOverlay from '../components/LoadingScreenOverlay';
import {
    COGNITO_USER_SIGN_OUT,
    COGNITO_USER_STATE_PROCESSING,
    CognitoUserActionTypes,
    useCognitoAnyUserState
} from '../reducers/cognitoUserReducer';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import { reauthenticate } from '../utils/Authentication/Reauthenticate';
import { getDb } from '../Datasource/LocalDB/LocalDB';
import { wipeDbSyncer } from '../Datasource/LocalDB/DbSyncer';

const WithAuthenticatedUser: FunctionComponent = ({children}) => {
    const cognitoUser = useCognitoAnyUserState();
    const tlbUser = useAnyTlbUserState();
    const dispatch = useDispatch<Dispatch<TlbUserActionTypes | CognitoUserActionTypes>>();
    useEffect(() => {
        reauthenticate(dispatch);
        const onAuth = async (e: any) => {
            if (e.payload.event) {
                switch (e.payload.event) {
                    case 'signOut':
                        await getDb().wipe();
                        wipeDbSyncer();
                        dispatch({type: TLB_USER_SIGN_OUT});
                        dispatch({type: COGNITO_USER_SIGN_OUT});
                        setPreferredEnvironment(undefined);
                        break;
                    case 'signIn':
                        reauthenticate(dispatch);
                        break;
                }
            }
        };
        Hub.listen('auth', onAuth);
        return () => {
            Hub.remove('auth', onAuth);
        }
    }, [dispatch]);

    if (cognitoUser === COGNITO_USER_STATE_PROCESSING || tlbUser === TLB_USER_STATE_PROCESSING) {
        return <LoadingScreenOverlay/>
    } else if ('object' === typeof cognitoUser && cognitoUser.groups) {
        return <>{children}</>;
    } else {
        return <LoginScreen/>
    }

};

export default WithAuthenticatedUser;
