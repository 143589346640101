export const contactItemFields = `
    id
    userId
    isActiveCrew
    aircraftVisiblityLimitations
    fullReadAccessToAircrafts
    crewId
    hasDutyTracking
    deleted
    nameOfficialFirst
    nameOfficialLast
    name
    isReservationResource
    isCustomer
    calendarId
    baseAirportIcaos
    dispatchGroup
    accessRights
`;
